import request from "@/utils/request";
import { isBlank } from "@/utils/whale";
import { getToken } from "../../utils/auth";
import { cleanObject } from "@/utils/deepClone";

// 获取我参与的分页
export function myRelatedList(queryParams) {
  return request({
    url: "/form/related/list",
    method: "get",
    params: queryParams,
  });
}

// 获取我创建的分页
export function myCreateList(queryParams) {
  return request({
    url: "/form/create/list",
    method: "get",
    params: queryParams,
  });
}

// 获取项目列表
export function projectList(queryType, queryParams) {
  return request({
    url: "/form/list/" + queryType,
    method: "get",
    params: queryParams,
  });
}

// 新建项目
export function project(param) {
  return request({
    url: "/project",
    method: "post",
    data: param,
  });
}

// 获取项目详情
export function projectDetail(projectId) {
  return request({
    url: "/project/" + projectId,
    method: "get",
  });
}

// 修改项目
export function editProject(param) {
  return request({
    url: "/project",
    method: "put",
    data: param,
  });
}

// 复制项目
export function copyProject(projectId) {
  return request({
    url: "/project/copy/" + projectId,
    method: "post",
  });
}

// 解散项目
export function dismissProject(projectId) {
  return request({
    url: "/project/dissolution/" + projectId,
    method: "delete",
  });
}

// 删除应用
export function deleteProject(projectId) {
  return request({
    url: "/project/delete/" + projectId,
    method: "post",
  });
}

// 移动表单 项目选择列表
export function projectSelectList() {
  return request({
    url: "/project/selectList",
    method: "get",
  });
}

// 应用统计
export function projectStatistics(projectId) {
  return request({
    url: "/project/statistics/" + projectId,
    method: "get",
  });
}

// 历史表单列表
export function historyFormList(currentFormId, searchName) {
  let pathUrl = "/form/historyFormList/" + currentFormId;
  if (!isBlank(searchName)) {
    pathUrl = pathUrl + "?searchName=" + searchName;
  }
  return request({
    url: pathUrl,
    method: "get",
  });
}

// 历史表单选项查找用户
export function historySelectUser(formId, select) {
  return request({
    url: "/user/historySelect?formId=" + formId + "&select=" + select,
    method: "get",
  });
}

// 获取允许填写的手机号
export function fillAuthAllowPhone(formId) {
  let pathUrl = "/form/fillAuthAllowPhone/" + formId;
  return request({
    url: pathUrl,
    method: "get",
  });
}

// 新建表单
export function form(param) {
  return request({
    url: "/form",
    method: "post",
    data: param,
  });
}

// 复制表单
export function copyForm(formId) {
  return request({
    url: "/form/copy/" + formId,
    method: "post",
  });
}

// 修改表单
export function editForm(param) {
  return request({
    url: "/form",
    method: "put",
    data: param,
  });
}

// 移动表单
export function moveForm(param) {
  return request({
    url: "/form/move",
    method: "post",
    data: param,
  });
}

// 删除表单
export function deleteForm(formId) {
  return request({
    url: "/form/delete/" + formId,
    method: "post",
  });
}

//获取表单详情
export function formDetail(formId) {
  return request({
    url: "/form/" + formId,
    method: "get",
  });
}

// 3.0.0.1 拆分获取表单字段
export function formFieldList(formId) {
  return request({
    url: "/form/getFormField?formId=" + formId,
    method: "get",
  });
}

// 3.0.0.1 拆分获取表单自定义字段
export function customFormFieldList(formId) {
  return request({
    url: "/form/getFormCustom?formId=" + formId,
    method: "get",
  });
}

// 3.0.0.1 拆分获取表单附件
export function formFilesList(formId) {
  return request({
    url: "/form/getFormFiles?formId=" + formId,
    method: "get",
  });
}

// 修改表单名称
export function formName(param) {
  return request({
    url: "/form/formName",
    method: "put",
    data: param,
  });
}

//删除表单
export function formDel(formId) {
  return request({
    url: "/form/" + formId,
    method: "delete",
  });
}

// 用户对于表单的权限信息
export function formAuth(formId) {
  return request({
    url: "/form/userFormAuth/" + formId,
    method: "get",
  });
}

// 表单填报 表单信息填报规则校验
export function checkFill(formId) {
  return request({
    url: "fill/rule/check/" + formId,
    method: "get",
  });
}

// 表单填报
export function fill(param) {
  return request({
    url: "/fill",
    method: "post",
    data: param,
    // data: crypto.encrypt(JSON.stringify(param))
  });
}

// 支付表单 表单填报
export function orderCreat(param) {
  return request({
    url: "/order/build/pc",
    method: "post",
    data: param,
  });
}

// 支付表单 支付状态查询
export function orderStatus(orderNo) {
  return request({
    url: "/order/payStatus?orderNo=" + orderNo,
    method: "get",
  });
}

// 修改支付金额
export function orderPriceEdit(param) {
  return request({
    url: "/fill/order-price",
    method: "put",
    data: param,
  });
}

// 修改支付状态
export function payStatusEdit(param) {
  return request({
    url: "/fill/pay-status",
    method: "put",
    data: param,
  });
}

// 获取表单设置支付信息
export function formPayInfo(formId) {
  return request({
    url: "/form/form-pay-info?formId=" + formId,
    method: "get",
  });
}

// 批量导入填报excel模版下载
export function fillDownloadExcel(formId) {
  return request({
    url: "/fill/fillExcelTemplate/" + formId,
    timeout: 0,
    method: "get",
    responseType: "blob",
  });
}

// 确认批量导入填报excel
export function formFillExcelImport(file, formId) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/fill/excelImport/" + formId,
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

// 数据填报列表管理员批量导入他人填报，模版下载
export function fillDownloadOthersExcel(formId) {
  return request({
    url: "/fill/others-fill-template/" + formId,
    timeout: 0,
    method: "get",
    responseType: "blob",
  });
}

// 数据填报列表，管理员确认批量导入他人填报
export function formFillExcelOtherImport(file, confirmStatus, formId) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/fill/others-excel-import/" + confirmStatus + "/" + formId,
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}


// 管理员 - excel批量更新
export function othersExcelImportUpdate(file, formId) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/fill/others-excel-import-update/" + formId,
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}


// 获取个人填报列表
export function fillList(formId, queryParams) {
  let path = "/fill/myList/" + formId;
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.queryStatus)) {
  //     path = path + '&queryStatus=' + queryParams.queryStatus
  //   }
  // } else {
  //   if (!isBlank(queryParams.queryStatus)) {
  //     path = path + '?queryStatus=' + queryParams.queryStatus
  //   }
  // }
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.fillStartTime)) {
  //     path = path + '&fillStartTime=' + queryParams.fillStartTime
  //   }
  // } else {
  //   if (!isBlank(queryParams.fillStartTime)) {
  //     path = path + '?fillStartTime=' + queryParams.fillStartTime
  //   }
  // }
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.fillEndTime)) {
  //     path = path + '&fillEndTime=' + queryParams.fillEndTime
  //   }
  // } else {
  //   if (!isBlank(queryParams.fillEndTime)) {
  //     path = path + '?fillEndTime=' + queryParams.fillEndTime
  //   }
  // }
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.formFieldId)) {
  //     path = path + '&formFieldId=' + queryParams.formFieldId
  //   }
  // } else {
  //   if (!isBlank(queryParams.formFieldId)) {
  //     path = path + '?formFieldId=' + queryParams.formFieldId
  //   }
  // }
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.fillValue)) {
  //     path = path + '&fillValue=' + queryParams.fillValue
  //   }
  // } else {
  //   if (!isBlank(queryParams.fillValue)) {
  //     path = path + '?fillValue=' + queryParams.fillValue
  //   }
  // }
  // if (path.indexOf('?') != '-1') {
  //   if (!isBlank(queryParams.searchText)) {
  //     path = path + '&searchText=' + queryParams.searchText
  //   }
  // } else {
  //   if (!isBlank(queryParams.searchText)) {
  //     path = path + '?searchText=' + queryParams.searchText
  //   }
  // }
  return request({
    url: path,
    method: "post",
    data: cleanObject(queryParams),
  });
}

// 获取个人填报列表-带表单字段查询(数据关联使用)
export function fillListSearch(formId, queryParams) {
  let path = "/fill/relation-field/" + formId;
  return request({
    url: path,
    method: "post",
    data: queryParams,
  });
}

// 新版3.0.0.1 是否允许个人导出模版 下载模板

// 获取历史填报列表
export function historyFillList(historyFormId, status, currentFormId) {
  let path = "";
  path =
    "fill/historyFillList/" +
    historyFormId +
    "?currentFormId=" +
    currentFormId +
    "&status=" +
    status;
  return request({
    url: path,
    method: "get",
  });
}

// 获取个人填报详情
export function fillDetail(fillId, formId) {
  return request({
    url: "/fill",
    params: {
      fillId,
      formId,
    },
    method: "get",
  });
}

// 表单填报信息修改
export function editFill(param) {
  return request({
    url: "/fill",
    method: "put",
    data: param,
  });
}

// 通过管理员批量导入的填报，需要填报人确认，表单填报信息确认
export function confirmFill(param) {
  return request({
    url: "/fill/confirm",
    method: "put",
    data: param,
  });
}

// 表单填报删除
export function fillDel(fillIds, formId) {
  return request({
    url: "/fill",
    method: "delete",
    params: {
      fillIds,
      formId,
    },
  });
}

// 表单填报批量删除
export function fillBatchDel(formId, fillIds, type, fillCacheKey, excludeFillIds) {
  return new Promise(async (resolve, reject) => {
    if (type == 2) {
      const isKeyValid = await isBatchSelectCacheKeyExpired(fillCacheKey);

      if (!isKeyValid) {
        reject("删除标识已过期，请刷新页面");
        return;
      }
    }

    const data = {
      fillIds: fillIds,
      formId: formId,
      type: type,
      fillIdCacheKey: fillCacheKey,
      excludeFillIds:
        Array.isArray(excludeFillIds)? excludeFillIds.join(","):
        excludeFillIds
    };

    request({
      url: "/data/delete",
      method: "delete",
      data: data,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  // return request({
  //   url: '/data/delete',
  //   method: 'delete',
  //   data: {
  //     fillIds:fillIds,
  //     formId:formId,
  //     type:type,
  //     fillCacheKey
  //   }
  // })
}

// 查询指定key是否过期,等于0表示已过期,>0表示未过期,-2表示key不存在
export function isBatchSelectCacheKeyExpired(fillCacheKey) {
  return new Promise((resolve, reject) => {
    request({
      url: "/data/isExpire/" + fillCacheKey,
      method: "GET",
    }).then((result) => {
      console.log("isBatchSelectCacheKeyExpired", result);
      resolve(result.data > 0);
    });
  });
}

// 获取表单数据详情
export function fillDataDetail(formId, queryParams) {
  // console.log(queryParams)
  let path = "/data/list/" + formId;
  // if (!isBlank(queryParams.queryStatus)) {
  //   path = path + '&queryStatus=' + queryParams.queryStatus
  // }
  // if (!isBlank(queryParams.fillStartTime)) {
  //   path = path + '&fillStartTime=' + queryParams.fillStartTime
  // }
  // if (!isBlank(queryParams.fillEndTime)) {
  //   path = path + '&fillEndTime=' + queryParams.fillEndTime
  // }
  // if (!isBlank(queryParams.formFieldId)) {
  //   path = path + '&formFieldId=' + queryParams.formFieldId
  // }
  // if (!isBlank(queryParams.fillValue)) {
  //   path = path + '&fillValue=' + queryParams.fillValue
  // }
  // if (!isBlank(queryParams.searchText)) {
  //   path = path + '&searchText=' + queryParams.searchText
  // }
  return request({
    url: path,
    method: "post",
    data: cleanObject(queryParams),
  });
}

// 获取表单数据详情(用于选择)
export function selectFillDataDetail(formId, queryParams) {
  // console.log(queryParams)
  let path = "";
  path =
    "/form/listForPicSelect/" +
    formId +
    "?pageNum=" +
    queryParams.pageNum +
    "&pageSize=" +
    queryParams.pageSize;
  if (!isBlank(queryParams.queryStatus)) {
    path = path + "&queryStatus=" + queryParams.queryStatus;
  }
  if (!isBlank(queryParams.fillStartTime)) {
    path = path + "&fillStartTime=" + queryParams.fillStartTime;
  }
  if (!isBlank(queryParams.fillEndTime)) {
    path = path + "&fillEndTime=" + queryParams.fillEndTime;
  }
  if (!isBlank(queryParams.formFieldId)) {
    path = path + "&formFieldId=" + queryParams.formFieldId;
  }
  if (!isBlank(queryParams.fillValue)) {
    path = path + "&fillValue=" + queryParams.fillValue;
  }
  return request({
    url: path,
    method: "get",
  });
}

// 数据查看列表，是否显示填报状态列
export function dataFillStatus(formId) {
  return request({
    url: "/form/show-fill-status/" + formId,
    method: "get",
  });
}

// 表单ID查询表单字段
export function fieldList(formId) {
  return request({
    url: "/form/getFormField?formId=" + formId,
    method: "get",
  });
}

// 表单数据EXCEL表格下载 2021-11-02
// export function fillDataDownload(showImg, formId) {
//   return request({
//     url: '/data/downloadExcel/' + showImg + '/' + formId,
//     timeout: 0,
//     method: 'get',
//     responseType: 'blob'
//   })
// }
export function fillDataDownload(exportDTO) {
  return request({
    url: "/data/excelExport",
    timeout: 0,
    method: "post",
    data: exportDTO,
    responseType: "blob",
  });
}

// EXCEL带附件生成下载请求
export function fillDataZipDownHttp(exportDTO) {
  return request({
    url: "/dataDownload/zipDown/",
    timeout: 0,
    data: exportDTO,
    method: "post",
  });
}

// EXCEL带附件下载列表
export function fillDataZipDownList(formId) {
  return request({
    url: "/dataDownload/zipDownList/" + formId,
    timeout: 0,
    method: "get",
  });
}

// EXCEL带附件zip下载
export function fillDataDownloadZip(zipDownId) {
  return request({
    url: "/dataDownload/downloadZip/" + zipDownId,
    timeout: 0,
    method: "get",
    responseType: "blob",
  });
}

// 导出带图片的EXCEL
// export function fillDataDownloadFormPicDownload(exportDTO) {
//   return request({
//     url: "/dataDownload/formPicDown/",
//     method: "post",
//     data: exportDTO,
//   });
// }

// 获取导出带图片的EXCEL列表
export function fillDataDownloadFormPicDownList(formId) {
  return request({
    url: `/dataDownload/formPicDownList/${formId}`,
    method: "get",
  });
}

// 获取word导出下载列表
export function fillDataDownloadFormWordDownList(formId) {
  return request({
    url: `/dataDownload/exportWordDownList/${formId}`,
    method: "get"
  });
}

// 批量导出Word文档
export function fillDataDownloadFormWordDownload(exportDTO) {
  return request({
    url: "/dataDownload/wordDown/",
    method: "post",
    data: exportDTO,
  });
}

// 分页获取审核数据列表
export function reviewList(formId, queryParams) {
  let path = "";
  path = "/review/list/" + formId; // + '?pageNum=' + queryParams.pageNum + '&pageSize=' + queryParams.pageSize
  // if (!isBlank(queryParams.fillValue)) {
  //   path = path + '&fillValue=' + queryParams.fillValue
  // }
  // if (!isBlank(queryParams.formFieldId)) {
  //   path = path + '&formFieldId=' + queryParams.formFieldId
  // }
  // if (!isBlank(queryParams.fillStartTime)) {
  //   path = path + '&fillStartTime=' + queryParams.fillStartTime
  // }
  // if (!isBlank(queryParams.fillEndTime)) {
  //   path = path + '&fillEndTime=' + queryParams.fillEndTime
  // }
  // if (!isBlank(queryParams.queryStatus)) {
  //   path = path + '&queryStatus=' + queryParams.queryStatus
  // }
  // if (!isBlank(queryParams.searchText)) {
  //   path = path + '&searchText=' + queryParams.searchText
  // }

  return request({
    url: path,
    method: "post",
    data: cleanObject(queryParams),
  });
}

// 审核填报数据
export function review(param) {
  return request({
    url: "/review",
    method: "post",
    data: param,
  });
}

// 审核填报数据
export function auditFillUpdate(param) {
  return request({
    url: "/review/auditFillUpdate",
    method: "post",
    data: param,
  });
}

// 一键审核填报数据
export function batchReviewFill(param) {
  return new Promise(async (resolve, reject) => {
    if (param.fillIdCacheKey) {
      const isKeyValid = await isBatchSelectCacheKeyExpired(
        param.fillIdCacheKey
      );

      if (!isKeyValid) {
        reject("批量选择标识已过期，请刷新页面");
        return;
      }
    }

    request({
      url: "/review/batchReviewFill",
      method: "post",
      data: param,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 填报列表的审核结果
export function fillReviewList(fillId) {
  return request({
    url: "/review/fill/" + fillId,
    method: "get",
  });
}

// 修改项目分享url
export function setProjectShareUrl(param) {
  return request({
    url: "/project/shareUrl",
    method: "post",
    data: param,
  });
}

export function existUnReview(formId) {
  return request({
    url: "/form/existUnReview/" + formId,
    method: "get",
  });
}

// 修改表单分享url
export function setFormShareUrl(param) {
  return request({
    url: "/form/shareUrl",
    method: "post",
    data: param,
  });
}

// 新建页面
export function formPage(param) {
  return request({
    url: "/form/page",
    method: "post",
    data: param,
  });
}

// 修改页面
export function editPage(param) {
  return request({
    url: "/form/page",
    method: "put",
    data: param,
  });
}

// 表单拖拽排序
export function formSort(param) {

  if(!param.formIds || param.formIds.length==0 ) return;
  return request({
    url: "/form/sort",
    method: "put",
    data: param,
  });
}

// OSS删除文件信息
export function aliDelete(fileUrl) {
  return request({
    url: "/ali/delete?fileUrl=" + fileUrl,
    method: "post",
  });
}

// 新版---新建表单,保存基本信息
export function httpFormBase(param) {
  return request({
    url: "/form/saveFormBase",
    method: "post",
    data: param,
  });
}

// 新版---新建表单,保存表单字段设计
export function httpFormField(param) {
  return request({
    url: "/form/saveFormField",
    method: "post",
    data: param,
  });
}

// 新版---新建表单,保存表单提交设置
export function httpFormSubmit(param) {
  return request({
    url: "/form/saveFormSubmit",
    method: "post",
    data: param,
  });
}

// 新版---新建表单,保存表单支付设置
export function httpFormPay(param) {
  return request({
    url: "/form/saveFormPay",
    method: "post",
    data: param,
  });
}

// 新版---新建表单,保存表单流程设置
export function httpFormFlow(param) {
  return request({
    url: "/form/saveFormFlow",
    method: "post",
    data: param,
  });
}

// 新版---发布表单
export function httpFormRelease(param) {
  return request({
    url: "/form/release",
    method: "post",
    data: param,
  });
}

//  新版---表单统计
export function formStatics(formId) {
  return request({
    url: "/form/statistics/" + formId,
    method: "get",
  });
}
//  新版---表单字段统计
export function formFieldStatics(formId) {
  return request({
    url: "/form/field-statistics/" + formId,
    method: "get",
  });
}
//  新版---表单字段统计
export function formPicSelFieldStatics(formId) {
  return request({
    // url: '/form/field-statistics/' + formId,
    url: `/data/picSelectStatistics/${formId}`,
    method: "POST",
  });
}
// 新版---添加分享量
export function httpShareNum(formId) {
  return request({
    url: "/form/shareNum/" + formId,
    method: "post",
  });
}

// 新版---添加浏览量
export function httpViewNum(formId) {
  return request({
    url: "/form/viewNum/" + formId,
    method: "post",
  });
}
// 新版 3.0.0.1---保存填报人设置
export function httpFormFillAuth(param) {
  return request({
    url: "/form/saveFormFillAuth",
    method: "post",
    data: param,
  });
}

// 新版 3.0.0.1---保存审核人设置
export function httpFormAuditAuth(param) {
  return request({
    url: "/form/saveFormAuditAuth",
    method: "post",
    data: param,
  });
}

// 新版 3.0.0.1---保存表单查看人设置
export function httpFormViewAuth(param) {
  return request({
    url: "/form/saveFormViewAuth",
    method: "post",
    data: param,
  });
}

// 新版 3.0.0.1---保存表单查看人设置
export function httpFormAdminAuth(param) {
  return request({
    url: "/form/saveFormManager",
    method: "post",
    data: param,
  });
}

// 新版 3.0.0.1---保存表单导出设置
export function httpFormExport(param, exportFile, exportNameFormat) {
  let formData = new FormData();
  let url = "/form/saveFormExport"

  formData.append("exportIsPerson", param.exportIsPerson)
  formData.append("formId", param.formId)
  formData.append("exportIsPdf", param.exportIsPdf)
  formData.append("waterMark", param.waterMark)
  formData.append("exportAfterAudit", param.exportAfterAudit)

  if (exportFile) {
    formData.append("file", exportFile);
  }

  if(exportNameFormat && exportNameFormat.length > 0){
    formData.append("exportName",  JSON.stringify(exportNameFormat));
  }
  if (param.exportTemplate) {
    formData.append("exportTemplate", param.exportTemplate);
  }

  if (!isBlank(param.projectId)) {
    formData.append("projectId", param.projectId)
  }
  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

// 表单其他设置的提交
export function httpFormOtherSetting(param) {
  return request({
    url: "/form/saveFormOtherSetup",
    method: "post",
    data: param,
   });
}

// 新版 3.0.0.1---保存提交设置
export function httpFormTipSave(param) {
  return request({
    url: "/form/saveFormTip",
    method: "post",
    data: param,
  });
}

export function getCustomTip(formId) {
  return request({
    url: `/form/form-tip-info/${formId}`,
    method: "post",
  });
}

// 保存协议信息
export function saveFormAgreement(param) {
  return request({
    url: `/form/save-form-agreement`,
    method: "post",
    data: param,
  });
}

// 获取协议信息
export function formAgreementInfo(formAgreementId) {
  return request({
    url: `/form/form-agreement-info/${formAgreementId}`,
    method: "post",
  });
}

// 新版 3.0.0.1---填报导出word文件
export function exportFillWord(formId, fillId) {
  return request({
    url: "/export/word/" + formId + "/" + fillId + "?token=" + getToken(),
    method: "get",
    responseType: "blob",
  });
}

// 新版 3.0.0.1---填报导出pdf文件
export function exportFillPdf(formId, fillId) {
  return request({
    url: "/export/pdf/" + formId + "/" + fillId + "?token=" + getToken(),
    method: "get",
    responseType: "blob",
  });
}

// 新版 3.0.0.1---获取表单填报人详细信息
export function formFillAuthDetail(formId) {
  return request({
    url: "/form/getFormFillAuth?formId=" + formId,
    method: "get",
  });
}

// 新版 3.0.0.1---获取表单查看人详细信息
export function formViewAuthDetail(formId) {
  return request({
    url: "/form/getFormViewAuth?formId=" + formId,
    method: "get",
  });
}

// 新版 3.0.0.1---获取表单审核人详细信息
export function formAuditAuthDetail(formId) {
  return request({
    url: "/form/getFormAuditAuth?formId=" + formId,
    method: "get",
  });
}

// 新版 3.0.0.1---获取表单审核人详细信息
export function getFormFieldAuth(param) {
  return request({
    url: "/form/getFormFieldAuth",
    method: "post",
    data: param
  });
}

// 新版 3.0.0.1---获取表单审核人字段权限详细信息
export function formAuditAuthFieldDetail(formId) {
  return request({
    url: "/form/formFieldAuth/" + formId,
    method: "get",
  });
}

// 新版---生成表单、项目分享图片
export function shareImg(id, type) {
  return request({
    url: "/image/" + type + "/" + id,
    method: "get",
  });
}

// 新版---获取表单预约信息
export function formReserves(formId) {
  return request({
    url: "/form/getFormReserves?formId=" + formId,
    method: "get",
  });
}

// 新版---获取预约室时间段信息
export function reserveTimes(reserveId) {
  return request({
    url: "/reserve/getReserveTimes?reserveId=" + reserveId,
    method: "get",
  });
}

// 新版---填报获取预约室时间段信息
export function fillReserveTimes(reserveId, reserveDate) {
  return request({
    url:
      "/reserve/getFillReserveTimes?reserveId=" +
      reserveId +
      "&reserveDate=" +
      reserveDate,
    method: "get",
  });
}

// 新版---通知发送
export function noticeSend(sendMpNoticeDTO) {
  return request({
    url: "/mp-notice/send-notice",
    method: "post",
    data: JSON.stringify(sendMpNoticeDTO),
  });
}

// 新版---通知发送
export function setFormIsStop(formId, isStop) {
  return request({
    url: `/form/setFormIsStop`,
    method: "post",
    data: JSON.stringify({
      formId: formId,
      isStop: isStop,
    }),
  });
}

// 读取表单标签列表
export function getTagListByUserId(formId, isStop) {
  return request({
    url: `/form/getTagListByUserId`,
    method: "post",
    data: JSON.stringify({}),
  });
}

// 读取表单标签列表
export function getFillLogList(fillId, formId) {
  return request({
    url: "/fill/log/list/",
    method: "get",
    params: {
      fillId,
      formId,
    },
  });
}

//
export function aliUpload(file, folder, belongId) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/ali/upload?folder=" + folder + "&belongId=" + belongId,
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

export function deleleteFile(fileId) {
  return request({
    url: "/oss/file/" + fileId,
    method: "delete",
  });
}

//分享链接
export function shortLink(originalLink) {
  return request({
    url: "/short-link/generate",
    method: "post",
    data: { originalLink },
  });
}

//----------------------------------------------------------------------
// 新表单设计器的---新建表单,保存表单字段设计
export function httpSaveFormFieldV2(param) {
  return request({
    url: "/form-field/v2",
    method: "post",
    data: param,
  });
}

//新表单设计器的---新建表单,读取表单字段设计
export function httpGetFormFieldV2(formId) {
  return request({
    url: "/form-field/v2/" + formId,
    method: "get",
  });
}

//新表单设计器的---新建表单,读取表单字段设计
export function getFormFieldTree(formId) {
  return request({
    url: "/form-field/v2/fieldTree/" + formId,
    method: "get",
  });
}

//已填报人员列表
export function fillUserList(formId, pageNum, pageSize) {
  return request({
    url: `form/fill-user-list/${formId}?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: "get",
  });
}

//未已填报人员列表
export function unfillUserList(formId, pageNum, pageSize) {
  return request({
    url: `form/no-fill-user-list/${formId}?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: "get",
  });
}

//表单填报信息批量修改
export function batchUpdate(formId, fillIds, type, fillJson, fillCacheKey,excludeFillIds, label, fieldType,labelJson,fieldTypeJson ) {
  return new Promise(async (resolve, reject) => {
    if (type == 1) {
      const isKeyValid = await isBatchSelectCacheKeyExpired(fillCacheKey);

      if (!isKeyValid) {
        reject("批量修改标识已过期，请刷新页面");
        return;
      }
    }

    const data = {
      fillIds: fillIds,
      formId: formId,
      fillJson: fillJson,
      type: type,
      fillIdCacheKey: fillCacheKey,
      excludeFillIds:
        Array.isArray(excludeFillIds)? excludeFillIds.join(","):
        excludeFillIds,
      label,
      fieldType,
      labelJson,
      fieldTypeJson
    };

    request({
      url: `fill/batchUpdate`,
      method: "put",
      data: data,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

///---------------表单分组
//创建表单分组
export function saveFormGroup(data) {
  return request({
    url: `form/saveFormGroup`,
    method: "post",
    data: data,
  });
}

//删除表单分组
export function removeFormGroup(groupId) {
  return request({
    url: `form/removeFormGroup/${groupId}`,
    method: "delete",
  });
}

//读取表单分组
export function projectGroup(projectId) {
  return request({
    url: `project/group/${projectId}`,
    method: "get",
  });
}

//移动表单到分组
export function moveFormToGroup(formId, groupId) {
  return request({
    url: `form/moveFormGroup/${formId}/to/${groupId}`,
    method: "post",
  });
}

//移出表单分组
export function moveFormOutGroup(formId) {
  return request({
    url: `form/outFormGroup/${formId}`,
    method: "delete",
  });
}

//更改表单图标
export function editFormIcon(formId, formIcon) {
  return request({
    url: `form/icon/${formId}?icon=${formIcon}`,
    method: "put",
  });
}

//更改表单图标
export function updateFormGroup(data) {
  return request({
    url: `form/updateFormGroup/`,
    method: "post",
    data: data,
  });
}

//新增数据链接分享
export function saveDataShare(data) {
  return request({
    url: `form/share/`,
    method: "post",
    data: data,
  });
}

//查询分享列表
export function getDataShareList() {
  return request({
    url: `form/share/list`,
    method: "get",
  });
}

//获取数据链接分享是否需要输入密码
export function getDataShareRequiredPassword(id) {
  return request({
    url: `form/share/requiredPassword/${id}`,
    method: "get",
  });
}

//删除数据分享
export function deleteDataShare(ids) {
  return request({
    url: `form/share/${ids}`,
    method: "delete",
  });
}

//获取分享详情
export function getDataShare(id) {
  return request({
    url: `form/share/${id}`,
    method: "get",
  });
}

//验证输入的密码是否匹配
export function dataShareCheckPassword(id, password) {
  return request({
    url: `form/share/checkPassword/${id}?password=${password}`,
    method: "GET",
  });
}

//获取机构首页的表单列表
export function agencyHomeList(param) {
  const queryString = Object.keys(param || {})
    .filter((key) => !!param[key])
    .map((key) => {
      return `${key}=${param[key]}`;
    })
    .join("&");
  return request({
    url: `system/agencyHome/list?${queryString}`,
    method: "GET",
  });
}

// 设置表单到机构首页
export function setFormAgencyHome(data) {
  return request({
    url: `system/agencyHome/place`,
    method: "POST",
    data: data,
  });
}

export function saveFormAutocodeRule(data){
  return request({
    url: `form/saveFormAutocodeRule?formId=${data.formId}&autoCodeRule=${encodeURIComponent(data.autoCodeRule)}`,
    method: "POST",
    data:{
      formId:data.formId,
      autoCodeRule:data.autoCodeRule,
      autocodeStatus: data.autocodeStatus
    }
  })
}

export function getExportFileName(url){
  return request({
    url: `oss/file/fileUrl?fileUrl=${encodeURIComponent(url)}`,
    method: "GET"
  })
}

export function getWaterMarkFile(data){
  return request({
    url: `export/pdf/watermark`,
    method: "POST",
    data
  })
}

export function getFormFills(formId,fillIds){
  return request({
    url: `fill/fillIds`,
    method: "POST",
    data:{formId,fillIds}
  })
}

export function relativeAdd(addData){
  // let addData = {
  //   linkField: linkField, // 关联数据字段id fill-select 指向的字段
  //   linkFillid: redirectLinkFillId || sourceFillId, // 关联数据的fillId fill-select选出的数据
  //   linkFormId: sourceFormId, // 关联数据的formId fill-select指向的表单
  //   relativeId: this.field.id,
  //   referenceField: linkField, //引用字段id fill-select 指向的字段
  //   sourceFillids: this.getFillId?this.getFillId():'', // 编辑当前数据的id
  //   sourceFormId: this.getFormId?this.getFormId():'',
  // };

  return request({
    url: `relative/add`,
    method: "POST",
    data: addData
  })
}

export function relativeDel(delData){
  // let delData = {
  //   linkFillid: redirectLinkFillId || sourceFillId, // 关联数据的fillId fill-select选出的数据
  //   linkFormId: sourceFormId, // 关联数据的formId fill-select指向的表单
  //   relativeId: this.field.id,
  //   sourceFillid: this.getFillId ? this.getFillId() : '',
  //   sourceFormId: this.getFormId ? this.getFormId() : '',
  // };

  return request({
    url: `relative/removeFillId`,
    method: "POST",
    data: delData
  })
}


//读取表单分组
export function agGridTest() {
  return request({
    url: `export/agGrid/test`,
    method: "get",
  });
}

/**
 * 表单模板导入
 * @returns
 */
export function createFormByTemplate(file, agencyId){
  const formData = new FormData();
  formData.append('file', file);
  formData.append('agencyId', agencyId);
  return request({
    url: '/form/createByTemplate',
    method: "POST",
    data: formData,
    headers: {
      'Content-Type':'multipart/form-data'
    }
  })
}

/**
 * 应用模板导入
 * @returns
 */
export function createProjectByTemplate(file, agencyId){
  const formData = new FormData();
  formData.append('file', file);
  formData.append('agencyId', agencyId);
  return request({
    url: '/project/createByTemplate',
    method: "POST",
    data: formData,
    headers: {
      'Content-Type':'multipart/form-data'
    }
  })
}

//生成二维码
export function generateCode(params) {
  return request({
    url: `qrcode/generate-qrcode`,
    method: "get",
    params
  });
}
export function exportFormLogs(formId) {
  return request({
    url: `form/formLog/download/${formId}`,
    method: "get",
    responseType: "blob",
  });
}

/**
 * 更新填充用户信息
 * @param {Object} options - 包含以下属性的对象
 * @param {string} options.agencyUserId - 机构用户ID
 * @param {string} options.fillId - 填充ID
 * @param {string} options.formId - 表单ID
 * @param {string} options.number - 数字
 * @param {string} options.userId - 用户ID
 * @param {string} options.userName - 用户名
 */
export function updateFillUser({agencyUserId, fillId, formId, number, userId, userName}) {
  return request({
    url: `fill/updateFillUser`,
    method: "PUT",
    data: {
      agencyUserId,
      fillId,
      formId,
      number,
      userId,
      userName,
    },
  });
}



/**
 * 获取审核可退回列表
 *
 * @param formId 表单ID
 * @param fillId 填写ID
 * @returns 返回请求结果
 */
export function auditBackList(data) {
  return request({
    url: `review/audit/reviewBackList`,
    method: "post",
    data
  });
}


/**
 * 审核退回
 *
 * @param formId 表单ID
 * @param fillId 填写ID
 * @returns 返回请求结果
 */
export function auditBack(data) {
  return request({
    url: `review/auditBack`,
    method: "post",
    data
  });
}

export function auditLog(formId,fillId){
  return request({
    url: `review/auditLog/list`,
    method: "get",
    params: {formId,fillId}
  });
}