<template>
  <div class="views-tabs block-container">
    <el-tabs
      v-model="viewTabName"
      @tab-click="handleViewClick"
      v-if="formDetail.enableView"
    >
      <el-tab-pane :name="'viewInfo'">
        <span slot="label"> 概览 </span>
      </el-tab-pane>
      <el-tab-pane
        :name="'viewId:' + index"
        v-for="(item, index) in viewList"
        :key="index"
      >
        <span slot="label">
          <span>{{ item.name }}</span>
        </span>
      </el-tab-pane>
    </el-tabs>
    <div style="height:15px;" v-else></div>
  </div>
</template>
<script>
import { deepClone } from "@/utils/deepClone.js";
import {
  unLoginListViews,
  unlLoginFillDetail,
  unLoginViewData,
  viewDetail,
} from "@/api/system/views.js";
export default {
  props: {
    formDetail: { type: Object, default: () => {} },
    formId: { type: String, default: "" },
  },
  data() {
    return {
      viewList: [],
      viewTabName: "viewInfo",

    };
  },
  mounted() {
    this.getViewList();
  },
  methods: {
    handleViewClick() {
      this.showDetailType = -1;
      if (this.viewTabName.indexOf("viewId:") >= 0) {
        let viewIndex = Number(this.viewTabName.split(":")[1]);
        let viewInfo = this.viewList[viewIndex];
        this.currentViewId = viewInfo.id;
        // 加载视图
      } else {
        this.currentViewId = "";
      }
      this.$emit("viewChange",this.viewTabName, this.currentViewId)
    },

    // 读取视图列表
    getViewList() {
      return new Promise((resolve, reject) => {
        unLoginListViews(this.formId)
          .then((res) => {
            if (res.code === 200) {
              this.viewList = res.data;
            }
            resolve();
          })
          .catch((e) => {
            console.error("error", e);
            reject();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.views-tabs {
  padding: 8px 10px;
  padding-bottom: 0px;
  background-color: #fff;
  .el-tabs {
    &.fullWidth {
      margin-right: 0px;
    }
  }
  .el-dropdown {
    margin-left: 0px;
    margin-right: 5px;
    float: initial;
  }
  .el-icon-arrow-down {
    padding: 15px 0px;
  }

  .view-add {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}
</style>
