<template>
  <iframe v-if="screenItem" :src="screenItem.url" id="myiframe" scrolling="no" frameborder="0" width="100%" height="100%" style="position:absolute;top:0px;bottom:0px;left:0px;right:0px;"></iframe>
  <div v-else>
    未找到配置数据
  </div>
</template>
<script>
import { largeScreenList } from '@/api/system/largeScreen'
export default ({
  data() {
    return {
      formId: "",
      screenId: 0,
      screenItem: null
    }
  },
  created() {
    this.formId = this.$route.params.formId;
    this.screenId = this.$route.params.screenId;

    this.getScreenItem();
  },
  methods: {
    getScreenItem() {
      largeScreenList(this.formId).then(res => {
        let list = res.data;
        this.screenItem = list.find(x => x.id == this.screenId);

        document.title = this.screenItem.name;
      })
    }
  }
})
</script>
<style lang="sass" scoped>
</style>