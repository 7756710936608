import {
  checkFill,
  editFill,
  fill,
  getFillLogList,
  deleleteFile,
  getCustomTip,
  orderCreat,
} from "@/api/system/form";
import {
  beforeBackConfirm,
  showPicturePreview,
  changedValue,
} from "@/utils/formUtil";

import { aliUploader } from "@/utils/oss";
import { getSessionStorageObj } from "@/utils/db";
import { httpGetFormFieldV2, fillDetail } from "@/api/system/form";
import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
import { deepClone } from "../../../../../utils";

export default {
  data() {
    return {
      isShow: false,
      isShowInside: false,
      showVForm: false,
      isEdit: false,
      isView: true,
      fillId: "",
      formDetail: {},
      formJson: {},
      formData: {},
      logs: [],
      title: "",
      allowSave: true,
      isShowAuditButton: false,
      auditButtonCallback: null,
      customTip: {},
      fillSuccessShow: false,
    };
  },
  created() {
    const sessionStorageUser = getSessionStorageObj("users");
    const userToken = this.cookie.get("User-Token");
    this.optionData = {
      userToken: userToken,
      apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
      formId: this.formId,
      fillId: this.fillId,
    };
    this.optionData.formDataSelectHandler = this.$parent.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler =
      this.$parent.showDataAutoSelect;
    this.optionData.isUserAdmin = getSessionStorageObj("users").type > 1;

    // 打开嵌套表单
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formPicturePreviewHandler = showPicturePreview;
    this.optionData.userSelectHandler = this.showUserSelect;
    this.optionData.formCreateUser = sessionStorageUser;
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;

    this.optionData.formDataCardRender = this.dataCardRender();
  },
  computed: {
    formAgencyId() {
      return this.formDetail.agencyId;
    },
  },
  methods: {
    show(
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets /* 审核列表和管理列表显示隐藏字段 */,
      showFields /* 控制显示的字段（批量编辑等） */,
      enableReadonly /* 强制启用只读字段（管理列表的编辑模式）*/,
      agencyId,
      formDetail
    ) {
      this.title = "表单详情";
      this.isView = false;
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        edit: true,
        enableForm: "enableForm",
        showFields,
        enableReadonly,
        agencyId,
        formDetail,
      });
    },
    // showAdd( formId, formJson, showHiddenWidgets, showFields, agencyId ) {
    showAdd(params) {
      this.title = params.title || "表单填报";
      this.isView = false;
      this._show(params);
      // this.checkDraft();
      this.getCustomTip();
    },
    showView(
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets,
      showFields,
      enableReadonly,
      agencyId,
      formDetail
    ) {
      this.title = "表单详情";
      this.isView = true;
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        showFields: false,
        edit: true,
        enableForm: "disableForm",
        showFields,
        enableReadonly,
        agencyId,
        formDetail,
      });
    },
    showViewReadonly(
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets,
      showFields,
      enableReadonly,
      agencyId,
      formDetail
    ) {
      this.title = "表单详情";
      this.isView = true;
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        showFields: false,
        edit: false,
        enableForm: "disableForm",
        showFields,
        enableReadonly,
        agencyId,
        formDetail,
      });
    },
    onCloseFillSuccessPop() {
      this.fillSuccessShow = false;
    },
    // showFields, // 指定显示的字段
    async _show({
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets,
      edit,
      enableForm,
      showFields,
      enableReadonly = false,
      agencyId,
      formDetail,
      isAdd = false,
    }) {
      const _this = this;
      const sessionStorageUser = getSessionStorageObj("users");
      // this.optionData.aliOSSUpload =aliOSSUpload(true,{
      //   formId:formId||'formId',
      //   fillId:fillId||'fillId',
      //   agencyId: 'agencyId',
      //   userId:sessionStorageUser.userId||'userId',
      // })

      var dt = new Date();
      const showTime = function () {
        var dt1 = new Date();
        var cost = (dt1 - dt).valueOf();
        dt = dt1;
        return cost;
      };

      _this.isShowHiddenWidgets = showHiddenWidgets;
      _this.formJson = formJson;
      _this.formData = formData;
      _this.formDetail = formDetail;
      _this.formId = formId;
      _this.fillId = fillId;
      _this.agencyId = agencyId;
      // 编辑模式还是新增模式
      _this.isEdit = !!edit;
      _this.isShow = true;

      this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
      this.optionData.loadAgencyUserListHandler = agencyUserList;
      this.optionData.loadAgencyUserHandler = getAgencyUser;
      this.optionData.aliOSSUpload = aliUploader;
      this.optionData.formAgencyId = this.formAgencyId;

      this.optionData.aliOSSUploadConfig = {
        // OK
        formId: formId || "formId",
        fillId: fillId || "fillId",
        agencyId: agencyId,
        userId: sessionStorageUser.userId || "userId",
        uploadPath: "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/",
      };
      this.optionData.fileDelete = this.fileDelete;

      // 如果指定了显示哪些字段，挑选出来，否则显示全部字段。（图片选择的查看详情功能）
      // console.log("showFields", showFields);
      if (showFields) {
        const showNames = showFields.map((x) => x.name);
        const newFormWidgetList = [];
        const i = 0;
        for (let i = 0; i < formJson.widgetList.length; i++) {
          if (showNames.indexOf(formJson.widgetList[i].id) >= 0) {
            newFormWidgetList.push(formJson.widgetList[i]);
          }
        }
        formJson.widgetList = newFormWidgetList;
      }

      this.showVForm = true;

      this.$nextTick(() => {
        this.$refs.vformRender.clearFormDataModel()

        this.$refs.vformRender.setFormJson(formJson, () => {
          // 必须确保setFormJson完成后再设置数据，这样才不会影响设置了逻辑显示的字段
          // 提示: setFormJson里含有nextTick异步操作
          this.$refs.vformRender.setFormData(
            formData,
            this.formId,
            this.fillId
          )
          setTimeout(() => {
            // 如果启动了启用所有字段可编辑（管理列表中）
            if (enableReadonly) {
              // 启用表单所有字段可编辑，包括只读字段
              this.$refs.vformRender['enableForm'](this.optionData.isUserAdmin)
            }
            // else {
            //   // 正常的启用表单可编辑字段，只读字段仍然为不可编辑状态
            //   this.$refs.vformRender[enableForm]();
            // }

            // 如果是填报模式，不改变表单设置时的只读状态,只有查看模式才改变，并且强制禁用
            if (enableForm == 'disableForm') {
              this.$refs.vformRender['disableForm']()
            }

            this.$refs.vformRender.setIsDataEdit(false)
          }, 50)

          if (showHiddenWidgets) this.showHiddenWidgets()
          else this.hideHiddenWidgets()
        })
      })
      getFillLogList(fillId, this.formId).then((res) => {
        // 日志排序
        this.logs = res.data;
        // this.logs = res.data.reverse();
      });
    },
    checkDraft() {
      var draftFormId = localStorage.getItem("draft_formId");
      if (
        draftFormId != this.formId ||
        draftFormId == "" ||
        draftFormId == null
      ) {
        return false;
      }

      this.$confirm("有未保存的草稿，是否使用草稿数据", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          const fromData = localStorage.getItem("draft_formModel");
          this.$refs.vformRender.setFormData(
            JSON.parse(fromData),
            null,
            null,
            true
          );
          localStorage.removeItem("draft_formId");
          localStorage.removeItem("draft_formModel");
        })
        .catch(() => {
          localStorage.removeItem("draft_formId");
          localStorage.removeItem("draft_formModel");
        });
    },
    // 关联数据表单在查看、修改表单的时候，点击查看按钮加载所关联的表单数据
    showDataView(fillSelectOption, fillData, callback) {
      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });

      Promise.all([loadField, loadData]).then((values) => {
        this.showInside(
          fillData.fillId,
          fillSelectOption.formId,
          values[0],
          values[1],
          this.isShowHiddenWidgets,
          fillSelectOption.fields
        );
      });
    },
    getCustomTip() {
      const _this = this;
      // 获取表单配置(表单设计器)数据
      getCustomTip(_this.formId).then((response) => {
        this.customTip = response.data;
      });
    },
    dataCardRender() {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    setAllowSave(flag = true) {
      this.allowSave = flag;
    },
    disableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.disableForm();
      });
    },
    enableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.enableForm(this.optionData.isUserAdmin);
      });
    },
    showHiddenWidgets() {
      this.$refs.vformRender.showHiddenWidgets();
    },
    hideHiddenWidgets() {
      this.$refs.vformRender.hideHiddenWidgets();
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    
    addRelativeLink(fillId) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type === "fill-select" ||
          (x.field.subtype &&
            x.field.subtype == "relative" &&
            !(
              x.field.type === "sub-form" &&
              x.field.options.reference === "copy"
            ))
        // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
      );

      relativeWidgets.forEach(async (x) => {
        const widget = this.$refs.vformRender.getWidgetRef(
          x.field.options.name
        );
        await widget.syncRelativeChange(fillId);
      });
    },
    save(callback, quite) {
      // quite 参数表示不弹出提示
      const _this = this;
      _this.$refs.vformRender
        .getFormData()
        .then((fillJson) => {
          const changed = changedValue(this.formJson, this.formData, fillJson);
          editFill({
            formId: _this.formId,
            fillId: _this.fillId,
            fillJson: fillJson,
            fillChange: changed,
          }).then((response) => {
            if (response.code == 200) {
              if (!quite) {
                _this.msgSuccess("提交成功");
                _this.closeQuite();
              }
              _this.addRelativeLink(_this.fillId);

              if (callback && typeof callback === "function") {
                callback();
              }
              _this.$emit("on-save");
            }
          });
        })
        .catch((err) => {
          _this.msgError(err);
        });
    },
    saveAndAudit() {
      this.save(this.auditButtonCallback, "quite");
    },
    saveAdd(callback) {
      // 检查表单是否填写，如果未填写,且没有设置默认值，需要提示用户()
      // 这里只有创建一种情况，没有修改页面了。
      if (
        !this.$refs.vformRender.isFormDataEdit() &&
        !this.$refs.vformRender.isFormFillWithDefault()
      ) {
        this.msgError("请填写完表单信息后再提交");
        return;
      }

      const getFormJson = (cb) => {
        this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const fillJsonCopy = JSON.parse(JSON.stringify(fillJson));
            const param = {
              formId: this.formId,
              fillJson: fillJson,
              formAgreementId: this.formAgreementId,
              formAgreementSignature: "",
            };
            this.fillJson = fillJsonCopy;
            if (cb) cb(param);
          })
          .catch((err) => {
            this.msgError(err);
          });
      };

      // 检查表单信息填报规则校验
      checkFill(this.formId).then((response) => {
        if (response.code == 200) {
          getFormJson((param) => {
            fill(param).then(async (response) => {
              if (response.code == 200) {
                this.formSubmitFillId = response.data;
                await this.addRelativeLink(this.formSubmitFillId);

                // 如果当前表单id和草稿id一致，清空草稿
                if (this.formId === localStorage.getItem("draft_formId")) {
                  setTimeout(() => {
                    localStorage.removeItem("draft_formId");
                    localStorage.removeItem("draft_formModel");
                  }, 2000);
                }

                this.$refs.vformRender.resetForm(() => {
                  this.isShow = false;
                  // 如果不是后退才弹出支付窗口
                  this.fillSuccessShow = true;
                  if (!this.formDetail.pay) {
                  }

                  if (this.$refs.vformRender.stopDraft)
                    this.$refs.vformRender.stopDraft();
                });

                this.$emit("on-save");
              }
            });
          });
        }
      });
    },
    onlyAudit() {
      this.auditButtonCallback();
    },
    addRelativeLink(fillId) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type == "fill-select" ||
          x.field.type === "fill-select" ||
          (x.field.subtype &&
            x.field.subtype == "relative" &&
            !(
              x.field.type === "sub-form" &&
              x.field.options.reference === "copy"
            ))
        // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
      );

      return new Promise((resolve, reject) => {
        relativeWidgets.forEach(async (x) => {
          const widget = this.$refs.vformRender.getWidgetRef(
            x.field.options.name
          );
          if (widget.syncRelativeChange) {
            await widget.syncRelativeChange(fillId);
          }
        });
        resolve();
      });
    },
    close() {
      beforeBackConfirm(
        this.$refs.vformRender,
        () => {
          this.save(this.closeQuite);
        },
        this.closeQuite
      );
    },
    closeQuite() {
      this.isShow = false;
      setTimeout(() => {
        this.showVForm = false;
      }, 100);
    },
    // 表单开启支付功能，暂不支付
    onCancelPayFillClick() {
      this.fillSuccessShow = false;
      this.$emit("on-save");
    },
    // 表单开启支付功能，立即支付
    onConfirmPayFillClick() {
      const _this = this;
      const dto = {
        fillId: this.formSubmitFillId,
        formId: this.formId,
        amount: this.fillJson.__amount
          ? Number(this.fillJson.__amount || "1")
          : undefined,
      };

      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.formPayOpen = true;
          _this.orderPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["qrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.setTimer(1000, response.data.orderNo);
        }
      });
    },
    showUserSelect(selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },
    hideWidgets(widgets) {
      widgets.forEach((widget) => {
        this.$refs.vformRender.getWidgetRef(widget).setDisabled(true);
      });
    },
    showAuditButton(isShowButton, callback) {
      this.isShowAuditButton = isShowButton;
      this.auditButtonCallback = callback;
    },
    fileDelete(file) {
      deleleteFile(file.fileId).then((res) => {});
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then((res) => {});
      }
    },
  },
};
