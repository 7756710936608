<template>
  <div vif="token">
    <h5Render ref="renderForm" />
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import h5Render from "@/views/system/form/h5Render.vue";
export default {
  data () {
    return {
      formId: '',
      fillId: '',
      fieldName: '',
      formMode: '',
      listType: '',
      token: ''
    }
  },
  components: {
    h5Render
  },
  computed: {
  },
  created () {
    // this.token = Cookies.get('User-Token')
    // if (!this.token) {
    //   this.$router.push({ path: '/login', query: { redirect: encodeURIComponent(window.location.hash.replace('#/', '')) } })
    // } else {
    //   this.$route.params.token = this.token;
      this.$route.query.formMode = 'signField';
    // }
  },
  mounted () {
  },
  beforeUnmount () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
</style>
