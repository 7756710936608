<!-- 这个文件未使用 -->
<template>
  <el-dialog title="选择表单数据" :visible.sync="isShow" width="350px" :before-close="close" :modal-append-to-body="true">
    <template v-if="fillSelectWidgetOptions.exactSearch">
      <el-input size="small" placeholder="搜索" :clearable="true" v-model="search">
        <el-button slot="append" @click="searchHandler" icon="el-icon-search"></el-button>
      </el-input>
    </template>
    <template v-else>
      <div class="search-container">
        <div v-for="(item,index) in fillSelectWidgetOptions.exactSearchField" :key="index" class="search-item">
          <div class="search-item-title">{{item.displayName}}</div>
          <div>
            <el-input size="small" :clearable="true" v-model="searchObject[item.name]">
            </el-input>
          </div>
        </div>
        <div style="text-align:right;width:100%;margin-top:10px;padding-right:10px;">
          <el-button size="mini" type="primary" @click="searchHandler">搜索</el-button>
        </div>
      </div>
    </template>

    <div class="box-card-container" v-if="table.data.length>0">
      <div v-for="(item,index) in table.data" :key="index" class="item">
        <filleCard :renders="renderProvider" :fields="fillSelectOption.fields" :cardData="item" :isSelect="true" @view="viewItem(item)" @selectItem="select(item)"></filleCard>
      </div>
    </div>
    <VFormInsideViewer ref="dlgvFormInsideViewer" :formDetail="formDetail" v-if="isShow"></VFormInsideViewer>
    <div style="text-align:center;margin-top:10px">
      <!-- <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="table.total" @current-change="handlePageChange">
            </el-pagination> -->
    </div>

    <div class="empty-data" v-if="table.data.length==0">
      暂无数据
    </div>
  </el-dialog>

</template>
<script>
import { formDetail, httpGetFormFieldV2, selectFillDataDetail } from '@/api/system/form'
import { fillList, fillListSearch } from '@/api/system/form'

import { isBlank } from "@/utils/whale";
import { payInfo, statusInfo, fillStatusInfo, agencyInfo, extraFillInfo } from '@/utils/grid.js'
import filleCard from './fill-select-widget-components/fill-select-card.vue'
import { renderProvider } from '@/utils/gridrender/cellRender'

import VFormInsideViewer from '../vFormViewer/inside.vue';
// var wx = require('jweixin-module');/

export default {
  components: { filleCard, VFormInsideViewer },
  data () {
    return {
      // 表单关联字段的配置
      fillSelectOption: {},
      // 选择完成后的回调
      selectdHandler: null,
      isShow: false,
      pageNum: 1,
      pageSize: 20,
      renderProvider: renderProvider,
      table: {
        data: [],
        total: 0
      },
      search: "",
      formJson: {
        formConfig: {},
        widgetList: [],
        fieldList: []
      }
    }
  },
  created () {
    // 初始化JSSDK
    // wx.config({
    //   // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //   debug: true,
    //   // 必填，公众号的唯一标识
    //   appId: '',
    //   // 必填，生成签名的时间戳
    //   timestamp: ,
    //   // 必填，生成签名的随机串
    //   nonceStr: '',
    //   // 必填，签名
    //   signature: '',
    //   // 必填，需要使用的JS接口列表
    //   jsApiList: ['setClipboardData', 'getClipboardData']
    // });

    // // 通过ready接口处理成功验证
    // wx.ready(function () {
    //   // 设置剪贴板内容
    //   wx.setClipboardData({
    //     data: '这是要复制的内容',
    //     success: function (res) {
    //       alert('复制成功');
    //     }
    //   });

    //   // 获取剪贴板内容
    //   wx.getClipboardData({
    //     success: function (res) {
    //       alert('剪贴板的内容为：' + res.data);
    //     }
    //   });
    // });

    // // 通过error接口处理失败验证
    // wx.error(function (res) {
    //   alert('配置错误：' + res.errMsg); // 错误信息
    // });
  },
  methods: {
    // 获取表单的其他配置
    getFormDetail (formId) {
      return new Promise((resolve, reject) => {
        // 获取表单其他配置
        formDetail(formId).then((response) => {
          this.formDetail = response.data
          this.isReserve = response.data.isReserve
          resolve()
        })
      })
    },
    handleDataPageChange () { },
    onHandlePreviewImg () { },
    // 打开选择窗口
    async show (fillSelectOption, selectdHandler) {
      this.fillSelectWidgetOptions = fillSelectOption.options
      this.fillSelectOption = fillSelectOption

      this.selectedItems = fillSelectOption.selectedList
      this.maxCount = fillSelectOption.maxCount
      this.formData = fillSelectOption.formData
      this.express = fillSelectOption.express


      this.search = "";
      this.selectdHandler = selectdHandler
      this.isShow = true
      // await this.getFormDetail(fillSelectOption.formId)
      this.getTableData()

    },
    autoFill (fillSelectOption, confirmSelectdHandler) {
      this.search = "";

      fillSearch(fillSelectOption, this.formData, this.search, this.searchObject, { pageSize: 1, pageNum: 1 })
        .then(({ data, total }) => {
          this.table.data = data;

          this.table.total = total;
          this.selectedItemsTmp = this.selectedItems;
          if (confirmSelectdHandler) confirmSelectdHandler(data)
        })
    },
    searchHandler () {
      this.table.data.splice(0);
      this.getTableData();
    },
    // 关闭
    close () {
      this.isShow = false
    },
    // 读取表单填报数据
    getTableData () {
      const queryValueBuilder = (expressItem) => {
        if (expressItem.valueType === "formField") {
          return this.formData[expressItem.value]
        } else {
          return expressItem.value;
        }
      }
      const queryParams = {
        // pageNum: this.pageNum,
        // pageSize: this.pageSize,
        // query:{
        search: this.search,
        connector: (this.express.connector || "and").toLowerCase(),
        rcs: this.express.list.map(x => {
          return {
            value: queryValueBuilder(x),
            operator: x.operator,
            key: x.key,
            type: x.type
          }
        }),
        searchCriteria: []
        // }
      }

      // selectFillDataDetail(this.fillSelectOption.formId, queryParams).then((response) => {
      // fillList(this.fillSelectOption.formId, queryParams).then((response) => {
      fillListSearch(this.fillSelectOption.formId, queryParams).then((response) => {
        this.table.data = []
        //for (let i = 0; i < response.rows.length; i++) {
        for (let i = 0; i < response.data.length; i++) {
          const item = response.data[i]
          const obj = {
            ...item.fillJson,
            ...payInfo(item), // 从item 获取支付信息
            ...statusInfo(item), // 从item获取状态信息
            ...fillStatusInfo(item, false), // 从item获取填报确认状态 导入待确认/导入已确认 参数2:是否显示批量填报的状态
            ...agencyInfo(item), // 从item获取机构信息
            ...extraFillInfo(item)
          }

          this.table.data.push(obj)
          this.table.total = response.total
        }
        if (isBlank(this.table.data)) {
          this.dataEmptyText = '暂无数据'
        }
      })
    },
    select (item) {
      if (this.selectdHandler) this.selectdHandler(item)
      this.close()
    },
    async viewItem (item) {
      const res = await httpGetFormFieldV2(this.fillSelectOption.formId)

      const formJson = res.data
      const formData = item
      this.$refs.dlgvFormInsideViewer.showView(item.fillId, this.fillSelectOption.formId, formJson, formData, this.$parent.cur != 3)
    }
  }
}
</script>
<style scoped lang="scss">
.box-card-container {
  max-height: 700px;
  padding-right: 10px;
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.box-card {
  margin-bottom: 10px;

  .box-card-title-label {
    font-weight: bold;
    &:after {
      content: ':';
      margin-right: 5px;
    }
  }
  &-item {
    height: 25px;
    line-height: 25px;
    &-label {
      font-weight: bold;
      &:after {
        content: ':';
        margin-right: 5px;
      }
    }
  }
}

::v-deep .el-dialog {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: fixed !important;
  top: 2vh !important;
  margin: 0 auto 50px;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  width: 50%;
  left: 2vw;
  right: 2vw;
  bottom: 2vh;
}
::v-deep .el-dialog__header {
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
}
::v-deep .el-dialog__headerbtn {
  top: 15px;
  right: 10px;
}
::v-deep .el-dialog__body {
  padding: 10px 10px;
  height: calc(100% - 110px);
  .box-card-container {
    height: 100%;
    max-height: 100%;
  }
}

.empty-data {
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
}

// div一行两列的样式，每一列有个标题
div.search-item {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 50%;
}
div.search-item-title {
  width: 100px;
}
div.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
}
</style>