import Axios from "axios";
import store from "@/store";
import { getSessionStorageObj } from "@/utils/db";
import { getExportFileName, getWaterMarkFile } from "@/api/system/form";

import Vue from "vue";
import { MessageBox, Message } from "element-ui";
const _Message = Message;

import { getColor } from "@/utils/whale";
import moment from 'moment'

let userType = -1;
let users = getSessionStorageObj("users");
if (users) userType = users.type;

export const CellCheckRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let spans = [];

    let regionNames = row[column.property];
    try {
      if (regionNames) {
        for (let i = 0; i < regionNames.length; i++) {
          let name = regionNames[i];
          if (i == regionNames.length - 1) {
            spans.push(<span>{name}</span>);
          } else {
            spans.push(<span style="margin-right:3px">{name}</span>);
          }
        }
      }
    } catch (e) {
      console.log("渲染Check出错", e);
      console.log(row, column, regionNames);
    }

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellRegionRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let spans = [];
    let regionNames = row[column.property];
    try {
      if (regionNames) {
        if (typeof regionNames == "string") {
          let adds = regionNames.split(/\s|-/);
          adds.forEach((add) => {
            spans.push(<div>{add}</div>);
          });
          return spans;
        }

        for (let i = 0; i < regionNames.length; i++) {
          let name = regionNames[i];
          if (i == regionNames.length - 1) {
            spans.push(<span>{name}</span>);
          } else {
            spans.push(<span style="margin-right:3px">{name}</span>);
            spans.push(<span style="margin-right:2px">/</span>);
          }
        }
      }
    } catch (e) {
      console.log("渲染Region出错", e);
      console.log(row, column, regionNames);
    }

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

// export const CellAddressRender = {
//     renderDefault(h, renderOpts, params) {
//         let { row, column } = params
//         let { events } = renderOpts

//         let pcas=store.state.codes.pcasCode;
//         let regionCodes=row[column.property];
//         let regionNames=[];

//         if(!regionCodes||!regionCodes.location||regionCodes.location.length==0) return;

//         let findRegionName=(src,value)=>{
//             let item=src.find(x=>x.value==value);
//             if(item)
//                 regionNames.push(item.label);
//             return item;
//         }

//         let idxItem={children:pcas};
//         for(let i=0;i<regionCodes.location.length;i++){
//             if(idxItem)
//             idxItem=findRegionName(idxItem.children,regionCodes.location[i]);
//         }

//         let spans=[];
//         for(let i=0;i< regionNames.length;i++){
//             let name=regionNames[i];
//             spans.push(<span style="margin-right:3px">{name}</span>)

//         }
//         spans.push(<span>{regionCodes.address}</span>);

//         return spans
//     },
//     // 导出模板，例如导出插槽中自定义的内容
//     exportMethod(params) {
//         const { row, column } = params
//         return '自定义内容'
//     }
// }

export const CellAddressRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let addressObj = row[column.property];
    let spans = [];
    try {
      if (addressObj) {
        if (!addressObj.location && typeof addressObj == "string") {
          return spans;
        }

        for (let i = 0; i < addressObj.location.length; i++) {
          let name = addressObj.location[i];
          spans.push(<span style="margin-right:2px">{name}</span>);
          if (i != addressObj.location.length - 1) {
            spans.push(<span style="margin-right:2px">/</span>);
          }
        }
        if (addressObj.address) {
          if (addressObj.location.length > 0) {
            spans.push(<span style="margin-right:2px">/</span>);
          }
          spans.push(<span>{addressObj.address}</span>);
        }
      }
    } catch (e) {
      console.log("渲染address出错", e);
      console.log(row, column, addressObj);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellUniversityRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];

    try {
      spans.push(<span>{cellValue}</span>);
    } catch (e) {
      console.log("渲染University出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellRadioRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];
    try {
      const codeText = (item) => {
        if (item.code) return <span>({item.code})</span>;
      };

      const putItemSpans = (optionItems) => {
        let item = optionItems.find((x) => x.value == cellValue);
        let label;

        if (!item && !cellValue) return [];
        //如果值未为空，并且不是通过输入创建的值
        else if (!item && cellValue) {
          //如果值为空，因为手动输入并创建了选项
          spans.push(cellValue);
          return spans;
        } else {
          label = item.label;
          // if(item&&item.code){
          //   label+=`(值:${item.code})`
          // }
        }

        if (item) {
          if (
            column.isOpenColor ||
            (column.params && column.params.isOpenColor)
          ) {
            if (item.color) {
              spans.push(
                <el-tag
                  color={item.color.bgColor || "#2196F3"}
                  style={{
                    color: item.color.fontColor || "#FFFFFF",
                    border: "none 0px",
                  }}
                  size="small"
                >
                  {item.label}
                  {codeText(item)}
                </el-tag>
              );
              // if(item.code) spans.push(<span>(值:{item.code})</span>);
            } else {
              spans.push(
                <el-tag
                  color="#2196F3"
                  style={{ color: "#FFFFFF", border: "none 0px" }}
                  size="mini"
                >
                  {item.label}
                  {codeText(item)}
                </el-tag>
              );
              // if(item.code) spans.push(<span>(值:{item.code})</span>);
            }
          } else {
            spans.push(
              <el-tag
                color="#F2F2F2"
                style={{ color: "#666666", border: "none 0px" }}
                size="mini"
              >
                {item.label}
                {codeText(item)}
              </el-tag>
            );
          }
        }
      };
      if (
        column.params &&
        (column.params.optionsItem || column.params.optionItems)
      ) {
        let optionItems =
          column.params.optionsItem || column.params.optionItems;
        putItemSpans(optionItems);
      } else if (column.filterOptions) {
        putItemSpans(column.filterOptions);
      } else {
        console.log("radio参数错误", column, column.params);
      }
    } catch (e) {
      console.log("渲染radio出错", e);
      console.log(row, column, cellValue);
    }

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellCheckboxRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];
    try {
      if (!cellValue) return "";

      const codeText = (item) => {
        if (item.code) return <span>({item.code})</span>;
      };

      const putItemSpans = (optionItems) => {
        for (let i = 0; i < cellValue.length; i++) {
          let item = optionItems.find((x) => x.value == cellValue[i]);
          if (!item) continue;
          let label = item.label;
          // if(item.code){
          //   label+=`(值:${item.code})`
          // }
          if (item) {
            if (
              column.isOpenColor ||
              (column.params && column.params.isOpenColor)
            ) {
              if (item.color) {
                spans.push(
                  <el-tag
                    color={item.color.bgColor || "#2196F3"}
                    style={{
                      color: item.color.fontColor || "#FFFFFF",
                      border: "none 0px",
                      marginRight: i != cellValue.length - 1 ? "5px" : "0px",
                    }}
                    size="small"
                  >
                    {label}
                    {codeText(item)}
                  </el-tag>
                );
                // if(item.code) spans.push(<span>(值:{item.code})</span>);
              } else {
                spans.push(
                  <el-tag
                    color="#2196F3"
                    style={{
                      color: "#FFFFFF",
                      border: "none 0px",
                      marginRight: i != cellValue.length - 1 ? "5px" : "0px",
                    }}
                    size="small"
                  >
                    {label}
                    {codeText(item)}
                  </el-tag>
                );
                // if(item.code) spans.push(<span>(值:{item.code})</span>);
              }
            } else {
              // spans.push(<span style={{marginRight:((i!=cellValue.length-1)?'5px':'0px')}}>{label}</span>);
              spans.push(
                <el-tag
                  color="#F2F2F2"
                  style={{
                    color: "#666666",
                    border: "none 0px",
                    marginRight: i != cellValue.length - 1 ? "5px" : "0px",
                  }}
                  size="small"
                >
                  {label}
                  {codeText(item)}
                </el-tag>
              );
            }
          }
        }
      };
      //以前表单拼错了optionsItems，因此需要做兼容，拼错的全部用在表格显示的地方，未拼错的用在表单数据关联选择的地方
      if (
        column.params &&
        (column.params.optionsItem || column.params.optionItems)
      ) {
        let optionItems =
          column.params.optionsItem || column.params.optionItems;
        putItemSpans(optionItems);
      } else if (!column.params && column.filterOptions) {
        putItemSpans(column.filterOptions);
      } else {
        console.log("Checkbox参数错误", column, column.params);
      }
    } catch (e) {
      console.log("渲染checkbox出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellCascaderRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];

    try {
      if (!cellValue) return "";

      //获取选项label,并写入到span里
      const putItemSpans = (options) => {
        if (cellValue.length > 0 && Array.isArray(cellValue[0])) {
          for (let i = 0; i < cellValue.length; i++) {
            if (Array.isArray(cellValue[i])) {
              getCascadeLabel(options, cellValue[i], 0);

              if (i < cellValue.length - 1) {
                spans.push(
                  <span style="margin-left:2px;margin-right:3px">/</span>
                );
              }
            }
          }
        } else {
          getCascadeLabel(options, cellValue, 0);
        }
      };

      const getCascadeLabel = (options, cellValueItem, cellValueIdx) => {
        if (cellValueIdx < cellValueItem.length) {
          let item = options.find(
            (x) => x.value == cellValueItem[cellValueIdx]
          );

          if (!item) return "";
          let label = item.label;
          if (item.code) {
            label += `(值${item.code})`;
          }

          if (item) {
            if (cellValueIdx < cellValueItem.length - 1) {
              spans.push(<span style="margin-right:3px;">{label}</span>);
              spans.push(<span style="margin-right:3px">/</span>);
            } else {
              spans.push(<span>{label}</span>);
            }
          }

          if (item && item.children && cellValueIdx < cellValueItem.length) {
            getCascadeLabel(item.children, cellValueItem, cellValueIdx + 1);
          }
        }
      };

      if (column.params && column.params.optionsItem) {
        let options = column.params.optionsItem;
        putItemSpans(options);
      } else if (column.optionsItem) {
        putItemSpans(column.optionsItem);
      }

      return spans;
    } catch (e) {
      console.log("渲染Cascader出错", e);
      console.log(row, column, cellValue);
    }
  },
};

export const CellRateRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    let spans = [];

    let cellValue = row[column.property];

    try {
      let optionItems =
        column.params.optionsItem ||
        column.params.optionItems ||
        column.params.filterOptions;
      let item = optionItems[cellValue - 1];
      if (item) spans.push(<span>{item}</span>);
    } catch (e) {
      console.log("渲染Rate出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellRichEditorRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let _this = this;

    let spans = [];
    try {
      let utils = require("@/utils/index.js");
      let clickHandler = utils.dispatchComponent.apply(this, [
        "MyVxeTable",
      ]).showRichEdiorViewer;

      if (cellValue)
        // spans.push(<span>{item.replace(/<[^>]+>/g, "").substring(0,50)}</span>);
        spans.push(
          <span
            class="cell-link-view"
            onClick={(event) => {
              event.stopPropagation();
              clickHandler(column.property, cellValue);
            }}
          >
            {cellValue.replace(/<[^>]+>/g, "")}
          </span>
        );
    } catch (e) {
      console.log("渲染RichEditor出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellSubFormRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];
    try {
      let utils = require("@/utils/index.js");
      let providerName = ["MyVxeTable", "vFormViewer","h5Render"];
      let component;
      let clickHandler;
      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showSubform;
          break;
        }
      }

      if (!clickHandler) {
        clickHandler = function (property, cellValue, column) {
      //     console.log(
      //       utils.dispatchComponent.apply(this, [providerName[findProviderIndex], true])
      //     );
      };
      }
      spans.push(
        <span
          class="cell-link-view cell-link-view-fill-select"
          onClick={(event) => {
            event.stopPropagation();
            clickHandler(column.property, cellValue, column);
          }}
        >
          点击查看详情
        </span>
      );
    } catch (e) {
      console.log("渲染SubForm出错", e);
      console.log(row, column, cellValue);
    }

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellPictureUploaderRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];

    let spans = [];
    try {
      let sizeName = ["B", "KB", "MB", "GB"];
      let countSize = (countFile, bracket = true) => {
        if (!countFile.size) {
          return "";
        }
        let size = countFile.size;
        let i = 0;

        while (size > 1024) {
          size = size / 1024;
          i++;
        }
        if (bracket) {
          return "[" + size.toFixed(2) + sizeName[i] + "]";
        } else {
          return size.toFixed(2) + sizeName[i];
        }
      };
      let fileType = (fileName) => {
        return "ico-" + fileName.substring(fileName.lastIndexOf(".") + 1);
      };
      let utils = require("@/utils/index.js");

      //提供查看文件上传服务的组件
      let providerName = ["MyVxeTable", "FieldWidget", "fillSelectCard"];
      let component;
      let clickHandler;
      let clickListHandler;
      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showPictureView;
          clickListHandler = component.showPictureList;
          break;
        }
      }
      let width = 400;
      if (column.width && typeof(column.width)=='string') {
        width = parseFloat(column.width.replace(/px/, ""));
      }

      let maxPics = Math.floor(width / 55);
      if (cellValue) {
        // for (
        //   let i = 0;
        //   i < (cellValue.length > maxPics ? maxPics : cellValue.length);
        //   i++
        // ) {
        for (let i = 0; i < cellValue.length; i++) {
          if (cellValue[i]) {
            let backgroundImage =
              "background-image:url('" +
              (cellValue[i].thumb ? cellValue[i].thumb : cellValue[i].url) +
              "');margin:0px 2px";
            spans.push(
              <el-tooltip
                popper-class="file-info"
                effect="light"
                placement="top"
                open-delay={300}
              >
                <div class="file-info" slot="content">
                  <div class="file-info-thumb">
                    {cellValue[i].mediaType &&
                    cellValue[i].mediaType.indexOf("video") >= 0 ? (
                      <i
                        class="el-icon-video-play"
                        style="position:absolute;bottom:10px;left:10px;font-size:24px;color:#f5f5f5;"
                      ></i>
                    ) : (
                      ""
                    )}
                    <img
                      src={cellValue[i].thumb}
                      style="width:100%;cursor:pointer"
                      onClick={(event) => {
                        event.stopPropagation();
                        clickHandler(
                          column.property,
                          cellValue[i],
                          cellValue,
                          i
                        );
                      }}
                    />
                  </div>
                  <div class="file-info-extra">
                    <div class="file-info-size">
                      {countSize(cellValue[i], false)}
                    </div>
                    <div class="file-info-actions">
                      <a href={cellValue[i].url} target="_blank">
                        <svg-icon iconClass="download2"></svg-icon>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div class={'cell-link-view ico-file '+fileType(cellValue[i].name)}></div> */}
                <span
                  class="picture-view"
                  onClick={(event) => {
                    event.stopPropagation();
                    clickHandler(column.property, cellValue[i], cellValue, i);
                  }}
                  style={backgroundImage}
                ></span>
              </el-tooltip>
            );
          }
        }
        // if (cellValue.length > maxPics && clickListHandler) {
        //   spans.push(
        //     <span
        //       class="picture-view more"
        //       onClick={() =>
        //         clickListHandler(column.property, null, cellValue, maxPics)
        //       }
        //     >
        //       更多
        //     </span>
        //   );
        // }
      }
    } catch (e) {
      console.log("渲染PictureUploader出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellFileUploaderRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];

    try {
      let sizeName = ["B", "KB", "MB", "GB"];
      let countSize = (countFile, bracket = true) => {
        if (!countFile.size) {
          return "";
        }
        let size = countFile.size;
        let i = 0;

        while (size > 1024) {
          size = size / 1024;
          i++;
        }
        if (bracket) {
          return "[" + size.toFixed(2) + sizeName[i] + "]";
        } else {
          return size.toFixed(2) + sizeName[i];
        }
      };

      let fileType = (fileName) => {
        return "ico-" + fileName.substring(fileName.lastIndexOf(".") + 1);
      };

      let filePreview = (fileName, openWindow = false) => {
        let base64FileName = btoa(fileName);
        let url = `${process.env.VUE_APP_BASE_FILE_PREVIEW}/onlinePreview?url=${encodeURIComponent(base64FileName)}`;

        if (openWindow) {
          let find = (el)=>{
            if(el.$options.componentName=="VFormRender"){
              return el;
            }else if(el.$parent){
              return find(el.$parent)
            }
            return null;
          }
  
          let showPreviewFile = find(this.$parent);
          if(showPreviewFile){
            showPreviewFile.previewFile(url)
          }else{
            window.open(url);
          }
        }
        return url;
      };

      // 从URL中拆解文件名和扩展名
      let extractFileName = (url) => {
        let originalFileUrl = url.substring(url.lastIndexOf("/") + 1);
        // 分离扩展名和文件名
        let fileName = originalFileUrl.substring(
          0,
          originalFileUrl.lastIndexOf(".")
        );
        let fileExt = originalFileUrl.substring(
          originalFileUrl.lastIndexOf(".")
        );
        return { fileName, fileExt };
      };

      let isWaterMark = (fileExt) => {
        // 如果扩展名不属于['pdf','doc','docx'] 直接返回
        if (
          [".pdf", ".doc", ".docx"].indexOf(fileExt) == -1 ||
          !column.params.waterMark ||
          column.params.waterMark.length == 0
        ) {
          return false;
        } else {
          return true;
        }
      };
      let waterMarkText = () => {
        let waterMarkArr = [];
        column.params.waterMark.forEach((item) => {
          if (item.type === "field") {
            waterMarkArr.push(row[item.name]);
          } else if (item.type === "symbol") {
            waterMarkArr.push(item.name);
          } else if (item.type === "sysField") {
            if (item.name === "STATUS") {
              const statusMap = {
                1: "无需审核",
                2: "待一级审核",
                3: "一级审核通过",
                4: "一级审核不通过",
                5: "一级审核返回修改",
                6: "待二级审核",
                7: "二级审核通过",
                8: "二级审核不通过",
                9: "二级审核返回修改",
                10: "待三级审核",
                11: "三级审核通过",
                12: "三级审核不通过",
                13: "三级审核返回修改",
                14: "审核完成",
              };
              waterMarkArr.push(statusMap[row.status]);
            } else if (item.name === "AUDITRESULT") {
              waterMarkArr.push(
                row.auditAuth3Remarks ||
                  row.auditAuth2Remarks ||
                  row.auditAuth1Remarks
              );
            }
          }
        });
        return waterMarkArr.join("");
      };

      let isAllowDownload = ()=>{
        try{
          let columnConfig = column.params||column;
          if(columnConfig.exportAfterAudit===true && row.status != 14)
          {
            // _Message({
            //   message: '只有审核通过的数据才可以导出！',
            //   type: 'warning',
            // })
            return false
          }
        }catch{  }
        return true;
      }


      let fileRenameDownload = async (url) => {

        // if(!isAllowDownload()){
        //   return ;
        // }

        // 文件原始路径
        let fileUrl = url;
        let fullFileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        let originalFile, watermarkFile, exportFile;
        

        // 获取原始上传文件的，文件名，扩展名，根据文件名判断是否使用水印
        originalFile = extractFileName(url);

        // 判断文件是否需要改名
        const fileNameRes = await getExportFileName(url);
        if (fileNameRes.data && fileNameRes.data.fileName) {
          fullFileName = fileNameRes.data.originalFileName;
          exportFile = extractFileName(fullFileName);
        }

        // 判断是否使用水印
        let waterMark = isWaterMark(originalFile.fileExt);

        if (waterMark) {
          // 如果使用水印，调用外部函数，获取水印文件URL
          const waterMarkFileRes = await waterMarkHandler(
            (exportFile || originalFile).fileName,
            column.property,
            waterMarkText(),
            fileUrl
          );
          if (waterMarkFileRes.data) {
            // 下载文件URL 改为水印URL
            fileUrl = waterMarkFileRes.data;
            watermarkFile = extractFileName(fileUrl);
            fullFileName =
              (exportFile || originalFile).fileName + watermarkFile.fileExt;
          }
        }

        const xhr = new XMLHttpRequest();
        if(fileUrl.indexOf('http://')>-1){
          fileUrl = fileUrl.replace("http://", "//");
        }
        xhr.open("GET", fileUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            const blob = new Blob([xhr.response], {
              type: "application/octet-stream",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fullFileName;
            link.click();

            setTimeout(() => {
              link.remove();
            });
          }
        };
        xhr.send();
      };

      let utils = require("@/utils/index.js");
      //提供查看文件上传服务的组件
      let providerName = [
        "MyVxeTable",
        "VFormRender",
        "FieldWidget",
        "FillSelectCardSelector",
      ];
      let currentProviderName = "";
      let component;
      let clickListHandler;
      let waterMarkHandler;
      let inRenderPreview;
      

      


      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickListHandler = component.showFileList;
          currentProviderName = providerName[i];
          waterMarkHandler = component.getWaterMark;
          break;
        }
      }

      if (cellValue) {
        // let showFilesCount=(cellValue.length > 1 ? 1 : cellValue.length);
        //v4.11 改为显示图标，并且能显示多少就多少不弹窗
        let showFilesCount = cellValue.length;
        if (
          currentProviderName == "FieldWidget" ||
          currentProviderName == "FillSelectCardSelector"
        ) {
          showFilesCount = cellValue.length;
        }
        for (let i = 0; i < showFilesCount; i++) {
          if (cellValue[i]) {
             let preparedDownloadEl;
            if(isAllowDownload()){
             preparedDownloadEl =  <a
             onClick={(event) => {
               event.stopPropagation();
               fileRenameDownload(cellValue[i].url);
             }}
           >
             <svg-icon iconClass="download2"></svg-icon>
           </a>
           }


            spans.push(
              <el-tooltip
                popper-class="file-info"
                effect="light"
                placement="top"
                open-delay={300}
              >
                <div class="file-info" slot="content">
                  <div class="file-info-name">{cellValue[i].name}</div>
                  <div class="file-info-extra">
                    <div class="file-info-size">
                      {countSize(cellValue[i], false)}
                    </div>
                    <div class="file-info-actions">
                      <a href={filePreview(cellValue[i].url)} target="_blank">
                        <svg-icon iconClass="file-preview"></svg-icon>
                      </a>
                      {preparedDownloadEl}
                    </div>
                  </div>
                </div>
                <div class={"cell-link-view ico-file " + fileType(cellValue[i].name)}
                  onClick={(event) => {
                    event.stopPropagation();
                    filePreview(cellValue[i].url, true);
                  }}
                ></div>
              </el-tooltip>
            );
          }
        }
      }
    } catch (e) {
      console.log("渲染FileUploader出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellAgencyDeptRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let agencyDeptList = row["agencyDeptList"];

    let deptList = store.state.dept.dept;
    let spans = [];
    try {
      if (agencyDeptList == null) return "--";

      for (let i = 0; i < agencyDeptList.length; i++) {
        let agencyDept = agencyDeptList[i];
        let agencyArray = agencyDept.parents.split(",");
        agencyArray.push(agencyDept.agencyDeptId);
        let agencyName = [];

        for (let j = 0; j < agencyArray.length; j++) {
          let dept = deptList.find((x) => x.agencyDeptId == agencyArray[j]);
          if (dept) agencyName.push(dept.deptName);
        }
        let subSpans = [];
        for (let t = 0; t < agencyName.length; t++) {
          subSpans.push(<span class="cell-dept-item">{agencyName[t]}</span>);
        }
        spans.push(
          <div class="cell-dept">
            {i + 1}. {subSpans}
          </div>
        );
      }
    } catch (e) {
      console.log("渲染AgencyDept出错", e);
      console.log(row, column, agencyDeptList);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

//图片选择渲染
export const CellPictureSelectRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];

    try {
      if (cellValue) {
        if (renderOpts.total) {
          spans.push(
            <span>
              已选择{cellValue.length}项/共{renderOpts.total}项
            </span>
          );
        } else {
          spans.push(<span>已选择{cellValue.length}项</span>);
        }
      }
    } catch (e) {
      console.log("渲染PictureSelect出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};


//数据关联渲染
export const CellFillSelectRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    let cellValue = row[column.property];
    let spans = [];
    try {
      let utils = require("@/utils/index.js");

      if (userType == -1) {
        let users = getSessionStorageObj("users");
        if (users) userType = users.type;
      }
      let isAdmin = userType > 1; //1普通用户 2机构管理员 3子管理员
      //提供查看服务的组件
      let providerName = ["MyVxeTable", "FieldWidget"];
      let component;
      let clickHandler;

      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showFillView;
          break;
        }
      }

      // 获取关联树的标题
      const fillText = (titleName, cellValue) => {
        if (titleName.indexOf("userselect") >= 0) {
          return cellValue.map((x) => x.name).join(",");
        } else {
          return cellValue;
        }
      };

      if (cellValue && cellValue.selectList) {
        let max = 1;
        let len = cellValue.selectList.length;

        //只有管理员可以点击查看关联的数据
        let titleName ='';
        if(column.params){
          titleName = column.params.titleFieldName;
        }else{
          titleName = column.titleFieldName;
        }
        // for (let i = 0; i < (len > max ? max : len); i++) {
        for (let i = 0; i < len; i++) {
          let selectItem = cellValue.selectList[i];
          titleName = titleName || cellValue.titleName;

          let showFields = null;
          if (
            column.params &&
            column.params.filters &&
            column.params.filters.length > 0 &&
            column.params.filters[0].data
          ) {
            showFields = column.params.filters[0].data.fields;
          }
          // debugger

          // if (isAdmin) {
          //   spans.push(
          //     <div
          //       class="cell-link-view"
          //       onClick={(event) => {
          //         event.stopPropagation();
          //         clickHandler(cellValue, selectItem, i, showFields);
          //       }}
          //     >
          //       {/* <svg-icon iconClass="fill-link"></svg-icon>{" "} */}
          //       {fillText(titleName, selectItem[titleName])}
          //     </div>
          //   );
          // } else {
          //   spans.push(<div>{fillText(titleName, selectItem[titleName])}</div>);
          // }

          spans.push(
            <el-tooltip
              popper-class="file-info"
              effect="light"
              placement="top"
              open-delay={300}
            >
              <div slot="content">
              {fillText(titleName, selectItem[titleName])}
              </div>
              <el-tag
                style={{
                  border: "none 0px",
                  maxWidth: "260px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginRight: i != cellValue.length - 1 ? "5px" : "0px",
                }}
                size="small"
              >
                 {fillText(titleName, selectItem[titleName])}
              </el-tag>
            </el-tooltip>
          );
        }
        if (len > max) {
          // spans.push(<div style="user-select:none">其他...</div>);
        }
      }
    } catch (e) {
      console.log("渲染FillSelect出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

//数据选择器
export const CellDataChooseRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    let cellValue = row[column.property];
    let spans = [];
    try {
      let utils = require("@/utils/index.js");

      let isAdmin = userType > 1; //1普通用户 2机构管理员 3子管理员
      //提供查看服务的组件
      let providerName = ["MyVxeTable", "FieldWidget"];
      let component;
      let clickHandler;

      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showFillView;
          break;
        }
      }

      // 获取关联树的标题
      const fillText = (titleName, cellValue) => {
        if (titleName.indexOf("userselect") >= 0) {
          return cellValue.map((x) => x.name).join(",");
        } else {
          return cellValue;
        }
      };

      let titleName = '';
      let columnConfig = column.params?column.params: column;
        titleName =columnConfig.titleFieldName || columnConfig.fields[0].name;
      if (cellValue) {
        let max = 1;
        let len = cellValue.length;
        

        //只有管理员可以点击查看关联的数据
        // for (let i = 0; i < (len > max ? max : len); i++) {
        for (let i = 0; i < len; i++) {
          let selectItem = cellValue[i];
          selectItem.formId =columnConfig.formId;

          let showFields = columnConfig.fields;

          // if (isAdmin) {
          // spans.push(
          //   <div
          //     class="cell-link-view"
          //     onClick={(event) => {
          //       event.stopPropagation();
          //       clickHandler(selectItem, selectItem, i, showFields);
          //     }}
          //   >
          //     <svg-icon iconClass="fill-link"></svg-icon>{" "}
          //     {fillText(titleName, selectItem[titleName])}
          //   </div>
          // );
          // } else {
          //   spans.push(<div>{fillText(titleName, selectItem[titleName])}</div>);
          // }
          spans.push(
            <el-tooltip
              popper-class="file-info"
              effect="light"
              placement="top"
              open-delay={300}
            >
              <div slot="content">
                {selectItem[titleName]}
              </div>
              <el-tag
                style={{
                  border: "none 0px",
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginRight: i != cellValue.length - 1 ? "5px" : "0px",
                }}
                size="small"
              >
                {selectItem[titleName]}
              </el-tag>
            </el-tooltip>
          );
        }
        if (len > max) {
          // spans.push(<div style="user-select:none">其他...</div>);
        }
      }
    } catch (e) {
      console.log("渲染DataChoose出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellSignRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];

    let spans = [];
    try {
      let utils = require("@/utils/index.js");

      //提供查看文件上传服务的组件
      let providerName = ["MyVxeTable", "FieldWidget", "fillSelectCard"];
      let component;
      let clickHandler;
      let clickListHandler;
      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showSignView;
          clickListHandler = component.showPictureList;
          break;
        }
      }
      let width = 400;
      if (column.width) {
        width = parseFloat(column.width.replace(/px/, ""));
      }

      let maxPics = Math.floor(width / 55);
      if (cellValue) {
        let signList = [];
        if (cellValue.indexOf("[") == 0) {
          signList = JSON.parse(cellValue);
        } else {
          signList = [{ userId: 0, sign: cellValue }];
        }

        for (let t = 0; t < signList.length; t++) {
          if (!signList[t].sign) {
            continue;
          }
          let backgroundImage =
            "background-image:url('" + signList[t].sign + "');margin:0px 2px";
          spans.push(
            <span
              class="picture-view"
              onClick={(event) => {
                event.stopPropagation();
                clickHandler(
                  column.property,
                  signList.map((x) => x.sign),
                  [signList[t].sign],
                  0
                );
              }}
              style={backgroundImage}
            ></span>
          );
        }
      }
    } catch (e) {
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellUserSelectRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    let cellValue = row[column.property];
    let spans = [];

    try {
      const avatarSpan = (row) => {
        if (!!row.avatarUrl) {
          let avatarUrl =
            "background-image:url(" +
            row.avatarUrl +
            "?x-oss-process=image/resize,m_fixed,h_100,w_100)";
          return <span class="user-card-avatar" style={avatarUrl}></span>;
        } else {
          return (
            <span class="user-card-avatar">{row.name.substring(0, 1)}</span>
          );
        }
      };

      const isBindSpan = (row) => {
        if (row.bindStatus && row.bindStatus == 0) {
          return <span class="user-bind">(未绑定)</span>;
        }
      };

      const userCard = (row) => {
        let span = (
          <el-tooltip
            effect="light"
            placement="top"
            style="vertical-align:middle"
          >
            <div slot="content">
              <div>编号：{row.number}</div>
              <div>所属部门：{CellSysUserRender.deptRender(h, row)}</div>
            </div>
            <div class="user-card-wrpper">
              <span class="user-card">
                {avatarSpan(row)}
                {row.name}
              </span>
              <span>{isBindSpan(row)}</span>
            </div>
          </el-tooltip>
        );

        return span;
      };

      if (cellValue) {
        for (let i = 0; i < cellValue.length; i++) {
          let x = cellValue[i];
          // if (i != cellValue.length - 1){}
          //   spans.push(<span style="margin-right:5px">{x.name}</span>);
          // else spans.push(<span>{x.name}</span>);
          // 显示用户卡片
          spans.push(userCard(x));
        }
      }
    } catch (e) {
      console.log("渲染UserSelect出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellSysUserRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let spans = [];

    try {
      if (column.title == "创建者") {
        spans.push(<span class="user-card">{row.fillUserName}</span>);
      } else if (column.title == "拥有者") {
        spans.push();
      } else if (column.title == "填报人") {
        // spans.push(<span class="user-card">{row.fillUserName}</span>);

        const avatarSpan = (row) => {
          if (!!row.fillUserAvatarUrl) {
            let avatarUrl =
              "background-image:url('" +
              row.fillUserAvatarUrl +
              "?x-oss-process=image/resize,m_fixed,h_100,w_100')";
            return <span class="user-card-avatar" style={avatarUrl}></span>;
          } else {
            return (
              <span class="user-card-avatar">
                {row.fillUserName.substring(0, 1)}
              </span>
            );
          }
        };

        const isBindSpan = (row) => {
          if (row.bindStatus && row.bindStatus == 0) {
            return <span class="user-bind">(未绑定)</span>;
          }
        };

        spans.push(
          <el-tooltip
            effect="light"
            placement="top"
            style="vertical-align:middle"
          >
            <div slot="content">
              <div>编号：{row.fillUserNumber}</div>
              <div>所属部门：{CellSysUserRender.deptRender(h, row)}</div>
            </div>
            <div class="user-card-wrpper">
              <span class="user-card">
                {avatarSpan(row)}
                {row.fillUserName}
              </span>
              <span>{isBindSpan(row)}</span>
            </div>
          </el-tooltip>
        );
      }
    } catch (e) {
      console.log("渲染SysUser出错", e);
      console.log(row, column);
    }

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
  deptRender(h, row) {
    let agencyDeptList = row["agencyDeptList"];

    let deptList = store.state.dept.dept;
    let spans = [];

    if (agencyDeptList == null) return "--";

    for (let i = 0; i < agencyDeptList.length; i++) {
      let agencyDept = agencyDeptList[i];
      let agencyArray = agencyDept.parents.split(",");
      agencyArray.push(agencyDept.agencyDeptId);
      let agencyName = [];

      for (let j = 0; j < agencyArray.length; j++) {
        let dept = deptList.find((x) => x.agencyDeptId == agencyArray[j]);
        if (dept) agencyName.push(dept.deptName);
      }
      let subSpans = [];
      for (let t = 0; t < agencyName.length; t++) {
        subSpans.push(<span class="cell-dept-item">{agencyName[t]}</span>);
      }
      spans.push(
        <div class="cell-dept">
          {i + 1}. {subSpans}
        </div>
      );
    }
    return spans;
  },
};

export const CellPayStatusRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let spans = [];

    try {
      if (row.payStatusTxt === "已支付") {
        spans.push(
          <el-tooltip effect="light" placement="top">
            <div slot="content">
              支付时间: {CellPayStatusRender.payTimeRender(h, row)}
            </div>
            <span class="cursor">
              {row.payStatusTxt}(￥{row.orderPriceTxt})
            </span>
          </el-tooltip>
        );
      } else {
        spans.push(
          <span class="cursor">
            {row.payStatusTxt}(￥{row.orderPriceTxt})
          </span>
        );
      }
    } catch (e) {
      console.log("渲染PayStatus出错", e);
      console.log(row, column);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
  payTimeRender(h, row) {
    if (row.payStatusTxt == "已支付") {
      return <span>{row.payTime}</span>;
    } else {
      return [];
    }
  },
};

export const CellOCRCodeRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];

    let spans = [];
    try {
      let sizeName = ["B", "KB", "MB", "GB"];
      let countSize = (countFile, bracket = true) => {
        if (!countFile.size) {
          return "";
        }
        let size = countFile.size;
        let i = 0;

        while (size > 1024) {
          size = size / 1024;
          i++;
        }
        if (bracket) {
          return "[" + size.toFixed(2) + sizeName[i] + "]";
        } else {
          return size.toFixed(2) + sizeName[i];
        }
      };
      let fileType = (fileName) => {
        return "ico-" + fileName.substring(fileName.lastIndexOf(".") + 1);
      };
      let utils = require("@/utils/index.js");

      //提供查看文件上传服务的组件
      let providerName = ["MyVxeTable", "FieldWidget", "fillSelectCard"];
      let component;
      let clickHandler;
      let clickListHandler;
      for (let i = 0; i < providerName.length; i++) {
        component = utils.dispatchComponent.apply(this, [providerName[i]]);
        if (component) {
          clickHandler = component.showPictureView;
          clickListHandler = component.showPictureList;
          break;
        }
      }
      let width = 400;
      if (column.width) {
        width = parseFloat(column.width.replace(/px/, ""));
      }

      let ocr_fields = {
        HealthCode: {
          name: "姓名",
          idNumber: "身份证号",
          date: "最近检验日期",
          time: "最近检验时间",
          dateTime: "检测日期时间",
          color: "健康码颜色",
          remarks: "备注",
        },
        TravelCode: {
          color: "颜色",
          phoneNumber: "手机号",
          date: "日期",
          time: "时间",
          dateTime: "日期时间",
          reachedCity: "途径城市",
          riskArea: "途径中高风险地区",
        },
        NucleicAcidResult: {
          name: "姓名",
          idNumber: "身份证号",
          samplingDate: "采样日期",
          samplingTime: "采样时间",
          dateTime: "采样日期时间",
          testOrganization: "检测机构",
          testItem: "检测项目",
          testResult: "检测结果",
        },
      };

      let maxPics = Math.floor(width / 55);

      if (cellValue) {
        let infoSpans = [];
        if (cellValue.ocr_data)
          Object.keys(cellValue.ocr_data).forEach((data) => {
            if (ocr_fields[cellValue.ocr_type] && cellValue.ocr_data[data]) {
              infoSpans.push(
                <div style="line-height:20px;">
                  {ocr_fields[cellValue.ocr_type][data]}：
                  {cellValue.ocr_data[data]}
                </div>
              );
            }
          });

        for (let i = 0; i < cellValue.ocr_img.length; i++) {
          if (cellValue.ocr_img[i]) {
            let backgroundImage =
              "background-image:url('" +
              (cellValue.ocr_img[i].thumb
                ? cellValue.ocr_img[i].thumb
                : cellValue.ocr_img[i].url) +
              "');margin:0px 2px";
            spans.push(
              <el-tooltip
                popper-class="file-info"
                effect="light"
                placement="top"
                open-delay={300}
              >
                <div class="file-info" slot="content">
                  <div class="file-info-thumb">
                    <img
                      src={cellValue.ocr_img[i].thumb}
                      style="width:100%"
                      onClick={(event) => {
                        event.stopPropagation();
                        clickHandler(
                          column.property,
                          cellValue.ocr_img[i],
                          cellValue.ocr_img,
                          i
                        );
                      }}
                    />
                  </div>
                  <div class="">{infoSpans}</div>
                  <div class="file-info-extra" style="margin-top:10px">
                    <div class="file-info-size">
                      图片尺寸:{countSize(cellValue.ocr_img[i], false)}
                    </div>
                    <div class="file-info-actions">
                      <a href={cellValue.ocr_img[i].url} target="_blank">
                        <svg-icon iconClass="download2"></svg-icon>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div class={'cell-link-view ico-file '+fileType(cellValue[i].name)}></div> */}
                <span
                  class="picture-view"
                  onClick={(event) => {
                    event.stopPropagation();
                    clickHandler(column.property, cellValue[i], cellValue, i);
                  }}
                  style={backgroundImage}
                ></span>
              </el-tooltip>
            );
          }
        }
      }
    } catch (e) {
      console.log("渲染OCRCode出错", e);
      console.log(row, column, cellValue);
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellAuditRemarkRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;
    let utils = require("@/utils/index.js");

    let spans = [];
    let cellValue = row["reviewRemark"];
    // //提供查看文件上传服务的组件
    let providerName = ["MyVxeTable", "FieldWidget"];
    let component;
    let clickHandler;
    for (let i = 0; i < providerName.length; i++) {
      component = utils.dispatchComponent.apply(this, [providerName[i]]);
      if (component) {
        clickHandler = component.showSignRemark;
        break;
      }
    }

    // let backgroundImage = "background-image:url('" +cellValue + "');margin:0px 2px";
    // let image={
    //   thumb:cellValue,
    //   url:cellValue
    // }

    // spans.push(<span class="audit-sign-view" onClick={() => clickHandler(column.property, image, [image], 0) } style={backgroundImage} ></span>)
    spans.push(
      <span
        class="audit-remark cell-link-view"
        onClick={(event) => {
          event.stopPropagation();
          clickHandler(row);
        }}
      >
        {cellValue}
      </span>
    );

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const CellAuditSignRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let utils = require("@/utils/index.js");
    let spans = [];
    let cellValue = row["reviewSign"];
    //提供查看文件上传服务的组件
    let providerName = ["MyVxeTable", "FieldWidget", "fillSelectCard"];
    let component;
    let clickHandler;
    let clickListHandler;
    for (let i = 0; i < providerName.length; i++) {
      component = utils.dispatchComponent.apply(this, [providerName[i]]);
      if (component) {
        clickHandler = component.showPictureView;
        clickListHandler = component.showPictureList;
        break;
      }
    }

    let backgroundImage =
      "background-image:url('" + cellValue + "');margin:0px 2px";
    let image = {
      thumb: cellValue,
      url: cellValue,
    };

    spans.push(
      <span
        class="audit-sign-view"
        onClick={(event) => {
          event.stopPropagation();
          clickHandler(column.property, image, [image], 0);
        }}
        style={backgroundImage}
      ></span>
    );

    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const EditInputRender = {
  renderEdit(h, renderOpts, params) {
    let { row, column } = params;
    var el = null;
    var setValue = (el) => {
      row[column.field] = el.target.value;
    };
    var setKeyDown = () => {
      row.setEditStatus(true);
      row.editStatus = true;
    };

    setTimeout(() => {
      el = document.querySelector(".editable-input");
      if (el) {
        el.focus();
      }
    }, 200);

    return [
      <input
        type="text"
        id="editable_input"
        class="editable-input"
        value={row[column.field]}
        onKeydown={setKeyDown}
        onBlur={setValue}
      ></input>,
    ];
  },
  // 可编辑显示模板
  renderCell(h, renderOpts, params) {
    let { row, column } = params;
    return [<span>{row[column.field]}</span>];
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};

export const renderProvider = {
  region: CellRegionRender,
  address: CellAddressRender,
  university: CellUniversityRender,
  radio: CellRadioRender,
  select: CellRadioRender,
  checkbox: CellCheckboxRender,
  cascader: CellCascaderRender,
  rate: CellRateRender,
  "rich-editor": CellRichEditorRender,
  "sub-form": CellSubFormRender,
  "matrix-form": CellSubFormRender,
  "picture-upload": CellPictureUploaderRender,
  "file-upload": CellFileUploaderRender,
  sign: CellSignRender,
  "user-select": CellUserSelectRender,
  userselect: CellUserSelectRender,
  ownUser: CellSysUserRender,
  createUser: CellSysUserRender,
  fillUser: CellSysUserRender,
  payStatus: CellPayStatusRender,
  ocrcode: CellOCRCodeRender,
  "fill-select": CellFillSelectRender,
  "data-choose": CellDataChooseRender,
};

export const CellSysTimeRender = {
  renderDefault(h, renderOpts, params) {
    let { row, column } = params;
    let { events } = renderOpts;

    let cellValue = row[column.property];
    let spans = [];
    try {
      if (!cellValue) return "";
      return moment(cellValue).format("MM-DD HH:mm")
    } catch (e) {
    }
    return spans;
  },
  // 导出模板，例如导出插槽中自定义的内容
  exportMethod(params) {
    const { row, column } = params;
    return "自定义内容";
  },
};
