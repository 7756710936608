<template>
  <div v-cloak>
    <div class="detail-nav" v-if="standalone">
      <div class="detail-container app-inner-container">
        <div class="detail-left">
          <div class="detail-left-logo" @click="naviHome">
            <svg-icon icon-class="home" />
          </div>
          <div class="separator"></div>
          <span class="headerNow" @click="onBackDetail">{{
            formDetail.name
          }}</span>
          <template v-if="cur !== 1 && cur !== 2">
            <div class="separator"></div>
            <span class="headerNow" style="cursor: inherit;">{{
              breadcrumbName
            }}</span>
          </template>
        </div>
        <title-user-menu name-color="#2B344199" icon-color="#2B344199"></title-user-menu>
      </div>
    </div>
    <div class="app-container form-detail-container" :style="[
          {
            width: cur == 5 || !standalone ? '100%' : '',
            top: !standalone ? '0px' : '56px',
            marginTop: standalone ? '16px' : '0px',
            maxWidth: '100%'
          }
        ]">
      <div class="forms-content" :style="[
        { width: cur == 5 || cur == 3 || cur == 4 ? '100%' : '100%' },
        { background: cur == 1 ? 'none' : '#ffffff' }
      ]">
        <!--{width:(cur == 5 ? (gridOptions.columns.length * 200 + 40) + 'px' : '100%')}, {height: cur == 5 ? fillDataHeight + 'px' : 'auto'} -->
        <div v-show="cur == 1" class="form-cur1-wrapper" style="">
          <!-- <detailFormInfoSkeleton v-show="formLoading"/> -->
          <div class="form-detail-top-part" :class="[viewName]">
            <el-row :gutter="20" class="el-row" type="flex" style="margin: 0px 0px 10px">
              <el-col :span="7" :xs="24" style="padding: 0; width: 200px; height: 120px;">
                <div class="form-img">
                  <!-- <img :src="formDetail.cover + '?x-oss-process=image/resize,m_fixed,h_150,w_300' " v-if="formDetail && formDetail.cover" /> -->
                  <div class="img" :style="{backgroundImage:'url('+formDetail.cover + '?x-oss-process=image/resize,m_fixed,h_150,w_300)' }" v-if="formDetail && formDetail.cover" />
                </div>
              </el-col>
              <el-col :span="19" :xs="24" style="padding: 0; margin-left: 20px; position: relative; width:100%;">
                <div class="form-info">
                  <h1 class="clearfix form-title">
                    <div class="form-title-left">
                      <span style="overflow: hidden; text-overflow: ellipsis;white-space: nowrap;">{{ formDetail.name }}</span>
                      <span style="width: 60px;  margin-left: 20px">
                        <el-tag v-if="!formDetail.isStop" size="mini" class="form-state-tag stop" type="info">已暂停</el-tag>
                        <el-tag v-else size="mini" class="form-state-tag" :class="getTagClass(formDetail.formStateTxt)">{{
                          formDetail.formStateTxt }}</el-tag>
                      </span>
                    </div>
                    <div class="form-share-warp">
                      <div class="btn-wrapper" @click="onCollectClick">
                        <el-button size="mini" class="collect-btn btn" :class="{ active: formDetail.favorite }">
                          <svg-icon class="btn-icon" :icon-class="formDetail.favorite
                            ? 'formListCollect'
                            : 'formListUnCollect'
                            " />
                          <span>收藏</span>
                        </el-button>
                      </div>
                      <div class="btn-wrapper" @click="onShareClick">
                        <el-button size="mini" class="btn">
                          <svg-icon class="btn-icon" icon-class="formListShare" />
                          <span>分享</span>
                        </el-button>
                      </div>
                    </div>
                  </h1>
                  <div class="form-detail-top-bottom">
                    <div class="clearfix form-date" style="position:relative">
                      <p class="fill-date">
                        <span style="width: 75px; display: inline-block;">开始时间：</span>
                        <span>{{
                          formDetail.fillStartTime
                          ? formDetail.fillStartTime
                          : "——"
                        }}</span>
                      </p>
                      <div style="position:absolute;right:0px;top:10px;">
                        <formTagEditor v-model="formDetail.tagList" :readonly="true"></formTagEditor>
                      </div>
                    </div>
                    <div class="clearfix form-date">
                      <p class="fill-date">
                        <span style="width: 75px; display: inline-block;">截止时间：</span>
                        <span>{{
                          formDetail.fillEndTime ? formDetail.fillEndTime : "——"
                        }}</span>
                      </p>
                    </div>
                    <div class="clearfix form-date">
                      <div class="fill-date">
                        <span style="width: 75px; display: inline-block;">填报人数：</span>
                        <span>{{
                          formDetail.fillNum != null
                          ? formDetail.fillNum + "人可填"
                          : "填报人数无限制"
                        }}</span>
                        <span v-if="formDetail.fillNumPublic">,</span>
                        <span v-if="formDetail.fillNumPublic">
                          {{
                            formDetail.completedFillNum != null
                            ? formDetail.completedFillNum + "人已填"
                            : ""
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="clearfix form-date">
                      <div class="fill-date">
                        <span style=" width: 75px; display: inline-block;">创建机构：</span>
                        <span>{{
                          formDetail.founder
                          ? formDetail.founder
                          : formDetail.createName
                        }}</span>
                        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="formDetail.agencyId" />
                        <!--                          <span v-else>（个人）</span>-->
                      </div>
                    </div>
                    <div class="form-fill-btn">
                      <el-button type="primary" size="medium" @click="onFillClickCheck" :disabled="disableFill||formLoading" class="btn">{{ formDetail.startFillText || "开始填报" }}</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="el-row" type="flex" style="margin-left: 0; margin-right: 0;height: 30px; padding: 0; display: flex; display: -webkit-flex; align-items: center;justify-content:space-between;">
              <el-col :span="8" :xs="24" type="flex" style="padding: 0; width: 200px;" class="form-num-wrap">
                <div class="form-total-num">
                  <svg-icon icon-class="formDetailSeeNum" style="margin-right: 5px;font-size: 14px;" />
                  <span>{{ formDetail.viewNum || 0 }}</span>
                </div>
                <div class="form-total-num">
                  <svg-icon icon-class="formDetailShareNum" style="margin-right: 5px;font-size: 14px;" />
                  <span>{{formDetail.shareNum || 0 }}</span>
                </div>
                <div class="form-total-num">
                  <svg-icon icon-class="formDetailCollectNum" style="margin-right: 5px;font-size: 14px;" />
                  <span>{{ formDetail.favoriteNum || 0 }}</span>
                </div>
              </el-col>
              <el-col :span="9" :xs="16" style="padding: 0px 0px 0px 20px; position: relative; box-sizing: border-box;flex-grow:1;width:auto;">
                <template v-if="formDetail.name && !formDetail.enableView">
                  <el-button class="operate-btn" size="small" @click="onTabClick(3)" :disabled="formLoading" v-show="formAuth.currentQueryFillListAuth">我的填报</el-button>
                  <el-button class="operate-btn" size="small" @click="onTabClick(4)" :disabled="formLoading" v-show="formAuth.currentQueryReviewListAuth">审核列表</el-button>
                  <el-button class="operate-btn" size="small" @click="onTabClick(5)" :disabled="formLoading" v-show="formAuth.currentQueryDataListAuth">管理列表</el-button>
                  <!-- TODO 视图功能暂时去掉了 -->
                  <!-- <el-button class="operate-btn" size="small" @click="onTabClick(6)" v-show="formAuth.currentQueryDataListAuth">视图</el-button> -->
                  <el-button class="operate-btn" size="small" @click="onTabClick(8)" :disabled="formLoading" v-show="formAuth.currentStatisticsAuth">统计报表</el-button>
                </template>
              </el-col>
              <el-col :span="9" :xs="8" style="padding: 0px 0px 0px 20px; position: relative; box-sizing: border-box;flex-grow:0;flex-shrink:0;text-align:right;">
                <editLinks :formDetail.sync="formDetail" :loginUserId="loginUserId" :loginAgencyUserId="loginAgencyUserId" formType="form" @refreshList="refreshList" @formStopUpdate="handleFormStopUpdate" @home="returnProjectHome" :returnType="standalone ? 'back' : 'refresh'"></editLinks>
              </el-col>
            </el-row>
          </div>
          <div class="form-info-tab-bottom">
            <template v-if="viewName==='baseView'">
              <div class="tab-content-nav" v-if="formDetail.details != '' || formCustomFileList.length != 0">
                <div class="content-nav-title" @click="onFormInfoTabClick(1)" :class="{ active: formInfoCur === 1 }" v-if="formDetail && formDetail.details != ''">
                  <svg-icon icon-class="formDetailInfo" />{{ formCustomDetails }}
                </div>
                <div class="content-nav-title" @click="onFormInfoTabClick(2)" :class="{ active: formInfoCur === 2 }" v-if="formCustomFileList && formCustomFileList.length > 0">
                  <svg-icon icon-class="formDetailFile" />{{ formCustomFile }}
                </div>
              </div>
              <div class="info-tab-content" v-if="formInfoCur === 1">
                <div class="ql-editor details-intro" vhtml="formDetail.details">
                  <pre v-html="formDetail.details"></pre>
                </div>
              </div>
              <div class="info-tab-content" v-if="formInfoCur === 2">
                <Download :fileList="formCustomFileList"></Download>
                <!-- <div class="details-file">
                    <a class="file-wrapper"  :href="item.url" target="_blank" v-for="(item,index) in formCustomFileList" click="onDownloadFormFileClick(item)" :key="index">
                      <svg-icon v-if="item.suffixType === 'image'" icon-class="annex_image" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'word'" icon-class="annex_word" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'excel'" icon-class="annex_excel" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'pdf'" icon-class="annex_pdf" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'audio'" icon-class="annex_audio" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'video'" icon-class="annex_video" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'zip'" icon-class="annex_zip" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'ppt'" icon-class="annex_ppt" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon v-if="item.suffixType === 'other'" icon-class="annex_other" style="width: 22px; height: 22px;vertical-align: middle;" />
                      <svg-icon class="down-icon" icon-class="download2" />
                      <span>{{ item.name }}</span>
                    </a>
                  </div> -->
              </div>
              <div v-if="formDetail.details == '' && formCustomFileList.length == 0">
                <el-empty :image-size="200" description="暂无填报内容"></el-empty>
              </div>
            </template>
            <template v-else-if="viewName==='webView'">
              <div style="text-align:center">
                <WebViewIntroduce :formDesign="formDesign" :fileList="formCustomFileList" :projectDetail="formDetail"></WebViewIntroduce>
              </div>
            </template>
          </div>
        </div>
        <!-- 填报内容 -->
        <div v-show="cur == 2" style="padding: 20px;">
          <!-- 如果是新表单 -->
          <!-- <el-row :gutter="20" type="" v-show="formVer==2" style="margin: 0;background-color:white;">
            <h1 class="details-title">
              <el-button type="warning" icon="el-icon-upload" size="mini" v-if="formDetail.isBatchFill" @click="onHandleImportClick" v-show="isImport">批量填报</el-button>
            </h1>
            <div v-if="isHistoryFill && formDetail && formDetail.historyForm" style="margin-top: 30px;" @click="onSeeHistoryFillDetail">
              <el-button size="mini" type="text">{{formDetail.historyForm.name}}</el-button>
              <el-button size="mini" type="text">查看填报内容</el-button>
            </div>
            <div class="form-fill-detail">
              <el-alert v-if="formDetail.fillAnony" title="已开启匿名提交功能，您的个人信息将不会被管理员看到" show-icon type="warning" :closable="false"></el-alert>
              <v-form-render ref="vformRender" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false">
                <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
                  <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
                </template>
              </v-form-render>
              <div slot="footer" class="fill-detail-footer" v-if="!isEditFill">
                <el-button size="large" type="primary" @click.native="onSaveFillClick()" style="padding: 12px 30px;" :disabled="fillBtnClick">提交</el-button>
              </div>
            </div>
          </el-row> -->
        </div>
        <!--确认填报（通过管理员批量导入的填报，需要填报人确认）类似查看、修改填报-->
        <div v-show="cur == 9" class="form-info-tab-content" style="padding: 20px;">
          <el-row :gutter="20" class="el-row" type="" style="padding: 0 20px">
            <div class="form-fill-detail">
              <v-form-render ref="vformConfirmRender" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false">
                <template v-slot:myVxeTable="{
                  fields,
                  datas,
                  isAdmin,
                  viewItem,
                  removeItem
                }">
                  <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
                </template>
              </v-form-render>
              <div slot="footer" class="fill-detail-footer" v-if="!isEditFill">
                <el-button size="mini" type="primary" @click.native="onFillConfirmClick" style="padding: 12px 30px;" :disabled="fillBtnClick">确认</el-button>
              </div>
            </div>
          </el-row>
        </div>
        <!-- 填报列表 我的填报 -->
        <div v-show="cur == 3" style="height: 100%;display: flex; flex-direction: column; padding-top: 10px;">
          <div style="height: 100%;display: inline-block;flex-grow: 1;flex-shrink: 1;overflow-y: auto;overflow:hidden;" :style="`height:${viewName=='webView'?'500px':'100%'};`">
            <MyVxeTable ref="fillGridTable" v-if="cur == 3" :columns="fillGridOptions.columns" :loading="fillGridOptions.loading" :data="fillGridOptions.data" :params="fillGridOptions.params" @page-change="handleDataPageChange" @view-picture="onHandlePreviewImg" @filter-change="fillFilterChanged"
                        @sort-change="fillSortChanged" :agencyId="formDetail.agencyId" :formJson="formJson" :formDetail="formDetail" :rowEditable="fillTableRowEditable" @setEditRowIndex="setEditRowIndex" @clear-global-search="clearGlobalSearch">
              <template #toolbar_search>
                <el-input v-model="fillQueryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." @clear="onHandleResetFillClick" clearable @keyup.enter.native="onHandleQueryFillClick"></el-input>
              </template>
              <template #toolbar_button>
                <!-- <el-tooltip class="item" effect="dark" content="草稿箱" placement="top-start"> -->
                <el-button type="default" icon="el-icon-box" size="mini" @click="openDraftBox">草稿箱</el-button>
                <!-- </el-tooltip> -->
                <el-button type="success" size="mini" @click="onBatchPayHandler(fillGridOptions.params.selectedRowKeys)" :disabled="disableFill" v-if="formPay" style="height:29px;">批量支付</el-button>
                <el-button type="primary" size="mini" @click="onFillClickCheck" style="height:29px;" :disabled="disableFill">继续填报</el-button>

                <draft ref="draft" :formAuth="formAuth" :formId="formId" :formJson="formJson" :formDetail="formDetail" @on-draft-submit="onHandleResetFillClick"></draft>
              </template>
              <template v-slot:status_text>
                <vxe-column title="当前状态" width="150" field="status" header-class-name="sysfield" :filters="[{ data: {} }]" :filter-render="{ name: 'filter-status' }" :params="filters.status">
                  <!-- <vxe-column title="当前状态" width="150" header-class-name="sysfield" :filters="[{ data: []}]" :filter-render="{name:'filter-status'}" :params="filters.status"> -->
                  <template #default="{ row }">
                    <StatusCode :statusCode="row.status" :row="row" :fillStatusCode="row.fillStatus"></StatusCode>
                  </template>
                </vxe-column>
              </template>
              <template #operate>
                <vxe-column :field="statusTxt" min-width="150" slots:default="operate" fixed="right" header-class-name="sysfield" align="center" type="operate">
                  <template v-slot:header>
                    <span>操作</span>
                  </template>
                  <template #default="{ row, rowIndex }">
                    <template v-if="editRowIndex !== rowIndex">
                      <el-button type="text" size="mini" status="primary" content="查看" @click="onViewFillClick(row['fillId'], 'FILL', row)">查看</el-button>
                      <template v-if="formDetail.auditAuth">
                        <el-button type="text" size="mini" status="primary" content="修改" @click="onEditFillClick(row['fillId'], 'FILL', row)" v-if="row['status'] == 1 ||
                              row['status'] == 2 ||
                              row['status'] == 5 ||
                              row['status'] == 9 ||
                              row['status'] == 13
                              " :disabled="disableEditFill && disableUserFill(row)">修改</el-button>
                      </template>

                      <el-button type="text" size="mini" status="primary" content="修改" @click="onEditFillClick(row['fillId'], 'FILL', row)" v-else :disabled="disableEditFill && disableUserFill(row)">修改</el-button>
                      <el-button type="text" size="mini" status="primary" content="确认" @click="onConfirmFillBtnClick(row['fillId'])" v-if="row['fillStatus'] == 2">确认</el-button>
                      <template v-if="formDetail.pay && disableFill">
                        <el-tooltip class="item" effect="dark" content="表单已暂停，无法支付" placement="top-start">
                          <span>
                            <el-button type="text" size="mini" status="primary" content="支付" disabled v-if="row['payStatus'] == 2">支付</el-button>
                            <!-- <vxe-button type="text" style="margin-left:0px" status="primary" content="支付" disabled v-if="row['payStatus'] == 2"></vxe-button> -->
                          </span>
                        </el-tooltip>
                      </template>
                      <template v-if="formDetail.pay && !disableFill">
                        <!-- <vxe-button type="text" style="margin-left:0px" status="primary" content="支付" @click="onPayFillClick(row['fillId'], 2)" v-if="row['payStatus'] == 2"></vxe-button> -->
                        <el-button type="text" size="mini" status="primary" content="支付" @click="onPayFillClick(row['fillId'], 2)" v-if="row['payStatus'] == 2">支付</el-button>
                      </template>
                      <!-- v4.0.3 【有支付的表单，给管理员开启删除功能】 2.个人填报列表中，如果是未支付状态，则可以自己删除，如果已支付，则删除按钮变为灰色 -->
                      <!-- 支付状态：可删除：1无需支付、2待支付    不可删除：3已支付、4确认支付 -->
                      <!-- Status 审核状态：1无需审核、2待一级审核、3一级审核通过、4、一级审核不通过、5一级审核返回修改、6待二级审核、7二级审核通过、8二级审核不通过、9二级审核返回修改 -->
                      <template v-if="row.payStatus <= 2">
                        <!-- <vxe-button type="text" style="margin-left:0px" status="primary" content="删除" @click="onDeleteFillClick(row['fillId'])" v-if="row['status'] == 1 || row['status'] == 2" :disabled="formDetail.isStop?false:true"></vxe-button> -->
                        <el-button type="text" size="mini" status="primary" content="删除" @click="onDeleteFillClick(row['fillId'])" v-if="row['status'] == 1 || row['status'] == 2" :disabled="disableFill">删除</el-button>
                      </template>
                      <template v-if="row.payStatus > 2">
                        <!-- <vxe-button type="text" style="margin-left:0px" status="primary" content="删除" @click="onDeleteFillClick(row['fillId'])" v-if="row['status'] == 1 || row['status'] == 2" disabled></vxe-button> -->
                        <el-button type="text" size="mini" status="primary" content="删除" @click="onDeleteFillClick(row['fillId'])" v-if="row['status'] == 1 || row['status'] == 2" disabled>删除</el-button>
                      </template>
                      <el-dropdown v-if="isShowFillListMoreButton && (!formDetail.exportAfterAudit || (formDetail.exportAfterAudit && row['status'] == 14))" style="float:initial;margin-left:10px">
                        <span class="el-dropdown-link">
                          <el-button type="text" size="mini">更多...
                            <el-icon class="el-icon--right">
                              <arrow-down />
                            </el-icon>
                          </el-button>
                        </span>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <!-- 仅允许审核后的导出 -->
                            <template v-if="!formDetail.exportAfterAudit || (formDetail.exportAfterAudit && row['status'] == 14)">
                              <el-dropdown-item v-show="formDetail.exportIsPerson &&
                              formDetail.exportTemplate != null &&
                              !formDetail.exportIsPdf
                              ">
                                <el-button type="text" status="primary" content="WORD导出" @click="onExportMyFill(row['fillId'], 'Word',row)">WORD导出</el-button>
                              </el-dropdown-item>
                              <el-dropdown-item v-show="formDetail.exportIsPerson &&
                              formDetail.exportTemplate != null &&
                              formDetail.exportIsPdf
                              ">
                                <el-button type="text" status="primary" content="PDF导出" @click="onExportMyFill(row['fillId'], 'Pdf',row)">PDF导出</el-button>
                              </el-dropdown-item>
                            </template>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                    <template v-if="editRowIndex === rowIndex">
                      <!-- 显示提交修改和取消修改按钮 -->
                      <el-button type="text" size="mini" status="danger" @click="() => {
                        $refs.gridTable.cancelEditFillClick(
                          { row, rowIndex },
                          true
                        );
                      }
                        ">取消</el-button>
                      <el-button type="text" size="mini" status="primary" @click="() => {
                        $refs.gridTable.saveEditFillClick(
                          { row, rowIndex },
                          true
                        );
                      }
                        ">提交</el-button>
                    </template>
                  </template>
                </vxe-column>
              </template>
            </MyVxeTable>
          </div>
        </div>
        <!-- 审核列表 -->
        <div v-show="cur == 4" style="height: 100%;display: flex; flex-direction: column;padding-top: 10px;" :style="`height:${viewName=='webView'?'500px':'100%'};`">
          <div style="height: 100%;display: inline-block;flex-grow:1;flex-shrink: 1;overflow-y: auto;overflow:hidden;">
            <MyVxeTable ref="auditTable" v-if="cur == 4" :columns="reviewGridOptions.columns" :loading="reviewGridOptions.loading" :data="reviewGridOptions.data" :params="reviewGridOptions.params" :pageConfig="reviewGridOptions.pagerConfig" :selectAllRowsHandler_="GetAllDataRows"
                        :allowCheckAllPagesData="false" @page-change="handleReviewPageChange" @view-picture="onHandlePreviewImg" @filter-change="reviewFilterChanged" @sort-change="reviewSortChanged" :agencyId="formDetail.agencyId" :formJson="formJson" :formDetail="formDetail"
                        :rowEditable="reviewTableRowEditable" @setEditRowIndex="setEditRowIndex">
              <template #toolbar_search>
                <el-input v-model="queryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." @clear="onHandleResetReviewClick" clearable @keyup.enter.native="onHandleQueryReviewClick"></el-input>
              </template>
              <template #toolbar_button>
                <el-button type="primary" size="mini" :disabled="reviewGridOptions.params.selectedRowKeys.length == 0
                  " @click="
    onBatchReviewClick(
      reviewGridOptions.params.selectedRowKeys,
      reviewGridOptions.params.isAllDataSelected,
      reviewGridOptions.params.excludeIds
    )
    " :title="reviewGridOptions.params.isAllDataSelected?'批量审核时无法审核全部记录':''">批量审核</el-button>
              </template>
              <template v-slot:status_text>
                <!-- <vxe-column title="当前状态" width="150" :filters="[{ data: []}]" header-class-name="sysfield" :filter-render="{name:'filter-status'}" :params="filters.status"> -->
                <vxe-column title="当前状态" width="150" field="status" header-class-name="sysfield" filter-type="status" :isSysField="true" :filters="[{ data: {} }]" :filter-render="{ name: 'filter-status' }" :params="filters.status">
                  <template #default="{ row }">
                    <StatusCode :statusCode="row.status" :row="row" :fillStatusCode="row.fillStatus"></StatusCode>
                  </template>
                </vxe-column>
              </template>
              <template v-slot:operate>
                <vxe-column min-width="150" slots:default="operate" fixed="right" header-class-name="sysfield" align="center" type="operate">
                  <template v-slot:header>
                    <span>操作</span>
                  </template>
                  <template #default="{ row, rowIndex }">
                    <template v-if="editRowIndex !== rowIndex">
                      <!-- <vxe-button type="text" status="primary" content="查看" @click="onEditFillClick(row['fillId'], 1)">查看</vxe-button> -->
                      <el-button type="text" size="mini" status="primary" content="查看" @click="onViewFillClick(row['fillId'], 'AUDIT', row)">查看</el-button>

                      <template v-if="row['auditButton'] && (row['status'] == '2' || row['status'] == '10'|| row['status'] =='6')">
                        <el-button type="text" size="mini" status="primary" content="审核" @click="onAuditFillClick( row['fillId'], row ) " v-if="row['status'] == '2' || row['status'] == '6' || row['status'] == '10' ">审核</el-button>
                      </template>

                      <!-- 0: {{row['auditButton']}}
                      A:{{formDetail.auditAuthReUpdate}}
                        B:{{isShowAuthReUpdate(row)}} -->

                      <template v-if="formDetail.auditAuthReUpdate && isShowAuthReUpdate(row)">
                        <el-button type="text" size="mini" status="primary" content="修改审核" @click="onEditReviewClick(row)">修改审核</el-button>
                      </template>
                    </template>
                    <template v-if="editRowIndex === rowIndex">
                      <!-- 显示提交修改和取消修改按钮 -->
                      <el-button type="text" size="mini" status="danger" @click="() => {
                        $refs.gridTable.cancelEditFillClick(
                          { row, rowIndex },
                          true
                        );
                      }
                        ">取消</el-button>
                      <el-button type="text" size="mini" status="primary" @click="() => {
                        $refs.gridTable.saveEditFillClick(
                          { row, rowIndex },
                          true
                        );
                      }
                        ">提交</el-button>
                    </template>
                  </template>
                </vxe-column>
              </template>
            </MyVxeTable>
            <!-- <vxe-pager align="right" :current-page.sync="reviewGridOptions.pagerConfig.currentPage" :page-size.sync="reviewGridOptions.pagerConfig.pageSize" :total="reviewGridOptions.pagerConfig.total" @page-change="handleReviewPageChange"> </vxe-pager> -->
          </div>
        </div>
        <!-- 管理列表 -->
        <div v-show="cur == 5" style="height: 100%;padding-top: 10px;">
          <MyVxeTable ref="gridTable" v-if="cur == 5" :columns="gridOptions.columns" :loading="gridOptions.loading" :data="gridOptions.data" :params="gridOptions.params" :pageConfig="gridOptions.pagerConfig" :selectAllRowsHandler_="GetAllDataRows" @page-change="handleDataPageChange"
                      @view-picture="onHandlePreviewImg" @filter-change="gridFilterChanged" @sort-change="gridSortChanged" :agencyId="formDetail.agencyId" :formJson="formJson" :formDetail="formDetail" :rowEditable="gridTableRowEditable" @setEditRowIndex="setEditRowIndex"
                      @clear-global-search="clearGlobalSearch">
            <template #toolbar_search>
              <el-input v-model="dataQueryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." @clear="onHandleResetDataClick" clearable @keyup.enter.native="onHandleQueryDataClick"></el-input>
            </template>
            <template #toolbar_share>
              <el-tooltip class="item" effect="dark" content="分享" placement="top">
                <el-link icon="el-icon-share" :underline="false" @click="onHandleShareClick"></el-link>
              </el-tooltip>
            </template>
            <template #toolbar_function>
              <el-button :type="item.backGround" :icon="item.icon" @click="doDataRefreshHandler(item)" v-for="(item,index) in dataRefreshActions" :key="index">{{item.name}}</el-button>
              <el-button v-if="showStatisticRefreshButton(formDetail)" @click="refreshStatisticHandler(formDetail)">数据刷新</el-button>
              <el-button v-if="showStatusButtonShangHaiShiJue(formDetail)" @click="shangHaiShiJueChouJian(formDetail)">论文抽取</el-button>
              <el-button v-if="showStatusButtonShangHaiShiJue(formDetail)" @click="shangHaiShiJuePiPei(formDetail)">专家自动匹配</el-button>
              <!-- <el-button v-if="showStatisticRefreshButton(formDetail)>=0" @click="refreshFjnus">数据刷新</el-button> -->
              <!-- 显示北方工业大学的大创项目管理--项目综合管理数据刷新 -->
              <!-- <el-button v-if="showStatisticRefreshButton_dachuang(formDetail)" @click="refreshFjnusDaChuang">数据刷新</el-button> -->
              <!-- 英才数据刷新 -->
              <!-- <el-button v-if="showStatisticRefreshButton_dachuang1(formDetail)" @click="refreshYingCaiDaChuang">数据刷新</el-button> -->
              
              <el-dropdown style="float:right;margin-left:10px">
                <el-button style="background: #f2f5f7;border: none;" :style="gridOptions.params.selectedRowKeys.length > 0
                  ? 'border: 1px solid #3476f0; '
                  : ''
                  ">
                  批量操作 {{gridOptions.params.selectedRowKeys.length > 0 ? gridOptions.params.selectedRowKeys.length + " 条"
                    : (gridOptions.params.isAllDataSelected && gridOptions.params.allDataTotal ? gridOptions.params.allDataTotal + " 条" : '' )
                  }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onExportForGridClick('excel', null)" data-tag="excel">导出Excel</el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="onExportForGridClick('picexcel',null)" data-tag="picexcel">导出带图片的Excel</el-dropdown-item> -->
                  <!-- <el-dropdown-item @click.native="onExportForGridClick('excel2',null)" data-tag="excel2">Excel的合并导出</el-dropdown-item> -->
                  <el-dropdown-item @click.native="onExportForGridClick('file',null)" data-tag="file">导出附件</el-dropdown-item>
                  <el-dropdown-item @click.native="onExportForGridClick('word',null)" data-tag="word">导出word/pdf文档
                    <el-tooltip content="仅能导出审核完成的数据" placement="top-start" v-if="formDetail.exportAfterAudit">
                      <i class="el-icon-info" />>
                    </el-tooltip>
                  </el-dropdown-item>
                  <el-divider></el-divider>
                  <el-dropdown-item @click.native="onHandleImportNotSelfClick()">批量导入</el-dropdown-item>
                  <el-dropdown-item @click.native="batchUpdateDataForExcel = true">批量更新数据</el-dropdown-item>
                  <el-dropdown-item @click.native="showMultiEdit = true" :disabled="gridOptions.params.selectedRowKeys.length == 0 && !gridOptions.params.isAllDataSelected">
                    批量编辑
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="showMultiDelete = true" :disabled="gridOptions.params.selectedRowKeys.length == 0 && !gridOptions.params.isAllDataSelected">
                    批量删除
                  </el-dropdown-item>
                  <el-divider></el-divider>
                  <el-dropdown-item @click.native="onHandleNoticeClick(formDetail)">发布通知</el-dropdown-item>
                  <el-divider v-if="formDetail.pay"></el-divider>
                  <!-- <el-dropdown-item command="batch-pay">支付</el-dropdown-item> -->
                  <el-dropdown-item v-if="formDetail.pay" @click.native="onHandleEditPayPriceClick()" :disabled="gridOptions.params.selectedRowKeys.length == 0">修改订单金额</el-dropdown-item>
                  <el-dropdown-item v-if="formDetail.pay" @click.native="onHandleConfirmPayClick()" :disabled="gridOptions.params.selectedRowKeys.length == 0">调整支付状态</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <multiEdit :showMultiEdit.sync="showMultiEdit" :formJson="formJson" :formId="formId" :refresh="onHandleQueryDataClick" :formOptionData="optionData" :fillIds="gridOptions.params.selectedRowKeys" :fillCacheKey="gridOptions.fillCacheKey" :excludeIds="gridOptions.params.excludeIds"
                         :selectAll="gridOptions.params.isAllDataSelected"></multiEdit>
              <multiDelete :showMultiDelete.sync="showMultiDelete" :formJson="formJson" :formId="formId" :refresh="onHandleQueryDataClick" :formOptionData="optionData" :fillIds="gridOptions.params.selectedRowKeys" :fillCacheKey="gridOptions.fillCacheKey" :excludeIds="gridOptions.params.excludeIds"
                           :formName="formDetail.name" :selectAll="gridOptions.params.isAllDataSelected"></multiDelete>
            </template>
            <template #toolbar_button>
              <el-button type="primary" @click="onFillClickCheck" :disabled="disableFill">开始填报</el-button>
            </template>
            <template v-slot:status_text>
              <vxe-column title="当前状态" width="150" field="status" header-class-name="sysfield" filter-type="status" :isSysField="true" :filters="[{ data: {} }]" :filter-render="{ name: 'filter-status' }" :params="filters.status"><!--  状态列始终显示，去掉了v-if -->
                <template #default="{ row }">
                  <StatusCode :statusCode="row.status" :row="row" :fillStatusCode="row.fillStatus"></StatusCode>
                </template>
              </vxe-column>
            </template>
            <template v-slot:operate>
              <vxe-column min-width="150" slots:default="operate" fixed="right" align="center" type="operate">
                <template v-slot:header>
                  <span>操作</span>
                </template>
                <!-- 操作列的普通模式 -->
                <template #default="{ row, rowIndex }">
                  <template v-if="editRowIndex !== rowIndex">
                    <el-button type="text" status="primary" size="mini" content="查看" @click="onViewFillClick(row['fillId'], 'MANAGE', row)">查看</el-button>
                    <template v-if="formDetail.auditAuth">
                      <el-button type="text" size="mini" status="primary" content="修改" @click="onEditFillClick(row['fillId'], 'MANAGE', row)" v-if="true ||
                          row['status'] == 1 ||
                          row['status'] == 2 ||
                          row['status'] == 5 ||
                          row['status'] == 9
                          ">修改</el-button>
                    </template>
                    <el-button v-else type="text" size="mini" status="primary" content="修改" @click="onEditFillClick(row['fillId'], 'MANAGE', row)">修改</el-button>
                    <!-- v4.0.3调整: 给有支付的表单管理员开启删除功能，只有创建者的管理员页面，有删除按钮 -->
                    <!-- 无支付表单所有管理权限人都可删除，有支付的只有创建者可以删除 -->
                    <template v-if="!formDetail.pay ||
                      (formDetail.pay && formDetail.userId == loginUserId)
                      ">
                      <el-button type="text" size="mini" status="warning" content="删除" @click="
                        onDeleteManageFillClick(row['fillId'], row['formId'])
                        " v-if="true || row['status'] == 1 || row['status'] == 2">删除</el-button>
                    </template>
                    <el-dropdown v-if="isShowAdminListMoreButton && (!formDetail.exportAfterAudit || (formDetail.exportAfterAudit && row['status'] == 14))" style="float:initial;margin-left:10px">
                      <span class="el-dropdown-link">
                        <el-button type="text" size="mini">更多...
                          <el-icon class="el-icon--right">
                            <arrow-down />
                          </el-icon>
                        </el-button>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!-- 仅允许审核后的导出 -->
                          <template v-if="!formDetail.exportAfterAudit || (formDetail.exportAfterAudit && row['status'] == 14)">
                            <el-dropdown-item v-show="formDetail.exportTemplate &&
                            formDetail.exportTemplate != null &&
                            !formDetail.exportIsPdf
                            ">
                              <el-button type="text" icon="el-icon-download" status="primary" content="WORD导出" @click="onExportMyFill(row['fillId'], 'Word')">WORD导出</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item v-show="formDetail.exportTemplate &&
                            formDetail.exportTemplate != null &&
                            formDetail.exportIsPdf
                            ">
                              <el-button type="text" icon="el-icon-download" status="primary" content="PDF导出" @click="onExportMyFill(row['fillId'], 'Pdf')">PDF导出</el-button>
                            </el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                  <template v-if="editRowIndex === rowIndex">
                    <!-- 显示提交修改和取消修改按钮 -->
                    <el-button type="text" size="mini" status="danger" @click="() => {
                      $refs.gridTable.cancelEditFillClick(
                        { row, rowIndex },
                        true
                      );
                    }
                      ">取消</el-button>
                    <el-button type="text" size="mini" status="primary" @click="() => {
                      $refs.gridTable.saveEditFillClick(
                        { row, rowIndex },
                        true
                      );
                    }
                      ">提交</el-button>
                  </template>
                </template>
              </vxe-column>
            </template>
          </MyVxeTable>
        </div>
        <div v-if="cur == 6" style="height: 100%;padding-top: 10px;">
          <!-- <ag-grid :formId="formId" :formDetail="formDetail" :formJson="formJson" :showMultiEdit="showMultiEdit" :showMultiDelete="showMultiDelete" :formOptionData="optionData" :fillCacheKey="gridOptions.fillCacheKey" :excludeIds="gridOptions.params.excludeIds" :share="onHandleShareClick"
                   :fillInsert="onFillClickCheck" :viewFill="onViewFillClick" :editFill="onEditFillClick" :deleteFill="onDeleteManageFillClick" :fillSelectView="showFillView" :export="onExportForGridClick" :batchImport="onHandleImportNotSelfClick" :batchPay="onBatchPayHandler"
                   :batchEditPayPrice="onHandleEditPayPriceClick" :batchConfirmPay="onHandleConfirmPayClick" :notice="onHandleNoticeClick"></ag-grid> -->
        </div>
        <!-- 统计报表 -->
        <div v-show="cur == 8" style="padding: 20px;height:100%;overflow-y:auto">
          <div class="chart-desc-container">
            <div class="statics-wrapper" v-if="largeFormList.length>0">
              <div class="static-title-tabs">
                <div class="static-tab click-tab" v-for="(item, index) in largeFormList" :key="index" @click="showLargeScreen(item)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="statics-wrapper">
              <div class="statics-title">发布概况</div>
              <div class="count-chart-container">
                <div class="count-chart-item">
                  <span class="current-amount">{{ formStatics.viewNum }}</span>
                  <span class="sum">总浏览量</span>
                </div>
                <div class="count-chart-item">
                  <span class="current-amount">{{
                    formStatics.favoriteNum
                  }}</span>
                  <span class="sum">收藏量</span>
                </div>
                <div class="count-chart-item">
                  <span class="current-amount">{{ formStatics.shareNum }}</span>
                  <span class="sum">分享量</span>
                </div>
              </div>
            </div>
            <div class="statics-wrapper">
              <div class="statics-title">数据概况</div>
              <div class="count-chart-container">
                <div class="count-chart-item">
                  <span class="current-amount">{{
                    formStatics.totalFillNum
                  }}</span>
                  <span class="sum">提交数据总量</span>
                </div>
                <div class="count-chart-item">
                  <span class="current-amount">{{
                    formStatics.todayFillNum
                  }}</span>
                  <span class="sum">今日新增</span>
                </div>
                <div class="count-chart-item" @click="fillUserViewer" style="cursor:pointer;">
                  <span class="current-amount">{{
                    formStatics.fillUserNum
                  }}</span>
                  <span class="sum">已填报人数</span>
                </div>
                <div class="count-chart-item" v-if="formStatics.notFillUserNum != null" @click="unfillUserViewer" style="cursor:pointer;">
                  <span class="current-amount">{{
                    formStatics.notFillUserNum
                  }}</span>
                  <span class="sum">未填报人数</span>
                </div>
                <div class="count-chart-item" v-if="formStatics.totalUserNum != null">
                  <span class="current-amount">{{
                    formStatics.totalUserNum
                  }}</span>
                  <span class="sum">总人数</span>
                </div>
                <div class="count-chart-item" v-if="formStatics.completeProportion != null">
                  <span class="current-amount">{{
                    formStatics.completeProportion
                  }}</span>
                  <span class="sum">完成率</span>
                </div>
              </div>
            </div>
            <div class="statics-wrapper" v-if="staticFieldList && staticFieldList.length > 0">
              <div class="statics-title">数据统计</div>
              <div class="form-data-list">
                <div class="data-list-item" v-for="(item, index) in staticFieldList" :key="'file_'+index">
                  <template v-if="item.fieldType==='radio'||item.fieldType==='select'||item.fieldType==='checkbox'">
                    <h3>
                      <span class="num">{{ padLeft(index + 1) }}</span>
                      <span class="fieldTitle">{{ item.title }}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="fieldTitle">【{{
                          item.fieldType === "radio"
                          ? "单选"
                          : item.fieldType === "checkbox"
                            ? "多选"
                            : "下拉选项"
                        }}】</span>
                    </h3>
                    <el-tabs value="dataTable">
                      <el-tab-pane label="数据表格" name="dataTable">
                        <div style="width: 650px; min-height: 300px;margin-bottom:50px">
                          <!--                                <el-table :data="item.fieldOptionList" border show-summary :summary-method="getSummaries">-->
                          <el-table :data="item.fieldOptionList" border>
                            <el-table-column prop="name" label="选项" min-width="200"></el-table-column>
                            <el-table-column prop="value" label="小计" width="100" align="center"></el-table-column>
                            <el-table-column prop="ratio" label="占比" width="300" align="left">
                              <template slot-scope="scope">
                                <el-progress v-if="!scope.row.summary" :percentage="Number((
                                    (scope.row.value * 100 ) /
                                    (item.validNum||1)
                                  ).toFixed(2))
                                    " :text-inside="true" :stroke-width="15"></el-progress>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="统计图" name="statChart">
                        <div class="statics-echarts-container">
                          <div style="width: 650px; min-height: 300px;margin-bottom:50px ">
                            <myEcharts :echartsData="getEchartsData(item.fieldOptionList,item.fieldOptionLimit)" :type="item.echartsType" width="500px" :height="item.fieldOptionList.length * 10 + 200 + 'px'" />
                          </div>
                          <div>
                            <el-form>
                              <el-form-item label="图表">
                                <el-select v-model="item.echartsType" value-key="echartsType" @change="echartsTypeSelect">
                                  <el-option label="饼状图" value="pie" v-if="item.fieldType !== '7'"></el-option>
                                  <el-option label="柱状图" value="bar"></el-option>
                                  <el-option label="折线图" value="line"></el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="显示数量" v-if="item.fieldOptionList.length>10">
                                <el-select v-model="item.fieldOptionLimit" placeholder="">
                                  <el-option label="不限制" :value="0"></el-option>
                                  <el-option label="5" :value="5"></el-option>
                                  <el-option label="10" :value="10"></el-option>
                                  <el-option label="20" :value="20"></el-option>
                                  <el-option label="50" :value="50"></el-option>
                                </el-select>
                              </el-form-item>
                            </el-form>

                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </template>
                  <template v-else-if="item.fieldType==='图片选择'">
                    <h3>
                      <span class="num">{{ padLeft(index + 1) }}</span>
                      <span class="fieldTitle">{{ item.title }}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="fieldTitle">【图片选择】</span>
                    </h3>
                    <el-tabs value="dataTable">
                      <el-tab-pane label="数据表格" name="dataTable">
                        <div style="width: 650px; min-height: 300px;margin-bottom:20px">
                          <!--                                <el-table :data="item.fieldOptionList" border show-summary :summary-method="getSummaries">-->
                          <el-table :data="item.fieldOptionList" border>
                            <el-table-column prop="name" label="名称" min-width="200">
                              <template slot-scope="scope">
                                <div style="vertical-align:middle">
                                  <div class="divPreview" @click="showStatusDataImagePreview(scope.row)" v-if="scope.row.thumb" :style="{
                                    backgroundImage:
                                      'url(\'' + scope.row.thumb + '\')'
                                  }"></div>
                                  <div class="divPreviewName">
                                    {{ scope.row.name }}
                                  </div>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="value" label="小计" width="100" align="center"></el-table-column>
                            <el-table-column prop="ratio" label="占比" width="300" align="center">
                              <template slot-scope="scope">
                                <el-progress v-if="!scope.row.summary" :percentage="Number((
                                (scope.row.value * 100) /
                                (scope.row.totalSelect||1)
                              ).toFixed(2))
                                "></el-progress>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="统计图" name="statChart">
                        <div class="statics-echarts-container">
                          <div style="width: 650px; min-height: 300px;margin-bottom:20px ">
                            <myEcharts :echartsData="getEchartsData(item.fieldOptionList)" :type="item.echartsType" width="500px" :height="item.fieldOptionList.length * 30 + 120 + 'px'
                              " />
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </template>
                  <template v-else>
                    <h3>
                      <span class="num">{{ padLeft(index + 1) }}</span>
                      <span class="fieldTitle">{{ item.displayName }}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="fieldTitle">【{{
                          item.type === "input"
                          ? "单行输入": "多行输入"}}】</span>
                      <div style="margin-left:40px;display:inline-block;">
                        <el-button type="primary" @click="$refs.wordcloudDialog.showDialog(item,formId)">词云统计</el-button>
                      </div>
                    </h3>
                    <div style="margin-bottom:30px"></div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- #region 弹窗 -->
    <!-- 查看每一条填报的审核结果-->
    <el-dialog title="审核结果" :visible.sync="fillReviewOpen" width="800px" append-to-body @close="onCloseFillReviewDialog">
      <el-table :data="fillReviewList" style="width: 100%">
        <el-table-column label="审核时间" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.reviewTime ? scope.row.reviewTime : "--"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核人" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">待{{ scope.row.waitReviewAgencyUserName }}审核</span>
            <span v-else>{{
                scope.row.reviewAgencyUserName
                ? scope.row.reviewAgencyUserName
                : "--"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="scope">
            <span slot="label" v-if="scope.row.status == '1'">审核中</span>
            <span slot="label" v-if="scope.row.status == '2'">已通过</span>
            <span slot="label" v-if="scope.row.status == '3'">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="审核意见" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.reviewRemark ? scope.row.reviewRemark : "--"
              }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--审核弹框-->
    <el-dialog title="审核详情" :visible.sync="reviewDialog.open" width="800px" destroy-on-close append-to-body custom-class="audit-process-dialog" @opened="onShowReviewDialog" @close="onHandleReviewClose">
      <div class="review-contain" v-loading="isAuditLoading">
        <el-form ref="reviewFormDialog" :model="reviewForm" :rules="reviewRules" class="audit-form">
          <el-form-item label="审核状态" prop="auditStatus">
            <el-radio-group v-model="reviewForm.auditStatus" @change="onRadioReviewChange">
              <el-radio label="1" style="margin-bottom: 0">通过</el-radio>
              <el-radio label="2" style="margin-bottom: 0">不通过</el-radio>
              <el-radio label="3" style="margin-bottom: 0">返回修改</el-radio>
            </el-radio-group>
            <el-alert v-if="reviewForm.auditStatus == '2'" title="请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。" style="line-height:20px;" type="warning" show-icon></el-alert>
          </el-form-item>
          <el-form-item label="审核意见" prop="reviewRemark" style="line-height:0px;">
            <el-input type="textarea" v-model="reviewForm.reviewRemark" placeholder="请输入审核意见" style="margin-bottom:2px;"></el-input>
          </el-form-item>
          <el-form-item label="审核签字" prop="reviewSign" v-if="formDetail.auditAuthSign">
            <!-- 审核签名 -->
            <sign ref="signRef" :userId="loginUserId" :agencyId="formDetail.agencyId" :fillId="reviewForm.fillId" :formId="formDetail.formId" :status="reviewForm.status" v-model="reviewForm.reviewSign"></sign>
          </el-form-item>
          <div style="height:20px"></div>
        </el-form>
      </div>
      <div slot=footer>
        <div style="width:100%;text-align:right">
          <el-button type="primary" size="small" @click="onSubmitMultipleReviewClick" v-if="isMultipleSelectionFill">提交</el-button>
          <el-button type="primary" size="small" @click="onSubmitReviewClick" v-else>提交</el-button>
          <el-button size="small" @click="onCancelReviewClick">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!--页面详情分享-->
    <el-dialog title="填报地址" :visible.sync="shareOpen" width="1000px" append-to-body @close="handleFillDiaClose">
      <div class="share-container">
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">
          链接分享
        </h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">
          将填报地址粘贴到浏览器访问，或者直接发给填报者
        </p>
        <div class="" style="width: 100%;height: auto; ">
          <el-input type="text" :value="shareFillUrl" readonly class="fl" style="width: 60%; margin-right: 40px"></el-input>
          <p>
            <el-button type="primary" @click="onCopyFillClick">复制链接</el-button>
            <el-button type="primary" style="margin-left: 20px;" @click="onToClick">直接打开</el-button>
          </p>
        </div>
        <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">
          小程序码分享
        </h5>
        <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">
          选择小程序码样式，保存小程序码直接发给填报者
        </p>
        <img :src="shareImageUrl" class="list-share-image" />
        <div style=" width: 398px; margin: auto; text-align: center;">
          <el-button type="primary" @click="onSaveClick" style="margin: 20px auto 0;">保存小程序码</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dataDownloadOpen" width="600px" append-to-body>
      <div class="data-download-content" :style="{
          justifyContent:
            formDetail.userId == loginUserId ? 'space-around' : 'center'
        }">
        <div class="type-content-default" @click="onDownLoadExcel">
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/default_icon.png" />
          <p>Excel文件</p>
        </div>
        <div class="type-content-page" @click="onDownLoadAll" v-if="formDetail.userId == loginUserId">
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/page_icon.png" />
          <p>Excel文件（含附件）</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="导出详情" :visible.sync="dataDownloadAllOpen" width="1000px" append-to-body>
      <el-button type="primary" size="mini" style="margin-bottom: 20px;" @click="onCreateDownloadClick">数据下载</el-button>
      <el-table :data="fillDataZipDownList" border style="width: 100%">
        <el-table-column prop="createTime" label="下载创建时间" align="center"></el-table-column>
        <el-table-column prop="downStartTime" label="数据打包开始时间" align="center"></el-table-column>
        <el-table-column prop="downEndTime" label="链接生成时间" align="center"></el-table-column>
        <el-table-column prop="expireTime" label="链接过期时间" align="center"></el-table-column>
        <el-table-column label="任务状态" align="center">
          <template slot-scope="scope">
            <span slot="label" v-if="scope.row.status == '1'">排队下载中</span>
            <span slot="label" v-else-if="scope.row.status == '2'">数据打包中</span>
            <span slot="label" v-else-if="scope.row.status == '3'">数据打包完成</span>
            <span slot="label" v-else-if="scope.row.status == '4'">
              <span v-if="scope.row.errorMsg">{{ scope.row.errorMsg }}</span>
              <span v-else>数据打包失败</span>
            </span>
            <span slot="label" v-else-if="scope.row.status == '5'">下载链接过期</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" :disabled="scope.row.status == '3' ? false : true">
              <a :href="apiPathUrl +
                  '/dataDownload/downloadZip/' +
                  scope.row.zipDownId +
                  '?token=' +
                  Authorization
                  " target="_blank" v-if="scope.row.status == '3'">下载</a>
              <a href="javascript:void(0);" v-else style="cursor: not-allowed; color: #C0C4CC">下载</a>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--批量填报-->
    <el-dialog title="导入填报" :visible.sync="fillOpen" width="400px" append-to-body @close="onCloseUploadFillClick">
      <el-upload ref="formFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleFillFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" @click="onDownloadExcelTemplate" style="font-size: 12px;">下载模板</el-button>
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
            提示：仅允许导入“xls”或“xlsx”格式文件！
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadFillClick">确 定</el-button>
        <el-button @click="onCancelUploadFillClick">取 消</el-button>
      </div>
    </el-dialog>
    <vxe-modal v-model="showDetails" title="查看图片" width="500" height="600" :mask="false" :lock-view="false" resize>
      <template #default>
        <div class="preview-img-bar">
          <span @click="onMagnifyImg">
            <svg-icon icon-class="imgMagnify" style="width: 18px; height: 18px;vertical-align: -4px; margin-right: 5px;" />放大
          </span>
          <span @click="onShrinkImg">
            <svg-icon icon-class="imgShrink" style="width: 18px; height: 18px;vertical-align: -4px; margin-right: 5px;" />缩小
          </span>
          <span @click="onRotateImg">
            <svg-icon icon-class="imgScale" style="width: 16px; height: 16px;vertical-align: -4px; margin-right: 5px;" />旋转
          </span>
        </div>
        <div class="show-image">
          <img :src="showDetailsImg" :style="styleObj" @mousewheel.prevent="onGunlunImg" />
        </div>
      </template>
    </vxe-modal>
    <!--数据查看，管理员批量导入他人填报-->
    <el-dialog title="导入他人填报" :visible.sync="notSelfFillOpen" width="400px" append-to-body @close="onCloseNotSelfUploadFillClick">
      <el-upload ref="notSelfFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleNotSelfFillFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" @click="onDownloadNotSelfExcelTemplate" style="font-size: 12px;">下载模板</el-button>
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
            提示：仅允许导入“xls”或“xlsx”格式文件！
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmNotSelfUploadFillClick">确 定</el-button>
        <el-button @click="onCancelNotSelfUploadFillClick">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 管理员批量更新数据 -->
    <el-dialog title="批量更新数据" :visible.sync="batchUpdateDataForExcel" width="400px" append-to-body @close="onCloseNotSelfUploadFillClick">
      <el-upload ref="notSelfFillUpload" :limit="1" accept=".xlsx, .xls" action="" :http-request="handleNotSelfFillFileRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <!-- <el-button type="text" @click="onExcelDataImportTemplate" style="font-size: 12px;">下载模板</el-button> -->
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">
            提示：仅允许导入“xls”或“xlsx”格式文件！
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onBatchUpdateDataForExcel">确 定</el-button>
        <el-button @click="onCancelNotSelfUploadFillClick">取 消</el-button>
      </div>
    </el-dialog>
    <!--数据查看，管理员批量导入他人填报-->
    <el-dialog title="提示" :visible.sync="notSelfFillFailOpen" width="400px" append-to-body @close="onCloseNotSelfUploadFillClick">
      <div style="line-height:30px;">
        系统检查出您导入的数据有部分存在问题，已将问题数据整理成为Excel，请点击下载修改
      </div>
      <div style="height:30px;line-height:30px;">
        <a :href="notSelfFillFailUrl" style="color:#1890FF">点击此处下载导入失败的Excel</a>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="notSelfFillFailOpen = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--数据查看，管理员批量导入他人填报-->
    <!--填报成功，公众号引导提示-->
    <el-dialog :visible.sync="fillSuccessShow" width="430px" custom-class="el-dialog-hide-header" append-to-body @close="onCloseFillSuccessPop">
      <!-- <el-dialog :visible="true" width="430px" append-to-body @close="onCloseFillSuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;">
          <i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>提交成功！
        </h1>
        <!-- 表单未开启支付，显示提交成功提示 -->
        <!-- 默认提交文字 -->
        <template v-if="customTip.commitTipShow == 0">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">
            若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”
          </p>
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px">
            例如可实时接收审核状态变更提醒、管理员定向提醒通知等。
          </p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px;" />
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div v-html="customTip.commitTipContent" style="max-height:450px;overflow-y:auto;"></div>
        </template>
        <template v-if="formDetail.pay">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 10px auto 20px; line-height: 20px;">
            系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。
          </p>
          <div class="pay-footer" style="display: flex; display: -webkit-flex; justify-content: space-around;">
            <el-button @click="onCancelPayFillClick">暂不支付</el-button>
            <el-button type="primary" @click="onConfirmPayFillClick">立即支付</el-button>
          </div>
        </template>
      </div>
    </el-dialog>
    <!--填报成功，公众号引导提示-->
    <!--支付成功，提示文字-->
    <el-dialog :visible.sync="paySuccessShow" width="430px" custom-class="el-dialog-hide-header" append-to-body close="onClosePaySuccessPop">
      <!-- <el-dialog :visible="true" width="430px" append-to-body @close="onClosePaySuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;">
          <i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>支付成功！
        </h1>
        <!-- 默认提交提交文字 -->
        <template v-if="customTip.payTipShow == 0">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">
            若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”
          </p>
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px">
            例如可实时接收审核状态变更提醒、管理员定向提醒通知等。
          </p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px;" />
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div v-html="customTip.payTipContent" style="max-height:450px;overflow-y:auto;"></div>
        </template>
      </div>
    </el-dialog>
    <!--支付成功，提示文字-->
    <!--支付填报-->
    <el-dialog title="数据填报支付" :visible.sync="formPayOpen" custom-class="el-dialog-hide-header" width="400px" append-to-body @close="onClosePay">
      <h3 style="width: 100%; text-align: center">
        本次填报支付{{ orderPrice / 100 }}元
      </h3>
      <div class="qrCodeCon" ref="qrCodeUrl" style="text-align: center; margin: auto; width: 200px;" id="qrCode"></div>
      <h3 style="width: 100%; text-align: center">微信扫一扫付款</h3>
    </el-dialog>
    <!--支付填报-->
    <!--批量设置通知-->
    <el-dialog title="通知" :visible.sync="dataNoticeShow" width="640px" append-to-body @opened="onDataNoticeOpen" @close="onDataNoticeClose">
      <h5 class="noticeHTitle">
        <span style="font-size: 14px; color: #999; font-weight: normal;">注：关注微信公众“鲸鱼填报”的用户才能收到此通知</span>
        <img :src="qrImg" style="width: 25px; height: 25px; cursor: pointer;" class="officialQrImg" />
        <img :src="chatImg" class="officialChatImg" />
      </h5>
      <el-form ref="noticeForm" :model="noticeModel" :rules="noticeRules" label-width="90px" label-position="right" @submit.native.prevent>
        <el-form-item prop="noticeOverview" label="通知概览">
          <el-col :span="24">
            <el-input type="textarea" v-model="noticeModel.noticeOverview" placeholder="请输入通知概览"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item prop="noticeDetails" label="通知详情">
          <!-- <div id="wangEditor"></div> -->
          <richEditor :formId="formId" :agencyId="agencyId" uploadPath="fillFile/{agencyId}/{formId}/formNotice/" v-model="noticeModel.noticeDetails" :Settings="{ placeholder: '请输入通知详情' }"></richEditor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmNoticeClick">确 定</el-button>
        <el-button @click="onDataNoticeClose">取 消</el-button>
      </div>
    </el-dialog>
    <!--批量设置通知-->
    <!-- 数据分享页面 -->
    <dataShare v-model="dataShareShow" :formId="formId" :formJson="this.formJson" ref="dataShare"></dataShare>
    <!-- 数据分享页面 -->
    <!--我的填报列表，支付-->
    <el-dialog title="表单填报支付" :visible.sync="myFillListPayOpen" width="400px" append-to-body @close="onCloseMyFillListPay">
      <h3 style="width: 100%; text-align: center">
        本次填报支付{{ myFillListPayPrice / 100 }}元
      </h3>
      <div class="qrCodeCon" ref="myFillListQrCodeUrl" style="text-align: center; margin: auto; width: 200px;" id="myFillListQrCode"></div>
      <h3 style="width: 100%; text-align: center">微信扫一扫付款</h3>
    </el-dialog>
    <!--我的填报列表，支付-->
    <!--数据查看列表，修改订单金额-->
    <el-dialog title="修改订单金额" :visible.sync="editPayPriceOpen" width="400px" append-to-body @close="onCloseEditPayPriceClick">
      <el-form ref="editPayPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
        <el-form-item label="订单金额" prop="pay">
          <el-input type="text" placeholder="请输入支付金额" v-model="editPayPrice" style="width:200px; display: inline-block;"></el-input>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmEditPayPriceClick">确 定</el-button>
        <el-button @click="onCloseEditPayPriceClick">取 消</el-button>
      </div>
    </el-dialog>
    <!--数据查看列表，修改订单金额-->
    <!--数据查看列表，确认到款-->
    <el-dialog title="确认支付" :visible.sync="confirmPayStatusOpen" width="400px" append-to-body @close="onCloseEditPayStatusClick">
      <el-form ref="confirmPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
        <el-form-item label="支付状态" prop="payStatus">
          <el-select v-model="confirmPayStatus" placeholder="请选择支付状态">
            <el-option label="未支付" value="2"></el-option>
            <el-option label="确认到款" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmPayStatusClick">确 定</el-button>
        <el-button @click="onCloseEditPayStatusClick">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 导出excel选择需要导出的列 -->
    <el-dialog title="选择需要导出的列信息" :visible.sync="exportFieldSelect" width="40%" append-to-body>
      <div style="height: 500px;overflow-y: auto;">
        <el-tree ref="channelTree" :default-checked-keys="defaultSelectAll" :props="defaultFieldProps" :check-on-click-node="true" :data="exportFieldList" show-checkbox node-key="field">
          <span slot-scope="{ node, data }">
            <el-badge v-if="data.type === 'sysField'" value="系" class="item" type="primary" style="font-size: 10px;">
              <span style="font-size: 14px;">{{ node.label }}</span>
            </el-badge>
            <span v-else>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>

      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onExportExcelClick(exportExcelType)">确 定</el-button>
        <el-button @click="exportFieldSelect = false">取 消</el-button>
      </div>
    </el-dialog>

    <VFormViewer ref="dlgvFormViewer" :formAuth="formAuth" @on-save="onHandleEditRefreshData" :onHandleImportClick="onHandleImportClick"></VFormViewer>
    <fillSelector ref="fillSelector" :optionData="optionData"></fillSelector>
    <fillUserViewer ref="fillUserViewer" :formId="formId"></fillUserViewer>
    <fillAgreement ref="fillAgreement" :formAgreement="agreement" @agree="onFillClick"></fillAgreement>
    <userSelect ref="userSelect" :callback="userSelectCallback" :formDetail="formDetail"></userSelect>
    <wordcloudDialog ref="wordcloudDialog"></wordcloudDialog>
    <!-- 强制绑定该用户的手机号 -->
    <bindUserMobile></bindUserMobile>

    <!-- #endregion -->
  </div>
</template>
<script>
import axios, { all } from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getDateDiff, parseTime } from "@/utils/whale";
import IdentityCodeValid from "@/utils/checkIdent";

import { aliUploader } from "@/utils/oss";
import { getSessionStorageObj } from "@/utils/db";

import {
  beforeBackConfirm,
  showPicturePreview,
  changedValue,
} from "@/utils/formUtil";
import { fillAgencyDeptList } from "@/api/system/agency";
import { getCity, getCounty, getProvince } from "@/api/system/config";

import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
import { deepClone } from "@/utils/deepClone.js";

import multiEditMixin from "./components/multiEdit/multiEditMixin.js";

import bindUserMobile from "./components/forceBindMobile/bindUserMobile";
import wordcloudDialog from './components/wordcloudDialog'
import Draft from './components/draft'
import { loadDataRefreshConfig } from '@/api/system/statistics'


import Download from '@/components/FileDownload/download.vue'
// #region api/system/form
import {
  batchReviewFill,
  checkFill,
  editFill,
  confirmFill,
  fill,
  fillReviewList,
  fillDownloadExcel,
  fillDownloadOthersExcel,
  othersExcelImportUpdate,
  formFillExcelImport,
  formFillExcelOtherImport,
  fillDataDetail,
  fillDataDownload,
  fillDataZipDownHttp,
  fillDataZipDownList,
  fillDetail,
  fillList,
  historyFillList,
  formAuth,
  formDetail, //表单配置数据接口
  formPayInfo, //表单配置的支付信息接口
  formFieldList,
  customFormFieldList,
  formFilesList,
  dataFillStatus,
  formReserves,
  fillReserveTimes,
  review,
  auditFillUpdate,
  reviewList,
  fillDel,
  fieldList,
  orderCreat,
  orderStatus,
  aliDelete,
  formStatics,
  formFieldStatics,
  formPicSelFieldStatics,
  httpShareNum,
  exportFillWord,
  exportFillPdf,
  fillDataDownloadFormPicDownload,
  fillDataDownloadFormPicDownList,
  fillDataDownloadFormWordDownList,
  fillDataDownloadFormWordDownload,
  shareImg,
  noticeSend,
  aliUpload,
  deleleteFile,
  orderPriceEdit,
  payStatusEdit,
  getCustomTip,
  httpGetFormFieldV2,
  shortLink,
  httpViewNum,
  getFormFieldAuth,
  formAuditAuthFieldDetail,
  getFormFieldTree
} from "@/api/system/form";

// #endregion
import { largeScreenList } from "@/api/system/largeScreen";
import { favorite, userBrowseRecord } from "@/api/system/user";
import ELEMENT from "element-ui";
import XEUtils from "xe-utils";
import { VXETable, Table } from "vxe-table";
import zhCN from "vxe-table/lib/locale/lang/zh-CN";
import QRCode from "qrcodejs2";
import { getToken } from "@/utils/auth";
// import wangEditor from 'wangeditor'
import { putFile, setOssConfig } from "@/utils/ossUtils";
import MyEcharts from "@/components/MyEcharts";
import gridConfig from "@/utils/grid.js";
import { renderForm, getFormData } from "@/utils/VFormDesignerHelper.js";

import TableComponents from "@/components/Table";
import MyVxeTable from "./components/tables/table.vue";
import VFormViewer from "./components/vFormViewer/index.vue";
import StatusCode from "./components/statusCode.vue";

import editLinks from "./components/editLinks.vue";
import richEditor from "@/components/RichEditor";
import picViewer from "@/utils/imagePreview.js";
import fillSelector from "./components/fillSelector";
import fillUserViewer from "./components/fillUserViewer";
import formTagEditor from "./components/formTag/editor.vue";
import fillAgreement from "./components/fillAgreement/index.vue";
import userSelect from "@/views/system/mechan/components/user-select.vue";
import multiEdit from "@/views/system/form/components/multiEdit/index.vue";
import multiDelete from "@/views/system/form/components/multiDelete/index.vue";
import sign from "./components/sign/auditSignPC.vue";
import dataShare from "./components/dataShare/index.vue";
import TitleUserMenu from "@/components/TitleUserMenu/index.vue";
import request from "@/utils/request";
import FillSelectTableView from "@/views/system/form/components/fillSelector/tableView";
// import detailFormInfoSkeleton from '@/views/system/form/components/skeleton/detailFormInfo'

import fjnuStatisticsMixin from "@/views/system/form/mixins/fjnuStatisticsMixin";

import emitter from 'element-ui/lib/mixins/emitter.js'
import { mapState } from 'vuex'

import WebViewIntroduce from "@/views/system/webView/introduce";

// import DeptTree from '@/components/DeptTree/index'
// import AgGrid from "./agGrid";
export default {
  name: "Detail",
  componentName: "Detail",
  components: {
    Treeselect,
    VXETable,
    Table,
    MyEcharts,
    MyVxeTable,
    VFormViewer,
    StatusCode,
    editLinks,
    richEditor,
    fillSelector,
    formTagEditor,
    fillUserViewer,
    fillAgreement,
    ...TableComponents,
    userSelect,
    multiEdit,
    multiDelete,
    sign,
    dataShare,
    // DeptTree,
    TitleUserMenu,
    bindUserMobile,
    FillSelectTableView,
    wordcloudDialog,
    Draft,
    // AgGrid
    // detailFormInfoSkeleton
    WebViewIntroduce,
    Download
  },
  mixins: [multiEditMixin, fjnuStatisticsMixin, emitter],
  props: {
    //是独立页面还是嵌入页面（表单查看是独立的，应用内表单是嵌入的）
    standalone: {
      type: Boolean,
      default: true,
    },
    formDesign: {
      type: Object,
      default: () => { }
    },
    reloadProjectList: {
      type: Function,
    },
    showCur: {
      type: Number,
      default: 1
    }
  },
  inject: ["getFormDetailObj"],
  data () {
    return {
      selectAll: "",
      formLoading: true,
      logoImg:
        "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png",

      formId: null,
      agencyId: null,
      cur: 1,
      formVer: 2,
      formDetail: {},
      formType: "form",
      dataFillStatus: false, // 数据查看列表，填报状态(管理员通过批量导入他人填报数据)
      formInfoCur: null,
      formFieldList: [],
      formCustomDetails: "",
      formCustomFile: "",
      formCustomFileList: [],
      formAuth: {},
      shareOpen: false,
      shareFillUrl: "",
      shareItemInfo: {}, // 点击分享每个表单信息
      base64DataUrl: "", // 保存小程序码，便于下载
      isShall: "",
      shallImg: "",
      shareImageUrl: "", // 新版---分享图片 base64
      number: null, // 计数器
      isResourceShow: 0,
      statusTxt: "",
      agreement: null,
      // 填报列表
      fillId: null,
      isEditFill: false,
      agencyDeptOptions: [], // 组织架构
      agencyDeptOptionsOrigin: [],
      provinceList: [], // 省市区联动
      cityList: [],
      countyList: [],
      addressFlagArr: [], // 省市区联动过滤数组
      fieldPicList: [],
      props: {
        label: "label", // 显示的选项
        value: "label", // 选择的value值
        children: "children", // 子级属性名
        expandTrigger: "hover",
      }, // 指定层级属性
      pickerOptions: {
        disabledDate: (time) => {
          const startDateTime = new Date("1900/1/1").getTime();
          const endDateTime = new Date("2100/12/31").getTime();
          return time.getTime() < startDateTime || time.getTime() > endDateTime;
        },
      },
      fillReviewOpen: false, // 查看每一条填报的审核结果弹框
      fillReviewList: [], // 查看每一条填报的审核结果list
      // 审核列表
      reviewSelectArr: [], // 审核筛选数组
      queryParams: {
        pageNum: 1, // 每页查询几条数据
        pageSize: 10, // 查询第几页
        formFieldId: "",
        fillValue: "",
        queryStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        updateStartTime: "",
        updateEndTime: "",
      },
      total: 0, // 审核list页数
      reviewDialog: {
        type: 'audit',
        open: false,
      }, // 审核弹框
      reviewForm: {
        reviewRemark: null,
        auditStatus: null,
        reviewSign: null,
        version: null,
      },
      reviewFormError: {
        reviewRemark: '',
        reviewSign: '',
      },
      reviewRules: {
        auditStatus: [
          {
            required: true,
            message: "审核状态不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      multipleSelectionFill: [], // 一键审核所有数据
      isMultipleSelectionFill: false, // 是否是一键审核
      startPickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDateS(time);
        },
      },
      endPickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      dataDetailList: [], // 数据详情
      tableLabel: [], // 数据详情表格表头
      tableData: [], // 数据详情表格数据
      curTableLabelLen: null,
      historyTableLabelLen: null,
      editor: null,
      editorContent: "",
      dataTotal: 0,
      fillDetail: [], // 查看填报数据，即填报详情
      loginUserId: null,
      loginAgencyUserId: null,
      dataDownloadOpen: false,
      batchUpdateDataForExcel: false,
      dataDownloadAllOpen: false,
      fillDataZipDownList: [],
      fillOpen: false, // 批量导入填报
      fillFile: null,
      isImport: true, // 是否可以批量填报， 即是否显示批量填报btn
      // 针对关联历史表单
      historyFillId: null,
      currentFormHistory: [],
      isHistoryFill: false,
      historyFillDetailDialog: false,
      historyFillFormFieldList: [],
      historyFillDetail: {},
      historyFillDetailId: null,
      // 表单支付
      formPayOpen: false,
      orderPrice: null,
      qrTimer: null, // 二维码定时器
      // 上传图片传值给后台参数
      Authorization: "",
      uploadImgParam: {
        folder: "fillImg",
      },
      uploadFileParam: {
        folder: "fillFile",
      },
      fillBtnClick: false, // 填报提交btn是否可以点击提交
      // 个人填报列表插件
      fillGridOptions: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        // showOverflow: true,
        loading: false,
        columns: [],
        data: [],
        params: {
          selectedRowKeys: [],
          excludeIds: [],
        },
        tableToolbar: {
          custom: true,
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100, 200],
        },
      },
      fillJson: {},
      fillQueryParams: {
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        updateStartTime: "",
        updateEndTime: "",
        formFieldId: "",
        fillValue: "",
      },
      fillEmptyText: "",
      fillStartPickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledFillDateE(time);
        },
      },
      fillEndPickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledFillDateS(time);
        },
      },
      // 个人填报列表，表单开通支付功能，去支付
      myFillListPayOpen: false,
      myFillListPayPrice: null,
      myFillListQrTimer: null, // 二维码定时器
      // 审核列表表格插件
      reviewGridOptions: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        // showOverflow: true,
        loading: false,
        columns: [],
        data: [],
        fillCacheKey: "",
        params: {
          selectedRowKeys: [],
          selectionRows: [],
          excludeIds: [],
        },
        tableToolbar: {
          custom: true,
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100, 200],
        },
      },
      reviewEmptyText: "",
      /* table选中keys*/
      selectedRowKeys: [],
      /* table选中records*/
      selectionRows: [],
      showDetails: false,
      showDetailsImg: "",
      // 数据查看表格插件
      gridOptions: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        // showOverflow: true,
        loading: false,
        columns: [],
        data: [],
        fillCacheKey: "",
        tableToolbar: {
          custom: true,
        },
        params: {
          selectedRowKeys: [],
          excludeIds: [],
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 20,
          pageSizes: [20, 50, 100, 200],
        },
      },
      filters: {
        status: {
          column: "status",
          field: "status",
          fieldType: "sysStatus",
          isSysField: true,
          optionsItem: [
            { value: "-1", label: "待您审核" },
            { value: "1", label: "待审核" },
            { value: "2", label: "已通过" },
            { value: "3", label: "未通过" },
            { value: "4", label: "返回修改" },
            { value: "5", label: "待一级审核" },
            { value: "6", label: "待二级审核" },
            { value: "7", label: "待三级审核" },
            { value: "8", label: "进行中" },
            { value: "9", label: "已结束" },
          ],
          options: [
            { value: "1", label: "待审核" },
            { value: "2", label: "已通过" },
            { value: "3", label: "未通过" },
            { value: "4", label: "返回修改" },
          ],
        },
        fillStatus: {
          column: "fillStatus",
          field: "fillStatus",
          fieldType: "fillStatus",
          isSysField: true,
          optionsItem: [
            { value: "1", label: "个人填报" },
            { value: "2", label: "导入待确认" },
            { value: "3", label: "导入已确认" },
            { value: "4", label: "导入无需确认" },
          ],
          options: [
            { value: "1", label: "个人填报" },
            { value: "2", label: "导入待确认" },
            { value: "3", label: "导入已确认" },
            { value: "4", label: "导入无需确认" },
          ],
        },
      },
      dataQueryParams: {
        formFieldId: "",
        fillValue: "",
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        fillStatus: "",
        updateEndTime: "",
        updateStatus: "",
      },
      dataStartPickerOptions: {
        disabledDate: (time) => {
          return this.dataDealDisabledDateS(time);
        },
      },
      dataEndPickerOptions: {
        disabledDate: (time) => {
          return this.dataDealDisabledDate(time);
        },
      },
      // tableToolbar: {
      //   buttons: [
      //     { code: 'myExport', type: 'text', name: 'Excel文件', icon: 'el-icon-paperclip'},
      //     { code: 'myExportIncludePic', type: 'text', name: 'Excel文件（含图片缩略图）', icon: 'el-icon-picture-outline'},
      //     { code: 'myExportIncludeFile', type: 'text', name: 'Excel文件（含附件）', icon: 'el-icon-files'},
      //   ],
      //   custom: true
      // },
      dataEmptyText: "",
      curFormFiled: [],
      curFormHistoryFiled: [],
      // 数据查看列表table选中keys
      selectedDataRowKeys: [],
      // 数据查看列表table选中records
      selectionDataRows: [],
      // 数据查看列表---管理员批量导入他人填报
      notSelfFillOpen: false,
      // 数据查看列表---管理员批量导入他人数据失败，下载失败的excel
      notSelfFillFailOpen: false,
      notSelfFillFailUrl: "",
      otherFillFile: null,
      apiPathUrl: process.env.VUE_APP_BASE_API,
      // 新版
      formStatics: {}, // 表单统计
      fieldList: [], // 表单字段统计
      fillDataHeight: document.documentElement.clientHeight - 84, // 数据查看高度
      fillDataMaxHeight: document.documentElement.clientHeight - 172,
      isReserve: false, // 表单是否有预约字段
      reserveList: [],
      reserveDate: "", // 预约日期,默认当前日期
      reservePickerOptions: {
        disabledDate (v) {
          return v.getTime() < new Date().getTime() - 86400000;
        },
      },
      reserveId: "", // 预约室ID
      reserveTimes: [], // 预约时间段
      reserveMaxTime: null,
      breadcrumbName: "",
      imgPreviewVisible: false,
      imgPreviewPath: "",
      multiples: 1, // 放大或者缩小
      deg: 0, // 旋转的角度
      styleObj: {}, // 拖拽时修改图片的样式
      endX: 0,
      endY: 0,
      fillSuccessShow: false, // 填报成功，引导关注公众号
      paySuccessShow: false, // 支付成功，引导关注公众号
      dataNoticeShow: false, // 批量设置通知
      qrImg: require("../../../assets/images/qr-icon.png"),
      chatImg: require("../../../assets/images/code.jpeg"),
      noticeModel: {
        noticeOverview: "",
        noticeDetails: "",
      },
      noticeRules: {
        noticeOverview: [
          {
            required: true,
            message: "通知概览不能为空",
            trigger: ["change", "blur"],
          },
        ],
        noticeDetails: [
          {
            required: true,
            message: "通知详情不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      // noticeEditor: {},
      noticeClickNum: 0, // 数据查看列表点击查询按钮次数
      formSubmitFillId: null, // 表单开通支付功能，提交填报成功，返回fillId
      editPayPriceOpen: false, // 表单开通支付功能，管理员修改订单金额
      editPayPrice: null, // 表单开通支付功能，修改的订单金额，单位为元
      confirmPayStatusOpen: false, // 表单开通支付功能，管理员确认到款
      confirmPayStatus: null, // 表单开通支付功能，管理员确认到款类型，确认到款，未支付
      formJson: { fieldList: [] },
      formData: {},
      optionData: {},
      downloadParams: {},
      customTip: {},
      formTags: [],
      largeFormList: [],
      formAgreementId: null,
      ...gridConfig,
      userSelectCallback: null,
      dataShareShow: false,
      exportDownloadType: "file",
      loadings: {
        execApi: false,
      },
      editRowIndex: -1,
      showMultiEdit: false,
      showMultiDelete: false,
      staticFieldList: [],
      isAuditLoading: false,
      exportFieldSelect: false,
      exportExcelType: 0,
      exportFieldList: [],
      defaultSelectAll: [],
      defaultFieldProps: {
        label: 'headerName'
      },
      dataRefreshActions: []
    };
  },
  async created () {
    const userToken = this.cookie.get("User-Token");
    const sessionStorageUser = getSessionStorageObj("users");
    try {
      this.optionData = {
        userToken: userToken,
        apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
        formId: this.decode(this.$route.params.formId),
        formPayInfo: this.getFormPayInfo,
      };
    } catch {
      this.optionData = {
        userToken: userToken,
        apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
        formPayInfo: this.getFormPayInfo,
      };
    }

    //图片选项、关联选择中使用的渲染器等数据
    this.optionData.formDataSelectHandler = this.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler = this.showDataAutoSelect;
    this.optionData.formDataSelectMultiFillHandler = this.showDataMultiSelect;
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formPicturePreviewHandler = showPicturePreview;
    this.optionData.formDataCardRender = this.dataCardRender();
    this.optionData.formAgencyId = this.formAgencyId();
    this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
    this.optionData.loadAgencyUserListHandler = agencyUserList;
    this.optionData.loadAgencyUserHandler = getAgencyUser;
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;

    // this.optionData.aliOSSUpload =aliUploader(true,
    //  {  //OK
    //   formId:'formId',
    //   agencyId:'agencyId',
    //   userId:sessionStorageUser.userId||'userId',
    //   uploadPath:'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
    // })
    this.optionData.aliOSSUpload = aliUploader;
    this.optionData.aliOSSUploadConfig = {
      //OK
      formId: "formId",
      agencyId: "agencyId",
      userId: sessionStorageUser.userId || "userId",
      uploadPath: "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/",
    };

    this.optionData.userSelectHandler = this.showUserSelect;
    this.optionData.formCreateUser = sessionStorageUser;
    this.optionData.isUserAdmin = getSessionStorageObj("users").type > 1;
    this.optionData.fileDelete = this.fileDelete;
    this.$store.dispatch("dept/GetDeptList").then((res) => { });

    // 判断是独立运行模式，还是嵌入到页面的模式
    if (this.standalone) {
      if (this.myIsNaN(this.$route.params.id)) {
        this.init(this.$route.query.type, this.$route.params.id);
      } else {
        this.init(this.$route.query.type, this.decode(this.$route.params.id));
      }
    }
  },
  mounted () { },
  computed: {
    ...mapState({
      viewName: state => state.designView.viewName
    }),
    disableFill: function () {
      // isStop 是反的，true的时候没有stop，false的时候是stop
      // disableFill 返回true时表示禁止修改
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentInsertFillAuth ? false : true)
      );
    },
    disableEditFill: function () {
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentEditFillAuth == true ? false : true)
      );
    },
    // 有支付的表单
    formPay: function () {
      return this.formDetail.pay;
    },
    // 是否显示管理列表的更多
    isShowAdminListMoreButton: function () {
      return !!this.formDetail.exportTemplate;
    },
    // 是否显示个人填报列表的更多
    isShowFillListMoreButton: function () {
      return !!this.formDetail.exportTemplate && this.formDetail.exportIsPerson;
    },
  },
  watch: {
    cur: {
      handler (val) {
        // 如果是嵌入式模式，返回页面变化事件
        if (!this.standalone) {
          this.$emit("cur_changed", {
            cur: this.cur,
            formDetailName: this.formDetail.name,
            breadcrumbName: this.breadcrumbName,
          });
        }
      },
    },
    showCur: {
      immediate: true,
      handler (val) {
        this.onTabClick(val);
      },
    }
  },
  methods: {
    setEditRowIndex: function (rowIndex) {
      this.editRowIndex = rowIndex;
    },
    disableUserFill: function (row) {
      return row["status"] != 5 && row["status"] != 9 && row["status"] != 13;
    },
    getTagClass (text) {
      switch (text) {
        case "进行中":
          return "ing";
        case "已结束":
          return "stop";
        default:
          return "un-start";
      }
    },
    myIsNaN (value) {
      return typeof value === "number" && !isNaN(value);
    },
    init (type, id) {
      this.formId = id;
      this.formLoading = true;
      this.cur = 1;
      this.optionData.formId = this.formId;
      if (!this.isBlank(type)) {
        this.formType = type; // 应用模式
      } else {
        this.formType = "form"; // 表单模式
        userBrowseRecord({
          type: "form",
          typeId: this.formId,
        }).then((res) => { });
      }
      // const userToken = this.cookie.get('User-Token')
      // this.optionData = { userToken: userToken, apiUrl: `${process.env.VUE_APP_BASE_API_URL}`, formId: this.formId }

      this.loginUserId = getSessionStorageObj("users").userId;
      this.loginAgencyUserId = (
        getSessionStorageObj("users").agencyUserId || ""
      ).split(",");

      this.clearSearch();

      this.Authorization = getToken();
      this.uploadImgParam.belongId = this.formId;
      this.uploadFileParam.belongId = this.formId;
      this.reserveDate = this.getNowFormatDate();
      this.$forceUpdate();


      this.getFormDetail();
      this.getCustomTip();
      httpViewNum(this.formId);
    },
    clearSearch () {
      // 清除所有的搜索条件
      this.$nextTick(() => {
        // clearFilter  true参数：静默清除 不触发事件
        if (this.$refs.fillGridTable) this.$refs.fillGridTable.clearFilters(true);
        if (this.$refs.auditTable) this.$refs.auditTable.clearFilters(true);
        if (this.$refs.gridTable) this.$refs.gridTable.clearFilters(true);
      });
    },
    // 嵌入式运行时从外部传入cur
    switchCur (cur) {
      this.cur = cur;
    },
    // 读取审核列表所有数据（不分页）
    GetAllReviewRows (totalNum, callback) {
      let _this = this;
      const queryParams = {
        pageNum: 1,
        pageSize: totalNum,
        formFieldId: _this.queryParams.formFieldId,
        fillValue: _this.queryParams.fillValue,
        queryStatus: _this.queryParams.queryStatus,

        criteria: _this.reviewGridOptions.filterData,
        orders: _this.reviewGridOptions.sortData,
        // payStatus: _this.queryParams.payStatus,
        fillStartTime: _this.queryParams.fillStartTime,
        fillEndTime: _this.queryParams.fillEndTime,
        searchText: _this.queryParams.fillValue,
      };
      reviewList(_this.formId, queryParams).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.rows.length; i++) {
            const item = response.rows[i];

            const obj = { ...item.fillJson };
            obj["userId"] = item.userId;
            obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
              ? item.fillUserNumber
              : "--";
            obj["fillUserName"] = item.fillUserName;
            obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
            obj["fillTime"] = item.createTime;
            if (!_this.isBlank(item.agencyDeptLevel1)) {
              let agencyDeptLevel1 = "";
              for (let j = 0; j < item.agencyDeptLevel1.length; j++) {
                agencyDeptLevel1 += item.agencyDeptLevel1[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel1.length > 0) {
                agencyDeptLevel1 = agencyDeptLevel1.substr(
                  0,
                  agencyDeptLevel1.length - 1
                );
              }
              obj["agencyDeptLevel1"] = agencyDeptLevel1;
            } else {
              obj["agencyDeptLevel1"] = "--";
            }

            if (!_this.isBlank(item.agencyDeptLevel2)) {
              let agencyDeptLevel2 = "";
              for (let j = 0; j < item.agencyDeptLevel2.length; j++) {
                agencyDeptLevel2 += item.agencyDeptLevel2[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel2.length > 0) {
                agencyDeptLevel2 = agencyDeptLevel2.substr(
                  0,
                  agencyDeptLevel2.length - 1
                );
              }
              obj["agencyDeptLevel2"] = agencyDeptLevel2;
            } else {
              obj["agencyDeptLevel2"] = "--";
            }

            // obj['reviewRemark'] = !_this.isBlank(item.auditAuth2Remarks) ? item.auditAuth2Remarks : !_this.isBlank(item.auditAuth1Remarks) ? item.auditAuth1Remarks : '--'
            obj["reviewRemark"] =
              item.auditAuth3Remarks ||
              item.auditAuth2Remarks ||
              item.auditAuth1Remarks ||
              "";

            obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
            obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
            obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
            obj["auditAuth1Sign"] = item.auditAuth1Sign;
            obj["auditAuth2Sign"] = item.auditAuth2Sign;
            obj["auditAuth3Sign"] = item.auditAuth3Sign;
            obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
            obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
            obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
            obj["auditAuth1Operator"] = item.auditAuth1Operator;
            obj["auditAuth2Operator"] = item.auditAuth2Operator;
            obj["auditAuth3Operator"] = item.auditAuth3Operator;
            obj["auditAuth1Time"] = item.auditAuth1Time;
            obj["auditAuth2Time"] = item.auditAuth2Time;
            obj["auditAuth3Time"] = item.auditAuth3Time;
            obj["auditAuthSign"] = item.auditAuthSign;
            obj["auditAuthRemarks"] = item.auditAuthRemarks;
            obj["auditAuthTime"] = item.auditAuthTime;
            obj["status"] = item.status;
            obj["statusTxt"] = statusTxt;
            obj["fillId"] = item.fillId;
            obj["version"] = item.version;
            obj["auditButton"] = item.auditButton;

            obj["fillHeaders"] = Object.keys(item.fillJson);
            _this.reviewGridOptions.data.push(obj);
            _this.reviewGridOptions.pagerConfig.total = response.total;
          }
          _this.reviewGridOptions.loading = false;
          if (_this.isBlank(_this.reviewGridOptions.data)) {
            _this.reviewEmptyText = "暂无数据";
          }
        } else {
          _this.reviewGridOptions.loading = false;
          _this.msgError(response.msg);
        }
      });
    },
    // 读取管理列表所有数据（不分页）
    GetAllDataRows (totalNum, callback) {
      let _this = this;
      const queryParams = {
        pageNum: 1,
        pageSize: totalNum,
        orders: _this.dataQueryParams.sortData,
        formFieldId: _this.dataQueryParams.formFieldId,
        fillValue: _this.dataQueryParams.fillValue,
        queryStatus: _this.dataQueryParams.queryStatus,
        payStatus: _this.dataQueryParams.payStatus,
        fillStartTime: _this.dataQueryParams.fillStartTime,
        fillEndTime: _this.dataQueryParams.fillEndTime,
        searchText: _this.dataQueryParams.fillValue, //全局搜索
      };
      fillDataDetail(this.formId, queryParams).then((response) => {
        const rows = [];
        response.rows.forEach((item) => {
          const obj = {
            fillId: item.fillId,
            payStatus: item.payStatus,
            status: item.status,
            fillStatus: item.fillStatus,
            version: item.version,
          };
          rows.push(obj);
        });

        callback(rows);
      });
    },
    // 返回事件
    naviHome () {

      if (this.$router.options.from.path === '/login') {
        this.$router.push({
          path: '/index'
        })
      } else {
        this.$router.go(-1);
      }

      // this.$router.go(-1);
      // const naviBack = () => {
      //   this.$router.push({
      //     path: '/index'
      //   })
      // }
      // // 判断是否处在数据填报页面(cur=2),如果是检查表单是否编辑过，给个提示再后退
      // if (this.cur == 2) {
      //   beforeBackConfirm(
      //     this.$refs.vformRender,
      //     (clearFormHandler) => {
      //       this.onSaveFillClick(() => {
      //         clearFormHandler();
      //         naviBack();
      //       })
      //     },
      //     naviBack
      //   )
      // } else {
      //   naviBack()
      // }
    },
    // 新版点击表单名称
    onBackDetail () {
      const naviBack = () => {
        // this.clearFormDetail();
        this.cur = 1;
      };
      // 判断是否处在编辑页面(cur=2),如果是检查表单是否编辑过，给个提示再后退
      if (this.cur == 2) {
        beforeBackConfirm(
          this.$refs.vformRender,
          (clearFormHandler) => {
            this.onSaveFillClick(() => {
              clearFormHandler();
              naviBack();
            });
          },
          naviBack
        );
      } else {
        naviBack();
      }
    },
    // 收藏or取消收藏
    onCollectClick () {
      const _this = this;
      let operation = "";
      const param = {};
      if (
        !_this.isBlank(this.formDetail.favorite) &&
        _this.formDetail.favorite
      ) {
        // 如果是已收藏
        operation = "cancel";
      } else {
        operation = "favorite";
      }
      param.operation = operation;
      param.type = "form";
      param.typeId = _this.formId;
      favorite(param).then((response) => {
        if (response.code == 200) {
          const msg =
            !_this.isBlank(this.formDetail.favorite) &&
              _this.formDetail.favorite
              ? "取消收藏"
              : "收藏成功";
          _this.msgSuccess(msg);
          _this.formDetail.favorite = !_this.formDetail.favorite;
        }
      });
    },
    // 点击分享弹框
    async onShareClick () {
      const self = this;
      if (self.formDetail.status == "1") {
        this.$message({
          message: "未发布的表单暂不能分享～",
          type: "warning",
        });
        return;
      }
      self.getShareNum();
      const locationUrl = window.location.origin;
      const item = this.formDetail;
      const id = this.formId;
      const shareType = "form";
      const type = "form";
      self.shareFillUrl =
        locationUrl +
        "/share/" +
        self.encode(item.formId) +
        "?type=" +
        self.encode(type);

      shortLink(self.shareFillUrl).then((res) => {
        this.shareFillUrl = res.data;
      });
      self.shareItemInfo = item;
      self.getShareImage(id, shareType);
    },
    // 新版---生成分享图片
    getShareImage (id, type) {
      const _this = this;
      shareImg(id, type).then((res) => {
        if (res.code == 200) {
          _this.shareImageUrl = "data:image/jpg;base64," + res.data;
          _this.shareOpen = true;
        }
      });
    },
    // 新版---添加分享量
    async getShareNum () {
      const _this = this;
      const formId = _this.formId;
      httpShareNum(formId).then((response) => { });
    },
    // 复制填报地址
    async onCopyFillClick () {
      const oInput = document.createElement("input"); // 创建input节点
      oInput.value = this.shareFillUrl; // 给input的value赋值
      document.body.appendChild(oInput); // 向页面插入input节点
      oInput.select(); // 选中input
      try {
        await document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          type: "success",
          message: "复制成功",
        });
      } catch {
        this.$message({
          type: "success",
          message: "复制失败",
        });
      }
      document.body.removeChild(oInput);
    },
    // 直接打开填报地址页面
    onToClick () {
      const tempWindow = window.open("_blank");
      tempWindow.location.href = this.shareFillUrl;
    },
    // 保存小程序码
    onSaveClick () {
      const self = this;
      if (this.isBlank(this.shareItemInfo.shareQr)) {
        this.$message({
          message: "小程序码缺失，暂无法保存",
          type: "warning",
        });
        return;
      }
      this.downloadFile(this.shareItemInfo.name + ".jpg", self.shareImageUrl);
    },
    downloadFile (fileName, content) {
      const aLink = document.createElement("a");
      const blob = this.base64ToBlob(content); // new Blob([content]);

      const evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      ); // 兼容火狐
    },
    // base64转blob
    base64ToBlob (code) {
      const parts = code.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    fileDelete (file) {
      deleleteFile(file.fileId).then((res) => { });
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then((res) => { });
      }
    },
    // 监听分享弹框关闭
    handleFillDiaClose () {
      this.shareFillUrl = "";
    },
    // 详细介绍、附件列表 tab切换
    onFormInfoTabClick (index) {
      this.formInfoCur = index;
    },
    getCustomTip () {
      const _this = this;
      // 获取表单配置(表单设计器)数据
      getCustomTip(_this.formId).then((response) => {
        this.customTip = response.data;
      });
    },
    async getFormDetail () {
      const _this = this;
      // 获取表单其他配置
      const formDetailProxy = (formId) => {
        return new Promise((resolve, reject) => {
          if (this.getFormDetailObj) {
            this.formDetail = this.getFormDetailObj();
            resolve({data: this.formDetail})
          } else {
            formDetail(formId).then(response => {
              this.formDetail = response.data;
              resolve(response)
            })
          }
        })
      }

      const handleFormField = (response)=>{
        if (response.data) {
          _this.formJson = {
            formConfig: response.data.formConfig,
            widgetList: response.data.widgetList,
            fieldList: response.data.fieldList,
          };

          _this.$nextTick(() => {
            // _this.$refs.vformRender.setFormJson(_this.formJson);
            // _this.$refs.vformConfirmRender.setFormJson(_this.formJson);

            // _this.$refs.vformRender.setFormId(_this.formId);
            // _this.$refs.vformConfirmRender.setFormId(_this.formId);
            this.formLoading = false;
          });
        } else {
          _this.formJson = {
            fieldList: [],
          };
        }
      }

      const handleFormDetail = (response)=>{
        let formStateTxt = ""; // 表单状态（未开始、进行中、已结束）
        const curTime = response.data.currentTime|| parseTime(new Date());

        let fillStartTime = response.data.fillStartTime || "1970-01-01 00:00:00";
        let fillEndTime = response.data.fillEndTime || "2099-12-31 23:59:59";

        if (response.data.status == "1") {
          formStateTxt = "未发布";
        } else {
          if (getDateDiff(fillStartTime, curTime)) {
            formStateTxt = "未开始";
          } else if (
            getDateDiff(curTime, fillStartTime) &&
            getDateDiff(fillEndTime, curTime)
          ) {
            formStateTxt = "进行中";
          } else {
            formStateTxt = "已结束";
          }
        }
        response.data.formStateTxt = formStateTxt;

        // _this.formDetail.extension
        _this.isReserve = response.data.isReserve;
        _this.formAgreementId = response.data.formAgreementId;
        // 3.0.0.1 拆分 请求表单字段、自定义字段、附件列表
        // _this.getFormField()
        _this.getCustomFormFieldList();
        _this.getFormFiles();
        _this.getFormPayInfo().then((payInfo) => {
          _this.formPayInfo = payInfo.data;
        });
        _this.getDataFillStatus();

        //给aliOSS设置agencyId
        _this.optionData.aliOSSUploadConfig.formId = _this.formDetail.formId;
        _this.optionData.aliOSSUploadConfig.agencyId = _this.formDetail.agencyId;

        // _this.getFormAgreement()
        //如果表单开启了支付，读取支付配置
        // console.log('表单配置数据', _this.formDetail)
        // 数据查看---数据导出权限
        if (_this.formDetail.userId != _this.loginUserId) {
          // _this.tableToolbar.buttons = _this.tableToolbar.buttons.slice(0, 2)
        }
        // 向上层组件传递表单信息
        this.$emit("formDetail", this.formDetail)

        this.$emit('enable-view')
        if (_this.$route.query.cur) {
          let cur = _this.$route.query.cur;
          if ((cur >= 3 && cur <= 5) || cur === 8) {
            _this.onTabClick(cur);
          } else {
            _this.cur = cur;
          }
        } else {
          _this.cur = 1;
        }
      }

      const handleRefreshButton = (response)=>{
        this.dataRefreshActions = response.data
      }

      Promise.all([
        httpGetFormFieldV2(this.formId),
        this.getFormAuth(),
        formDetailProxy(this.formId),
        loadDataRefreshConfig(this.formId)
      ]).then((responseArray=>{
        handleFormField(responseArray[0])
        handleFormDetail(responseArray[2])
        handleRefreshButton(responseArray[3])
      }))
    },
    // 渲染表头
    renderTableColumns (refGrid, hideHiddenField, removeFields = []) {
      const _this = this;
      refGrid.columns = [];
      let fieldListTmp = deepClone(_this.formJson.fieldList);
      let fieldList = []
      // let notFilterManager = this.formDetail.managerUserId== true && this.formDetail.managerUserId.indexOf(this.loginUserId)>-1
      // let notManagerFormCreater = this.formDetail.userId=== this.loginUserId;

      fieldListTmp.forEach(field => {
        if (removeFields.indexOf(field.name) == -1) {
          fieldList.push(field);
        }
      })

      gridConfig
        .renderHeaderColumns(
          // fieldList.filter(x=>!x.hidden || notFilterManager || notManagerFormCreater),
          fieldList,
          {
            formDetail: _this.formDetail,
            dataFillStatus: _this.dataFillStatus,
          },
          { hideHiddenField: hideHiddenField }
        )
        .forEach((c) => {
          refGrid.columns.push(c);
        });

      console.log(refGrid.columns)
      return refGrid.columns;
    },
    // 渲染管理表头
    renderAdminTableHeaderColumns (refGrid) {
      const _this = this;

      gridConfig
        .renderAdminHeaderColumns(_this.formJson.fieldList, {
          formDetail: _this.formDetail,
          dataFillStatus: _this.dataFillStatus,
        })
        .forEach((c) => {
          refGrid.columns.push(c);
        });
    },
    // 渲染审核表头
    renderAuditTableHeaderColumns (refGrid) {
      const _this = this;

      gridConfig
        .renderAuditHeaderColumns(_this.formJson.fieldList, {
          formDetail: _this.formDetail,
          dataFillStatus: _this.dataFillStatus,
        })
        .forEach((c) => {
          refGrid.columns.push(c);
        });
    },
    // 渲染个人表头
    renderPersonalTableHeaderColumns (refGrid) {
      const _this = this;

      gridConfig
        .renderPersonalHeaderColumns(_this.formJson.fieldList, {
          formDetail: _this.formDetail,
          dataFillStatus: _this.dataFillStatus,
        })
        .forEach((c) => {
          refGrid.columns.push(c);
        });
    },
    getCustomFormFieldList () {
      const _this = this;
      customFormFieldList(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.formCustomDetails = !_this.isBlank(response.data.details)
            ? response.data.details
            : "表单介绍";
          _this.formCustomFile = !_this.isBlank(response.data.file)
            ? response.data.file
            : "表单附件";
        }
      });
    },
    getFormFiles () {
      const _this = this;
      formFilesList(_this.formId).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            const suffix = _this.getSuffix(response.data[i].name);
            let suffixType = null;
            if (
              suffix == "jpeg" ||
              suffix == "JPEG" ||
              suffix == "jpg" ||
              suffix == "JPG" ||
              suffix == "png" ||
              suffix == "PNG"
            ) {
              suffixType = "image";
            } else if (
              suffix == "doc" ||
              suffix == "DOC" ||
              suffix == "docx" ||
              suffix == "DOCX"
            ) {
              suffixType = "word";
            } else if (
              suffix == "exl" ||
              suffix == "EXL" ||
              suffix == "xlsx" ||
              suffix == "XLSX"
            ) {
              suffixType = "excel";
            } else if (suffix == "pdf" || suffix == "PDF") {
              suffixType = "pdf";
            } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
              suffixType = "audio";
            } else if (
              suffix == "mp4" ||
              suffix == "MP4" ||
              suffix == "mov" ||
              suffix == "m4v" ||
              suffix == "mpg" ||
              suffix == "wma" ||
              suffix == "avi" ||
              suffix == "flv" ||
              suffix == "mkv" ||
              suffix == "rm" ||
              suffix == "rmvb"
            ) {
              suffixType = "video";
            } else if (
              suffix == "zip" ||
              suffix == "ZIP" ||
              suffix == "rar" ||
              suffix == "RAR"
            ) {
              suffixType = "zip";
            } else if (
              suffix == "ppt" ||
              suffix == "PPT" ||
              suffix == "pptx" ||
              suffix == "PPTX"
            ) {
              suffixType = "ppt";
            } else {
              suffixType = "other";
            }
            response.data[i].suffixType = suffixType;
          }
          _this.formCustomFileList = response.data;
          if (_this.formDetail && _this.formDetail.details != "") {
            _this.formInfoCur = 1;
          } else {
            if (
              _this.formCustomFileList &&
              _this.formCustomFileList.length > 0
            ) {
              _this.formInfoCur = 2;
            }
          }
        }
      });
    },
    // 数据查看列表，是否显示填报状态列(管理员通过批量导入他人填报数据)
    getDataFillStatus () {
      const _this = this;
      dataFillStatus(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.dataFillStatus = response.data;
        }
      });
    },

    async getFormPayInfo (callback) {
      if (!this.formDetail.pay) {
        return {};
      }
      return formPayInfo(this.formId);
    },
    getSuffix (name) {
      return name.split(".").pop().toLowerCase();
    },
    getFormAuth () {
      formAuth(this.formId).then((response) => {
        if (response.code == 200) {
          this.formAuth = response.data;
        }
      });
    },

    getFormReserves () {
      const _this = this;
      formReserves(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.reserveList = response.data;
          if (!_this.isBlank(_this.fillId) && !_this.isEditFill) {
            let reserveMaxTime = null;
            const existType14 = _this.formFieldList.find(
              (item) => item.type == "14"
            );
            const existType14Value = this.formData[existType14.formFieldId];
            if (!_this.isBlank(existType14Value)) {
              const existReserveObj = _this.reserveList.find(
                (item) => item.reserveId == existType14Value
              );
              if (
                !_this.isBlank(existReserveObj) &&
                !_this.isBlank(existReserveObj.maxTime)
              ) {
                switch (existReserveObj.maxTime) {
                  case 1:
                    reserveMaxTime = 0.5;
                    break;
                  case 2:
                    reserveMaxTime = 1;
                    break;
                  case 3:
                    reserveMaxTime = 1.5;
                    break;
                  case 4:
                    reserveMaxTime = 2;
                    break;
                  case 5:
                    reserveMaxTime = 2.5;
                    break;
                  case 6:
                    reserveMaxTime = 3;
                    break;
                }
              }
            }
            _this.reserveMaxTime = reserveMaxTime;
          }
        } else {
          _this.msgError(response.msg);
        }
      });
    },
    // type == 13 处理tree数据
    getTreeData (data) {
      const newArr = typeof data === "string" ? JSON.parse(data) : data;
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].children && newArr[i].children.length < 1) {
          newArr[i].children = undefined; // children若为空数组，则将children设为undefined
        } else {
          this.getTreeData(newArr[i].children); // children若不为空数组，则继续 递归调用 本方法
        }
      }
      return newArr;
    },
    // 根据机构ID获取机构列表
    getDeptTreeSelect (agencyId) {
      fillAgencyDeptList(agencyId).then((response) => {
        const treeList = response.data;
        this.agencyDeptOptionsOrigin = treeList;
        this.agencyDeptOptions = this.handleTree(treeList, "agencyDeptId");
        this.agencyDeptOptions = this.getDeptTree(this.agencyDeptOptions);
      });
    },
    getDeptTree (tree = []) {
      const arr = [];
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {};
          obj.label = item.deptName;
          obj.id = item.agencyDeptId;
          if (item.children) {
            obj.children = this.getDeptTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    // 省市区三级联动 选择省
    getProvinceSelect (item, index) {
      this.formFieldList[index] = item;
      this.formFieldList[index].city = "";
      this.formFieldList[index].county = "";
      if (this.formFieldList[index].attribute != "省") {
        getCity(this.formFieldList[index].province).then((res) => {
          this.cityList = res.data;
          this.$forceUpdate();
        });
      }
      this.$forceUpdate();
      this.formData[item.formFieldId] = item.province;
    },
    getCitySelect (item, index) {
      this.formFieldList[index] = item;
      this.formFieldList[index].county = "";
      if (this.formFieldList[index].attribute != "省市") {
        getCounty(
          this.formFieldList[index].province,
          this.formFieldList[index].city
        ).then((res) => {
          this.countyList = res.data;
          this.$forceUpdate();
        });
      }
      this.$forceUpdate();
    },
    getCountySelect (item, index) {
      this.$forceUpdate();
    },
    onAddressChange () {
      this.$forceUpdate();
    },
    async onTabClick (index) {
      this.cur = index;
      this.$emit('tab-change', index);
      console.log('onTabClick', index)
      this.editRowIndex = -1;
      this.isEditFill = false;
      // this.clearFormDetail()
      let breadcrumbName = "";
      // 填报列表
      if (this.cur == 3) {
        this.fillQueryParams = {
          queryStatus: "",
          payStatus: "",
          fillStartTime: "",
          fillEndTime: "",
          formFieldId: "",
          fillValue: "",
        };


        this.fillGridOptions.pagerConfig.currentPage = 1;
        // this.fillGridOptions.columns = []
        // this.fillGridOptions.data = [];
        this.renderTableColumns(this.fillGridOptions, true);
        this.renderPersonalTableHeaderColumns(this.fillGridOptions);

        if (this.formAuth.currentQueryFillListAuth) {
          this.getMyFillList();
        }
        breadcrumbName = "已填列表";
      }
      // 审核列表
      if (this.cur == 4) {
        this.reviewGridOptions.pagerConfig.currentPage = 1;
        // this.reviewGridOptions.columns = []
        this.reviewGridOptions.data = [];


        // 在三级审核中配置的隐藏的字段
        let res = await formAuditAuthFieldDetail(this.formDetail.formId)
        let auditLevelFieldAuth = res.data
        let hiddenFields = {}
        let onlyHideFormJsonField = true; //默认根据表单配置来显示和隐藏字段

        this.formJson.fieldList.forEach(x => {
          if (x.hidden) {
            hiddenFields[x.name] = true;
          }
        })

        if (auditLevelFieldAuth && Array.isArray(auditLevelFieldAuth)) {
          // 如果在这里面控制了，用这里的显示隐藏覆盖表单中的
          onlyHideFormJsonField = false;
          auditLevelFieldAuth.forEach(auditLevel => {
            Object.keys(auditLevel.fieldAuth).forEach(key => {
              if (auditLevel.fieldAuth[key].find(x => x == 3) > -1) {
                hiddenFields[key] = true;
              }
            })
          })
        }
        //hiddenFields 转成数组
        let removeColumnFields = Object.keys(hiddenFields);


        this.renderTableColumns(this.reviewGridOptions, onlyHideFormJsonField, removeColumnFields);
        this.renderAuditTableHeaderColumns(this.reviewGridOptions);

        this.queryParams = {
          pageNum: 1,
          pageSize: 10,
          formFieldId: "",
          fillValue: "",
          queryStatus: "",
          // payStatus:'',
          fillStartTime: "",
          fillEndTime: "",
        };
        if (this.formAuth.currentQueryReviewListAuth) {
          this.getReviewList();
        }
        if (this.$refs.reviewXGrid) {
          this.$refs.reviewXGrid.clearSelected();
          this.$refs.reviewXGrid.clearCheckboxRow();
        }
        breadcrumbName = "审核列表";
      }
      // 数据管理列表
      if (this.cur == 5) {
        this.gridOptions.pagerConfig.currentPage = 1;
        this.gridOptions.columns = []
        // 模拟数据
        this.gridOptions.data = [];
        this.renderTableColumns(this.gridOptions, false);

        //判断并显示表格的列
        this.renderAdminTableHeaderColumns(this.gridOptions);
        this.dataQueryParams = {
          queryStatus: "",
          payStatus: "",
          fillStartTime: "",
          fillEndTime: "",
          formFieldId: "",
          fillValue: "",
          fillStatus: "",
        };
        if (this.formAuth.currentQueryDataListAuth) {
          this.getDataList();
        }
        breadcrumbName = "管理列表";
      }
      // 数据统计
      if (this.cur == 8) {
        this.getFormStatics();
        this.getFormLargeScreen();
        //调用大屏API获取大屏地址
        breadcrumbName = "统计报表";
      }
      this.breadcrumbName = breadcrumbName;
    },
    // 开始填报、继续填报，跳转填报页面
    onFillClickCheck () {
      //开始填报的检查：表单中是是否配置了字段，表单是否需要先同意协议
      if (this.isBlank(this.formJson)) {
        this.$message({
          type: "warning",
          message: "该表单暂无设置表单字段无法填报～",
        });
        return;
      }

      const startFill = () => {
        // FEAT#表单填报 开始新的填报
        // 打开填报弹窗
        this.$refs.dlgvFormViewer.showAdd({
          title: this.formDetail.startFillText,
          formId: this.formId,
          formDetail: this.formDetail,
          formData: {},
          formJson: deepClone(this.formJson),
          formMode: 'new',
          listType: 'FILL'
        });
      }

      if (this.formAgreementId != null) {
        this.$refs.fillAgreement.show(this.formAgreementId, startFill);
      } else {
        startFill();
      }
      // if (this.formAgreementId != null) {
      //   this.$refs.fillAgreement.show(this.formAgreementId);
      // } else {
      //   this.onFillClick();
      // }
    },
    onFillClick () {
      const _this = this;
      _this.clearFormDetail();
      _this.fillId = null;
      _this.isEditFill = false;

      _this.fillGridOptions.columns = [];
      _this.renderTableColumns(_this.fillGridOptions, true);
      _this.renderPersonalTableHeaderColumns(_this.fillGridOptions);

      checkFill(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.cur = 2;
          // 如果强制启用只读字段
          _this.$refs.vformRender.enableForm();

          _this.$refs.vformRender.enableForm(_this.optionData.isUserAdmin);
          _this.$refs.vformRender.hideHiddenWidgets();
        }
      });
      _this.breadcrumbName = "";
    },
    // 获取年月日
    getNowFormatDate () {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentDate = year + seperator1 + month + seperator1 + strDate;
      return currentDate;
    },
    // 页面详情 填报上传图片限制
    onHandleImgExceed () {
      this.$message.warning(`当前限制添加1张图片！`);
    },
    // 填报页面---填报上传图片前
    onBeforeImgUpload (file) {
      const filename = file.name;
      const extension = filename.substring(
        filename.lastIndexOf(".") + 1,
        filename.length
      );
      if (
        !(extension && /^(jpg|jpeg|png|gif)$/.test(extension.toLowerCase()))
      ) {
        this.$message.warning("上传图片只能是 jpg/png/jpeg/gif格式！");
        this.fillBtnClick = false;
        return false;
      } else {
        this.fillBtnClick = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.warning(`上传图片大小不能超过20MB！`);
        this.fillBtnClick = false;
        return false;
      } else {
        this.fillBtnClick = true;
      }
    },
    // 填报页面---填报上传图片成功
    onHandleImgSuccess (response, item, index) {
      const _this = this;
      if (!_this.isBlank(response) && !_this.isBlank(response.data)) {
        _this.formData[item.formFieldId] = response.data;
        _this.formFieldList[index].showRequiredTxt = false;
        if (item.required) {
          if (_this.isBlank(_this.formData[item.formFieldId])) {
            _this.formFieldList[index].errorText = item.label + "不能为空";
            _this.$forceUpdate();
            return;
          } else {
            _this.formFieldList[index].errorText = null;
            _this.$forceUpdate();
          }
        }
        _this.fillBtnClick = false;
      }
    },
    // 填报页面---填报上传图片删除
    onBeforeImgRemove (response, item, index) {
      this.formData[item.formFieldId] = "";
      this.fillBtnClick = false;
    },
    // 填报页面---填报上传图片预览
    onHandlePreviewImg (file) {
      this.multiples = 1;
      this.deg = 0;
      this.endX = 0;
      this.endY = 0;
      this.imgPreviewVisible = true;
      this.imgPreviewPath = file.url;

      this.styleObj = {};
      this.styleObj.backgroundImage = `url(${this.imgPreviewPath})`;
      this.styleObj.backgroundSize = "contain";
      this.styleObj.backgroundRepeat = "no-repeat";
      this.styleObj.backgroundPosition = "center center";
      this.styleObj.left = `${this.endX}px`;
      this.styleObj.top = `${this.endY}px`;
    },
    // 填报页面---填报上传图片预览并放大
    onMagnifyImg () {
      if (this.multiples >= 10) {
        return;
      }
      this.multiples += 0.25;
      // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg); left:${this.endX}px; top:${this.endY}px`
      this.styleObj.transform = `scale(${this.multiples}) rotateZ(${this.deg}deg)`;
      this.styleObj.backgroundSize = "contain";
      this.styleObj.backgroundRepeat = "no-repeat";
      this.styleObj.backgroundPosition = "center center";
      this.styleObj.left = `${this.endX}px`;
      this.styleObj.top = `${this.endY}px`;
      this.$forceUpdate();
    },
    // 填报页面---填报上传图片预览并缩小
    onShrinkImg () {
      if (this.multiples <= 0.25) {
        return;
      }
      this.multiples -= 0.25;
      // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg); left:${this.endX}px; top:${this.endY}px`
      this.styleObj.transform = `scale(${this.multiples}) rotateZ(${this.deg}deg)`;
      this.styleObj.backgroundSize = "contain";
      this.styleObj.backgroundRepeat = "no-repeat";
      this.styleObj.backgroundPosition = "center center";
      this.styleObj.left = `${this.endX}px`;
      this.styleObj.top = `${this.endY}px`;
      this.$forceUpdate();
    },
    // 填报页面---填报上传图片预览并旋转
    onRotateImg () {
      this.deg += 90;
      if (this.deg >= 360) {
        this.deg = 0;
      }
      // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg);left:${this.endX}px;top:${this.endY}px`
      this.styleObj.transform = `scale(${this.multiples}) rotateZ(${this.deg}deg)`;
      this.styleObj.backgroundSize = "contain";
      this.styleObj.backgroundRepeat = "no-repeat";
      this.styleObj.backgroundPosition = "center center";
      this.styleObj.left = `${this.endX}px`;
      this.styleObj.top = `${this.endY}px`;
      this.$forceUpdate();
    },
    // 填报页面---填报上传图片预览鼠标滚轮滑动
    onGunlunImg (e) {
      // console.log('填报页面---填报上传图片预览鼠标滚轮滑动', e)
      const direction = e.deltaY > 0 ? "down" : "up";

      this.styleObj = {};
      this.styleObj.backgroundImage = `url(${this.imgPreviewPath})`;
      this.styleObj.left = `${this.endX}px`;
      this.styleObj.top = `${this.endY}px`;

      if (direction === "up") {
        // 滑轮向上滚动
        if (this.multiples <= 0.25) {
          return;
        }

        this.multiples -= 0.25;
        this.styleObj.transform = `scale(${this.multiples}) rotateZ(${this.deg}deg)`;
      } else {
        // 滑轮向下滚动
        if (this.multiples >= 10) {
          return;
        }
        this.multiples += 0.25;
        this.styleObj.transform = `scale(${this.multiples}) rotateZ(${this.deg}deg)`;
      }
      console.log(this.styleObj);
    },
    // 填报页面---填报上传附件限制
    onHandleFileExceed () {
      this.$message.warning(`当前限制添加1个附件！`);
    },
    // 填报页面---填报上传附件前
    onBeforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.warning(`上传附件大小不能超过20MB！`);
      }
      if (isLt2M) {
        this.fillBtnClick = true;
      } else {
        return isLt2M;
      }
    },
    // 填报页面---填报上传附件成功
    onHandleFileSuccess (response, item, index) {
      const _this = this;
      if (!_this.isBlank(response) && !_this.isBlank(response.data)) {
        _this.formData[item.formFieldId] = response.data;
        _this.formFieldList[index].showRequiredTxt = false;
        if (item.required) {
          if (_this.isBlank(_this.formData[item.formFieldId])) {
            _this.formFieldList[index].errorText = item.label + "不能为空";
            _this.$forceUpdate();
            return;
          } else {
            _this.formFieldList[index].errorText = null;
            _this.$forceUpdate();
          }
        }
        _this.fillBtnClick = false;
      }
    },
    // 填报页面---填报上传附件删除
    onBeforeFileRemove (response, item, index) {
      this.formData[item.formFieldId] = "";
      this.fillBtnClick = false;
    },
    // 填报页面---填报上传附件下载
    onHandlePreviewFile (file) {
      const fileUrl = file.url;
      // 1.先创建一个a标签
      const a = document.createElement("a");
      // 2.给a标签的href赋上下载地址及下载名称
      const downloadName = this.getSuffName(fileUrl);
      a.download = downloadName;
      a.href = fileUrl;
      // 3.让a标签不显示在视图中
      a.style.display = "none";
      // 4.将a标签append到文档中
      document.body.appendChild(a);
      // 5.a标签自点击
      a.click();
      // 6.点击下载后，销毁这个节点
      document.body.removeChild(a);
    },
    // 填报页面---单行文本仅在输入框失去焦点或用户按下回车时触发
    onChangeInput (value, index, item) {
      if (this.isEditFill) return;
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (this.isBlank(value)) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        } else {
          if (item.type == "3") {
            const n = Number(value);
            if (isNaN(n)) {
              this.formFieldList[index].errorText = item.label + "格式不正确";
              this.$forceUpdate();
              return;
            } else {
              this.formFieldList[index].errorText = null;
              this.$forceUpdate();
            }
          } else {
            if (item.isRegExp) {
              if (item.attribute == "身份证号") {
                const ident = value.toUpperCase();
                const IdentFlag = IdentityCodeValid(ident);
                if (!IdentFlag) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              } else {
                if (!new RegExp(item.regExp).test(value)) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              }
            }
            if (item.isLength) {
              let errorMessage = null;
              if (value.length < item.minLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最少字数为" + item.minLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最少字数为" + item.minLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else if (value.length > item.maxLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最大字数为" + item.maxLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最大字数为" + item.maxLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else {
                this.formFieldList[index].errorText = null;
                this.$forceUpdate();
              }
            }
          }
        }
      } else {
        if (!this.isBlank(value)) {
          if (item.type == "3") {
            const n = Number(value);
            if (isNaN(n)) {
              this.formFieldList[index].errorText = item.label + "格式不正确";
              this.$forceUpdate();
              return;
            } else {
              this.formFieldList[index].errorText = null;
              this.$forceUpdate();
            }
          } else {
            if (item.isRegExp) {
              if (item.attribute == "身份证号") {
                const ident = value.toUpperCase();
                const IdentFlag = IdentityCodeValid(ident);
                if (!IdentFlag) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              } else {
                if (!new RegExp(item.regExp).test(value)) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              }
            }
            if (item.isLength) {
              let errorMessage = null;
              if (value.length < item.minLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最少字数为" + item.minLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最少字数为" + item.minLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else if (value.length > item.maxLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最大字数为" + item.maxLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最大字数为" + item.maxLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else {
                this.formFieldList[index].errorText = null;
                this.$forceUpdate();
              }
            }
          }
        } else {
          this.formFieldList[index].errorText = null;
          this.$forceUpdate();
        }
      }
    },
    // 填报页面---单行文本 失去焦点时触发
    onBlurInput (e, index, item) {
      if (this.isEditFill) return;
      this.formFieldList[index].showRequiredTxt = false;
      const value = this.formData[item.formFieldId];
      if (item.required) {
        if (this.isBlank(value)) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        } else {
          if (item.type == "3") {
            const n = Number(value);
            if (isNaN(n)) {
              this.formFieldList[index].errorText = item.label + "格式不正确";
              this.$forceUpdate();
              return;
            } else {
              this.formFieldList[index].errorText = null;
              this.$forceUpdate();
            }
          } else {
            if (item.isRegExp) {
              if (item.attribute == "身份证号") {
                const ident = value.toUpperCase();
                const IdentFlag = IdentityCodeValid(ident);
                if (!IdentFlag) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              } else {
                if (!new RegExp(item.regExp).test(value)) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              }
            }
            if (item.isLength) {
              let errorMessage = null;
              if (value.length < item.minLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最少字数为" + item.minLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最少字数为" + item.minLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else if (value.length > item.maxLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最大字数为" + item.maxLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最大字数为" + item.maxLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else {
                this.formFieldList[index].errorText = null;
                this.$forceUpdate();
              }
            }
          }
        }
      } else {
        if (!this.isBlank(value)) {
          if (item.type == "3") {
            const n = Number(value);
            if (isNaN(n)) {
              this.formFieldList[index].errorText = item.label + "格式不正确";
              this.$forceUpdate();
              return;
            } else {
              this.formFieldList[index].errorText = null;
              this.$forceUpdate();
            }
          } else {
            if (item.isRegExp) {
              if (item.attribute == "身份证号") {
                const ident = value.toUpperCase();
                const IdentFlag = IdentityCodeValid(ident);
                if (!IdentFlag) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              } else {
                if (!new RegExp(item.regExp).test(value)) {
                  this.formFieldList[index].errorText =
                    item.label + "格式不正确";
                  this.$forceUpdate();
                  return;
                } else {
                  this.formFieldList[index].errorText = null;
                  this.$forceUpdate();
                }
              }
            }
            if (item.isLength) {
              let errorMessage = null;
              if (value.length < item.minLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最少字数为" + item.minLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最少字数为" + item.minLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else if (value.length > item.maxLength) {
                if (item.type == "1") {
                  if (item.attribute != "文本") {
                    errorMessage = item.label + "格式不正确";
                  } else {
                    errorMessage =
                      item.label + "的最大字数为" + item.maxLength + "位";
                  }
                } else if (item.type == "2") {
                  errorMessage =
                    item.label + "的最大字数为" + item.maxLength + "位";
                }
                this.formFieldList[index].errorText = errorMessage;
                this.$forceUpdate();
                return;
              } else {
                this.formFieldList[index].errorText = null;
                this.$forceUpdate();
              }
            }
          }
        } else {
          this.formFieldList[index].errorText = null;
          this.$forceUpdate();
        }
      }
    },
    // 填报页面---单选
    onRadioChange (value, index, item) {
      if (this.isEditFill) return;
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (this.isBlank(this.formData[item.formFieldId])) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        } else {
          this.formFieldList[index].errorText = null;
          this.$forceUpdate();
        }
      }
    },
    // 填报页面---多选
    handleChange (value, index, item) {
      if (this.isEditFill) return;
      switch (item.type) {
        case "3":
          this.formData[item.formFieldId] = !this.isBlank(
            this.formData[item.formFieldId]
          )
            ? this.formData[item.formFieldId]
            : undefined; // el-input-number 默认值为0 置空 el-input-number的v-model绑定值不要为 "" , 而应该写为 undefined
          break;
        case "7": // 多选框
          this.formData[item.formFieldId] = item.selectCheckbox.join("{JYDSP}");
          this.formFieldList[index].showRequiredTxt = false;
          if (item.required) {
            if (this.isBlank(this.formData[item.formFieldId])) {
              this.formFieldList[index].errorText = item.label + "不能为空";
              this.$forceUpdate();
              return;
            } else {
              this.formFieldList[index].errorText = null;
              this.$forceUpdate();
            }
          }
          break;
      }
    },
    onChangeDate (value, index, item) {
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (this.isBlank(value)) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        } else {
          this.formFieldList[index].errorText = null;
          this.$forceUpdate();
        }
      }
    },
    onBlurDate (e, index, item) {
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (this.isBlank(this.formData[item.formFieldId])) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        }
      }
    },
    onSelectTree (value, index, item) {
      this.formData[item.formFieldId] = !this.isBlank(value.label)
        ? value.label
        : null;
    },
    onInputTree (value, index, item) {
      if (this.isBlank(value)) {
        this.formData[item.formFieldId] = null;
      }
      if (item.required) {
        if (this.isBlank(this.formData[item.formFieldId])) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        } else {
          this.formFieldList[index].showRequiredTxt = false;
          this.formFieldList[index].errorText = null;
          this.$forceUpdate();
        }
      }
    },
    onCloseTree (value, index, item) { },
    // 填报页面---多级下拉当选中节点变化时触发
    onHandleCascader (callback, index, item) {
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (!callback) {
          if (this.isBlank(this.formData[item.formFieldId])) {
            this.formFieldList[index].errorText = item.label + "不能为空";
            this.$forceUpdate();
            return;
          } else {
            this.formFieldList[index].errorText = null;
            this.$forceUpdate();
          }
        }
      }
    },
    // 填报页面---多级下拉下拉框出现/隐藏时触发
    onVisibleCascader (callback, index, item) { },
    // 新版---type == 14 预约 选择会议室
    onRadioReserveChange (value, index, item) {
      const _this = this;
      if (_this.isEditFill) return;
      _this.formFieldList[index].showRequiredTxt = false;
      _this.reserveId = value;
      const existReserveObj = _this.reserveList.find(
        (item) => item.reserveId == value
      );
      let reserveMaxTime = null;
      if (!_this.isBlank(existReserveObj.maxTime)) {
        switch (existReserveObj.maxTime) {
          case 1:
            reserveMaxTime = 0.5;
            break;
          case 2:
            reserveMaxTime = 1;
            break;
          case 3:
            reserveMaxTime = 1.5;
            break;
          case 4:
            reserveMaxTime = 2;
            break;
          case 5:
            reserveMaxTime = 2.5;
            break;
          case 6:
            reserveMaxTime = 3;
            break;
        }
      }
      _this.reserveMaxTime = reserveMaxTime;
      // 获取预约室时间段信息
      fillReserveTimes(_this.reserveId, _this.reserveDate).then((response) => {
        if (response.code == 200) {
          const timeList = response.data;
          for (let i = 0; i < timeList.length; i++) {
            timeList[i].endTime = timeList[i].time.split("-")[1];
            var date = new Date();
            var hour = date.getHours();
            const minute = date.getMinutes();
            const year = date.getFullYear();
            const month =
              date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            const day =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            const composeYMD = year + "-" + month + "-" + day;
            let isExpired = false; // 是否到期
            if (_this.dateDaxiao(_this.reserveDate, composeYMD) < 0) {
              console.log("说明所选预约时间小于当前年月日, 时间已过期");
              isExpired = true;
            } else if (_this.dateDaxiao(_this.reserveDate, composeYMD) == 0) {
              if (timeList[i].endTime.split(":")[0] < hour) {
                isExpired = true;
              } else if (timeList[i].endTime.split(":")[0] == hour) {
                if (timeList[i].endTime.split(":")[1] <= minute) {
                  isExpired = true;
                }
              } else if (timeList[i].endTime.split(":")[0] > hour) {
                isExpired = false;
              }
            } else {
              isExpired = false;
            }
            timeList[i].isExpired = isExpired;
            timeList[i].checked = false;
          }
          _this.reserveTimes = timeList;
        }
      });
    },
    getFillReserveTimes () {
      const _this = this;
      fillReserveTimes(_this.reserveId, _this.reserveDate).then((response) => {
        if (response.code == 200) {
          const timeList = response.data;
          for (let i = 0; i < timeList.length; i++) {
            timeList[i].endTime = timeList[i].time.split("-")[1];
            var date = new Date();
            var hour = date.getHours();
            const minute = date.getMinutes();
            const year = date.getFullYear();
            const month =
              date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            const day =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            const composeYMD = year + "-" + month + "-" + day;
            let isExpired = false; // 是否到期
            if (_this.dateDaxiao(_this.reserveDate, composeYMD) < 0) {
              console.log("说明所选预约时间小于当前年月日, 时间已过期");
              isExpired = true;
            } else if (_this.dateDaxiao(_this.reserveDate, composeYMD) == 0) {
              if (timeList[i].endTime.split(":")[0] < hour) {
                isExpired = true;
              } else if (timeList[i].endTime.split(":")[0] == hour) {
                if (timeList[i].endTime.split(":")[1] <= minute) {
                  isExpired = true;
                }
              } else if (timeList[i].endTime.split(":")[0] > hour) {
                isExpired = false;
              }
            } else {
              isExpired = false;
            }
            timeList[i].isExpired = isExpired;
            timeList[i].checked = false;
            if (_this.isBlank(_this.fillId)) {
              // 开始填报
            } else {
              // 修改填报
              const formFieldList = _this.formFieldList;
              let fillValue = "";
              for (let k = 0; k < formFieldList.length; k++) {
                if (formFieldList[k].type == "16") {
                  const formFieldId = formFieldList[k].formFieldId;
                  fillValue = _this.formData[formFieldId];
                }
              }
              let fillValueArr = [];
              if (!_this.isBlank(fillValue)) {
                fillValueArr = fillValue.split(",");
                if (!_this.isBlank(fillValueArr)) {
                  for (let j = 0; j < fillValueArr.length; j++) {
                    if (fillValueArr[j] == timeList[i].time) {
                      timeList[i].isExpired = false;
                      timeList[i].checked = true;
                      timeList[i].selectStatus = true;
                      timeList[i].alreadyBooked = true; // 已预约
                    }
                  }
                }
              }
            }
          }
          _this.reserveTimes = timeList;
        }
      });
    },
    dateDaxiao (t1, t2) {
      var date0 = new Date(t1);
      var date1 = new Date(t2);
      const differTime = date0 - date1;
      return differTime;
    },
    onChangerRserveDate (value, index, item) {
      const _this = this;
      if (_this.isEditFill) return;
      _this.formFieldList[index].showRequiredTxt = false;
      // 获取预约室时间段信息
      fillReserveTimes(_this.reserveId, _this.reserveDate).then((response) => {
        if (response.code == 200) {
          const timeList = response.data;
          for (let i = 0; i < timeList.length; i++) {
            timeList[i].endTime = timeList[i].time.split("-")[1];
            var date = new Date();
            var hour = date.getHours();
            const minute = date.getMinutes();
            const year = date.getFullYear();
            const month =
              date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            const day =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            const composeYMD = year + "-" + month + "-" + day;
            let isExpired = false; // 是否到期
            if (_this.dateDaxiao(_this.reserveDate, composeYMD) < 0) {
              console.log("说明所选预约时间小于当前年月日, 时间已过期");
              isExpired = true;
            } else if (_this.dateDaxiao(_this.reserveDate, composeYMD) == 0) {
              if (timeList[i].endTime.split(":")[0] < hour) {
                isExpired = true;
              } else if (timeList[i].endTime.split(":")[0] == hour) {
                if (timeList[i].endTime.split(":")[1] <= minute) {
                  isExpired = true;
                }
              } else if (timeList[i].endTime.split(":")[0] > hour) {
                isExpired = false;
              }
            } else {
              isExpired = false;
            }
            timeList[i].isExpired = isExpired;
            timeList[i].checked = false;
          }
          _this.reserveTimes = timeList;
        }
      });
    },
    onSelectTimeRange (index) {
      const _this = this;
      if (_this.isEditFill) return;
      if (_this.reserveTimes[index].isExpired) {
        return;
      }
      if (!_this.reserveTimes[index].selectStatus) {
        return;
      }
      _this.reserveTimes[index].checked = !_this.reserveTimes[index].checked;
      if (_this.reserveTimes[index].alreadyBooked) {
        console.log("已经预约的取消");
        const timeList = _this.reserveTimes;
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const year = date.getFullYear();
        const month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const composeYMD = year + "-" + month + "-" + day;
        let isExpired = false; // 是否到期
        if (_this.dateDaxiao(_this.reserveDate, composeYMD) < 0) {
          // console.log('说明所选预约时间小于当前年月日, 时间已过期')
          isExpired = true;
        } else if (_this.dateDaxiao(_this.reserveDate, composeYMD) == 0) {
          if (timeList[index].endTime.split(":")[0] < hour) {
            isExpired = true;
          } else if (timeList[index].endTime.split(":")[0] == hour) {
            if (timeList[index].endTime.split(":")[1] <= minute) {
              isExpired = true;
            }
          } else if (timeList[index].endTime.split(":")[0] > hour) {
            isExpired = false;
          }
        } else {
          isExpired = false;
        }
        timeList[index].isExpired = isExpired;
        timeList[index].alreadyBooked = false;
        _this.reserveTimes = timeList;
      }
    },
    addRelativeLink (fillId) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type == "fill-select" ||
          x.field.type === 'fill-select' ||
          (
            x.field.subtype && x.field.subtype == 'relative' &&
            !(x.field.type === 'sub-form' && x.field.options.reference === 'copy')
            // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
          )
      );

      return new Promise((resolve, reject) => {
        relativeWidgets.forEach(async (x) => {
          const widget = this.$refs.vformRender.getWidgetRef(
            x.field.options.name
          );
          if (widget.syncRelativeChange) {
            await widget.syncRelativeChange(fillId);
          }
        });
        resolve();
      });
    },
    clearFormCache () {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type == "fill-select" ||
          (x.field.subtype && x.field.subtype == "relative")
      );

      relativeWidgets.forEach(async (x) => {
        const widget = this.$refs.vformRender.getWidgetRef(
          x.field.options.name
        );
        await widget.clearTmpList();
      });
    },
    // 页面详情表单提交、表单修改
    onSaveFillClick (callback) {
      const _this = this;

      // 检查表单是否填写，如果未填写,且没有设置默认值，需要提示用户()
      // 这里只有创建一种情况，没有修改页面了。
      if (
        !_this.$refs.vformRender.isFormDataEdit() &&
        !_this.$refs.vformRender.isFormFillWithDefault()
      ) {
        _this.msgError("请填写完表单信息后再提交");
        return;
      }

      const getFormJson = (cb) => {
        _this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const fillJsonCopy = JSON.parse(JSON.stringify(fillJson));
            const param = {
              formId: _this.formId,
              fillJson: fillJson,
              formAgreementId: this.formAgreementId,
              formAgreementSignature: "",
            };
            this.fillJson = fillJsonCopy;
            if (cb) cb(param);
          })
          .catch((err) => {
            _this.msgError(err);
          });
      };

      // 检查fillId，为空 新增，否则修改
      if (_this.isBlank(_this.fillId)) {
        // 检查表单信息填报规则校验
        checkFill(_this.formId).then((response) => {
          if (response.code == 200) {
            getFormJson((param) => {
              fill(param).then(async (response) => {
                if (response.code == 200) {
                  // _this.msgSuccess('提交成功')

                  _this.formSubmitFillId = response.data;
                  await _this.addRelativeLink(this.formSubmitFillId);

                  _this.$refs.vformRender.resetForm();

                  // 如果不是后退才弹出支付窗口
                  if (!callback) {
                    _this.fillSuccessShow = true;
                    if (!_this.formDetail.pay) {
                      // 表单不开启支付功能，5秒后自动关闭关注公众号pop
                      // 迭代4.0.2支付成功后内容展示-禁用自动关闭
                      // setTimeout(function() {
                      //   _this.fillSuccessShow = false
                      // }, 5000)
                    }
                  } else {
                    setTimeout(function () {
                      if (callback) callback();
                    }, 1000);
                  }
                }
              });
            });
          }
        });
      } else {
        getFormJson((param) => {
          editFill(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("修改填报成功");
              _this.addRelativeLink();
              if (!_this.isBlank(_this.formDetail.historyFormId)) {
                _this.isHistoryFill = false;
              }
              _this.getFormAuth(_this.formId);
              // _this.fillGridOptions.columns = []
              _this.fillGridOptions.data = [];
              _this.getMyFillList();
              _this.cur = 3;
              _this.clearFormDetail();
              _this.fillId = null;
              callback();
            }
          });
        });
      }
    },
    // 页面详情表单提交成功后，提示引导关注公众号 关闭
    onCloseFillSuccessPop () {
      // console.log('检查弹框关闭后，是否走此方法，结果为true')
      this.fillSuccessShow = false;
      // 针对关联历史表单
      if (!this.isBlank(this.formDetail.historyFormId)) {
        this.historyFillId = null;
        this.currentFormHistory = [];
        this.isHistoryFill = false;
      }
      this.getFormAuth(this.formId);

      this.cur = 3;
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getMyFillList();
      this.clearFormDetail();
      this.breadcrumbName = "已填列表";
      localStorage.removeItem('draftId');
      localStorage.removeItem('draft_formModel');
    },
    // 页面详情表单提交成功后，提示引导关注公众号 关闭
    onClosePayFillSuccessPop () {
      // console.log('检查弹框关闭后，是否走此方法，结果为true')
      this.paySuccessShow = false;
    },
    // cur == 9 通过管理员批量导入的填报，需要填报人确认，此操作相当于修改填报
    onFillConfirmClick () {
      const _this = this;

      _this.$refs.vformConfirmRender
        .getFormData()
        .then((fillJson) => {
          const param = {
            fillId: _this.fillId,
            formId: _this.formId,
            fillJson: fillJson,
            fillChange: changedValue(
              this.formJson,
              this.confirmOldFillData,
              fillJson
            ),
          };

          confirmFill(param).then((response) => {
            if (response.code == 200) {
              _this.fillGridOptions.data = [];
              _this.getMyFillList();
              _this.cur = 3;
              _this.clearFormDetail();
              _this.fillId = null;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          _this.msgError(err);
        });
    },
    // 表单开启支付功能，暂不支付
    onCancelPayFillClick () {
      this.fillSuccessShow = false;
      // 暂不支付，跳转我的填报列表
      // if(!this.isBlank(this.formDetail.historyFormId)) {
      //   this.historyFillId = null
      //   this.currentFormHistory = []
      //   this.isHistoryFill = false
      // }
      // this.getFormAuth(this.formId)
      // this.fillGridOptions.columns = []
      // this.fillGridOptions.data = []
      // this.getMyFillList()
      // this.cur = 3
      // this.clearFormDetail()
      // this.breadcrumbName = '我的填报'
    },
    // 表单开启支付功能，立即支付
    onConfirmPayFillClick () {
      const _this = this;
      const dto = {
        fillId: _this.formSubmitFillId,
        formId: _this.formId,
        amount: this.fillJson.__amount
          ? Number(this.fillJson.__amount || "1")
          : undefined,
      };

      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.formPayOpen = true;
          _this.orderPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["qrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.setTimer(1000, response.data.orderNo);
        }
      });
    },
    setTimer (intMilliecond, orderNo) {
      const _this = this;
      let timeSeconds = 0;
      _this.qrTimer = setInterval(function () {
        timeSeconds += 1;
        if (timeSeconds <= 300) {
          _this.getQrPayState(orderNo);
        } else {
          clearInterval(_this.qrTimer);
        }
      }, intMilliecond);
    },
    getQrPayState (orderNo) {
      const _this = this;
      orderStatus(orderNo).then((response) => {
        if (response.code == 200) {
          if (response.data == true) {
            // _this.msgSuccess('支付成功')

            // 清空支付相关
            clearInterval(_this.qrTimer);
            document.getElementById("qrCode").innerHTML = "";
            _this.formPayOpen = false;

            // 清空表单提交成功弹框
            _this.fillSuccessShow = false;
            _this.paySuccessShow = true;
            // 5秒后自动关闭
            // 4.0.2迭代 去掉自动关闭
            // setTimeout(function() {
            //   _this.paySuccessShow = false
            // }, 5000)
          }
        } else {
          clearInterval(_this.qrTimer);
          document.getElementById("qrCode").innerHTML = "";
        }
      });
    },
    onClosePay () {
      clearInterval(this.qrTimer);
      document.getElementById("qrCode").innerHTML = "";
      // 关闭支付弹框，同时需要关闭表单提交成功弹框，跳转我的填报列表
      this.fillSuccessShow = false;
      this.formSubmitFillId = null;
    },
    clearFormDetail () {
      this.$refs.vformRender.resetForm();
    },
    // 点击列表的修改表单后，刷新列表
    onHandleEditRefreshData () {
      if (this.cur === 1) {
        this.onTabClick(3)
        // this.fillGridOptions.data = [];
        if (!this.formAuth.currentQueryFillListAuth) {
          this.getMyFillList();
        }
      }
      else if (this.cur === 3) {
        this.fillGridOptions.data = [];
        this.getMyFillList();
      }

      if (this.cur === 5) {
        this.gridOptions.data = [];
        this.getDataList();
      }
    },
    // 我的填报列表，点击查询事件
    onHandleQueryFillClick () {
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      if (this.$refs.fillGridTable) {
        this.$refs.fillGridTable.clearFilters(false);
      } else {
        this.getMyFillList();
      }
    },
    clearGlobalSearch () {
      this.fillQueryParams.fillValue = "";
      this.queryParams.fillValue = "";
      this.dataQueryParams.fillValue = "";

    },
    fillFilterChanged (filterObjects) {
      this.fillGridOptions.data = [];
      let filterData = {};
      if (filterObjects.length > 0) {
        this.clearGlobalSearch();
      }

      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "updateTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.updateStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.updateEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.fillQueryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.fillQueryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.fillQueryParams.fillStatus = x.value;
          }
          filterData[x.field] = x.value;
        } else {
          filterData[x.field] = x.value;
        }
      });
      if (filterObjects.length > 0) {
        this.fillQueryParams.filterData = filterData;
        this.fillQueryParams.filterObjects = filterObjects;
      } else {
        this.fillQueryParams.filterData = undefined;
        this.fillQueryParams.fillStartTime = undefined;
        this.fillQueryParams.fillEndTime = undefined;
        this.fillQueryParams.updateStartTime = undefined;
        this.fillQueryParams.updateEndTime = undefined;
        this.fillQueryParams.queryStatus = undefined;
        this.fillQueryParams.payStatus = undefined;
      }

      this.getMyFillList(() => {
        let sortData = this.fillQueryParams.sortData;
        this.restoreFillter(this.$refs.fillGridTable, filterData, sortData);

        // this.restoreFillter(this.$refs.fillGridTable, this.fillQueryParams.filterObjects , sortData)
      });
    },
    fillSortChanged (sortObjects) {
      this.fillGridOptions.data = [];

      this.fillQueryParams.sortData = sortObjects;
      this.getMyFillList(() => {
        let sortData = this.fillQueryParams.sortData;
        this.restoreFillter(
          this.$refs.fillGridTable,
          this.fillQueryParams.filterObjects,
          sortData
        );
      });
    },
    fillTableRowEditable ({ row }) {
      if (this.formDetail.auditAuth) {
        if (
          row["status"] == 1 ||
          row["status"] == 2 ||
          row["status"] == 5 ||
          row["status"] == 9 ||
          row["status"] == 13
        ) {
          if (
            this.formDetail.agencyId == "94" ||
            this.formDetail.agencyId == 94
          ) {
            return !(
              this.disableFill &&
              this.disableUserFill(row) &&
              row["status"] != 2
            );
          } else {
            return !(this.disableFill && this.disableUserFill(row));
          }
        } else {
          return false;
        }
      } else {
        return !(this.disableFill && this.disableUserFill(row));
      }
    },
    // 我的填报列表，点击重置事件
    onHandleResetFillClick () {
      this.fillQueryParams.queryStatus = "";
      this.fillQueryParams.payStatus = "";
      this.fillQueryParams.fillStartTime = "";
      this.fillQueryParams.fillEndTime = "";
      this.fillQueryParams.formFieldId = "";
      this.fillQueryParams.fillValue = "";
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.$refs.fillGridTable.clearFilter();
      this.$refs.fillGridTable.clearSort(true);
      this.$refs.fillGridTable.clearSelected();
      this.selectedDataRowKeys = [];
      // this.getMyFillList()
    },

    // 批量填报导入
    onHandleImportClick () {
      this.fillOpen = true;
    },
    // 下载模版
    onDownloadExcelTemplate () {
      fillDownloadExcel(this.formId).then(
        (data) => {
          if (!data) {
            return false;
          }
          // 获得时间戳
          const date = moment().format('YYYYMMDDHHmmss');
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `填报数据导入-${date}-${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },
    handleFillFileRequest ({ file }) {
      this.fillFile = file;
    },
    // 确认导入填报数据
    onConfirmUploadFillClick () {
      const _this = this;
      if (_this.isBlank(_this.fillFile)) {
        _this.$message({
          message: "请上传文件",
          type: "warning",
        });
        return;
      }
      formFillExcelImport(_this.fillFile, _this.formId).then((response) => {
        if (response.code == 200) {
          // loading.close()
          if (response.data && response.data.data !== "" && response.data.data !== null) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = response.data;
            return;
          }

          _this.msgSuccess("导入成功");
          _this.fillOpen = false;
          _this.fillFile = null;
          if (_this.$refs.formFillUpload) {
            _this.$refs.formFillUpload.clearFiles(); // 上传完成后执行清空
          }
          // _this.fillGridOptions.columns = []
          _this.fillGridOptions.data = [];
          _this.onTabClick(3);
          _this.$refs.dlgvFormViewer.close();
          // _this.cur = 3;
          // _this.$nextTick(()=>{
          // _this.getMyFillList();
          // })
        }
      });
    },
    onCloseUploadFillClick () {
      this.fillOpen = false;
      this.fillFile = null;
      if (this.$refs.formFillUpload) {
        this.$refs.formFillUpload.clearFiles();
      }
    },
    onCancelUploadFillClick () {
      this.fillOpen = false;
      this.fillFile = null;
      if (this.$refs.formFillUpload) {
        this.$refs.formFillUpload.clearFiles();
      }
    },
    // table插件vxe-table 个人填报列表
    getMyFillList (callback) {
      const _this = this;
      _this.fillGridOptions.loading = true;
      _this.fillEmptyText = "正在加载中...";
      _this.getMyFillDetail(callback);
    },
    // 读取我的填报列表
    getMyFillDetail (callback) {
      const _this = this;
      const queryParams = {
        queryStatus: _this.fillQueryParams.queryStatus,
        payStatus: _this.fillQueryParams.payStatus,
        criteria: _this.fillQueryParams.filterData,
        orders: _this.fillQueryParams.sortData,
        fillStartTime: _this.fillQueryParams.fillStartTime,
        fillEndTime: _this.fillQueryParams.fillEndTime,
        updateStartTime: _this.fillQueryParams.updateStartTime,
        updateEndTime: _this.fillQueryParams.updateEndTime,
        formFieldId: _this.fillQueryParams.formFieldId,
        fillValue: _this.fillQueryParams.fillValue,
        searchText: _this.fillQueryParams.fillValue,
      };
      fillList(_this.formId, queryParams).then((response) => {
        if (response.code == 200) {
          this.fillGridOptions.data.splice(0);
          if (response.data && response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              const item = response.data[i];

              // const fillDataArr = item.fillFieldList
              const obj = { ...item.fillJson };
              obj["fillUserNumber"] = !_this.isBlank(item.fillUserNumber)
                ? item.fillUserNumber
                : "---";
              obj["fillUserName"] = item.fillUserName;
              obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
              obj["fillTime"] = item.createTime;
              obj["createTime"] = item.createTime;
              obj["updateTime"] = item.updateTime;
              obj["reviewSign"] = item.reviewSign;

              obj["agencyDeptList"] = item.agencyDeptList;
              // 如果填报是管理员批量导入的，需要展示填报状态
              if (_this.dataFillStatus) {
                if (item.fillStatus == 2) {
                  const itemIndex = _this.fillGridOptions.columns.findIndex(
                    (items) => items.title == "填报时间"
                  );
                  if (itemIndex != -1) {
                    const fillStatusIndex =
                      _this.fillGridOptions.columns.findIndex(
                        (items) => items.title == "填报状态"
                      );
                    if (fillStatusIndex == -1) {
                      const addObj = {
                        field: "fillStatusTxt",
                        title: "填报状态",
                        width: 200,
                      };
                      _this.fillGridOptions.columns.splice(
                        itemIndex + 1,
                        0,
                        addObj
                      ); // 把填报状态插值到填报时间后
                    }
                  }
                }
                switch (item.fillStatus) {
                  case 1:
                    item.fillStatusTxt = "个人填报";
                    break;
                  case 2:
                    item.fillStatusTxt = "导入待确认";
                    break;
                  case 3:
                    item.fillStatusTxt = "导入已确认";
                    break;
                  case 4:
                    item.fillStatusTxt = "导入无需确认";
                    break;
                }
                obj["fillStatusTxt"] = item.fillStatusTxt;
                obj["fillStatus"] = item.fillStatus;
              }

              obj["status"] = item.status;
              // switch (item.status) {
              //   case 1:
              //     item.statusTxt = '无需审核'
              //     break
              //   case 2:
              //     if (!_this.isBlank(item.auditAuth1AgencyUserList)) {
              //       let auditAuth1AgencyUser = ''
              //       for (let j = 0; j < item.auditAuth1AgencyUserList.length; j++) {
              //         auditAuth1AgencyUser += item.auditAuth1AgencyUserList[j].name + ','
              //       }
              //       // 去掉最后一个逗号
              //       if (auditAuth1AgencyUser.length > 0) {
              //         auditAuth1AgencyUser = auditAuth1AgencyUser.substr(0, auditAuth1AgencyUser.length - 1)
              //       }
              //       item.statusTxt = '待' + auditAuth1AgencyUser + '审核' // '待一级审核'
              //     } else {
              //       item.statusTxt = '--'
              //     }
              //     break
              //   case 3:
              //     item.statusTxt = '已通过' // 一级审核通过
              //     break
              //   case 4:
              //     item.statusTxt = '已驳回' // 一级审核不通过
              //     break
              //   case 5:
              //     item.statusTxt = '返回修改' // 一级审核返回修改
              //     break
              //   case 6:
              //     let auditAuth2AgencyUser = ''
              //     if (!_this.isBlank(item.auditAuth2AgencyUserList)) {
              //       for (let j = 0; j < item.auditAuth2AgencyUserList.length; j++) {
              //         auditAuth2AgencyUser += item.auditAuth2AgencyUserList[j].name + ','
              //       }
              //       // 去掉最后一个逗号
              //       if (auditAuth2AgencyUser.length > 0) {
              //         auditAuth2AgencyUser = auditAuth2AgencyUser.substr(0, auditAuth2AgencyUser.length - 1)
              //       }
              //       item.statusTxt = '待' + auditAuth2AgencyUser + '审核' // '待一级审核'
              //     } else {
              //       item.statusTxt = '--'
              //     }
              //     break
              //   case 7:
              //     item.statusTxt = '已通过' // 二级审核通过
              //     break
              //   case 8:
              //     item.statusTxt = '已驳回' // 二级审核不通过
              //     break
              //   case 9:
              //     item.statusTxt = '返回修改' // 二级审核返回修改
              //     break
              // }

              obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
              obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
              obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
              obj["auditAuth1Sign"] = item.auditAuth1Sign;
              obj["auditAuth2Sign"] = item.auditAuth2Sign;
              obj["auditAuth3Sign"] = item.auditAuth3Sign;

              obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
              obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
              obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
              obj["auditAuth1Operator"] = item.auditAuth1Operator;
              obj["auditAuth2Operator"] = item.auditAuth2Operator;
              obj["auditAuth3Operator"] = item.auditAuth3Operator;
              obj["statusTxt"] = item.statusTxt;
              // obj['reviewRemark'] = !_this.isBlank(item.auditAuth2Remarks) ? item.auditAuth2Remarks : !_this.isBlank(item.auditAuth1Remarks) ? item.auditAuth1Remarks : '--'
              obj["reviewRemark"] =
                item.auditAuth3Remarks ||
                item.auditAuth2Remarks ||
                item.auditAuth1Remarks ||
                "";
              obj["fillId"] = item.fillId;
              // 表单开通支付
              obj["payStatus"] = item.payStatus;
              switch (item.payStatus) {
                case 1:
                  item.payStatusTxt = "无需支付";
                  break;
                case 2:
                  item.payStatusTxt = "待支付";
                  break;
                case 3:
                  item.payStatusTxt = "已支付";
                  break;
                case 4:
                  item.payStatusTxt = "确认支付";
                  break;
              }

              obj["payStatusTxt"] = item.payStatusTxt;
              obj["orderPriceTxt"] = item.orderPrice / 100 + "元";
              obj["payTime"] = !_this.isBlank(item.payTime)
                ? item.payTime
                : "--";
              obj["fillHeaders"] = Object.keys(item.fillJson);
              _this.fillGridOptions.data.push(obj);
            }
          } else {
            _this.fillEmptyText = "暂无数据";
          }
          _this.fillGridOptions.loading = false;

          _this.$nextTick(() => {
            if (callback) callback();
          });
        } else {
          _this.fillGridOptions.loading = false;
          _this.msgError(response.msg);
        }
      });
    },
    // 个人填报列表审核状态列颜色变化
    tableFillRowClassName ({ row, column, rowIndex, columnIndex }) {
      // return "";
      // if (
      //   column.title &&
      //   (column.title == "填报时间" ||
      //     column.title == "填报人编号" ||
      //     column.title == "填报人" ||
      //     column.title == "填报状态" ||
      //     column.title == "所属一级部门" ||
      //     column.title == "所属二级部门")
      // ) {
      //   return "fontNormal";
      // } else {
      //   return "fontWeight";
      // }
    },
    //批量支付
    onBatchPayHandler (selectedRowKeys) {
      let _this = this;
      let paySuccess = true;
      let fillIds = [];
      selectedRowKeys.forEach((row) => {
        fillIds.push(row.fillId);
        if (row.payStatus != 2) {
          paySuccess = paySuccess && false;
        }
      });

      if (!paySuccess) {
        this.$message({
          message:
            "选中的数据中，存在“已支付”或“支付确认”，请选择未支付的数据重试",
          type: "warning",
        });
        return;
      }

      const dto = {
        fillId: fillIds.join(','),
        formId: this.formId,
      };
      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.myFillListPayOpen = true;
          _this.myFillListPayPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["myFillListQrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.myFillListSetTimer(1000, response.data.orderNo);
        }
      });
    },
    // 筛选填报列表审核状态
    onSelectFillReviewStatus () {
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getMyFillList();
    },
    // 筛选填报列表时间校验
    dealDisabledFillDateS (time) {
      if (!this.isBlank(this.fillQueryParams.fillStartTime)) {
        return (
          time.getTime() <=
          new Date(this.fillQueryParams.fillStartTime).getTime()
        );
      }
    },
    dealDisabledFillDateE (time) {
      if (!this.isBlank(this.fillQueryParams.fillEndTime)) {
        return (
          new Date(this.fillQueryParams.fillEndTime).getTime() - 8.64e7 <
          time.getTime()
        );
      }
    },
    // 筛选填报列表开始日期、结束日期
    onChangeFillData () {
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getMyFillList();
    },
    // 筛选填报列表填报字段
    onSelectFillField () {
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getMyFillList();
    },
    // 筛选填报列表填报内容
    onChangeListFillValue () {
      // this.fillGridOptions.columns = []
      this.fillGridOptions.data = [];
      this.getMyFillList();
    },
    // 获取历史填报列表
    getHistoryFormFillList (historyFormId) {
      const _this = this;
      historyFillList(historyFormId, _this.fillReviewStatus, _this.formId).then(
        (response) => {
          if (response.code == 200) {
            const list = response.data;
            if (!_this.isBlank(list) && list.length == 1) {
              _this.cur = 2;
              _this.historyFillId = list[0].historyFillId;
              _this.historyFillDetailId = list[0].historyFillId;
              _this.isHistoryFill = true; // 开始填报已经关联历史表单的表单
            } else {
              _this.cur = 7;
              _this.currentFormHistory = response.data;
            }
          }
        }
      );
    },
    // 关联历史表单 开始填报 跳转列表中的开始填报
    onHistoryFillClick (historyFillId) {
      const _this = this;
      _this.historyFillId = historyFillId;
      _this.historyFillDetailId = historyFillId;
      _this.cur = 2;
      _this.isHistoryFill = true; // 开始填报已经关联历史表单的表单
    },
    // 关联历史表单 开始填报 跳转列表中的开始填报 页面详情查看填报详情
    onSeeHistoryFillDetail () {
      const _this = this;
      fillDetail(_this.historyFillDetailId).then((response) => {
        if (response.code == 200) {
          const data = response.data.fillFieldList;
          const formFieldList = response.data.formFieldList;
          for (let j = 0; j < formFieldList.length; j++) {
            if (formFieldList[j].type == "7") {
              formFieldList[j].selectCheckbox = [];
            }
            if (formFieldList[j].type == "9") {
              formFieldList[j].imgList = [];
            }
            if (formFieldList[j].type == "10") {
              formFieldList[j].fileList = [];
            }
          }
          const obj = {};
          for (let i = 0; i < data.length; i++) {
            const key = data[i].formFieldId;
            const formField = formFieldList.find(function (obj) {
              return obj.formFieldId === key;
            });

            let value = null;

            if (!this.isBlank(formField)) {
              // 省市区
              if (formField.type == "5") {
                let addressArr = null;
                if (formField.attribute == "省") {
                  if (formField.required) {
                    if (formField.content == "true") {
                      addressArr = data[i].fillValue.split("-");
                      formField.province = addressArr[0];
                      formField.address = addressArr[1];
                      value = formField.province + "-" + formField.address;
                    } else {
                      const index = data[i].fillValue.indexOf("-"); // 没有 返回-1
                      if (index != -1) {
                        addressArr = data[i].fillValue.split("-");
                        formField.province = addressArr[0];
                        formField.address = addressArr[1];
                        value = formField.province + "-" + formField.address;
                      } else {
                        formField.province = data[i].fillValue;
                        value = formField.province;
                      }
                    }
                  } else {
                    if (!_this.isBlank(data[i].fillValue)) {
                      addressArr = data[i].fillValue.split("-");
                      if (addressArr.length == 1) {
                        formField.province = addressArr[0];
                        formField.city = "";
                        formField.county = "";
                        formField.address = "";
                        value = formField.address;
                      } else if (addressArr.length == 2) {
                        formField.province = addressArr[0];
                        formField.city = "";
                        formField.county = "";
                        formField.address = addressArr[1];
                        value = formField.province + "-" + formField.address;
                      }
                    } else {
                      formField.province = "";
                      formField.address = "";
                      value = "";
                    }
                  }
                }
                if (formField.attribute == "省市") {
                  addressArr = data[i].fillValue.split("-");
                  if (addressArr.length == 1) {
                    formField.province = "";
                    formField.city = "";
                    formField.county = "";
                    formField.address = addressArr[0];
                    value = addressArr[0];
                  } else if (addressArr.length == 2) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = "";
                    formField.address = "";
                    value = formField.province + "-" + formField.city;
                  } else if (addressArr.length == 3) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = "";
                    formField.address = addressArr[2];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.address;
                  }
                }
                if (formField.attribute == "省市区") {
                  addressArr = data[i].fillValue.split("-");
                  if (addressArr.length == 1) {
                    formField.province = "";
                    formField.city = "";
                    formField.county = "";
                    formField.address = addressArr[0];
                    value = formField.address;
                  } else if (addressArr.length == 3) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = addressArr[2];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.county;
                  } else if (addressArr.length == 4) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = addressArr[2];
                    formField.address = addressArr[3];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.county +
                      "-" +
                      formField.address;
                  }
                }
              }

              // 多选
              if (formField.type == "7") {
                value = data[i].fillValue.split("{JYDSP}");
                formField.selectCheckbox = value;
              } else {
                value = data[i].fillValue;
              }

              if (formField.type == "9") {
                const imgList = [];
                if (!this.isBlank(data[i].fillValue)) {
                  imgList.push({
                    name: this.getSuffName(data[i].fillValue),
                    url: data[i].fillValue,
                  });
                }
                formField.imgList = imgList;
              }

              if (formField.type == "10") {
                const fileList = [];
                if (data[i].fillValue) {
                  fileList.push({
                    name: this.getSuffName(data[i].fillValue),
                    url: data[i].fillValue,
                  });
                }
                formField.fileList = fileList;
              }

              // 组织架构
              if (formField.type == "11") {
                value = !this.isBlank(data[i].fillValue)
                  ? data[i].fillValue
                  : null;
              }

              // 说明文字
              if (formField.type == "12") {
                value = !this.isBlank(formField.content)
                  ? formField.content
                  : "";
              }

              // 多级下拉
              if (formField.type == "13") {
                value = !this.isBlank(data[i].fillValue)
                  ? data[i].fillValue.split("-")
                  : [];
              }

              obj[key] = value;
            }
          }

          _this.historyFillDetail = obj;
          _this.historyFillFormFieldList = formFieldList;
        }
      });
      _this.historyFillDetailDialog = true;
    },
    onCloseHistoryFillDetailDialog () {
      this.historyFillDetail = {};
      this.historyFillFormFieldList = [];
    },
    // 关联历史表单 开始填报 跳转列表中的编辑填报
    onEditHistoryFillClick (fillId, historyFillId) {
      fillDetail(fillId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;
          const data = response.data.fillFieldList;
          const formFieldList = response.data.formFieldList;
          for (let j = 0; j < formFieldList.length; j++) {
            if (formFieldList[j].type == "7") {
              formFieldList[j].selectCheckbox = [];
            }
            if (formFieldList[j].type == "9") {
              formFieldList[j].imgList = [];
            }
            if (formFieldList[j].type == "10") {
              formFieldList[j].fileList = [];
            }
            if (formFieldList[j].type == "13") {
              formFieldList[j].content = this.getTreeData(
                JSON.parse(formFieldList[j].content)
              );
            }
          }
          const obj = {};
          for (let i = 0; i < data.length; i++) {
            const key = data[i].formFieldId;
            const formField = formFieldList.find(function (obj) {
              return obj.formFieldId === key;
            });

            let value = null;

            if (!this.isBlank(formField)) {
              // 省市区
              if (formField.type == "5") {
                let addressArr = null;
                if (formField.attribute == "省") {
                  if (formField.required) {
                    if (formField.content == "true") {
                      addressArr = data[i].fillValue.split("-");
                      formField.province = addressArr[0];
                      formField.address = addressArr[1];
                      value = formField.province + "-" + formField.address;
                    } else {
                      const index = data[i].fillValue.indexOf("-"); // 没有 返回-1
                      if (index != -1) {
                        addressArr = data[i].fillValue.split("-");
                        formField.province = addressArr[0];
                        formField.address = addressArr[1];
                        value = formField.province + "-" + formField.address;
                      } else {
                        formField.province = data[i].fillValue;
                        value = formField.province;
                      }
                    }
                  } else {
                    if (!this.isBlank(data[i].fillValue)) {
                      addressArr = data[i].fillValue.split("-");
                      if (addressArr.length == 1) {
                        formField.province = addressArr[0];
                        formField.city = "";
                        formField.county = "";
                        formField.address = "";
                        value = formField.address;
                      } else if (addressArr.length == 2) {
                        formField.province = addressArr[0];
                        formField.city = "";
                        formField.county = "";
                        formField.address = addressArr[1];
                        value = formField.province + "-" + formField.address;
                      }
                    } else {
                      formField.province = "";
                      formField.address = "";
                      value = "";
                    }
                  }
                }
                if (formField.attribute == "省市") {
                  addressArr = data[i].fillValue.split("-");
                  if (addressArr.length == 1) {
                    formField.province = "";
                    formField.city = "";
                    formField.county = "";
                    formField.address = addressArr[0];
                    value = addressArr[0];
                  } else if (addressArr.length == 2) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = "";
                    formField.address = "";
                    value = formField.province + "-" + formField.city;
                  } else if (addressArr.length == 3) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = "";
                    formField.address = addressArr[2];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.address;
                  }
                }
                if (formField.attribute == "省市区") {
                  addressArr = data[i].fillValue.split("-");
                  if (addressArr.length == 1) {
                    formField.province = "";
                    formField.city = "";
                    formField.county = "";
                    formField.address = addressArr[0];
                    value = formField.address;
                  } else if (addressArr.length == 3) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = addressArr[2];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.county;
                  } else if (addressArr.length == 4) {
                    formField.province = addressArr[0];
                    formField.city = addressArr[1];
                    formField.county = addressArr[2];
                    formField.address = addressArr[3];
                    value =
                      formField.province +
                      "-" +
                      formField.city +
                      "-" +
                      formField.county +
                      "-" +
                      formField.address;
                  }
                }
              }

              // 多选
              if (formField.type == "7") {
                value = data[i].fillValue.split("{JYDSP}");
                formField.selectCheckbox = value;
              } else {
                value = data[i].fillValue;
              }

              if (formField.type == "9") {
                const imgList = [];
                if (!this.isBlank(data[i].fillValue)) {
                  imgList.push({
                    name: this.getSuffName(data[i].fillValue),
                    url: data[i].fillValue,
                  });
                }
                formField.imgList = imgList;
              }

              if (formField.type == "10") {
                const fileList = [];
                if (data[i].fillValue) {
                  fileList.push({
                    name: this.getSuffName(data[i].fillValue),
                    url: data[i].fillValue,
                  });
                }
                formField.fileList = fileList;
              }

              // 组织架构
              if (formField.type == "11") {
                value = !this.isBlank(data[i].fillValue)
                  ? data[i].fillValue
                  : null;
              }

              // 说明文字
              if (formField.type == "12") {
                value = !this.isBlank(formField.content)
                  ? formField.content
                  : "";
              }

              // 多级下拉
              if (formField.type == "13") {
                value = !this.isBlank(data[i].fillValue)
                  ? data[i].fillValue.split("-")
                  : [];
              }

              obj[key] = value;
            }
          }

          this.formData = obj;
          this.cur = 2;
          this.formFieldList = formFieldList;
          this.isEditFill = false;
          this.fillId = fillId;
          this.historyFillDetailId = historyFillId;
          this.isHistoryFill = true;
        }
      });
    },

    /**
     * 点击查看按钮
     * @param {string} fillId - 填充ID
     * @param {string} listType - 列表类型, FILL：我的填报, MANAGE：管理列表, AUDIT: 审核列表
     * @param {object} row - 行数据
     */
    onViewFillClick (fillId, listType, row) {
      if (!fillId) {
        fillId = this.selectedDataRowKeys[0].fillId;
      }
      fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;
          const data = response.data.fillJson;
          const formFieldList = response.data.formFieldList;
          this.$refs.dlgvFormViewer.showView({
            fillId: fillId,
            formId: this.formId,
            formJson: deepClone(this.formJson),
            formData: { ...response.data, ...data },
            agencyId: this.formDetail.agencyId,
            formDetail: this.formDetail,
            formMode: 'view',
            listType: listType,
            showHiddenWidgets: listType === 'MANAGE',
            fillInfo: listType === 'MANAGE' ? {
              fillUserName: row.fillUserName,
              fillUserAvatarUrl: row.fillUserAvatarUrl,
              fillUserId: response.data.userId,
              createTime: row.createTime,
              updateTime: row.updateTime,
            } : null,
            callback: () => {
              this.afterShowCallback({ listType, formMode: 'view', fillId, fillDetailData: response.data, row })
            }
          });

        }
      })
    },

    /**
     * 点击审核按钮
     * @param {string} fillId - 填充ID
     * @param {object} row - 行数据
     */

    // !! view/sign/sign-form.vue
    // !! view/system/form/detail.vue
    onAuditFillClick (fillId, row) {
      if (!fillId) {
        fillId = this.selectedDataRowKeys[0].fillId;
      }
      fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;
          const data = response.data.fillJson;
          const formFieldList = response.data.formFieldList;

          this.$refs.dlgvFormViewer.show({
            fillId: fillId,
            formId: this.formId,
            formJson: deepClone(this.formJson),
            formData: { ...response.data, ...data },
            agencyId: this.formDetail.agencyId,
            formDetail: this.formDetail,
            formMode: 'audit',
            listType: 'AUDIT',
            fillJson: response.data.fillJson,
            showHiddenWidgets: true, // 审核列表显示隐藏字段
            enableReadonly: true, // 启用只读字段可编辑,
            fillInfo: {
              fillUserName: row.fillUserName,
              fillUserAvatarUrl: row.fillUserAvatarUrl,
              fillUserId: response.data.userId,
              createTime: row.createTime,
              updateTime: row.updateTime,
            },
            callback: () => {
              this.afterShowCallback({ listType: 'AUDIT', formMode: 'audit', fillId, fillDetailData: response.data, row })
            }
          });

          // if (row && parseInt(row["status"]) > 2) {
          // this.$refs.dlgvFormViewer.disableForm();
          // this.$refs.dlgvFormViewer.setAllowSave(false);
          // } else {
          // this.$refs.dlgvFormViewer.enableForm();
          // this.$refs.dlgvFormViewer.setAllowSave(true);
          // }
          // 第一个参数，是否显示审核按钮


        }
      })
    },

    // 编辑填报列表某一项
    // listType: 'MANAGE' 管理列表，或者无值
    onEditFillClick (fillId, listType, row) {
      if (!fillId) {
        fillId = this.selectedDataRowKeys[0].fillId;
      }
      fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.fillId = response.data.fillId;
          const data = response.data.fillJson;

          this.$refs.dlgvFormViewer.show({
            fillId,
            formId: this.formId,
            formJson: deepClone(this.formJson),
            formData: { ...response.data, ...data },
            showHiddenWidgets: listType != 'FILL', /* 非个人填报列表显示隐藏字段 */
            showFields: null,
            enableReadonly: listType == "MANAGE", /* 管理列表启用只读字段 */
            agencyId: this.formDetail.agencyId,
            formDetail: this.formDetail,
            formMode: 'edit',
            fillJson: response.data.fillJson,
            fillInfo: {
              fillUserName: row.fillUserName,
              fillUserAvatarUrl: row.fillUserAvatarUrl,
              fillUserId: response.data.userId,
              createTime: row.createTime,
              updateTime: row.updateTime,
            },
            listType: listType,
            callback: () => {
              this.afterShowCallback({ listType, formMode: 'edit', fillId, fillDetailData: response.data, row })
            }
          });

        }

      });
    },
    // 点击查看/修改 界面弹出后，根据处于所在的列表，判断显示哪些字段
    // !! view/sign/sign-form.vue
    // !! view/system/form/detail.vue
    afterShowCallback ({ listType, formMode, fillId, fillDetailData, row }) {
      //如果是个人填报列表，已支付状态的支付字段不能修改
      if (listType == 'FILL') {
        setTimeout(() => {
          //判断表单是否开启了支付，支付状态为已支付，表单是根据字段支付，三个条件为true，隐藏支付字段。
          if (
            this.formDetail.pay &&
            this.formDetail.payStatus > 2 &&
            this.formPayInfo
          ) {
            this.$refs.dlgvFormViewer.hideWidgets([
              this.formPayInfo.formFieldId,
            ]);
          }

        }, 200);
        if (formMode == 'view') {
          this.$refs.dlgvFormViewer.disableForm();
        } else {
        }
        this.$refs.dlgvFormViewer.setAllowSave(true);
        this.$refs.dlgvFormViewer.showAuditButton(false)
      } else if (listType == 'AUDIT') {
        this.$refs.dlgvFormViewer.showAuditButton(true, () => {
          fillDetail(fillId, this.formId).then((auditItem) => {
            this.onReviewClick(this.fillId, auditItem.data.version);
          });
        });

        // 获取表单按钮状态
        // 计算审核层级
        let level = 1;
        //Status 审核状态：1无需审核、2待一级审核、3一级审核通过、4、一级审核不通过、5一级审核返回修改、6待二级审核、7二级审核通过、8二级审核不通过、9二级审核返回修改
        const state = row['status'];
        switch (state) {
          case 1: level = 0; break;
          case 2:
          case 3:
          case 4:
          case 5: level = 1; break;
          case 6:
          case 7:
          case 8:
          case 9: level = 2; break;
          default: level = 3;
        }
        if (level > 0) {
          getFormFieldAuth({
            formId: this.formId,
            level: level,
            type: 1
          }).then((res) => {
            let fieldAuth = [];
            let readonlyWidgets = [];
            let hiddenWidgets = [];


            let notReadonlyWidgets = [];
            let notHiddenWidgets = [];
            if (res.data && res.data.fieldAuth) {
              fieldAuth = res.data.fieldAuth;
            } else {

              let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
              let allDisabledWidgetNames = allWidgetList.filter(x => x.options.disabled).map(x => x.options.name);
              let allHiddenWidgetNames = allWidgetList.filter(x => x.options.hidden).map(x => x.options.name);

              allDisabledWidgetNames.forEach(name => {
                readonlyWidgets.push(name);
              })
              allHiddenWidgetNames.forEach(name => {
                hiddenWidgets.push(name);;
              })
            }


            Object.keys(fieldAuth).forEach(key => {
              let val = fieldAuth[key];
              if (val.find(x => x == 2)) {
                readonlyWidgets.push(key)
              } else {
                notReadonlyWidgets.push(key)
              }
              if (val.find(x => x == 3)) {
                hiddenWidgets.push(key)
              } else {
                notHiddenWidgets.push(key)
              }
            })

            if (formMode == 'view') {
              this.$refs.dlgvFormViewer.setHiddenWidgets(hiddenWidgets);
              this.$refs.dlgvFormViewer.setNotHiddenWidgets(notHiddenWidgets);
              this.$refs.dlgvFormViewer.disableForm();
            } else {
              this.$refs.dlgvFormViewer.setReadonlyWidgets(readonlyWidgets, false);
              this.$refs.dlgvFormViewer.setNotReadonlyWidgets(notReadonlyWidgets, false);

              this.$refs.dlgvFormViewer.setHiddenWidgets(hiddenWidgets);
              this.$refs.dlgvFormViewer.setNotHiddenWidgets(notHiddenWidgets);
            }
            this.$refs.dlgvFormViewer.updateLogicalWidgets();

          });
        }
      } else if (listType == 'MANAGE') {
        if (formMode == 'view') {
          this.$nextTick(() => {
            let allWidgetList = this.getFormFieldList(this.formJson.widgetList);

            let allWidgetnames = allWidgetList.map(x => x.options.name);

            // 管理列表里需要所有字段可编辑，除了根据逻辑隐藏的字段外。
            // 因此只能将所有字段先显示出来，再重新出发一次逻辑显示
            this.$refs.dlgvFormViewer.setNotHiddenWidgets(allWidgetnames);
            this.$nextTick(() => {
              // 重新出发逻辑显示
              this.$refs.dlgvFormViewer.updateLogicalWidgets();
              this.$refs.dlgvFormViewer.disableForm();
            })

          })
        } else {
          let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
          let allWidgetnames = allWidgetList.map(x => x.options.name);
          this.$nextTick(() => {
            this.$refs.dlgvFormViewer.setNotReadonlyWidgets(allWidgetnames, false);
            this.$refs.dlgvFormViewer.setNotHiddenWidgets(allWidgetnames);

            this.$refs.dlgvFormViewer.enableForm();
            this.$refs.dlgvFormViewer.setAllowSave(true);
            this.$refs.dlgvFormViewer.showAuditButton(false)

            this.$nextTick(() => {
              // 重新出发逻辑显示
              this.$refs.dlgvFormViewer.updateLogicalWidgets();
            })
          })
        }
      }
    },
    getFormFieldList (widgetList) {
      let allWidgetList = [];

      widgetList.forEach(wgt => {
        allWidgetList.push(wgt);

        if (wgt.widgetList) {
          allWidgetList = allWidgetList.concat(this.getFormFieldList(wgt.widgetList));
        }
      })
      return allWidgetList;
    },
    // 查看每一条填报的审核结果
    onFillReviewDetailClick (fillId) {
      this.fillReviewOpen = true;
      fillReviewList(fillId).then((response) => {
        if (response.code == 200) {
          this.fillReviewList = response.data;
        }
      });
    },
    // 监听每一条填报的审核结果弹框关闭
    onCloseFillReviewDialog () {
      this.fillReviewOpen = false;
      this.fillReviewList = [];
    },
    // 删除某一条填报
    onDeleteFillClick (fillId) {
      const self = this;
      this.$confirm("是否确认删除该填报？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return fillDel(fillId, self.formId);
        })
        .then(() => {
          self.msgSuccess("删除成功");
          // self.fillGridOptions.columns = []
          self.fillGridOptions.data = [];
          self.getMyFillList();
        })
        .catch(function () { });
    },
    // 管理列表 删除某一条填报
    onDeleteManageFillClick (fillId) {
      const self = this;
      this.$confirm("是否确认删除该填报？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return fillDel(fillId, self.formId);
        })
        .then(() => {
          self.msgSuccess("删除成功");
          // self.gridOptions.columns = []
          self.gridOptions.data = [];
          self.getDataList();
        })
        .catch(function (e) {
          console.log("错误", e);
        });
    },
    // 新版 3.0.0.1 导出设置 允许个人导出
    onExportMyFill (fillId, exportType = "Word", row) {
      const _this = this;
      // window.location.href = _this.apiPathUrl + '/export/word/' + _this.formId + '/' + fillId + '?token=' + _this.Authorization
      const exportHandler =
        exportType == "Word" ? exportFillWord : exportFillPdf;
      exportHandler(_this.formId, fillId).then(
        (res) => {
          const { data, headers } = res;
          if (!data) {
            return false;
          }
          if (data.msg) {
            _this.$message.error(data.msg);
            return false;
          }

          const fileName = headers["content-disposition"].split("filename=")[1];
          const blob = new Blob([data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = `${decodeURIComponent(fileName)}`;
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },
    // 表单开通支付，我的填报列表，列中支付btn支付
    onPayFillClick (fillId) {
      const _this = this;
      const dto = {
        fillId: fillId,
        formId: this.formId,
      };
      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.myFillListPayOpen = true;
          _this.myFillListPayPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["myFillListQrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.myFillListSetTimer(1000, response.data.orderNo);
        }
      });
    },
    myFillListSetTimer (intMilliecond, orderNo) {
      const _this = this;
      let timeSeconds = 0;
      _this.myFillListQrTimer = setInterval(function () {
        timeSeconds += 1;
        if (timeSeconds <= 300) {
          _this.getMyFillListQrPayState(orderNo);
        } else {
          clearInterval(_this.myFillListQrTimer);
        }
      }, intMilliecond);
    },
    getMyFillListQrPayState (orderNo) {
      const _this = this;
      orderStatus(orderNo).then((response) => {
        if (response.code == 200) {
          if (response.data == true) {
            // _this.msgSuccess('支付成功')
            _this.paySuccessShow = true;
            // 5秒后自动关闭
            // 迭代4.0.2 去掉自动关闭
            // setTimeout(function() {
            //   _this.paySuccessShow = false
            // }, 5000)

            // 清空支付相关
            clearInterval(_this.myFillListQrTimer);
            document.getElementById("myFillListQrCode").innerHTML = "";
            _this.myFillListPayOpen = false;
            // 支付成功后，刷新我的填报列表
            _this.getFormAuth(_this.formId);
            // _this.fillGridOptions.columns = []
            _this.fillGridOptions.data = [];
            _this.getMyFillList();
          }
        } else {
          clearInterval(_this.myFillListQrTimer);
          document.getElementById("myFillListQrCode").innerHTML = "";
        }
      });
    },
    onCloseMyFillListPay () {
      this.myFillListPayOpen = false;
      // 清空支付相关

      this.$emit("on-save");
      clearInterval(this.myFillListQrTimer);
      document.getElementById("myFillListQrCode").innerHTML = "";
    },
    // table插件vxe-table 审核列表
    getReviewList (callback) {
      const _this = this;
      _this.reviewGridOptions.loading = true;
      _this.reviewEmptyText = "正在加载中...";
      _this.getReviewDetail(callback);
    },
    getReviewDetail (callback) {
      const _this = this;
      const queryParams = {
        pageNum: _this.reviewGridOptions.pagerConfig.currentPage
          ? _this.reviewGridOptions.pagerConfig.currentPage
          : 1,
        pageSize: _this.reviewGridOptions.pagerConfig.pageSize
          ? _this.reviewGridOptions.pagerConfig.pageSize
          : 10,
        formFieldId: _this.queryParams.formFieldId,
        fillValue: _this.queryParams.fillValue,
        queryStatus: _this.queryParams.queryStatus,
        fillStatus: _this.queryParams.fillStatus,
        criteria: _this.queryParams.filterData,
        orders: _this.queryParams.sortData,
        payStatus: _this.queryParams.payStatus,
        fillStartTime: _this.queryParams.fillStartTime,
        fillEndTime: _this.queryParams.fillEndTime,
        updateStartTime: _this.queryParams.updateStartTime,
        updateEndTime: _this.queryParams.updateEndTime,
        searchText: _this.queryParams.fillValue,
      };
      reviewList(_this.formId, queryParams).then((response) => {
        if (response.code == 200) {
          this.reviewGridOptions.fillCacheKey = response.fillCacheKey;
          for (let i = 0; i < response.rows.length; i++) {

            // 表单配置里的隐藏字段
            let formJsonHiddenFields = this.formJson.fieldList.filter(x => x.hidden).map(x => x.name);
            const item = response.rows[i];

            let fillJson = deepClone(item.fillJson)
            formJsonHiddenFields.forEach(x => {
              delete fillJson[x]
            });
            const obj = { ...fillJson };


            // 表单开通支付
            obj["payStatus"] = item.payStatus;
            switch (item.payStatus) {
              case 1:
                item.payStatusTxt = "无需支付";
                break;
              case 2:
                item.payStatusTxt = "待支付";
                break;
              case 3:
                item.payStatusTxt = "已支付";
                break;
              case 4:
                item.payStatusTxt = "确认支付";
                break;
            }

            if (!_this.isBlank(item.agencyDeptLevel1)) {
              let agencyDeptLevel1 = "";
              for (let j = 0; j < item.agencyDeptLevel1.length; j++) {
                agencyDeptLevel1 += item.agencyDeptLevel1[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel1.length > 0) {
                agencyDeptLevel1 = agencyDeptLevel1.substr(
                  0,
                  agencyDeptLevel1.length - 1
                );
              }
              obj["agencyDeptLevel1"] = agencyDeptLevel1;
            } else {
              obj["agencyDeptLevel1"] = "--";
            }

            if (!_this.isBlank(item.agencyDeptLevel2)) {
              let agencyDeptLevel2 = "";
              for (let j = 0; j < item.agencyDeptLevel2.length; j++) {
                agencyDeptLevel2 += item.agencyDeptLevel2[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel2.length > 0) {
                agencyDeptLevel2 = agencyDeptLevel2.substr(
                  0,
                  agencyDeptLevel2.length - 1
                );
              }
              obj["agencyDeptLevel2"] = agencyDeptLevel2;
            } else {
              obj["agencyDeptLevel2"] = "--";
            }

            obj["payStatusTxt"] = item.payStatusTxt;
            obj["orderPriceTxt"] = item.orderPrice / 100 + "元";

            obj["userId"] = item.userId;
            obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
              ? item.fillUserNumber
              : "--";
            obj["fillUserName"] = item.fillUserName;
            obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
            obj["fillTime"] = item.createTime;
            obj["createTime"] = item.createTime;
            obj["updateTime"] = item.updateTime;

            obj["reviewSign"] = item.reviewSign;
            if (!_this.isBlank(item.agencyDeptLevel1)) {
              let agencyDeptLevel1 = "";
              for (let j = 0; j < item.agencyDeptLevel1.length; j++) {
                agencyDeptLevel1 += item.agencyDeptLevel1[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel1.length > 0) {
                agencyDeptLevel1 = agencyDeptLevel1.substr(
                  0,
                  agencyDeptLevel1.length - 1
                );
              }
              obj["agencyDeptLevel1"] = agencyDeptLevel1;
            } else {
              obj["agencyDeptLevel1"] = "--";
            }
            if (!_this.isBlank(item.agencyDeptLevel2)) {
              let agencyDeptLevel2 = "";
              for (let j = 0; j < item.agencyDeptLevel2.length; j++) {
                agencyDeptLevel2 += item.agencyDeptLevel2[j].deptName + ",";
              }
              // 去掉最后一个逗号
              if (agencyDeptLevel2.length > 0) {
                agencyDeptLevel2 = agencyDeptLevel2.substr(
                  0,
                  agencyDeptLevel2.length - 1
                );
              }
              obj["agencyDeptLevel2"] = agencyDeptLevel2;
            } else {
              obj["agencyDeptLevel2"] = "--";
            }

            // obj['reviewRemark'] = !_this.isBlank(item.auditAuth2Remarks) ? item.auditAuth2Remarks : !_this.isBlank(item.auditAuth1Remarks) ? item.auditAuth1Remarks : '--'
            obj["reviewRemark"] =
              item.auditAuth3Remarks ||
              item.auditAuth2Remarks ||
              item.auditAuth1Remarks ||
              "";
            // let statusTxt = ''
            // console.log('statusTxt', item.status)
            // switch (item.status) {
            //   case 1:
            //     statusTxt = '无需审核'
            //     break
            //   case 2:
            //     let auditAuth1AgencyUser = ''
            //     if (!_this.isBlank(item.auditAuth1AgencyUserList)) {
            //       for (let j = 0; j < item.auditAuth1AgencyUserList.length; j++) {
            //         auditAuth1AgencyUser += item.auditAuth1AgencyUserList[j].name + ','
            //       }
            //       // 去掉最后一个逗号
            //       if (auditAuth1AgencyUser.length > 0) {
            //         auditAuth1AgencyUser = auditAuth1AgencyUser.substr(0, auditAuth1AgencyUser.length - 1)
            //       }
            //       statusTxt = '待' + auditAuth1AgencyUser + '审核' // '待一级审核'
            //     } else {
            //       statusTxt = '--'
            //     }
            //     break
            //   case 3:
            //     statusTxt = '已通过' // 一级审核通过
            //     break
            //   case 4:
            //     statusTxt = '已驳回' // 一级审核不通过
            //     break
            //   case 5:
            //     statusTxt = '返回修改' // 一级审核返回修改
            //     break
            //   case 6:
            //     let auditAuth2AgencyUser = ''
            //     if (!_this.isBlank(item.auditAuth2AgencyUserList)) {
            //       for (let k = 0; k < item.auditAuth2AgencyUserList.length; k++) {
            //         auditAuth2AgencyUser += item.auditAuth2AgencyUserList[k].name + ','
            //       }
            //       // 去掉最后一个逗号
            //       if (auditAuth2AgencyUser.length > 0) {
            //         auditAuth2AgencyUser = auditAuth2AgencyUser.substr(0, auditAuth2AgencyUser.length - 1)
            //       }
            //       statusTxt = '待' + auditAuth2AgencyUser + '审核' // '待二级审核'
            //     } else {
            //       statusTxt = '--'
            //     }
            //     break
            //   case 7:
            //     statusTxt = '已通过' // 二级审核通过
            //     break
            //   case 8:
            //     statusTxt = '已驳回' // 二级审核不通过
            //     break
            //   case 9:
            //     statusTxt = '返回修改' // 二级审核返回修改
            //     break
            //   case 14:
            //     statusTxt = '审核通过'
            //     console.log('statusTxt', statusTxt)
            //     break
            // }
            if (_this.dataFillStatus) {
              switch (item.fillStatus) {
                case 1:
                  item.fillStatusTxt = "个人填报";
                  break;
                case 2:
                  item.fillStatusTxt = "导入待确认";
                  break;
                case 3:
                  item.fillStatusTxt = "导入已确认";
                  break;
                case 4:
                  item.fillStatusTxt = "导入无需确认";
                  break;
              }
              obj["fillStatusTxt"] = item.fillStatusTxt;
              obj["fillStatus"] = item.fillStatus;
            }
            obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
            obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
            obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
            obj["auditAuth1Sign"] = item.auditAuth1Sign;
            obj["auditAuth2Sign"] = item.auditAuth2Sign;
            obj["auditAuth3Sign"] = item.auditAuth3Sign;
            obj["agencyDeptList"] = item.agencyDeptList;
            obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
            obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
            obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
            obj["auditAuth1Operator"] = item.auditAuth1Operator;
            obj["auditAuth2Operator"] = item.auditAuth2Operator;
            obj["auditAuth3Operator"] = item.auditAuth3Operator;

            obj["status"] = item.status;
            // obj['statusTxt'] = statusTxt
            obj["fillId"] = item.fillId;
            obj["fillStatus"] = item.fillStatus;
            obj["version"] = item.version;
            // obj['createTime'] = new Date(item.createTime).getTime()
            obj["auditButton"] = item.auditButton;

            obj["fillHeaders"] = Object.keys(item.fillJson);
            _this.reviewGridOptions.data.push(obj);
            _this.reviewGridOptions.pagerConfig.total = response.total;
          }
          _this.reviewGridOptions.loading = false;
          if (_this.isBlank(_this.reviewGridOptions.data)) {
            _this.reviewEmptyText = "暂无数据";
          }
          // _this.$nextTick(() => {
          if (callback) callback(_this.reviewGridOptions.data);
          // })
        } else {
          _this.reviewGridOptions.loading = false;
          _this.msgError(response.msg);
        }
      });
    },
    // 审核列表分页点击
    handleReviewPageChange ({ currentPage, pageSize }) {
      this.reviewGridOptions.pagerConfig.currentPage = currentPage;
      this.reviewGridOptions.pagerConfig.pageSize = pageSize;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList(() => {
        this.restoreFillter(
          this.$refs.auditTable,
          this.reviewGridOptions.filterData,
          this.reviewGridOptions.sortData
        );
      });
    },
    // 审核列表筛选
    reviewFilterChanged (filterObjects) {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      this.reviewGridOptions.data = [];
      let filterData = {};

      if (filterObjects.length > 0) {
        this.clearGlobalSearch();
      }

      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.queryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.queryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "updateTime") {
            let showValue = x.showValue.split("~");
            this.queryParams.updateStartTime = showValue[0] + " 00:00:00";
            this.queryParams.updateEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.queryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.queryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.queryParams.fillStatus = x.value;
          }
          filterData[x.field] = x.value;
        } else {
          filterData[x.field] = x.value;
        }
      });

      if (filterObjects.length > 0) {
        this.queryParams.filterData = filterData;
        this.queryParams.filterObjects = filterObjects;
      } else {
        this.queryParams.filterData = undefined;
        this.queryParams.fillStartTime = undefined;
        this.queryParams.fillEndTime = undefined;
        this.queryParams.updateStartTime = undefined;
        this.queryParams.updateEndTime = undefined;
        this.queryParams.queryStatus = undefined;
        this.queryParams.payStatus = undefined;
      }

      this.getReviewList(() => {
        // let filterData = this.reviewGridOptions.filterData;
        let sortData = this.reviewGridOptions.sortData;
        this.restoreFillter(this.$refs.auditTable, filterData, sortData);
        // this.restoreFillter(this.$refs.auditTable,filterData,sortData);
      });
    },
    reviewSortChanged (sortObjects) {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      this.reviewGridOptions.data = [];

      this.reviewGridOptions.sortData = sortObjects;
      this.getReviewList(() => {
        let sortData = this.reviewGridOptions.sortData;
        this.restoreFillter(
          this.$refs.auditTable,
          this.reviewGridOptions.filterData,
          sortData
        );
      });
    },
    reviewTableRowEditable () {
      return false;
    },

    // 审核列表区分固定字段和表单字段名称
    tableReviewRowClassName ({ row, column, rowIndex, columnIndex }) {
      return "";
      if (
        column.title &&
        (column.title == "填报人编号" ||
          column.title == "填报人" ||
          column.title == "填报时间" ||
          column.title == "所属一级部门" ||
          column.title == "所属二级部门")
      ) {
        return "fontNormal";
      }
      return "fontWeight";
    },
    // 确认填报（通过管理员批量导入的填报，需要填报人确认）
    onConfirmFillBtnClick (fillId) {
      this.fillId = fillId;
      fillDetail(fillId, this.formId).then((response) => {
        if (response.code == 200) {
          this.cur = 9;
          this.$nextTick(() => {
            this.confirmOldFillData = deepClone(response.data.fillJson);
            this.$refs.vformConfirmRender.setFormData(
              response.data.fillJson,
              this.formId,
              fillId
            );
          });

          this.isEditFill = false;
        }
      });
    },
    // 填报列表 图片、附件 列点击
    cellFillClickEvent (event) {
      const property = event.column.property;
      const value = event.row[property];
      if (!this.isBlank(property)) {
        if (property.indexOf("content") != -1) {
          // 图片点击查看详情
          const splitImgArr = property.split("content");
          const formFieldId = splitImgArr[1];
          const imgUrlField = "imgUrl" + formFieldId;
          const imgUrl = event.row[imgUrlField];
          if (!this.isBlank(imgUrl)) {
            this.multiples = 1;
            this.deg = 0;
            this.styleObj = null;
            this.endX = 0;
            this.endY = 0;
            this.showDetails = true;
            this.showDetailsImg = imgUrl;
          }
        } else if (property.indexOf("file") != -1) {
          // 附件点击下载
          const splitFileArr = property.split("file");
          const formFieldId = splitFileArr[1];
          const fileUrlField = "fileUrl" + formFieldId;
          const fileUrl = event.row[fileUrlField];
          if (!this.isBlank(fileUrl)) {
            if (
              fileUrl.indexOf(
                "https://smart-form-dev.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1 ||
              fileUrl.indexOf(
                "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1
            ) {
              // 1.先创建一个a标签
              const a = document.createElement("a");
              // 2.给a标签的href赋上下载地址及下载名称
              const downloadName = this.getSuffName(fileUrl);
              a.download = downloadName;
              a.href = fileUrl;
              // 3.让a标签不显示在视图中
              a.style.display = "none";
              // 4.将a标签append到文档中
              document.body.appendChild(a);
              // 5.a标签自点击
              a.click();
              // 6.点击下载后，销毁这个节点
              document.body.removeChild(a);
            }
          }
        }
      }
    },
    // 筛选审核列表填报字段
    onSelectReviewField () {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList();
    },
    // 筛选审核列表填报内容
    onChangeReviewFillValue () {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList();
    },
    // 筛选审核列表审核状态
    onSelectReviewStatus () {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList();
    },
    // 筛选审核列表开始日期、结束日期
    onChangeReviewData () {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList();
    },
    // 筛选审核列表时间校验
    dealDisabledDate (time) {
      if (!this.isBlank(this.queryParams.fillStartTime)) {
        return (
          time.getTime() <= new Date(this.queryParams.fillStartTime).getTime()
        );
      }
    },
    dealDisabledDateS (time) {
      if (!this.isBlank(this.queryParams.fillEndTime)) {
        return (
          new Date(this.queryParams.fillEndTime).getTime() - 8.64e7 <
          time.getTime()
        );
      }
    },
    // 图片、附件 列点击
    cellReviewClickEvent (event) {
      const property = event.column.property;
      const value = event.row[property];
      if (!this.isBlank(property)) {
        if (property.indexOf("content") != -1) {
          // 图片点击查看详情
          const splitImgArr = property.split("content");
          const formFieldId = splitImgArr[1];
          const imgUrlField = "imgUrl" + formFieldId;
          const imgUrl = event.row[imgUrlField];
          if (!this.isBlank(imgUrl)) {
            this.multiples = 1;
            this.deg = 0;
            this.styleObj = null;
            this.endX = 0;
            this.endY = 0;
            this.showDetails = true;
            this.showDetailsImg = imgUrl;
          }
        } else if (property.indexOf("file") != -1) {
          // 附件点击下载
          const splitFileArr = property.split("file");
          const formFieldId = splitFileArr[1];
          const fileUrlField = "fileUrl" + formFieldId;
          const fileUrl = event.row[fileUrlField];
          if (!this.isBlank(fileUrl)) {
            if (
              fileUrl.indexOf(
                "https://smart-form-dev.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1 ||
              fileUrl.indexOf(
                "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1
            ) {
              // 1.先创建一个a标签
              const a = document.createElement("a");
              // 2.给a标签的href赋上下载地址及下载名称
              const downloadName = this.getSuffName(fileUrl);
              a.download = downloadName;
              a.href = fileUrl;
              // 3.让a标签不显示在视图中
              a.style.display = "none";
              // 4.将a标签append到文档中
              document.body.appendChild(a);
              // 5.a标签自点击
              a.click();
              // 6.点击下载后，销毁这个节点
              document.body.removeChild(a);
            }
          }
        }
      }
    },
    setReviewRules () {
      // 根据配置判断，审核意见是否必填？
      if (this.formDetail.auditAuthReviewRemark) {
        this.reviewRules.reviewRemark = [
          {
            required: true,
            message: "请填写审核意见",
            trigger: "blur",
          },
        ];
      }
      // 根据配置判断是否手写签名必填。
      if (this.formDetail.auditAuthSign && this.formDetail.auditAuthReviewSign) {
        this.reviewRules.reviewSign = [
          {
            required: true,
            message: "请填写签名",
            trigger: "blur",
          },
        ];
      }

    },
    // 3.0.0.1---审核
    onReviewClick (fillId, version) {
      this.selectedRowKeys = { fillId: fillId, version: version };

      this.isMultipleSelectionFill = false;

      this.setReviewRules();
      this.reviewDialog.type = 'audit';
      this.reviewDialog.open = true;
      let row = this.reviewGridOptions.data.find((x) => x.fillId == fillId);

      //三个待审核状态不回显审核数据
      if (!["2", "6", "10"].find((x) => x === row.status.toString())) {
        this.reviewForm.auditStatus = row.auditStatus;
        this.reviewForm.reviewRemark = row.reviewRemark;
        this.reviewForm.reviewSign = row.reviewSign;
      }
      this.reviewForm.fillId = row.fillId;
      this.reviewForm.status = row.status;

      this.$nextTick(() => {
        // this.$refs['reviewFormDialog'].resetFields()
        this.$refs.reviewFormDialog.clearValidate();
      });
    },
    // 3.0.0.1---修改审核
    onEditReviewClick (row) {
      const _this = this;
      this.selectedRowKeys = { fillId: row.fillId, version: row.version };
      if (row.status == 6 || row.status == 10) {
        _this.reviewForm.auditStatus = "1";
      } else if (row.status == 4 || row.status == 8 || row.status == 12) {
        _this.reviewForm.auditStatus = "2";
      } else if (row.status == 5 || row.status == 9 || row.status == 13) {
        _this.reviewForm.auditStatus = "3";
      }

      _this.reviewForm.reviewRemark = row.reviewRemark == "--" ? "" : row.reviewRemark;
      _this.reviewForm.reviewSign = row.reviewSign;

      if (row.status >= 3 && row.status <= 6) {
        _this.reviewForm.reviewRemark = row.auditAuth1Remarks
        _this.reviewForm.reviewSign = row.auditAuth1Sign;
      } else if (row.status > 6 && row.status <= 10) {
        _this.reviewForm.reviewRemark = row.auditAuth2Remarks
        _this.reviewForm.reviewSign = row.auditAuth2Sign;
      } else if (row.status > 10 && row.status < 15) {
        _this.reviewForm.reviewRemark = row.auditAuth3Remarks
        _this.reviewForm.reviewSign = row.auditAuth3Sign;
      }

      _this.reviewForm.version = row.version;
      _this.reviewForm.fillId = row.fillId;
      _this.reviewForm.status = row.status;

      this.setReviewRules();
      this.isMultipleSelectionFill = false;
      this.reviewDialog.type = 'editAudit';
      this.reviewDialog.open = true;
      this.isAuditLoading = false;


      this.$nextTick(() => {
        this.$refs.reviewFormDialog.clearValidate();
      });

    },
    onRadioReviewChange (value) {
      if (value == "1") {
        // this.reviewForm.reviewRemark = '审核通过'
      } else if (value == "2" || value == "3") {
        // this.reviewForm.reviewRemark = null
      }
    },
    // 新版---审核弹框 确认点击
    async onSubmitReviewClick () {
      const _this = this;
      this.isAuditLoading = true;
      if (this.formDetail.auditAuthSign) {
        await this.$refs.signRef.promiseUpload();
      }

      _this.$refs["reviewFormDialog"].validate((valid, reviewFormData) => {
        if (valid) {
          const param = {
            auditStatus: parseInt(_this.reviewForm.auditStatus),
          };
          if (!_this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          if (!_this.isBlank(this.formDetail.auditAuthSign)) {
            //保存审核签名
            param.reviewSign = _this.reviewForm.reviewSign;
          }
          param.fillId = this.selectedRowKeys.fillId;
          param.formId = _this.formId;
          param.version = this.selectedRowKeys.version;

          const reviewApi = this.reviewDialog.type == 'audit' ? review : auditFillUpdate;

          reviewApi(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("已审核");
              _this.resetReviewForm(); // 审核成功后，清空审核弹框中数据
              // _this.reviewGridOptions.pagerConfig.currentPage = 1
              // _this.reviewGridOptions.columns = []
              _this.reviewGridOptions.data = [];
              _this.getReviewList();
              _this.reviewDialog.open = false;
              _this.isAuditLoading = false;
              if (_this.$refs.reviewXGrid) {
                _this.$refs.reviewXGrid.clearFilter();
                _this.$refs.reviewXGrid.clearSelected();
                _this.$refs.reviewXGrid.clearCheckboxRow();
              }
            } else {
              _this.isAuditLoading = false;
              _this.$message(response.msg);
            }
          }).catch(() => {
            _this.isAuditLoading = false;
          });
        } else {
          let errs = [];
          Object.keys(reviewFormData).forEach(x => {
            errs = [...errs, ...reviewFormData[x].map(x => x.message)];
          })
          // _this.$message(errs.join(','))
          _this.isAuditLoading = false;
        }
      })
    },
    // 一键审核确认点击
    async onSubmitMultipleReviewClick () {
      const _this = this;
      this.isAuditLoading = true;


      if (this.formDetail.auditAuthSign) {
        await this.$refs.signRef.promiseUpload();
      }

      _this.$refs["reviewFormDialog"].validate((valid) => {
        if (valid) {
          const param = {
            auditStatus: parseInt(_this.reviewForm.auditStatus),
            formId: _this.formId,
            fillList: this.reviewGridOptions.params.isAllDataSelected
              ? undefined
              : this.reviewGridOptions.params.selectedRowKeys.map((x) => {
                return { fillId: x.fillId, version: x.version };
              }),
            fillIdCacheKey: this.reviewGridOptions.params.isAllDataSelected
              ? this.reviewGridOptions.fillCacheKey
              : undefined,
            excludeFillIds: this.reviewGridOptions.params.isAllDataSelected
              ? this.reviewGridOptions.params.excludeIds.toString()
              : undefined,
            type: this.reviewGridOptions.params.isAllDataSelected ? 1 : 0,
          };
          if (!_this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          if (!_this.isBlank(this.formDetail.auditAuthSign)) {
            //保存审核签名
            param.reviewSign = _this.reviewForm.reviewSign;
          }
          batchReviewFill(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("已审核");
              // _this.getFormDetail()
              _this.resetReviewForm(); // 审核成功后，清空审核弹框中数据
              // _this.reviewGridOptions.pagerConfig.currentPage = 1
              // _this.reviewGridOptions.columns = []
              _this.reviewGridOptions.data = [];
              _this.getReviewList();
              _this.reviewDialog.open = false;
              _this.isMultipleSelectionFill = false;
              _this.isAuditLoading = false;
              _this.selectionRows = [];
              _this.selectedRowKeys = [];
              if (_this.$refs.reviewXGrid) {
                _this.$refs.reviewXGrid.clearFilter();
                _this.$refs.reviewXGrid.clearSelected();
                _this.$refs.reviewXGrid.clearCheckboxRow();
              }
            } else {
              _this.$message(response.msg);
            }
          });
        } else {
          _this.isAuditLoading = false;
        }
      });
    },
    // 审核弹框取消
    onCancelReviewClick () {
      this.resetReviewForm();
      this.reviewDialog.open = false;
    },
    // 监听审核弹框打开
    onShowReviewDialog () {
      this.$refs.reviewFormDialog.clearValidate();
    },
    // 监听审核弹框关闭
    onHandleReviewClose () {
      this.resetReviewForm();
      this.$refs.dlgvFormViewer.closeQuite();
    },
    // 审核弹框清空数据
    resetReviewForm () {
      // console.log('清空')
      // console.log(this.reviewForm)
      this.reviewForm.status = "";
      this.reviewForm.auditStatus = "";
      this.reviewForm.reviewRemark = "";
      this.reviewForm.fillId = "";
      this.reviewForm.reviewSign = '';
      // if (this.$refs['reviewFormDialog']) {
      //   this.$refs['reviewFormDialog'].resetFields()
      //   this.$refs.reviewFormDialog.clearValidate()
      // }

      // console.log(this.reviewForm)
      // this.$forceUpdate();
      // this.multipleSelectionFill = []
      // this.isMultipleSelectionFill = false
      // if (this.$refs.reviewXGrid) {
      //   this.$refs.reviewXGrid.clearFilter()
      //   this.$refs.reviewXGrid.clearSelected()
      //   this.$refs.reviewXGrid.clearCheckboxRow()
      // }
    },
    // version 2.4 确认审核
    onSubmitReviewTableClick () {
      const _this = this;
      _this.$refs["reviewForm"].validate((valid) => {
        if (valid) {
          const param = {
            fillId: _this.fillId,
            formId: _this.formId,
            auditStatus: parseInt(_this.reviewForm.auditStatus),
          };

          param.version = _this.reviewForm.version;
          if (!_this.isBlank(_this.reviewForm.reviewRemark)) {
            param.reviewRemark = _this.reviewForm.reviewRemark;
          }
          review(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("已审核");
              _this.resetReviewTableForm();
              _this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                queryStatus: "",
                // payStatus:'',
                fillStartTime: "",
                fillEndTime: "",
              };
              _this.getFormDetail();
              _this.reviewGridOptions.pagerConfig.currentPage = 1;
              // _this.reviewGridOptions.columns = []
              _this.reviewGridOptions.data = [];
              _this.getReviewList();
              _this.cur = 4;
            } else {
              _this.$message(response.msg);
            }
          });
        }
      });
    },
    // version 2.4 取消审核
    onCancelReviewTableClick () {
      this.cur = 4;
      this.resetReviewTableForm();
      this.multipleSelectionFill = [];
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        queryStatus: "",
        // payStatus:'',
        fillStartTime: "",
        fillEndTime: "",
      };
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.getReviewList();
    },
    // version 2.4 重置审核相关
    resetReviewTableForm () {
      this.reviewForm.status = "";
      this.reviewForm.reviewRemark = "";
      this.reviewForm.fillId = "";
      if (this.$refs["reviewForm"]) {
        this.$refs["reviewForm"].resetFields();
        this.$refs.reviewForm.clearValidate();
      }
      this.formData = {};
      // this.formFieldList = []
    },
    // 一键审核
    onBatchReviewClick (selectedRowKeys, isAllDataSelected, excludeIds) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.isBlank(this.selectedRowKeys) && !isAllDataSelected) {
        this.$message({
          message: "请先选择审核数据",
          type: "warning",
        });
        return false;
      }

      // 检查选中的数据是否可以审核
      const checkAllowAudit = () => {
        let allow = true;
        this.selectedRowKeys.forEach(key => {
          let selectedRow = this.reviewGridOptions.data.find(item => {
            return (item.fillId == key.fillId)
          })
          allow = allow && selectedRow && selectedRow.auditButton
            && (selectedRow['status'] == '2' || selectedRow['status'] == '6' || selectedRow['status'] == '10')

        })

        return allow
      }


      if (!checkAllowAudit()) {
        this.$message({
          message: "选中的数据中存在已审核数据，不能进行批量审核",
          type: "warning",
        });
        return false;
      }

      this.isMultipleSelectionFill = true;

      this.setReviewRules();
      this.reviewDialog.type = 'audit';
      this.reviewDialog.open = true;
      this.isAuditLoading = false;

      this.reviewForm.reviewSign = '';

      this.$nextTick(() => {
        // this.$refs['reviewFormDialog'].resetFields()
        this.$refs.reviewFormDialog.clearValidate();
      });
    },
    // 审核列表，点击查询事件
    onHandleQueryReviewClick () {
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];
      this.reviewGridOptions.filterData = undefined;

      if (this.$refs.auditTable) this.$refs.auditTable.clearFilters();
      // this.getReviewList();
    },
    // 审核列表，点击重置事件
    onHandleResetReviewClick () {
      this.queryParams.queryStatus = "";
      // this.queryParams.payStatus=''
      this.queryParams.fillStartTime = "";
      this.queryParams.fillEndTime = "";
      this.queryParams.formFieldId = "";
      this.queryParams.fillValue = "";
      this.reviewGridOptions.pagerConfig.currentPage = 1;
      // this.reviewGridOptions.columns = []
      this.reviewGridOptions.data = [];

      this.$refs.auditTable.clearFilter();
      this.$refs.auditTable.clearSort(true);
      this.$refs.auditTable.clearSelected();
      // this.getReviewList()
    },
    onHandleSelectionChange (event, item, index) {
      this.formFieldList[index].showRequiredTxt = false;
      if (item.required) {
        if (this.isBlank(this.formData[item.formFieldId])) {
          this.formFieldList[index].errorText = item.label + "不能为空";
          this.$forceUpdate();
          return;
        }
      }
    },
    // restoreFillter(el, filterData, sortData) {
    //   if (filterData) {
    //     Object.keys(filterData).forEach(f => {
    //       el.setFilter(f, filterData[f]);
    //     })
    //   }
    restoreFillter (el, filterDataArray, sortData) {
      if (filterDataArray && Array.isArray(filterDataArray)) {
        filterDataArray.forEach((f) => {
          el.setFilter(f.field, f.value);
        });
      } else if (filterDataArray) {
        Object.keys(filterDataArray).forEach((f) => {
          el.setFilter(f, filterDataArray[f]);
        });
      }

      if (sortData) {
        sortData.forEach((field) => {
          el.setSort(field);
        });
      }
    },
    // table插件vxe-table 数据查看列表
    getDataList (callback) {
      const _this = this;
      _this.gridOptions.loading = true;
      _this.dataEmptyText = "正在加载中...";
      _this.getFillDataDetail(callback);
    },
    getFillDataDetail (callback) {
      const _this = this;
      const queryParams = {
        pageNum: _this.gridOptions.pagerConfig.currentPage
          ? _this.gridOptions.pagerConfig.currentPage
          : 1,
        pageSize: _this.gridOptions.pagerConfig.pageSize
          ? _this.gridOptions.pagerConfig.pageSize
          : 10,
        criteria: _this.dataQueryParams.filterData,
        orders: _this.dataQueryParams.sortData,
        formFieldId: _this.dataQueryParams.formFieldId,
        fillValue: _this.dataQueryParams.fillValue,
        queryStatus: _this.dataQueryParams.queryStatus,
        payStatus: _this.dataQueryParams.payStatus,
        fillStartTime: _this.dataQueryParams.fillStartTime,
        fillEndTime: _this.dataQueryParams.fillEndTime,
        updateStartTime: _this.dataQueryParams.updateStartTime,
        updateEndTime: _this.dataQueryParams.updateEndTime,
        searchText: _this.dataQueryParams.fillValue, //全局搜索
        fillStatus: _this.dataQueryParams.fillStatus,
      };
      fillDataDetail(_this.formId, queryParams).then((response) => {
        this.gridOptions.fillCacheKey = response.fillCacheKey;
        for (let i = 0; i < response.rows.length; i++) {
          const item = response.rows[i];
          const obj = { ...item.fillJson };

          // 表单开通支付
          obj["payStatus"] = item.payStatus;
          switch (item.payStatus) {
            case 1:
              item.payStatusTxt = "无需支付";
              break;
            case 2:
              item.payStatusTxt = "待支付";
              break;
            case 3:
              item.payStatusTxt = "已支付";
              break;
            case 4:
              item.payStatusTxt = "确认支付";
              break;
          }
          obj["payStatusTxt"] = item.payStatusTxt;
          obj["orderPriceTxt"] = item.orderPrice / 100 + "元";
          obj["payTime"] = !_this.isBlank(item.payTime) ? item.payTime : "--";
          // let statusTxt = ''
          // switch (item.status) {
          //   case 1:
          //     statusTxt = '无需审核'
          //     break
          //   case 2:
          //     if (!_this.isBlank(item.auditAuth1AgencyUserList)) {
          //       let auditAuth1AgencyUser = ''
          //       for (let j = 0; j < item.auditAuth1AgencyUserList.length; j++) {
          //         auditAuth1AgencyUser += item.auditAuth1AgencyUserList[j].name + ','
          //       }
          //       // 去掉最后一个逗号
          //       if (auditAuth1AgencyUser.length > 0) {
          //         auditAuth1AgencyUser = auditAuth1AgencyUser.substr(0, auditAuth1AgencyUser.length - 1)
          //       }
          //       statusTxt = '待' + auditAuth1AgencyUser + '审核' // '待一级审核'
          //     } else {
          //       statusTxt = '--'
          //     }
          //     break
          //   case 3:
          //     statusTxt = '已通过' // 一级审核通过
          //     break
          //   case 4:
          //     statusTxt = '已驳回' // 一级审核不通过
          //     break
          //   case 5:
          //     statusTxt = '返回修改' // 一级审核返回修改
          //     break
          //   case 6:
          //     if (!_this.isBlank(item.auditAuth2AgencyUserList)) {
          //       let auditAuth2AgencyUser = ''
          //       for (let k = 0; k < item.auditAuth2AgencyUserList.length; k++) {
          //         auditAuth2AgencyUser += item.auditAuth2AgencyUserList[k].name + ','
          //       }
          //       // 去掉最后一个逗号
          //       if (auditAuth2AgencyUser.length > 0) {
          //         auditAuth2AgencyUser = auditAuth2AgencyUser.substr(0, auditAuth2AgencyUser.length - 1)
          //       }
          //       statusTxt = '待' + auditAuth2AgencyUser + '审核' // '待二级审核'
          //     } else {
          //       statusTxt = '--'
          //     }
          //     break
          //   case 7:
          //     statusTxt = '已通过' // 二级审核通过
          //     break
          //   case 8:
          //     statusTxt = '已驳回' // 二级审核不通过
          //     break
          //   case 9:
          //     statusTxt = '返回修改' // 二级审核返回修改
          //     break
          // }

          obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
          obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
          obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
          obj["auditAuth1Sign"] = item.auditAuth1Sign;
          obj["auditAuth2Sign"] = item.auditAuth2Sign;
          obj["auditAuth3Sign"] = item.auditAuth3Sign;
          obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
          obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
          obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
          obj["auditAuth1Operator"] = item.auditAuth1Operator;
          obj["auditAuth2Operator"] = item.auditAuth2Operator;
          obj["auditAuth3Operator"] = item.auditAuth3Operator;
          obj["status"] = item.status;
          // obj['statusTxt'] = statusTxt
          //obj['reviewRemark'] = !_this.isBlank(item.auditAuth2Remarks) ? item.auditAuth2Remarks : !_this.isBlank(item.auditAuth1Remarks) ? item.auditAuth1Remarks : '--'
          obj["reviewRemark"] =
            item.auditAuth3Remarks ||
            item.auditAuth2Remarks ||
            item.auditAuth1Remarks ||
            "";

          obj["userId"] = item.userId;
          obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
            ? item.fillUserNumber
            : "-";
          obj["fillUserName"] = item.fillUserName;
          obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
          obj["createTime"] = item.createTime;
          obj["updateTime"] = item.updateTime;
          obj["agencyDeptList"] = item.agencyDeptList;

          obj["reviewSign"] = item.reviewSign;
          if (_this.dataFillStatus) {
            switch (item.fillStatus) {
              case 1:
                item.fillStatusTxt = "个人填报";
                break;
              case 2:
                item.fillStatusTxt = "导入待确认";
                break;
              case 3:
                item.fillStatusTxt = "导入已确认";
                break;
              case 4:
                item.fillStatusTxt = "导入无需确认";
                break;
            }
            obj["fillStatusTxt"] = item.fillStatusTxt;
            obj["fillStatus"] = item.fillStatus;
          }

          obj["fillId"] = item.fillId;

          obj["fillHeaders"] = Object.keys(item.fillJson);
          _this.gridOptions.data.push(obj);
          _this.gridOptions.pagerConfig.total = response.total;
          _this.gridOptions.params.allDataTotal = response.total;
        }
        if (_this.isBlank(_this.gridOptions.data)) {
          _this.dataEmptyText = "暂无数据";
        }

        _this.gridOptions.loading = false;

        this.$nextTick(() => {
          if (callback) callback();
        });
      });
    },
    // 数据查看列表列颜色变化
    tableDataRowClassName ({ row, column, rowIndex, columnIndex }) {
      return "";
      if (
        column.title &&
        (column.title == "填报时间" ||
          column.title == "填报人编号" ||
          column.title == "填报人" ||
          column.title == "批量导入状态" ||
          column.title == "所属一级部门" ||
          column.title == "所属二级部门")
      ) {
        return "fontNormal";
      } else {
        return "fontWeight";
      }
    },
    handleDataPageChange ({ currentPage, pageSize }) {
      this.gridOptions.pagerConfig.currentPage = currentPage;
      this.gridOptions.pagerConfig.pageSize = pageSize;
      // this.gridOptions.columns = []
      this.gridOptions.data = [];
      this.getDataList(() => {
        this.restoreFillter(
          this.$refs.gridTable,
          this.dataQueryParams.filterData,
          this.dataQueryParams.sortData
        );
      });
    },
    // 筛选数据查看列表审核状态
    onDataSelectReviewStatus () {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.columns = [];
      this.gridOptions.data = [];
      this.getDataList();
    },
    // 筛选数据查看列表时间校验
    dataDealDisabledDate (time) {
      if (!this.isBlank(this.dataQueryParams.fillStartTime)) {
        return (
          time.getTime() <=
          new Date(this.dataQueryParams.fillStartTime).getTime()
        );
      }
    },
    dataDealDisabledDateS (time) {
      if (!this.isBlank(this.dataQueryParams.fillEndTime)) {
        return (
          new Date(this.dataQueryParams.fillEndTime).getTime() - 8.64e7 <
          time.getTime()
        );
      }
    },
    // 筛选数据查看列表开始日期、结束日期
    onChangeDataTime () {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.columns = [];
      this.gridOptions.data = [];
      this.getDataList();
    },
    // 筛选数据查看列表填报字段
    onSelectDataField () {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.columns = [];
      this.gridOptions.data = [];
      this.getDataList();
    },
    // 筛选数据查看列表填报内容
    onChangeDataFillValue () {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.columns = [];
      this.gridOptions.data = [];
      this.getDataList();
    },
    // 下载数据详情表格
    onDownloadClick () {
      this.dataDownloadOpen = true;
    },
    // 仅导出Excel
    onDownLoadExcel (showImg = "show", isFillSelect = 0, exportFields = []) {
      const formId = this.formId;
      const loading = ELEMENT.Loading.service({
        fullscreen: true,
        lock: true,
        text: "导出中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.5)",
      });
      // const showImg = 'show'
      fillDataDownload({
        showImg: showImg,
        isFillSelect: isFillSelect,
        exportFields: exportFields,
        ...this.downloadParams,
      }).then(
        (data) => {
          if (!data) {
            return false;
          }
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          loading.close();
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
          loading.close();
        }
      );
    },
    // 导出Excel和附件（图片）
    onDownLoadAll (fillId) {
      this.dataDownloadAllOpen = true;
      this.getZipDownList(this.formId);
    },
    // EXCEL带附件生成下载请求
    onCreateDownloadClick () {
      const formId = this.formId;
      if (this.exportDownloadType === "file") {
        fillDataZipDownHttp(this.downloadParams).then((response) => {
          if (response.code == 200) {
            this.$message({
              type: "success",
              message: "数据正在打包中，请稍后进行下载～",
            });

            //获取下载数据列表完成的
            let getZipDownListFinishHandler = (data) => {
              if (
                data.filter((x) => x.status <= 1).length == 0 ||
                timeoutTimes > 60
              ) {
                clearInterval(checkDownload);
              }
            };
            let timeoutTimes = 0;
            let checkDownload = setInterval(() => {
              timeoutTimes++;
              this.getZipDownList(formId, getZipDownListFinishHandler);
            }, 1000);
          }
        });
      } else if (this.exportDownloadType === "word") {
        fillDataDownloadFormWordDownload(this.downloadParams).then(
          (response) => {
            if (response.code == 200) {
              this.$message({
                type: "success",
                message: "数据正在打包中，请稍后进行下载～",
              });

              //获取下载数据列表完成的
              let getZipDownListFinishHandler = (data) => {
                if (
                  data.filter((x) => x.status <= 1).length == 0 ||
                  timeoutTimes > 60
                ) {
                  clearInterval(checkDownload);
                }
              };
              let timeoutTimes = 0;
              let checkDownload = setInterval(() => {
                timeoutTimes++;
                this.getExportWordDownList(formId, getZipDownListFinishHandler);
              }, 1000);
            }
          }
        );
      } else {
        fillDataDownloadFormPicDownload(this.downloadParams).then(
          (response) => {
            let getZipDownListFinishHandler = (data) => {
              if (
                data.filter((x) => x.status <= 1).length == 0 ||
                timeoutTimes > 60
              ) {
                clearInterval(checkDownload);
              }
            };
            let timeoutTimes = 0;
            let checkDownload = setInterval(() => {
              timeoutTimes++;
              this.getExportExcelPicList(formId, getZipDownListFinishHandler);
            }, 1000);
          }
        );
      }
    },
    getZipDownList (formId, callback) {
      fillDataZipDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    getExportExcelPicList (formId, callback) {
      fillDataDownloadFormPicDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    getExportWordDownList (formId, callback) {
      fillDataDownloadFormWordDownList(formId).then((response) => {
        if (response.code == 200) {
          this.fillDataZipDownList = response.data;
          if (callback) {
            callback(response.data);
          }
        }
      });
    },
    onDownloadFormFileClick (item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    searchEvent () {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.getDataList();
    },
    toolbarButtonClickEvent ({ code }) {
      const $grid = this.$refs.xGrid;
      switch (code) {
        case "myExport":
          this.$message({
            type: "warning",
            message: "正在开发中，请敬请期待！",
          });
          break;
        case "myExportIncludePic":
          this.onDownLoadExcel();
          break;
        case "myExportIncludeFile":
          this.onDownLoadAll();
          break;
      }
    },
    // 管理列表的导出
    onExportForGridClick (type, fillIds) {
      const tag = type
      let isAllDataSelected = false
      if (fillIds && fillIds.length > 0) {
        this.downloadParams = {
          formId: this.formId,
          type: 1,
          fillIds: fillIds,
        };
      } else {
        const selectKeys = this.gridOptions.params.selectedRowKeys;
        isAllDataSelected = this.gridOptions.params.isAllDataSelected;
        if (!isAllDataSelected && selectKeys.length == 0) {
          this.$message.error("请先勾选下载记录");
          return;
        }
        if (isAllDataSelected) selectKeys.splice(0);

        fillIds = selectKeys.map((x) => x.fillId);
        if (isAllDataSelected) {
          this.downloadParams = {
            formId: this.formId,
            searchText: this.dataQueryParams.fillValue,
            criteria: this.dataQueryParams.filterData,
            fillIdCacheKey: this.gridOptions.fillCacheKey,
            excludeFillIds: this.gridOptions.params.excludeIds.toString(),
            type: 2,
            fillStartTime: this.dataQueryParams.fillStartTime,
            fillEndTime: this.dataQueryParams.fillEndTime,
            queryStatus: this.dataQueryParams.queryStatus,
            payStatus: this.dataQueryParams.payStatus,
          };
        } else {
          this.downloadParams = {
            formId: this.formId,
            type: 1,
            fillIds: fillIds,
          };
        }
      }

      if (isAllDataSelected === false && this.formDetail.exportAfterAudit && type === 'word') {
        let fillJsons = this.gridOptions.data.filter(x => {
          return fillIds.findIndex(y => y == x.fillId) > -1
        });
        // 检查每一条formJson的数据，status是否为14

        if (!fillJsons.every(x => x.status == 14)) { //如果不是所有的都是14
          this.$message.error("导出数据中存在未完成审核的数据，请先审核通过后再进行导出");
          return;
        }
        // } else if( type==='word'){
        // 消息提示改为这下拉框中显示提示
        // this.$message({
        //   message: "选中数据中可能存在未审核完成的数据，仅能导出审核完成的数据",
        //   type: 'warning',
        //   duration: 3000,
        //   customClass: 'msgShowZIndexTop'
        // });
      }

      this.onExportProxy(tag);
    },
    onExportProxy (tag) {
      if (tag == "excel") {
        // 打开弹出框，让用户选择要导出的字段
        this.exportFieldSelect = true;
        // 加载字段
        getFormFieldTree(this.formId).then((response) => {
          if (response.code == 200) {
            this.exportFieldList = response.data;
            this.exportFieldList.forEach(e => {
              this.defaultSelectAll.push(e.field)
            })
          } else {
          }
        });
        this.exportExcelType = 0

        //是否有关联字段 0没有 1有-合并导出
        // this.onExportExcelClick(0);
      } else if (tag == "excel2") {
        // 打开弹出框，让用户选择要导出的字段
        this.exportFieldSelect = true;
        // 加载字段
        getFormFieldTree(this.formId).then((response) => {
          if (response.code == 200) {
            this.exportFieldList = response.data;
            this.exportFieldList.forEach(e => {
              this.defaultSelectAll.push(e.field)
            })
          } else {
          }
        });
        this.exportExcelType = 1
      } else if (tag == "picexcel") {
        this.exportDownloadType = "picexcel";
        this.onExportExcelThumbnailClick();
      } else if (tag == "file") {
        this.exportDownloadType = "file";
        this.onExportExcelFileClick();
      } else if (tag == "word") {
        this.exportDownloadType = "word";
        this.onExportWordClick();
      } else {
        // this.$message.error('未知的下载类型')
        this.onExportExcelClick(0);
      }
    },
    onExportExcelClick (isFillSelect) {
      let checkedKeys = this.$refs.channelTree.getCheckedKeys()
      let halfCheckedKeys = this.$refs.channelTree.getHalfCheckedKeys()
      const mergedArray = [...checkedKeys, ...halfCheckedKeys];
      this.onDownLoadExcel("hide", isFillSelect, mergedArray);
    },
    onExportExcelThumbnailClick () {
      this.dataDownloadAllOpen = true;
      this.getExportExcelPicList(this.formId);
    },
    onExportExcelFileClick () {
      this.onDownLoadAll();
    },
    onExportWordClick () {
      // this.$message({
      //   type: 'warning',
      //   message: '正在开发中，请敬请期待！'
      // })
      this.dataDownloadAllOpen = true;
      this.getExportWordDownList(this.formId);
    },
    // 新版---数据填报 图片、附件列点击
    cellClickEvent (event) {
      const property = event.column.property;
      const value = event.row[property];
      if (!this.isBlank(property)) {
        if (property.indexOf("content") != -1) {
          // 图片点击查看详情
          const splitImgArr = property.split("content");
          const formFieldId = splitImgArr[1];
          const imgUrlField = "imgUrl" + formFieldId;
          const imgUrl = event.row[imgUrlField];
          if (!this.isBlank(imgUrl)) {
            this.multiples = 1;
            this.deg = 0;
            this.styleObj = null;
            this.endX = 0;
            this.endY = 0;
            this.showDetails = true;
            this.showDetailsImg = imgUrl;
          }
        } else if (property.indexOf("file") != -1) {
          // 附件点击下载
          const splitFileArr = property.split("file");
          const formFieldId = splitFileArr[1];
          const fileUrlField = "fileUrl" + formFieldId;
          const fileUrl = event.row[fileUrlField];
          if (!this.isBlank(fileUrl)) {
            if (
              fileUrl.indexOf(
                "https://smart-form-dev.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1 ||
              fileUrl.indexOf(
                "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/fillFile/"
              ) > -1
            ) {
              // 1.先创建一个a标签
              const a = document.createElement("a");
              // 2.给a标签的href赋上下载地址及下载名称
              const downloadName = this.getSuffName(fileUrl);
              a.download = downloadName;
              a.href = fileUrl;
              // 3.让a标签不显示在视图中
              a.style.display = "none";
              // 4.将a标签append到文档中
              document.body.appendChild(a);
              // 5.a标签自点击
              a.click();
              // 6.点击下载后，销毁这个节点
              document.body.removeChild(a);
            }
          }
        } else {
          this.formVer = 2;
          renderForm.call(this, this.formJson);
          // 设置表单配置
          this.$nextTick(() => {
            this.$refs.vformRender.disableForm();
          });

          this.onEditFillClick(event.row["fillId"], 1);
        }
      }
    },
    // 数据查看---上传附件---截取附件名
    getSuffName (url) {
      return !this.isBlank(url)
        ? decodeURIComponent(url).substring(url.indexOf("_") + 1)
        : "";
    },
    // 数据查看---上传附件---附件下载
    onDownFileClick (address) {
      const blob = new Blob([
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      ]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = this.getSuffName(address);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 批量删除数据查看列表数据
    // 批量删除已封装到独立组件，因此删除2023-05-13
    // onBatchDelClick () {
    //   const _this = this
    //   if (_this.isBlank(_this.gridOptions.params.selectedRowKeys)) {
    //     _this.$message({
    //       message: '请先选择数据！',
    //       type: 'warning'
    //     })
    //     return false
    //   }
    //   const fillIdArr = []
    //   for (let i = 0; i < _this.gridOptions.params.selectedRowKeys.length; i++) {
    //     fillIdArr.push(_this.gridOptions.params.selectedRowKeys[i].fillId)
    //   }
    //   const fillIds = fillIdArr.toString()
    //   _this
    //     .$confirm('是否确认删除该数据？', '警告', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     })
    //     .then(function () {
    //       return fillDel(fillIds, _this.formId,  _this.gridOptions.fillCacheKey)
    //     })
    //     .then(() => {
    //       _this.msgSuccess('删除成功')
    //       _this.gridOptions.data = []
    //       _this.getDataList()
    //     })
    //     .catch(function () { })
    // },
    // 数据查看列表数据，设置批量通知
    onHandleNoticeClick () {
      this.dataNoticeShow = true;
    },
    // 数据查看列表数据，设置批量通知
    onHandleShareClick () {
      this.dataShareShow = true;
    },
    // 批量通知设置弹框，解决dialog弹出层初始化失败的问题
    onDataNoticeOpen () {
      // const editor = new wangEditor(`#wangEditor`)
      // // 配置 onchange 回调函数，将数据同步到 vue 中
      // editor.config.onchange = (newHtml) => {
      //   // const safeHtml = xss(newHtml)
      //   this.noticeModel.noticeDetails = newHtml
      // }
      // // 默认情况下，显示所有菜单
      // editor.config.menus = ['head', 'bold', 'fontSize', 'italic', 'underline', 'strikeThrough', 'indent', 'lineHeight', 'foreColor', 'backColor', 'link', 'list', 'justify', 'quote', 'emoticon', 'image', 'table', 'code', 'splitLine', 'undo', 'redo']
      // // placeholder 的提示文字
      // editor.config.placeholder = '请输入通知详情'
      // // 配置 z-index
      // editor.config.zIndex = 1999
      // // 隐藏插入网络图片的功能
      // editor.config.showLinkImg = false
      // const _this = this
      // editor.config.customUploadImg = function(resultFiles, insertImgFn) {
      //   // resultFiles 是 input 中选中的文件列表, insertImgFn 是获取图片 url 后，插入到编辑器的方法
      //   // let sessionStorageUser = getSessionStorageObj('users')
      //   const folder = 'formNotice'
      //   var formId = _this.formId
      //   aliUpload(resultFiles[0], folder, formId)
      //     .then(function(url) {
      //       console.log('通知详情上传图片===', url)
      //       insertImgFn(url.data)
      //     })
      //     .catch(function(err) {})

      //   // const fileName = sessionStorageUser.userId + '/' + Date.parse(new Date()) + '_' + resultFiles[0].name
      //   // putFile(fileName, resultFiles[0]).then(function (url) {
      //   // 上传图片，返回结果，将图片插入到编辑器中
      //   //   insertImgFn(url)
      //   // }).catch(function (err) {})
      // }
      // // 创建编辑器
      // editor.create()
      // this.noticeEditor = editor
      this.$refs.noticeForm.resetFields();
      if (this.$refs["noticeForm"]) {
        this.$refs["noticeForm"].clearValidate();
      }
      this.noticeModel.noticeOverview = "";
      this.noticeModel.noticeDetails = "";
    },
    // 批量通知设置弹框，确定事件
    onConfirmNoticeClick () {
      const _this = this;
      // console.log(this.gridOptions.params);
      const selectKeys = this.gridOptions.params.selectedRowKeys;
      const isAllDataSelected = this.gridOptions.params.isAllDataSelected;
      // return;
      _this.$refs["noticeForm"].validate((valid) => {
        if (valid) {
          const sendMpNoticeDTO = {
            // formId: _this.formId,
            listCheckParam: {
              fillEndTime: this.dataQueryParams.fillEndTime,
              fillIds: selectKeys.map((x) => x.fillId),
              fillStartTime: this.dataQueryParams.fillStartTime,
              formId: this.formId,
              queryStatus: this.dataQueryParams.queryStatus,
              type: isAllDataSelected ? 2 : 1,
              fillIdCacheKey: this.gridOptions.params.isAllDataSelected
                ? this.gridOptions.fillCacheKey
                : undefined,
              excludeFillIds: this.gridOptions.params.isAllDataSelected
                ? this.gridOptions.params.excludeIds.toString()
                : undefined,
            },
            noticeOverview: _this.noticeModel.noticeOverview,
            noticeDetails: _this.noticeModel.noticeDetails,
          };

          const sendMpNoticeScreenDTO = {};
          if (!_this.isBlank(_this.dataQueryParams.queryStatus)) {
            sendMpNoticeScreenDTO.queryStatus =
              _this.dataQueryParams.queryStatus;
          } else if (!_this.isBlank(_this.dataQueryParams.fillStartTime)) {
            sendMpNoticeScreenDTO.fillStartTime =
              _this.dataQueryParams.fillStartTime;
          } else if (!_this.isBlank(_this.dataQueryParams.fillEndTime)) {
            sendMpNoticeScreenDTO.fillEndTime =
              _this.dataQueryParams.fillEndTime;
          } else if (!_this.isBlank(_this.dataQueryParams.formFieldId)) {
            sendMpNoticeScreenDTO.formFieldId =
              _this.dataQueryParams.formFieldId;
          } else if (!_this.isBlank(_this.dataQueryParams.fillValue)) {
            sendMpNoticeScreenDTO.fillValue = _this.dataQueryParams.fillValue;
          }

          const fillIdArr = [];
          for (let i = 0; i < _this.selectedDataRowKeys.length; i++) {
            fillIdArr.push(_this.selectedDataRowKeys[i].fillId);
          }

          let sendType = null;

          if (
            fillIdArr.length == 0 &&
            JSON.stringify(sendMpNoticeScreenDTO) == "{}"
          ) {
            sendType = 3;
          } else if (fillIdArr.length > 0) {
            sendType = 1;
            sendMpNoticeDTO.fillIds = fillIdArr;
          } else {
            sendType = 2;
            sendMpNoticeDTO.sendMpNoticeScreenDTO = sendMpNoticeScreenDTO;
          }

          sendMpNoticeDTO.sendType = sendType;

          console.log("设置通知传参", sendMpNoticeDTO);

          // return

          noticeSend(sendMpNoticeDTO).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("通知发送成功");

              _this.dataNoticeShow = false;

              // if (_this.noticeEditor) {
              //   _this.noticeEditor.destroy()
              // }
              // _this.noticeEditor = null
              _this.$refs.noticeForm.resetFields();
              if (_this.$refs["noticeForm"]) {
                _this.$refs["noticeForm"].clearValidate();
              }
              _this.noticeModel.noticeOverview = "";
              _this.noticeModel.noticeDetails = "";

              // if(_this.$refs.xGrid) {
              //   _this.$refs.xGrid.clearFilter()
              //   _this.$refs.xGrid.clearSelected()
              //   _this.$refs.xGrid.clearCheckboxRow()
              // }

              // _this.selectedRowKeys = []

              // _this.noticeClickNum = 0
            } else {
            }
          });
        }
      });
    },
    // 批量通知设置弹框，关闭和取消事件
    onDataNoticeClose () {
      this.dataNoticeShow = false;
      // if (this.noticeEditor) {
      //   this.noticeEditor.destroy()
      // }
      // this.noticeEditor = null
      this.$refs.noticeForm.resetFields();
      if (this.$refs["noticeForm"]) {
        this.$refs["noticeForm"].clearValidate();
      }
      // this.noticeClickNum = 0
      // if(this.$refs.xGrid) {
      //   this.$refs.xGrid.clearFilter()
      //   this.$refs.xGrid.clearSelected()
      //   this.$refs.xGrid.clearCheckboxRow()
      // }
      // this.selectedRowKeys = []
    },
    // 表单开通支付，管理员修改支付金额
    onHandleEditPayPriceClick () {
      const _this = this;
      if (_this.isBlank(this.gridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      // console.log('选择的数据', _this.selectedDataRowKeys, _this.selectionDataRows)
      _this.editPayPriceOpen = true;
    },
    // 表单开通支付，管理员确定修改支付金额
    onConfirmEditPayPriceClick () {
      const _this = this;

      const inconformityArr = [];
      for (
        let i = 0;
        i < _this.gridOptions.params.selectedRowKeys.length;
        i++
      ) {
        // const dataRow = this.gridOptions.data.find((x) => x.fillId == _this.gridOptions.params.selectedRowKeys[i].fillId)
        const dataRow = _this.gridOptions.params.selectedRowKeys[i];
        if (dataRow.payStatus == 3 || dataRow.payStatus == 4) {
          inconformityArr.push(dataRow);
        }
      }
      if (inconformityArr.length > 0) {
        this.$message({
          type: "warning",
          message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
        });
        return;
      }
      if (_this.isBlank(_this.editPayPrice)) {
        this.$message({
          type: "warning",
          message: "支付金额不能为空～",
        });
        return;
      }
      const n = Number(_this.editPayPrice);
      if (isNaN(n)) {
        _this.$message({
          type: "warning",
          message: "支付金额格式有误～",
        });
        return;
      } else if (n < 0.01) {
        _this.$message({
          type: "warning",
          message: "设置支付金额最小为0.01元～",
        });
        return;
        // } else if (n > 3000) {
        //   _this.$message({
        //     type: 'warning',
        //     message: '设置支付金额不能超过3000元～'
        //   })
        //   return
      } else {
        _this.isRegPrice(n);
      }
      const fillIds = [];
      for (
        let j = 0;
        j < _this.gridOptions.params.selectedRowKeys.length;
        j++
      ) {
        fillIds.push(_this.gridOptions.params.selectedRowKeys[j].fillId);
      }
      const dto = {
        fillIds: fillIds,
        formId: _this.formId,
        orderPrice: _this.editPayPrice * 100,
      };
      orderPriceEdit(dto).then((response) => {
        if (response.code == 200) {
          _this.msgSuccess("修改成功");
          _this.editPayPrice = null;
          _this.editPayPriceOpen = false;
          if (_this.$refs.xGrid) {
            _this.$refs.xGrid.clearFilter();
            _this.$refs.xGrid.clearSelected();
            _this.$refs.xGrid.clearCheckboxRow();
          }
          // _this.selectionDataRows = []
          // _this.gridOptions.columns = []
          _this.gridOptions.data = [];
          _this.getDataList();
          // _this.gridOptions.params.selectedRowKeys.splice(0);
        } else {
        }
      });
    },
    // 表单开通支付，管理员取消修改支付金额
    onCloseEditPayPriceClick () {
      this.editPayPriceOpen = false;
      this.editPayPrice = null;
      this.editPayPriceOpen = false;
      if (this.$refs.xGrid) {
        this.$refs.xGrid.clearFilter();
        this.$refs.xGrid.clearSelected();
        this.$refs.xGrid.clearCheckboxRow();
      }
      this.selectedDataRowKeys = [];
      this.selectionDataRows = [];
    },
    // 表单开通支付，管理员点击确认到款
    onHandleConfirmPayClick () {
      const _this = this;
      if (_this.isBlank(_this.gridOptions.params.selectedRowKeys)) {
        _this.$message({
          message: "请先选择数据！",
          type: "warning",
        });
        return false;
      }
      _this.confirmPayStatusOpen = true;
    },
    // 表单开通支付，确认修改支付状态
    onConfirmPayStatusClick () {
      const _this = this;
      if (_this.isBlank(_this.confirmPayStatus)) {
        this.$message({
          type: "warning",
          message: "请选择支付状态～",
        });
        return;
      }

      const inconformityArr = [];
      // console.log('支付状态', _this.confirmPayStatus)
      if (_this.confirmPayStatus == 2) {
        // 选择的未支付的状态
        for (
          let i = 0;
          i < _this.gridOptions.params.selectedRowKeys.length;
          i++
        ) {
          if (
            _this.gridOptions.params.selectedRowKeys[i].payStatus == 2 ||
            _this.gridOptions.params.selectedRowKeys[i].payStatus == 3
          ) {
            inconformityArr.push(_this.gridOptions.params.selectedRowKeys[i]);
          }
        }
        if (inconformityArr.length > 0) {
          _this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“未支付”，修改失败～",
          });
          return;
        }
      }
      if (_this.confirmPayStatus == 4) {
        // 选择的确认到款的状态
        for (
          let i = 0;
          i < _this.gridOptions.params.selectedRowKeys.length;
          i++
        ) {
          if (
            _this.gridOptions.params.selectedRowKeys[i].payStatus == 3 ||
            _this.gridOptions.params.selectedRowKeys[i].payStatus == 4
          ) {
            inconformityArr.push(_this.gridOptions.params.selectedRowKeys[i]);
          }
        }
        if (inconformityArr.length > 0) {
          _this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
          });
          return;
        }
      }
      const fillIds = [];
      for (
        let j = 0;
        j < _this.gridOptions.params.selectedRowKeys.length;
        j++
      ) {
        fillIds.push(_this.gridOptions.params.selectedRowKeys[j].fillId);
      }
      const dto = {
        fillIds: fillIds,
        formId: _this.formId,
        payStatus: _this.confirmPayStatus,
        type: 0
      };
      // console.log('最终修改支付状态的参数', dto)
      payStatusEdit(dto).then((response) => {
        if (response.code == 200) {
          _this.msgSuccess("修改成功");
          _this.confirmPayStatus = null;
          _this.confirmPayStatusOpen = false;
          // if (_this.$refs.xGrid) {
          //   _this.$refs.xGrid.clearFilter()
          //   _this.$refs.xGrid.clearSelected()
          //   _this.$refs.xGrid.clearCheckboxRow()
          // }
          // _this.selectedDataRowKeys = []
          // _this.selectionDataRows = []
          _this.$refs.gridTable.clearSelected();
          // _this.gridOptions.columns = []
          _this.gridOptions.data = [];
          _this.getDataList();
        }
      });
    },
    isRegPrice (total) {
      const dot = String(total).indexOf(".");
      if (dot != -1) {
        const dotCnt = String(total).substring(dot + 1, total.length);
        if (dotCnt.length > 2) {
          total = total.toFixed(2);
          this.$message({
            type: "warning",
            message: "支付金额只保留两位小数哦～",
          });
          this.editPayPrice = total;
        }
      }
    },
    // 表单开通支付，管理员关闭修改支付状态
    onCloseEditPayStatusClick () {
      this.confirmPayStatus = null;
      this.confirmPayStatusOpen = false;
      if (this.$refs.xGrid) {
        this.$refs.xGrid.clearFilter();
        this.$refs.xGrid.clearSelected();
        this.$refs.xGrid.clearCheckboxRow();
      }
      this.selectedDataRowKeys = [];
      this.selectionDataRows = [];
    },
    // 数据查看列表---复选框事件(保留翻页选中的数据)
    selectChangeDataEvent ({ checked, records, reserves, row }) {
      // return;
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length == 0) {
          const noPageArr = [];
          for (let i = 0; i < records.length; i++) {
            const obj = {};
            obj["fillId"] = records[i].fillId;
            noPageArr.push(obj);
          }
          this.selectedDataRowKeys = noPageArr;
          console.log("this.selectedDataRowKeys", this.selectedDataRowKeys);
          this.selectionDataRows = records;
        } else {
          // id集合，翻页存在已选中的数据时,拼接新选中的数据
          const recordsArr = [];
          for (let i = 0; i < records.length; i++) {
            const obj = {};
            obj["fillId"] = records[i].fillId;
            recordsArr.push(obj);
          }
          const reservesArr = [];
          for (let i = 0; i < reserves.length; i++) {
            const obj = {};
            obj["fillId"] = reserves[i].fillId;
            reservesArr.push(obj);
          }
          this.selectedDataRowKeys = [...recordsArr, ...reservesArr];
          // 数据集合，翻页存在已选中的数据时,拼接新选中的数据
          this.selectionDataRows = [...reserves, ...records];
        }
      } else {
        // 取消选中时
        const idIndex = this.selectedDataRowKeys.indexOf(row.fillId);
        if (idIndex > -1) {
          // 删除取消选中删除指定元素id
          this.$delete(this.selectedDataRowKeys, idIndex);
        }

        let dataIndex = null;
        for (let i = 0; i < this.selectionDataRows.length; i++) {
          if (this.selectionDataRows[i].fillId == row.fillId) {
            dataIndex = i;
            break;
          }
        }
        // 删除取消选中的元素整个对象
        this.$delete(this.selectedDataRowKeys, dataIndex);
      }
    },
    // 数据查看列表---vxe复选框当前页全选中方法(保留翻页选中的数据)
    selectAllDataEvent ({ checked, records, reserves }) {
      // 全选中时
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length == 0) {
          const noPageArr = [];
          for (let i = 0; i < records.length; i++) {
            const obj = {};
            obj["fillId"] = records[i].fillId;
            noPageArr.push(obj);
          }
          this.selectedDataRowKeys = noPageArr;
          this.selectionDataRows = records;
        } else {
          // id集合，翻页存在已选中的数据时,拼接新选中的数据
          const recordsArr = [];
          for (let i = 0; i < records.length; i++) {
            const obj = {};
            obj["fillId"] = records[i].fillId;
            recordsArr.push(obj);
          }
          const reservesArr = [];
          for (let i = 0; i < reserves.length; i++) {
            const obj = {};
            obj["fillId"] = reserves[i].fillId;
            reservesArr.push(obj);
          }
          this.selectedDataRowKeys = [...recordsArr, ...reservesArr];
          // 数据集合，翻页存在已选中的数据时,拼接新选中的数据
          this.selectionDataRows = [...reserves, ...records];
        }
      } else {
        // 取消全选时,直接将翻页数据赋值，当前页数据不用加上
        this.selectionRows = reserves;
        const reservesArr = [];
        for (let i = 0; i < reserves.length; i++) {
          const obj = {};
          obj["fillId"] = reserves[i].fillId;
          if (!this.isBlank(reserves[i].version)) {
            obj["version"] = reserves[i].version;
          }
          reservesArr.push(obj);
        }
        this.selectedDataRowKeys = reservesArr;
      }
    },
    // 数据查看列表---不满足条件的记录禁用复选框
    checkDataMethod ({ row }) {
      // 暂时去掉
      if (row.status != 1 && row.status != 2) {
        return false;
      } else {
        return true;
      }
      return true;
    },
    // 数据查看列表，点击查询事件
    onHandleQueryDataClick () {
      // this.gridOptions.columns = []
      this.gridOptions.data = [];
      this.gridOptions.pagerConfig.currentPage = 1;

      this.$refs.gridTable.clearSelected();

      if (this.$refs.gridTable) this.$refs.gridTable.clearFilters();
      // this.getDataList();
      this.noticeClickNum++;
    },
    // 管理列表，点击查询事件
    gridFilterChanged (filterObjects) {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.data = [];
      let filterData = {};

      if (filterObjects.length > 0) {
        this.clearGlobalSearch();
      }
      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.dataQueryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.dataQueryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "updateTime") {
            let showValue = x.showValue.split("~");
            this.dataQueryParams.updateStartTime = showValue[0] + " 00:00:00";
            this.dataQueryParams.updateEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.dataQueryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.dataQueryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.dataQueryParams.fillStatus = x.value;
          }
          filterData[x.field] = x.value;
        } else {
          filterData[x.field] = x.value;
        }
      });

      if (filterObjects.length > 0) {
        this.dataQueryParams.filterData = filterData;
        this.dataQueryParams.filterObjects = filterObjects;
      } else {
        this.dataQueryParams.filterData = undefined;
        this.dataQueryParams.fillStartTime = undefined;
        this.dataQueryParams.fillEndTime = undefined;
        this.dataQueryParams.updateStartTime = undefined;
        this.dataQueryParams.updateEndTime = undefined;
        this.dataQueryParams.queryStatus = undefined;
        this.dataQueryParams.payStatus = undefined;
      }

      this.getDataList(() => {
        let sortData = this.dataQueryParams.sortData;
        this.restoreFillter(this.$refs.gridTable, filterData, sortData);
        // this.restoreFillter(this.$refs.gridTable, this.dataQueryParams.filterObjects , sortData);
      });

      this.noticeClickNum++;
    },
    gridSortChanged (sortObjects) {
      this.gridOptions.pagerConfig.currentPage = 1;
      this.gridOptions.data = [];

      this.dataQueryParams.sortData = sortObjects;
      this.getDataList(() => {
        // let filterData = this.dataQueryParams.filterData;
        let sortData = this.dataQueryParams.sortData;
        this.restoreFillter(
          this.$refs.gridTable,
          this.dataQueryParams.filterObjects,
          sortData
        );
      });
    },
    gridTableRowEditable ({ row }) {
      return true;
    },
    // 数据查看列表，点击重置事件
    onHandleResetDataClick () {
      this.dataQueryParams.queryStatus = "";
      this.dataQueryParams.payStatus = "";
      this.dataQueryParams.fillStartTime = "";
      this.dataQueryParams.fillEndTime = "";
      this.dataQueryParams.formFieldId = "";
      this.dataQueryParams.fillValue = "";
      this.gridOptions.pagerConfig.currentPage = 1;
      // this.gridOptions.columns = []
      this.gridOptions.data = [];
      //clearFilter 触发页面刷新，不需要再手动加载数据了
      this.$refs.gridTable.clearFilter();
      this.$refs.gridTable.clearSort(true);
      this.$refs.gridTable.clearSelected();
      if (this.$refs.xGrid) {
        this.$refs.xGrid.clearFilter();
        this.$refs.xGrid.clearSelected();
        this.$refs.xGrid.clearCheckboxRow();
      }
      this.selectedDataRowKeys = [];
      //clearFilter 触发页面刷新，不需要再手动加载数据了
      // this.getDataList()
    },
    // 数据查看列表----批量导入他人数据
    onHandleImportNotSelfClick () {
      this.notSelfFillOpen = true;
    },
    handleNotSelfFillFileRequest ({ file }) {
      this.otherFillFile = file;
    },
    // 数据查看列表----模版下载帮助他人批量填报
    onDownloadNotSelfExcelTemplate () {
      fillDownloadOthersExcel(this.formId).then(
        (data) => {
          if (!data) {
            return false;
          }
          // 获得时间戳
          const date = moment().format('YYYYMMDDHHmmss');
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `他人填报数据导入-${date}-${this.formDetail.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.$message.error("下载异常，请稍后再试");
        }
      );
    },
    // 数据查看列表----确认批量导入他人数据
    onConfirmNotSelfUploadFillClick () {
      const _this = this;
      if (_this.isBlank(_this.otherFillFile)) {
        _this.$message({
          message: "请上传文件！",
          type: "warning",
        });
        return;
      }
      _this
        .$confirm(
          "批量导入的数据是否需要填报人确认？选择需要，则经本人确认后才算提交完成。选择不需要，数据直接导入并完成提交。",
          "警告",
          {
            confirmButtonText: "需要",
            cancelButtonText: "不需要",
            type: "warning",
          }
        )
        .then(function () {
          // console.log('点击好的操作')
          return formFillExcelOtherImport(_this.otherFillFile, 1, _this.formId);
        })
        .then((res) => {
          // console.log('成功操作')
          _this.msgSuccess("导入成功");
          _this.notSelfFillOpen = false;
          _this.otherFillFile = null;
          if (_this.$refs.notSelfFillUpload) {
            _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
          }
          _this.cur = 5;

          if (res.data) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = res.data;
          }
          // _this.gridOptions.columns = []
          // _this.gridOptions.data = []
          // _this.getDataList()
        })
        .catch(function (res) {
          //点击为他人导入
          return formFillExcelOtherImport(_this.otherFillFile, 2, _this.formId);
        })
        .then((res) => {
          // console.log('成功操作')
          _this.msgSuccess("导入成功");
          _this.notSelfFillOpen = false;
          _this.otherFillFile = null;
          if (_this.$refs.notSelfFillUpload) {
            _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
          }
          _this.cur = 5;

          if (res.data) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = res.data;
          }

          // _this.gridOptions.columns = []
          _this.gridOptions.data = [];
          _this.getDataList();
        });
    },
    onBatchUpdateDataForExcel () {
      const _this = this;
      if (_this.isBlank(_this.otherFillFile)) {
        _this.$message({
          message: "请上传文件！",
          type: "warning",
        });
        return;
      }
      othersExcelImportUpdate(_this.otherFillFile, _this.formId)
        .then((res) => {
          // console.log('成功操作')
          _this.msgSuccess("导入成功");
          _this.batchUpdateDataForExcel = false;
          _this.otherFillFile = null;
          if (_this.$refs.notSelfFillUpload) {
            _this.$refs.notSelfFillUpload.clearFiles(); // 上传完成后执行清空
          }
          _this.cur = 5;

          if (res.data) {
            _this.notSelfFillFailOpen = true;
            _this.notSelfFillFailUrl = res.data;
          }
          // _this.gridOptions.columns = []
          _this.gridOptions.data = []
          _this.getDataList()
        })
    },
    onCancelNotSelfUploadFillClick () {
      this.notSelfFillOpen = false;
      this.batchUpdateDataForExcel = false;
      this.otherFillFile = null;
      if (this.$refs.notSelfFillUpload) {
        this.$refs.notSelfFillUpload.clearFiles();
      }
    },
    // 新版 获取表单数据统计
    getFormStatics () {
      const _this = this;
      const formId = _this.formId;
      formStatics(formId).then((response) => {
        const data = response.data;
        // if(!_this.isBlank(data.fieldList)) {
        //   for(let i = 0; i < data.fieldList.length; i++) {
        //     let fieldContent = data.fieldList[i].fieldContent
        //     let fieldContentArr = []
        //     for (let j in fieldContent) {
        //       fieldContentArr.push({
        //         name: j,
        //         value: fieldContent[j]
        //       })
        //     }
        //     data.fieldList[i].fieldContentArr = fieldContentArr
        //   }
        // }
        _this.formStatics = data;
      });

      // 统计字段信息
      this.fieldList = [];

      const fieldListPromise = new Promise((resolve, reject) => {
        if (this.formJson.fieldList.length > 0) {
          formFieldStatics(formId).then((res) => {
            const fieldList = [];
            const fieldDataList = res.data;
            console.log("统计字段信息", fieldDataList);
            fieldDataList.forEach((fieldInfo) => {
              fieldList.push({
                title: fieldInfo.fieldTitle,
                fieldId: fieldInfo.fieldId,
                fieldType: fieldInfo.type,
                validNum: fieldInfo.fillNums,
                fieldOptionList: this.getFieldOptionList(fieldInfo),
                fieldOptionLimit: 0,
                echartsType: fieldInfo.type !== "7" ? "pie" : "bar",
              });
            });
            this.fieldList = fieldList;
            resolve()
          });
        }
      })

      // 统计图片选择信息
      this.fieldPicList = [];
      const picFieldListPromise = new Promise((resolve, reject) => {

        // if (this.formJson.fieldList.length > 0) {
        formPicSelFieldStatics(formId).then((res) => {
          const fieldList = [];
          const fieldDataList = res.data;
          console.log("统计图片选择字段信息", fieldDataList);

          Object.keys(fieldDataList).forEach((fieldInfo) => {
            fieldList.push({
              title: fieldDataList[fieldInfo].picSelectName,
              fieldId: fieldInfo,
              fieldType: "图片选择",
              validNum: fieldDataList[fieldInfo].total,
              fieldOptionList: this.getPicFieldOptionList(
                fieldDataList[fieldInfo]
              ),
              echartsType: "pic-bar",
            });
          });
          this.fieldPicList = fieldList;
          console.log("图片统计信息", this.fieldPicList);
          this.$forceUpdate();
          resolve()
        });


      })

      Promise.all([fieldListPromise, picFieldListPromise]).then(() => {
        for (let i = 0; i < this.formJson.fieldList.length; i++) {
          var field = this.formJson.fieldList[i];
          if (field.type === "picture-select") {
            let fieldInfo = this.fieldPicList.find(x => x.fieldId == field.name);
            if (fieldInfo) this.staticFieldList.push(fieldInfo);
          }
          else if (field.type === "select" || field.type === "radio" || field.type === "checkbox") {
            let fieldInfo = this.fieldList.find(x => x.fieldId == field.name);
            if (fieldInfo) this.staticFieldList.push(fieldInfo);
          } else if (field.type === "input" || field.type === 'textarea') {
            if (field.subtype && field.subtype == 'autocode') continue;
            this.staticFieldList.push(field);
          }

        }
        console.log('fieldPicList', this.fieldPicList)
        console.log('fieldPicList', this.fieldList)
      })

    },
    // 补零
    padLeft (number) {
      return number < 10 ? '0' + number : number
    },
    getFormLargeScreen () {
      largeScreenList(this.formId).then((response) => {
        this.largeFormList = response.data;
      });
    },
    showLargeScreen (item) {
      window.open(`/largeScreen.html#/${item.formId}/${item.id}`);
    },
    onCloseNotSelfUploadFillClick () {
      this.notSelfFillOpen = false;
      this.batchUpdateDataForExcel = false;
      this.otherFillFile = null;
      if (this.$refs.notSelfFillUpload) {
        this.$refs.notSelfFillUpload.clearFiles();
      }
    },
    // 转换字段选项数据格式
    getFieldOptionList (fieldInfo) {
      const fieldMap = fieldInfo.fieldContent;
      const validNum = fieldInfo.fillNums;
      const fieldOptionList = [];
      const keys = Object.keys(fieldMap);
      if (keys && keys.length > 0) {
        keys.forEach((key, index) => {
          fieldOptionList.push({
            name: key,
            value: fieldMap[key],
          });
        });
        fieldOptionList.push({
          name: "有效填写人数",
          value: validNum,
          summary: true,
        });
      }
      return fieldOptionList;
    },
    // 转换图片选择字段选项数据格式
    getPicFieldOptionList (fieldInfo) {
      const fieldMap = fieldInfo.picSelectInfoVOMap;
      const validNum = fieldInfo.total;
      let totalSelect = 0;

      const fieldOptionList = [];
      const keys = Object.keys(fieldMap);
      if (keys && keys.length > 0) {
        keys.forEach((key) => {
          totalSelect = totalSelect + fieldMap[key].count;
        });
        keys.forEach((key) => {
          fieldOptionList.push({
            name: fieldMap[key].desc,
            value: fieldMap[key].count,
            thumb: fieldMap[key].thumb,
            url: fieldMap[key].url,
            totalSelect: totalSelect,
          });
        });
        fieldOptionList.push({
          name: "有效填写人数",
          value: validNum,
          summary: true,
        });
      }
      return fieldOptionList;
    },
    getEchartsData (fieldOptionList, maxLength) {
      if (maxLength) {
        return fieldOptionList.filter((option) => !option.summary && option.value > 0).splice(0, maxLength);
      } else {
        return fieldOptionList.filter((option) => !option.summary && option.value > 0);
      }

    },
    getSummaries (param, value) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "有效填写人数";
          return;
        }
        if (index > 1) return;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "0";
        }
      });

      return sums;
    },

    echartsTypeSelect (e) {
      this.$forceUpdate();
    },
    refreshList () {
      if (this.reloadProjectList) this.reloadProjectList();
    },
    showStatusDataImagePreview (item) {
      const viewer = new picViewer();
      viewer.show({ src: item.thumb, url: item.url, alt: item.name }, 0, {
        toolbar: {
          zoomIn: { show: true, size: "large" },
          zoomOut: { show: true, size: "large" },
          rotateLeft: { show: true, size: "large" },
          rotateRight: { show: true, size: "large" },
          flipHorizontal: { show: true, size: "large" },
          flipVertical: { show: true, size: "large" },
          reset: { show: false, size: "large" },
          prev: { show: false, size: "large" },
          play: { show: false, size: "large" },
          next: { show: false, size: "large" },
        },
        navbar: false,
      });
    },
    //填报窗口 数据选择组件弹出选择数据
    showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.show(fillSelectOption, callback);
    },
    showDataAutoSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect (fillSelectOption, callback, search, searchObject) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFillMulti(fillSelectOption, callback, search, searchObject);
    },
    async showDataView (fillSelectOption, fillData, callback) {
      // 加载表单配置数据
      fillSelectOption.formId = fillSelectOption.formId || this.formId;
      const res = await httpGetFormFieldV2(fillSelectOption.formId);
      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });
      Promise.all([loadField, loadData]).then((values) => {
        this.$refs.dlgvFormViewer.showView({
          fillId: fillData.fillId,
          formId: fillSelectOption.formId,
          formJson: values[0],
          formData: values[1],
          showHiddenWidgets: this.cur != 3,
          showField: fillSelectOption.fields,
          agencyId: this.formDetail.agencyId,
          // fillInfo: listType == "MANAGE"?{
          //     fillUserName: row.fillUserName,
          //     fillUserAvatarUrl: row.fillUserAvatarUrl,
          //     fillUserId: response.data.userId,
          //     createTime: row.createTime,
          //     updateTime: row.updateTime,
          //   }: null,
        });
      });
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    formAgencyId () {
      return this.formDetail.agencyId;
    },
    handleFormStopUpdate (isStop) {
      this.formDetail.isStop = isStop;
      this.$forceUpdate();
    },
    returnProjectHome () {
      this.$emit("multipleFormDelete");
    },
    fillUserViewer () {
      this.$refs.fillUserViewer.showFilledUserView();
    },
    unfillUserViewer () {
      this.$refs.fillUserViewer.showUnfilledUserView();
    },
    showUserSelect (selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },
    fileDelete (file) {
      deleleteFile(file.fileId).then((res) => { });
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then((res) => { });
      }
    },
    execRequest (method, url, params) {
      this.loadings["execApi"] = true;
      request({
        url: url,
        method: method,
        params: params,
      })
        .then(() => {
          this.loadings["execApi"] = false;
          this.$message.success("操作成功");
        })
        .error((e) => {
          console.log(e);
          this.loadings["execApi"] = false;
          this.$message.wraning("操作失败");
        });
    },
    isShowAuthReUpdate (row) {
      // 获取数组是否为空
      const isArrayNotEmpty = (arr) => {
        return arr && arr.length !== 0;
      };
      const isAssign = (assign) => {
        if (!assign) return false;

        return assign.userId === this.loginUserId;
      }

      // 通过判断待审核人数组是否为空，判断一共有几级审核
      const totalLevel = isArrayNotEmpty(row.auditAuth3AgencyUserList) ? 3 : isArrayNotEmpty(row.auditAuth2AgencyUserList) ? 2 : isArrayNotEmpty(row.auditAuth1AgencyUserList) ? 1 : 0;
      // 判断当前用户的审核级别
      const auditLevel3 = isAssign(row.auditAuth3Operator)  // 当前用户是三级实际审核人
      const auditLevel2 = isAssign(row.auditAuth2Operator)  // 当前用户是二级实际审核人
      const auditLevel1 = isAssign(row.auditAuth1Operator)  // 当前用户是一级实际审核人

      // 如果没有审核，或者当前用户未参与任何审核，不显示审核修改按钮
      if (totalLevel === 0 || (auditLevel1 === 0 && auditLevel2 === 0 && auditLevel3 === 0)) {
        return false;
      }

      // 当前审核区间
      var currentLevel = 0;
      // 通过判断当前状态是否为审核通过，如果是，则显示修改按钮
      var agencyUserList;


      // 有多级审核，一级审核(通过获不通过)，或仅有一级审核，判断当前用户是否是审核人
      if ((row.status > 3 && row.status <= 7) || (row.status === 14 && totalLevel == 1)) {
        // 一级审核区间
        agencyUserList = row.auditAuth1AgencyUserList;
        currentLevel = 1;

        // 如果是一级审核人
        if (auditLevel1) {
          return true;
        }

      }
      // 有多级审核，二级级审核完成，或仅有一级审核，判断当前用户是否是审核人
      else if ((row.status > 7 && row.status <= 10) || (row.status === 14 && totalLevel == 2)) {
        // 二级审核区间
        agencyUserList = row.auditAuth2AgencyUserList;
        currentLevel = 2;

        // 如果是二级审核人
        if (auditLevel2) {
          return true;
        }

      } else if ((row.status > 10 && row.status <= 13) || (row.status === 14 && totalLevel == 3)) {
        // 三级审核区间
        agencyUserList = row.auditAuth3AgencyUserList;
        currentLevel = 3;

        // 如果是三级审核人
        if (auditLevel3) {
          return true;
        }
      }
      return false;
      // if (row.status === 14) {
      //   if (!!row.auditAuth1AgencyUserList.find(x => x.userId === this.loginUserId)) {
      //     return true;
      //   } else if (!!row.auditAuth2AgencyUserList.find(x => x.userId === this.loginUserId)) {
      //     return true;
      //   } else if (!!row.auditAuth3AgencyUserList.find(x => x.userId === this.loginUserId)) {
      //     return true;
      //   }
      // }

      // if (!agencyUserList) return false;

      // return !!agencyUserList.find(x => x.userId === this.loginUserId)
    },

    // 判断表单字段中是否有多人签名
    isMultiSignInForm () {

      console.log(this.formJson)
    },

    openDraftBox () {
      this.$refs.draft.openDraftBox();
    }
  },
};
</script>

<style lang="scss">
@import url('/static/css/iconfont/iconfont.css');

[v-cloak] {
  display: none;
}

/* table 样式 */
.details-intro table {
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-collapse: collapse;
}

.details-intro table td,
.details-intro table th {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  padding: 10px 5px;
  text-align: center;
}

/* blockquote 样式 */
.details-intro blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
.details-intro code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 10px;
  margin: 0 3px;
  overflow-x: scroll;
}

.details-intro pre code {
  display: block;
}

/* ul ol 样式 */
.details-intro ul,
.details-intro ol {
  margin: 10px 0 10px 20px;
}

.detail-nav {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 56px;
  -webkit-transform: translateZ(0);
  margin: auto;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);

  .detail-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .detail-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 56px;
    font-size: 14px;
    color: #333333;

    .detail-left-logo {
      cursor: pointer;
      font-size: 18px;
      margin-left: 10px;
    }

    .separator {
      width: 1px;
      height: 20px;
      border-right: 1px solid #dbdde7;
      margin: 0 16px;
    }

    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.form-detail-container {
  // position: relative;
  // padding-top: 0;
  // background: #f2f3f4;
  // margin: 10px auto;
  // -webkit-box-shadow: none;
  // box-shadow: none;
  // border-radius: 0;
  height: 100%;
  position: absolute;
  padding-top: 0;
  margin-top: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  top: 60px;
  bottom: 10px;
  right: 0px;
  left: 0px;
  // overflow-y: hidden;
  overflow-y: auto;
  border-radius: 0;

  .forms-content {
    height: 100%;
    max-width: 100%;
    // height: auto;
    margin: 0px auto;
    background: #ffffff;
    padding: 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
    // max-width: 90%;

    .form-cur1-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .form-detail-top-part {
      width: 100%;
      padding: 20px 20px 15px;
      background: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      border-radius: 4px;

      .form-img {
        width: 200px;
        height: 120px;

        img,
        .img {
          width: 100%;
          max-width: 100%;
          height: 120px;
          border-radius: 6px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .form-info {
        width: 100%;
        position: relative;
        padding: 0 0 0 0;
        box-sizing: border-box;
        height: 120px;

        .form-title {
          width: 100%;
          font-size: 16px;
          color: #333333;
          margin: 0px;
          line-height: 22px;
          font-weight: 400;
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          align-items: center;

          .form-title-left {
            flex: 1;
            -webkit-flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            display: -webkit-flex;

            .form-state-tag {
              height: 18px;
              line-height: 18px;

              &.un-start {
                color: #3476f0;
                background-color: #ebf1fe;
                border-color: #d6e4fc;
              }

              &.ing {
                background-color: #effaf5;
                border-color: #dff5eb;
                color: #61cd9d;
              }

              &.stop {
                color: #a5abb1;
                background: #888d931a;
                border-color: #888d9380;
              }
            }
          }

          button {
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            align-items: center;
          }

          .form-share-warp {
            width: 170px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: flex-end;

            .btn-wrapper {
              cursor: pointer;
              display: inline-block;
              margin-left: 8px;

              .btn {
                width: 58px;
                height: 26px;
                font-size: 12px;
                font-weight: 400;
                color: #646d7a;
                line-height: 22px;
                padding: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
              }

              .collect-btn {
                color: #646d7a;

                // border-color: #dbdde7;
                &.active {
                  color: #ffa500;
                  border-color: #ffa500;
                }

                &:hover {
                  border-color: rgba(255, 165, 0, 0.8);
                  background-color: rgba(255, 165, 0, 0.1);
                }

                .collect-icon {
                  margin-right: 8px;
                  font-size: 14px;
                }
              }

              .btn-icon {
                margin-right: 4px;
                font-size: 14px;
              }
            }
          }
        }

        .form-detail-top-bottom {
          position: absolute;
          bottom: 0px;
          width: 100%;

          .form-date {
            width: 100%;
            font-size: 12px;
            height: 22px;
            line-height: 22px;
            color: #a5abb1;

            .fill-date {
              margin: 0;
              padding: 0;

              .is-agency-icon {
                display: inline-block;
                width: 40px;
                height: 16px;
                margin-left: 5px;
                vertical-align: -4px;
              }
            }
          }

          .form-fill-btn {
            position: absolute;
            bottom: 8px;
            right: 0;

            .btn {
              width: 150px;
              height: 40px;
              font-size: 16px;
              background: #3476f0;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          .formStatus {
            position: absolute;
            right: 0;
            bottom: 60px;
          }

          .form-fill {
            width: 100%;
            font-size: 12px;
            line-height: 22px;
            color: #909090;
            margin: 0 0;
          }

          .form-do {
            width: 100%;
            font-size: 14px;
            margin: 15px 0 0;
            text-align: right;
            display: block;

            span {
              margin-left: 10px;
            }
          }
        }
      }

      .form-num-wrap {
        width: 200px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        line-height: 30px;

        .form-total-num {
          font-size: 12px;
          line-height: 16px;
          color: #a5abb1;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          flex: 1;
          -webkit-flex: 1;
          text-align: center;
          justify-content: center;
        }
      }

      .operate-btn {
        margin-left: 0;
        padding: 7px 15px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #3476f0;
        border-color: #3476f0;
      }

      &.webView {
        width: 1280px;
        box-shadow: none;
        border-radius: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .form-info-tab-bottom {
      width: 100%;
      // height: calc(100vh - 315px);
      background: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      border-radius: 4px;
      margin-top: 20px;
      flex: 1;
      padding: 0px 20px;
      .info-tab-content {
        padding: 20px 0px;
        box-sizing: border-box;
      }

      .tab-content-nav {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f2f3f4;
        // padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;

        .content-nav-title {
          color: #646d7a;
          font-size: 14px;
          line-height: 48px;
          font-weight: 500;
          margin-right: 20px;
          display: inline-block;
          position: relative;

          &.active {
            line-height: 48px;
            color: #3476f0;

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              bottom: 0;
              left: 0;
              background: #3476f0;
              border-radius: 3px 3px 0 0;
            }
          }

          svg {
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .details-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    justify-content: space-between;

    .title-span {
      font-size: 14px;
      color: #404040;
      line-height: 20px;
    }

    .title-line {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      -moz-flex: 1;
      flex: 1;
      height: 1px;
      background: #e5e5e5;
      margin-left: 20px;
    }
  }

  .details-intro {
    width: 100%;
    font-size: 14px;
    color: #394349;
    word-break: break-all;
    padding: 0 0px 0px;
    box-sizing: border-box;
  }

  .details-file {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .file-wrapper {
      position: relative;
      width: 752px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 10px;
      padding-left: 8px;
      color: #646d7a;
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }

      .down-icon {
        font-size: 16px;
        color: #3476f0;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -8px;
        display: none;
      }

      &:hover {
        background: rgba(0, 92, 238, 0.07);
      }

      &:hover .down-icon {
        display: block;
      }
    }
  }
}

.el-progress-bar__innerText {
  color: #ebeef5 !important;
  //文字阴影
  text-shadow: 2px 1px 0px #133c88;
}

.form-fill-detail {
  margin: 30px auto;
  width: 800px;

  .fill-detail-item {
    width: 100%;
    margin-bottom: 20px;

    .label-title {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      font-weight: 600;
      color: #394349;
      line-height: 18px;
      padding-bottom: 10px;
    }

    .time-slot-list {
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        width: 23.5%;
        padding: 20px 7px;
        border: 1px solid #ebebeb;
        border-radius: 2px;
        color: #333;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.65);
        -webkit-transition: color 0.2s, background-color 0.3s;
        transition: color 0.2s, background-color 0.3s;
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 2%;
        cursor: pointer;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &.is-disabled {
          color: #333;
          background-color: #f4f4f4;
          cursor: no-drop;
        }

        span {
          display: inline-block;
          width: 100%;
          line-height: 24px;
          font-size: 14px;
        }

        &.active {
          border: 1px solid #409eff;
        }
      }
    }
  }

  .fill-detail-footer {
    width: 100%;
    margin: 60px auto 0;
    text-align: center;
  }

  .edit-detail-item {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    color: #394349;
  }
}

.fontWeight {
  font-size: 12px !important;
  color: #666666 !important;
  font-weight: inherit !important;
}

.fontWeightCur {
  color: #1890ff !important;
  font-weight: inherit !important;
}

.fontWeightHistory {
  color: #999 !important;
  font-weight: inherit !important;
}

.fontNormal {
  color: #606266 !important;
  font-weight: initial !important;
}

.colorBlue {
  color: #409eff !important;
  cursor: pointer;
}

.share-container {
  margin-top: -30px;
  width: 100%;

  p {
    padding: 0;
    margin: 0 auto;
  }

  .shareFillImg {
    display: block;
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }

  .share-lite-contain {
    width: 380px;
    height: 550px;
    background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/canvas_default.png')
      no-repeat top center;
    background-size: 380px 550px;
    padding-top: 63px;
    margin: auto;

    .top-logo {
      width: 100%;
      padding: 25px 0 15px 40px;
      box-sizing: border-box;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 107px;
        height: 29px;
      }
    }

    .share-card {
      width: calc(100% - 50px);
      height: 470px;
      margin: 0 25px 25px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 20px 0;
      position: relative;
      border-radius: 10px;

      .share-form-img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 166px;
        margin: 20px auto 5px;
        border-radius: 10px;
      }

      .share-form-name {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #404040;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .share-form-fill {
        font-size: 12px;
        line-height: 20px;
        color: #909090;
        margin-left: 70px;
        margin-top: 4px;
      }

      .share-form-bottom {
        width: 100%;
        margin-top: 25px;

        .share-form-qr {
          width: 140px;
          height: 140px;
          margin: auto;
          text-align: center;
          display: block;
        }

        .share-form-qr-text {
          width: 100%;
          font-size: 11px;
          line-height: 16px;
          color: #394349;
          text-align: center;
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  .list-share-image {
    width: 380px;
    height: 550px;
    display: block;
    margin: auto;
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /*Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
  }
}

.el-table .cell {
  white-space: nowrap;
  padding: 0 10px;
}

.el-select-dropdown__item {
  max-width: 800px;
  height: auto;
  overflow: inherit;
  white-space: inherit;
  text-overflow: inherit;
}

.el-radio {
  white-space: normal;
  margin-bottom: 10px;
}

.el-checkbox {
  white-space: normal;
  margin-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.data-download-content {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;

  .type-content-default {
    width: 156px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;

    img {
      display: inline-block;
      vertical-align: middle;
      width: 156px;
      height: 120px;
      margin-top: 40px;
    }
  }

  .type-content-page {
    width: 160px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;

    img {
      display: inline-block;
      vertical-align: middle;
      width: 135px;
      height: 126px;
      margin-top: 40px;
    }
  }
}

.aa {
  background: red;
}

/*新版数据统计style*/
.statics-wrapper {
  width: 100%;
  margin-bottom: 20px;

  .statics-title {
    color: #1f2d3d;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 20px;
  }

  .count-chart-container {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    border: 1px solid #e5e5e5;
    padding: 20px 0;
    box-sizing: border-box;

    .count-chart-item {
      text-align: center;
      font-weight: 400;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      flex: 1;
      -webkit-flex: 1;
      user-select: none;

      .current-amount {
        font-size: 30px;
        color: #262a3e;
        margin-bottom: 10px;
      }

      .sum {
        padding-left: 2px;
        color: #b0b0b9;
        font-size: 14px;
      }
    }
  }

  .form-data-list {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;

    .data-list-item {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;

      h3 {
        padding: 0;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #262a3e;

        .num {
          color: #1890ff;
          margin-right: 10px;
        }
      }

      .fieldContent {
        margin-top: 10px;

        .fieldContent-item {
          width: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          align-items: center;
          line-height: 30px;
          font-size: 14px;
          color: #262a3e;

          .field-value {
            color: #898989;
          }
        }
      }

      .statics-echarts-container {
        display: flex;
        flex-direction: row;
      }

      .divPreview {
        height: 30px;
        width: 30px;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .divPreviewName {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .static-title-tabs {
    height: 35px;
    line-height: 35px;

    margin-bottom: 10px;

    .static-tab {
      height: 35px;
      text-align: center;
      display: inline-block;
      padding: 0px 15px;
      cursor: pointer;
      user-select: none;
      border-radius: 5px;

      &:hover {
        color: #1890ff;
      }

      &.active {
        color: #1890ff;
        border-radius: 5px 5px 0px 0px;
        border: solid 1px #dfe4ed;
        background-color: white;
      }

      &.click-tab {
        border: solid 1px #dfe4ed;
        margin-right: 5px;
      }
    }
  }
}

/*预览图片*/
.preview-img-bar {
  width: 100%;
  margin-bottom: 10px;

  span {
    margin-right: 15px;
    line-height: 18px;
    height: 18px;
    display: inline-block;
    cursor: pointer;
  }
}

.show-image {
  width: 100%;
  // height: 100%;
  height: calc(100% - 30px);
  position: relative;
  background-position: center center;
  overflow: auto;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
  }
}

.noticeHTitle {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -30px;
  cursor: pointer;
}

.officialChatImg {
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  right: 50px;
  display: none;
  z-index: 100;
}

.noticeHTitle:hover .officialChatImg {
  display: block;
}

.el-button--cyan {
  background-color: #20b2aa;
  border-color: #20b2aa;
  color: #ffffff;
}

.vxe-cell--title {
  // display: flex;
  // align-items: flex-start;
  // justify-content: center;
  font-size: 12px;
  color: #999999;
}

.jyd-icon-paixu {
  font-size: 14px;
  color: #d3d3d3;
}

.vxe-cell--filter .vxe-filter--btn {
  font-size: 11px;
  color: #d3d3d3;
}

.custom-sort {
  // padding: 0 4px;
  display: inline-block;
  width: 20px;
  height: 22px;
  line-height: 22px;
  text-align: center;
}

.custom-sort.is-order {
  color: #409eff;
}

.vxe-button + .vxe-button {
  margin-left: 0px !important;
}

::v-deep .vxe-table--fixed-wrapper .clearExpandTemplate {
  display: none;
}

.el-dialog-hide-header.el-dialog .el-dialog__header {
  border-bottom: 0px none;
  padding-top: 15px;
}

.el-divider--horizontal {
  margin: 10px 0;
}

.list-search {
  .el-form-item--mini.el-form-item {
    margin-bottom: 0px;
  }
}

.vxe-toolbar {
  height: auto;
}

.audit-form {
  .el-form-item__label {
    line-height: 40px !important;
  }
}
</style>
<style>
.msgShowZIndexTop {
  z-index: 9999 !important;
}
.audit-process-dialog .el-loading-mask {
  bottom: -80px;
  left: -20px;
}
</style>
