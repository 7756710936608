var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100vh", "background-color": "#f4f8fe" } },
    [
      _vm.viewName === "baseView"
        ? [
            _c("div", { staticClass: "detail-nav" }, [
              _c(
                "div",
                { staticClass: "detail-left" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "detail-left-logo",
                      attrs: { to: "/index" },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: {
                          width: "18px",
                          height: "18px",
                          "vertical-align": "-2px",
                        },
                        attrs: { "icon-class": "myHome" },
                      }),
                      _c("span", [_vm._v("首页")]),
                    ],
                    1
                  ),
                  _c("svg-icon", {
                    staticClass: "separator",
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "vertical-align": "-2px",
                    },
                    attrs: { "icon-class": "myRightArrow" },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "headerNow",
                      on: { click: _vm.onProjectNameClick },
                    },
                    [_vm._v(_vm._s(_vm.projectDetail.name))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "detail-right-login",
                  on: { click: _vm.toLogin },
                },
                [
                  _c("span", { staticClass: "right-login-text" }, [
                    _vm._v("您暂未登录，请微信扫码或账号登录"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "mini" } },
                    [_vm._v("登录")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "clearfix app-container app-detail-container" },
              [
                _c("div", { staticClass: "clearfix project-relate-form" }, [
                  _c("div", { staticClass: "relate-form-left" }, [
                    _vm._m(0),
                    _c(
                      "ul",
                      { staticStyle: { padding: "0", margin: "0 0 20px" } },
                      _vm._l(_vm.formList, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "dragItem",
                            class: { active: _vm.cur == index },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "form-name",
                                on: {
                                  click: function ($event) {
                                    return _vm.onNavTabClick(item, index)
                                  },
                                },
                              },
                              [
                                item.icon
                                  ? _c("i", {
                                      class: item.icon,
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "vertical-align": "middle",
                                      },
                                    })
                                  : _c("i", {
                                      staticClass: "el-icon-document",
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "vertical-align": "middle",
                                      },
                                    }),
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "relate-form-right" }, [
                    _vm.cur == null
                      ? _c(
                          "div",
                          { staticClass: "form-info-content" },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "el-row",
                                staticStyle: {
                                  margin: "0px",
                                  padding: "20px 20px 10px 15px",
                                  "background-color": "white",
                                  "border-radius": "4px",
                                  "box-sizing": "border-box",
                                },
                                attrs: { gutter: 20, type: "flex" },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      width: "200px",
                                    },
                                    attrs: { span: 9, xs: 24 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "project-detail-img" },
                                      [
                                        _vm.projectDetail &&
                                        _vm.projectDetail.cover
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.projectDetail.cover,
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      padding: "0 0 0 20px",
                                      position: "relative",
                                      "box-sizing": "border-box",
                                      flex: "1",
                                      "-webkit-flex": "1",
                                      "min-width": "calc(100% - 320px)",
                                    },
                                    attrs: { span: 18, xs: 24 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "project-detail-info" },
                                      [
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "clearfix project-detail-title",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.projectDetail.name)
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "project-detail-top-bottom",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "clearfix project-detail-date",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "create-date",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                          display:
                                                            "inline-block",
                                                        },
                                                      },
                                                      [_vm._v("创建时间")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          margin: "0 10px",
                                                        },
                                                      },
                                                      [_vm._v(":")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.projectDetail
                                                            .createTime
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "clearfix project-detail-date",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "create-date",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                          display:
                                                            "inline-block",
                                                        },
                                                      },
                                                      [
                                                        _vm._v("类"),
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              opacity: "0",
                                                            },
                                                          },
                                                          [_vm._v("类型")]
                                                        ),
                                                        _vm._v("型"),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          margin: "0 10px",
                                                        },
                                                      },
                                                      [_vm._v(":")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v("应用"),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "project-detail-creater",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "creater" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                          display:
                                                            "inline-block",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                            },
                                                          },
                                                          [_vm._v("创")]
                                                        ),
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                              margin: "0 6px",
                                                            },
                                                          },
                                                          [_vm._v("建")]
                                                        ),
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                            },
                                                          },
                                                          [_vm._v("人")]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          margin: "0 10px",
                                                        },
                                                      },
                                                      [_vm._v(":")]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.projectDetail
                                                            .createName
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.projectDetail.agencyId
                                                      ? _c("img", {
                                                          staticClass:
                                                            "is-agency-icon",
                                                          attrs: {
                                                            src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.projectDetail && _vm.projectDetail.details != ""
                              ? _c(
                                  "el-row",
                                  {
                                    staticClass: "el-row",
                                    staticStyle: {
                                      margin: "20px 0",
                                      "background-color": "white",
                                      "border-radius": "4px",
                                      "margin-bottom": "0px",
                                    },
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-content-nav",
                                        style: [
                                          {
                                            display:
                                              _vm.projectDetail.details == "" &&
                                              _vm.formCustomFileList.length == 0
                                                ? "none"
                                                : "block",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "content-nav-title",
                                            class: {
                                              active: _vm.formInfoCur === 1,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onFormInfoTabClick(1)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              staticStyle: {
                                                width: "22px",
                                                height: "22px",
                                                "margin-right": "5px",
                                                "vertical-align": "-5px",
                                              },
                                              attrs: {
                                                "icon-class": "form-detail",
                                              },
                                            }),
                                            _vm._v("应用介绍 "),
                                          ],
                                          1
                                        ),
                                        _vm.projectDetail.fileList &&
                                        _vm.projectDetail.fileList.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-nav-title",
                                                class: {
                                                  active: _vm.formInfoCur === 2,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onFormInfoTabClick(
                                                      2
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticStyle: {
                                                    width: "22px",
                                                    height: "22px",
                                                    "margin-right": "5px",
                                                    "vertical-align": "-5px",
                                                  },
                                                  attrs: {
                                                    "icon-class": "form-file",
                                                  },
                                                }),
                                                _vm._v("附件列表 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm.formInfoCur == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "info-tab-content" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "details-intro" },
                                              [
                                                _c("pre", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.projectDetail.details
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.formInfoCur == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "info-tab-content" },
                                          [
                                            _c("Download", {
                                              attrs: {
                                                fileList:
                                                  _vm.projectDetail.fileList,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c("el-row", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        margin: "0px",
                                        height: "calc(100vh - 255px)",
                                        "margin-top": "20px",
                                        padding: "20px 20px 10px 15px",
                                        "background-color": "white",
                                        "border-radius": "4px",
                                        "box-sizing": "border-box",
                                      },
                                    },
                                    [
                                      _c("el-empty", {
                                        attrs: {
                                          "image-size": 200,
                                          description: "暂无内容",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "form-info-content" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formCur == 1,
                                  expression: "formCur == 1",
                                },
                              ],
                              staticClass: "form-info-tab-content",
                            },
                            [
                              _c("blank-view-bar", {
                                attrs: {
                                  formJson: _vm.formJson,
                                  formDetail: _vm.formDetail,
                                  formId: _vm.formId,
                                },
                                on: { viewChange: _vm.handleViewClick },
                              }),
                              _vm.viewTabName === "viewInfo"
                                ? [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "el-row",
                                        staticStyle: {
                                          margin: "0",
                                          padding: "20px",
                                          "padding-top": "0px",
                                        },
                                        attrs: { gutter: 20, type: "flex" },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              padding: "0",
                                              width: "200px",
                                              height: "120px",
                                            },
                                            attrs: { span: 9, xs: 24 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-img" },
                                              [
                                                _vm.formDetail &&
                                                _vm.formDetail.cover
                                                  ? _c("img", {
                                                      attrs: {
                                                        src:
                                                          _vm.formDetail.cover +
                                                          "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              padding: "0 0 0 20px",
                                              position: "relative",
                                              "box-sizing": "border-box",
                                              flex: "1",
                                              "-webkit-flex": "1",
                                              "min-width": "calc(100% - 320px)",
                                            },
                                            attrs: { span: 18, xs: 24 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-info" },
                                              [
                                                _c(
                                                  "h1",
                                                  {
                                                    staticClass:
                                                      "clearfix form-title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-title-left",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              flex: "1",
                                                              "-webkit-flex":
                                                                "1",
                                                              overflow:
                                                                "hidden",
                                                              "text-overflow":
                                                                "ellipsis",
                                                              "white-space":
                                                                "nowrap",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .name
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width: "60px",
                                                                  "margin-top":
                                                                    "-2px",
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                !_vm.formDetail
                                                                  .isStop
                                                                  ? _c(
                                                                      "el-tag",
                                                                      {
                                                                        staticClass:
                                                                          "form-state-tag stop",
                                                                        attrs: {
                                                                          size: "mini",
                                                                          type: "info",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "已暂停"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "el-tag",
                                                                      {
                                                                        staticClass:
                                                                          "form-state-tag",
                                                                        class:
                                                                          _vm.getTagClass(
                                                                            _vm
                                                                              .formDetail
                                                                              .formStateTxt
                                                                          ),
                                                                        attrs: {
                                                                          size: "mini",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .formDetail
                                                                              .formStateTxt
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticClass:
                                                          "form-num-wrap",
                                                        staticStyle: {
                                                          padding: "0",
                                                          width: "300px",
                                                        },
                                                        attrs: {
                                                          span: 9,
                                                          xs: 24,
                                                          type: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "form-total-num",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-view",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px",
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .viewNum &&
                                                                    _vm
                                                                      .formDetail
                                                                      .viewNum >
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .viewNum /
                                                                        10000 +
                                                                        "W+"
                                                                    : _vm
                                                                        .formDetail
                                                                        .viewNum ==
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .viewNum /
                                                                      10000
                                                                    : _vm
                                                                        .formDetail
                                                                        .viewNum
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "form-total-num",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.toLogin,
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-share",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px",
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .shareNum &&
                                                                    _vm
                                                                      .formDetail
                                                                      .shareNum >
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .shareNum /
                                                                        10000 +
                                                                        "W+"
                                                                    : _vm
                                                                        .formDetail
                                                                        .shareNum ==
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .shareNum /
                                                                      10000
                                                                    : _vm
                                                                        .formDetail
                                                                        .shareNum
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "form-total-num",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.toLogin,
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-star-off",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px",
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .favoriteNum &&
                                                                    _vm
                                                                      .formDetail
                                                                      .favoriteNum >
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .favoriteNum /
                                                                        10000 +
                                                                        "W+"
                                                                    : _vm
                                                                        .formDetail
                                                                        .favoriteNum ==
                                                                      10000
                                                                    ? _vm
                                                                        .formDetail
                                                                        .favoriteNum /
                                                                      10000
                                                                    : _vm
                                                                        .formDetail
                                                                        .favoriteNum
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-detail-top-bottom",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix form-date",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "fill-date",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width: "70px",
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "开始时间"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 10px",
                                                                },
                                                              },
                                                              [_vm._v(":")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .fillStartTime
                                                                    ? _vm
                                                                        .formDetail
                                                                        .fillStartTime
                                                                    : "——"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix form-date",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "fill-date",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width: "70px",
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "截止时间"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 10px",
                                                                },
                                                              },
                                                              [_vm._v(":")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .fillEndTime
                                                                    ? _vm
                                                                        .formDetail
                                                                        .fillEndTime
                                                                    : "——"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix form-date",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fill-date",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width: "70px",
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "填报人数"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 10px",
                                                                },
                                                              },
                                                              [_vm._v(":")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .fillNum !=
                                                                    null
                                                                    ? _vm
                                                                        .formDetail
                                                                        .fillNum +
                                                                        "人可填"
                                                                    : "填报人数无限制"
                                                                )
                                                              ),
                                                            ]),
                                                            _vm.formDetail
                                                              .fillNumPublic
                                                              ? _c("span", [
                                                                  _vm._v(","),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.formDetail
                                                              .fillNumPublic
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .formDetail
                                                                          .completedFillNum !=
                                                                          null
                                                                          ? _vm
                                                                              .formDetail
                                                                              .completedFillNum +
                                                                              "人已填"
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix form-date",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fill-date fl",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  width: "70px",
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-style":
                                                                          "normal",
                                                                      },
                                                                  },
                                                                  [_vm._v("创")]
                                                                ),
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-style":
                                                                          "normal",
                                                                        margin:
                                                                          "0 6px",
                                                                      },
                                                                  },
                                                                  [_vm._v("建")]
                                                                ),
                                                                _c(
                                                                  "i",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-style":
                                                                          "normal",
                                                                      },
                                                                  },
                                                                  [_vm._v("人")]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 10px",
                                                                },
                                                              },
                                                              [_vm._v(":")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .founder
                                                                    ? _vm
                                                                        .formDetail
                                                                        .founder
                                                                    : _vm
                                                                        .formDetail
                                                                        .createName
                                                                )
                                                              ),
                                                            ]),
                                                            _vm.formDetail
                                                              .agencyId
                                                              ? _c("img", {
                                                                  staticClass:
                                                                    "is-agency-icon",
                                                                  attrs: {
                                                                    src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-fill-btn",
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "18px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              size: "medium",
                                                              disabled: true,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.onFillClick,
                                                            },
                                                          },
                                                          [_vm._v("开始填报")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-info-tab-bottom",
                                        style: [
                                          {
                                            borderTop:
                                              _vm.formDetail.details == "" &&
                                              _vm.formCustomFileList.length == 0
                                                ? "none"
                                                : "20px solid #f4f8fe",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tab-content-nav",
                                            style: [
                                              {
                                                display:
                                                  _vm.formDetail.details ==
                                                    "" &&
                                                  _vm.formCustomFileList
                                                    .length == 0
                                                    ? "none"
                                                    : "block",
                                              },
                                            ],
                                          },
                                          [
                                            _vm.formDetail &&
                                            _vm.formDetail.details != ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "content-nav-title",
                                                    class: {
                                                      active:
                                                        _vm.formInfoCur === 1,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onFormInfoTabClick(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "formDetailInfo",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formCustomDetails
                                                      ) + " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.formCustomFileList &&
                                            _vm.formCustomFileList.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "content-nav-title",
                                                    class: {
                                                      active:
                                                        _vm.formInfoCur === 2,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onFormInfoTabClick(
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "formDetailFile",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formCustomFile
                                                      ) + " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.formInfoCur === 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "info-tab-content",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "ql-editor details-intro",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formDetail.details
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.formInfoCur === 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "info-tab-content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "details-file",
                                                  },
                                                  [
                                                    _c("Download", {
                                                      attrs: {
                                                        fileList:
                                                          _vm.formCustomFileList,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.formDetail.details == "" &&
                                        _vm.formCustomFileList.length == 0
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-empty", {
                                                  attrs: {
                                                    "image-size": 200,
                                                    description: "暂无填报内容",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.viewTabName != "viewInfo",
                                      expression: "viewTabName!='viewInfo'",
                                    },
                                  ],
                                  staticStyle: {
                                    margin: "0",
                                    height: "100%",
                                    "flex-grow": "1",
                                    padding: "0px",
                                    "padding-top": "0px",
                                  },
                                },
                                [
                                  _vm.fillGridOptions.columns.length > 0
                                    ? _c("MyVxeTable", {
                                        ref: "fillGridTable",
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "inline-flex",
                                        },
                                        attrs: {
                                          columns: _vm.fillGridOptions.columns,
                                          loading: _vm.fillGridOptions.loading,
                                          data: _vm.fillGridOptions.data,
                                          params: _vm.fillGridOptions.params,
                                          pageConfig:
                                            _vm.fillGridOptions.pagerConfig,
                                          selectAllRowsHandler_:
                                            _vm.GetAllDataRows,
                                          agencyId: _vm.formDetail.agencyId,
                                          formJson: _vm.showAllFieldFormJson,
                                          formDetail: _vm.formDetail,
                                        },
                                        on: {
                                          "page-change":
                                            _vm.handleDataPageChange,
                                          "view-picture":
                                            _vm.onHandlePreviewImg,
                                          "filter-change":
                                            _vm.fillFilterChanged,
                                          "sort-change": _vm.fillSortChanged,
                                          "clear-global-search":
                                            _vm.clearGlobalSearch,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "toolbar_search",
                                              fn: function () {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      "prefix-icon":
                                                        "el-icon-search",
                                                      placeholder:
                                                        "全局搜索...",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      clear:
                                                        _vm.onHandleResetFillClick,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.onHandleQueryFillClick(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fillQueryParams
                                                          .fillValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.fillQueryParams,
                                                          "fillValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fillQueryParams.fillValue",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "status_text",
                                              fn: function () {
                                                return [
                                                  _c("vxe-column", {
                                                    attrs: {
                                                      title: "当前状态",
                                                      width: "150",
                                                      field: "status",
                                                      "header-class-name":
                                                        "sysfield",
                                                      filters: [{ data: {} }],
                                                      "filter-render": {
                                                        name: "filter-status",
                                                      },
                                                      params:
                                                        _vm.filters.status,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var row = ref.row
                                                            return [
                                                              _c("StatusCode", {
                                                                attrs: {
                                                                  statusCode:
                                                                    row.status,
                                                                  row: row,
                                                                  fillStatusCode:
                                                                    row.fillStatus,
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      700667606
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "operate",
                                              fn: function () {
                                                return [
                                                  _c("vxe-column", {
                                                    attrs: {
                                                      type: "operate",
                                                      "min-width": "150",
                                                      "max-width": "150",
                                                      "slots:default":
                                                        "operate",
                                                      fixed: "right",
                                                      "header-class-name":
                                                        "sysfield",
                                                      align: "center",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "header",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _vm._v("操作"),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var row = ref.row
                                                            var rowIndex =
                                                              ref.rowIndex
                                                            return [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    size: "mini",
                                                                    status:
                                                                      "primary",
                                                                    content:
                                                                      "详情",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onViewFillClick(
                                                                          row[
                                                                            "fillId"
                                                                          ],
                                                                          "FILL",
                                                                          row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("详情")]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3580161991
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1362066262
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm.viewName === "webView"
        ? [
            _c("WebViewIndex", {
              attrs: {
                projectDetail: _vm.projectDetail,
                formGroupList: _vm.projectDetail.formGroupList,
                fileList: _vm.formCustomFileList,
                agencyId: _vm.projectDetail.agencyId,
                isLogin: false,
              },
              on: {
                "go-login": function ($event) {
                  _vm.loginDialog.open = true
                },
              },
            }),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "登录",
            visible: _vm.loginDialog.open,
            width: "360px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.loginDialog, "open", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "logo-info" }, [
            _c("img", {
              staticClass: "logo-image",
              attrs: {
                src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png",
              },
            }),
            _vm.isTestVersion
              ? _c("span", { staticStyle: { color: "#f00000" } }, [
                  _vm._v("（测试版）"),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "pop-login-tab" }, [
            _c(
              "div",
              {
                staticClass: "login-tab-item",
                class: { active: _vm.loginCur == 1 },
                on: {
                  click: function ($event) {
                    return _vm.loginTabClick(1)
                  },
                },
              },
              [_vm._v("账号登录")]
            ),
            _c(
              "div",
              {
                staticClass: "login-tab-item",
                class: { active: _vm.loginCur == 2 },
                on: {
                  click: function ($event) {
                    return _vm.loginTabClick(2)
                  },
                },
              },
              [_vm._v("扫码登录")]
            ),
            _c(
              "div",
              {
                staticClass: "login-tab-item",
                class: { active: _vm.loginCur == 3 },
                on: {
                  click: function ($event) {
                    return _vm.loginTabClick(3)
                  },
                },
              },
              [_vm._v("手机号登录")]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loginCur == 1,
                  expression: "loginCur == 1",
                },
              ],
              staticClass: "pop-login-content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  attrs: { model: _vm.loginForm, rules: _vm.loginFormRules },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { prop: "username" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          "auto-complete": "off",
                          placeholder: "请输入账号",
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "30px" },
                      attrs: { prop: "password" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          "auto-complete": "off",
                          placeholder: "请输入密码",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isShowCode
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { prop: "code" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "63%" },
                            attrs: {
                              "auto-complete": "off",
                              placeholder: "请输入验证码",
                            },
                            model: {
                              value: _vm.loginForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "code", $$v)
                              },
                              expression: "loginForm.code",
                            },
                          }),
                          _c("div", { staticClass: "login-code" }, [
                            _c("img", {
                              staticClass: "login-code-img",
                              attrs: { src: _vm.codeUrl },
                              on: { click: _vm.getCode },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "100%", "margin-bottom": "30px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            loading: _vm.loading,
                            size: "medium",
                            type: "primary",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [
                          !_vm.loading
                            ? _c("span", [_vm._v("登 录")])
                            : _c("span", [_vm._v("登 录 中...")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loginCur == 2,
                  expression: "loginCur == 2",
                },
              ],
              staticClass: "pop-login-content",
            },
            [
              _c("img", {
                staticClass: "login-lite-img",
                style: [
                  { width: _vm.isTestVersion ? "210px" : "" },
                  { height: _vm.isTestVersion ? "auto" : "" },
                ],
                attrs: { src: _vm.qrImg },
                on: { click: _vm.refreshQrImg },
              }),
              _vm.isTestVersion
                ? _c(
                    "p",
                    {
                      staticClass: "login-lite-text",
                      staticStyle: { color: "#f00000" },
                    },
                    [_vm._v("测试环境不生成小程序码")]
                  )
                : _c(
                    "p",
                    {
                      staticClass: "login-lite-text",
                      style: { color: _vm.isValid ? "#f00000" : "" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.isValid
                            ? "请使用微信扫码登录"
                            : "二维码已失效，请点击更换"
                        )
                      ),
                    ]
                  ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loginCur == 3,
                  expression: "loginCur == 3",
                },
              ],
              staticClass: "pop-login-content",
            },
            [
              _c(
                "div",
                { staticClass: "phone-login" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "mobileLoginForm",
                      attrs: {
                        model: _vm.mobileLoginForm,
                        rules: _vm.mobileLoginRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "prefix-icon": "el-icon-mobile",
                              placeholder: "请输入手机号",
                            },
                            model: {
                              value: _vm.mobileLoginForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.mobileLoginForm, "mobile", $$v)
                              },
                              expression: "mobileLoginForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "smsCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 120px)" },
                            attrs: {
                              "prefix-icon": "el-icon-message",
                              placeholder: "请输入验证码",
                            },
                            model: {
                              value: _vm.mobileLoginForm.smsCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.mobileLoginForm, "smsCode", $$v)
                              },
                              expression: "mobileLoginForm.smsCode",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "fr send-code" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isGetCode,
                                      expression: "isGetCode",
                                    },
                                  ],
                                  attrs: { plain: "", type: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getMobileLoginCode($event)
                                    },
                                  },
                                },
                                [_vm._v("获取验证码")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isGetCode,
                                      expression: "!isGetCode",
                                    },
                                  ],
                                  attrs: {
                                    plain: "",
                                    type: "primary",
                                    disabled: (_vm.disabled = !_vm.isGetCode),
                                  },
                                },
                                [_vm._v(_vm._s(_vm.count) + "s后重试")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "100%",
                            "margin-bottom": "30px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                loading: _vm.loading,
                                size: "medium",
                                type: "primary",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleMobileLogin($event)
                                },
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleMobileLogin($event)
                                },
                              },
                            },
                            [
                              !_vm.loading
                                ? _c("span", [_vm._v("登 录")])
                                : _c("span", [_vm._v("登 录 中...")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("VFormViewer", { ref: "dlgvFormViewer", attrs: { login: false } }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-tool" }, [
      _c("div", { staticClass: "menu-title-text" }, [_vm._v(" 应用概览 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }