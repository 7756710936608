var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-detail-top-part skeleton" }, [
      _c(
        "div",
        {
          staticClass: "el-row el-row el-row--flex",
          staticStyle: { margin: "0px 0px 10px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-col el-col-7 el-col-xs-24",
              staticStyle: { padding: "0px", width: "200px", height: "120px" },
            },
            [_c("div", { staticClass: "form-img skeleton-color" })]
          ),
          _c(
            "div",
            {
              staticClass: "el-col el-col-19 el-col-xs-24",
              staticStyle: {
                padding: "0px",
                "margin-left": "20px",
                position: "relative",
                width: "100%",
              },
            },
            [
              _c("div", { staticClass: "form-info" }, [
                _c("h1", { staticClass: "clearfix form-title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-title-left",
                      staticStyle: { height: "30px" },
                    },
                    [_c("div", { staticClass: "w200 skeleton-color" })]
                  ),
                  _c("div", { staticClass: "form-share-warp skeleton-color" }, [
                    _c("div", { staticClass: "btn-wrapper" }),
                    _c("div", { staticClass: "btn-wrapper" }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-detail-top-bottom",
                    staticStyle: {
                      top: "40px",
                      height: "80px !important",
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "flex-end",
                    },
                  },
                  [
                    _c("div", { staticClass: " w200 skeleton-color" }),
                    _c("div", {
                      staticClass: " w100 skeleton-color",
                      staticStyle: { height: "30px!important" },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "el-row el-row el-row--flex",
          staticStyle: {
            "margin-left": "0px",
            "margin-right": "0px",
            height: "30px",
            padding: "0px",
            display: "-webkit-flex",
            "align-items": "center",
            "justify-content": "space-between",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "form-num-wrap el-col el-col-8 el-col-xs-24",
              staticStyle: {
                padding: "0px",
                width: "200px",
                height: "30px",
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
              attrs: { type: "flex" },
            },
            [
              _c("div", {
                staticClass: "skeleton-color",
                staticStyle: { width: "60px", height: "30px" },
              }),
              _c("div", {
                staticClass: "skeleton-color",
                staticStyle: { width: "60px", height: "30px" },
              }),
              _c("div", {
                staticClass: "skeleton-color",
                staticStyle: { width: "60px", height: "30px" },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "el-col el-col-9 el-col-xs-16",
              staticStyle: {
                padding: "0px 0px 0px 20px",
                position: "relative",
                "box-sizing": "border-box",
                "flex-grow": "1",
                width: "auto",
              },
            },
            [
              _c("div", {
                staticClass: "skeleton-color w200",
                staticStyle: { width: "350px!important" },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "el-col el-col-9 el-col-xs-8",
              staticStyle: {
                padding: "0px 0px 0px 20px",
                position: "relative",
                "box-sizing": "border-box",
                "flex-grow": "0",
                "flex-shrink": "0",
                "text-align": "right",
              },
            },
            [
              _c("div", { staticClass: "editLinks skeleton-color w100" }, [
                _c("div", { staticClass: "form-settings" }),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }