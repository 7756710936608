import { aliOSSUpload } from "@/utils/oss";
export default {
  created() {},
  data() {
    return {
      uploadCount: 0,
      fileList: [],
      aliOSSUpload: null,
    };
  },
  methods: {
    uploadFile(param) {
      let $this = this;

      const uploadHandler = new Promise(async (resolve, reject) => {
        let file = {
          uid: param.file.uid,
          lastModified: param.file.lastModified,
          name: param.file.name,
          size: param.file.size,
          percent: 0,
          sort: this.fileList.length + 1,
          raw: param.file,
        };

        $this.fileList.push(file);

        if(!this.agencyId){
          this.msgError("未找到agencyId")
        }

        $this.aliOSSUpload = aliOSSUpload(false, {  //OK
          formId: $this.formId,
          agencyId: $this.agencyId,
        });

        $this.handleOnUploadFileChange(file, $this.fileList);
      });
      uploadHandler.abort = () => {
        $this.aliOSSUpload.stop();
      };
      return uploadHandler;
    },
    async handleOnUploadFileChange(file, fileList) {
      this.uploadCount++;

      let uploadConfig = this.aliOSSUpload.getUploadConfig();

      let uploadPath = `fillFile/${uploadConfig.agencyId}/${uploadConfig.formId}/formInfoFiles/`;
      const uploadFile = () => {
        return new Promise((resolve) => {
          this.aliOSSUpload.upload(
            [file.raw],
            uploadPath,
            (uploadResult) => {
              file.url = uploadResult.url;
              resolve(uploadResult.url);
            },
            (progress) => {
              file.percent = (progress * 100).toFixed(0);
            },
            (exceptionResult) => {
              this.uploadCount--;
            }
          );
        });
      };

      Promise.resolve()
        .then(uploadFile)
        .then((uploadResult) => {
          delete file.raw;
          delete file.status;
          delete file.percentage;
          delete file.progress;
          delete file.response;

          this.fileList = [...fileList];
          // this.$forceUpdate();

          this.$emit("saveBtnClick", false);
          this.uploadCount--;
        });
    },
  },
};
