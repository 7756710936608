var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "表单数据",
            visible: true,
            "visible.sync": "isShow",
            width: "340px",
            "append-to-body": "",
            "before-close": _vm.close,
          },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "left", staticStyle: { width: "100%" } },
              [
                _c("v-form-render", {
                  ref: "vformRender",
                  attrs: {
                    formJson: _vm.formJson,
                    formData: _vm.formData,
                    optionData: _vm.optionData,
                    previewState: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "myVxeTable",
                      fn: function (ref) {
                        var fields = ref.fields
                        var datas = ref.datas
                        var isAdmin = ref.isAdmin
                        var viewItem = ref.viewItem
                        var removeItem = ref.removeItem
                        return [
                          _c("fill-select-table-view", {
                            attrs: {
                              fields: fields,
                              datas: datas,
                              isAdmin: isAdmin,
                              viewItem: viewItem,
                              removeItem: removeItem,
                              onHandlePreviewImg: _vm.onHandlePreviewImg,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.isEdit
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.save },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("userSelect", {
        ref: "userSelect",
        attrs: { callback: _vm.userSelectCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }