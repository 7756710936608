var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        display: "flex",
        "flex-direction": "column",
      },
    },
    [
      _c("detail-nav", {
        ref: "detailNav",
        attrs: { breadcrumb: _vm.breadcrumb },
        on: { "login-success": _vm.handleLoginSuccess },
      }),
      false
        ? _c("div", { staticClass: "detail-nav" }, [
            _c(
              "div",
              { staticClass: "detail-left" },
              [
                _c(
                  "router-link",
                  { staticClass: "detail-left-logo", attrs: { to: "/index" } },
                  [
                    _c("svg-icon", {
                      staticStyle: {
                        width: "18px",
                        height: "18px",
                        "vertical-align": "-2px",
                      },
                      attrs: { "icon-class": "myHome" },
                    }),
                    _c("span", [_vm._v("首页")]),
                  ],
                  1
                ),
                _c("svg-icon", {
                  staticClass: "separator",
                  staticStyle: {
                    width: "18px",
                    height: "18px",
                    "vertical-align": "-2px",
                  },
                  attrs: { "icon-class": "myRightArrow" },
                }),
                _c("span", { staticClass: "headerNow" }, [
                  _vm._v(_vm._s(_vm.formDetail.name)),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "app-container form-detail-container",
          staticStyle: { height: "calc(100% - 60px)" },
        },
        [
          _c(
            "div",
            { staticClass: "forms-content", staticStyle: { height: "100%" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cur == 1,
                      expression: "cur == 1",
                    },
                  ],
                  staticStyle: {
                    height: "100%",
                    display: "flex",
                    "flex-direction": "column",
                  },
                },
                [
                  _c("blank-view-bar", {
                    attrs: {
                      formJson: _vm.formFieldList,
                      formDetail: _vm.formDetail,
                      formId: _vm.id,
                    },
                    on: { viewChange: _vm.handleViewClick },
                  }),
                  _vm.viewTabName === "viewInfo"
                    ? _c(
                        "div",
                        { staticClass: "form-detail-top-part" },
                        [
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "el-row",
                                staticStyle: { margin: "0px 0px 20px" },
                                attrs: { gutter: 20, type: "flex" },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      width: "200px",
                                      height: "120px",
                                    },
                                    attrs: { span: 7, xs: 24 },
                                  },
                                  [
                                    _c("div", { staticClass: "form-img" }, [
                                      _vm.formDetail && _vm.formDetail.cover
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.formDetail.cover +
                                                "?x-oss-process=image/resize,m_fixed,h_150,w_300",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      "margin-left": "20px",
                                      position: "relative",
                                    },
                                    attrs: { span: 19, xs: 24 },
                                  },
                                  [
                                    _c("div", { staticClass: "form-info" }, [
                                      _c(
                                        "h1",
                                        { staticClass: "clearfix form-title" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-title-left" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    flex: "1",
                                                    "-webkit-flex": "1",
                                                    overflow: "hidden",
                                                    "text-overflow": "ellipsis",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formDetail.name
                                                    ) + " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "60px",
                                                        "margin-top": "-2px",
                                                        "margin-left": "20px",
                                                      },
                                                    },
                                                    [
                                                      !_vm.formDetail.isStop
                                                        ? _c(
                                                            "el-tag",
                                                            {
                                                              staticClass:
                                                                "form-state-tag stop",
                                                              attrs: {
                                                                size: "mini",
                                                                type: "info",
                                                              },
                                                            },
                                                            [_vm._v("已暂停")]
                                                          )
                                                        : _c(
                                                            "el-tag",
                                                            {
                                                              staticClass:
                                                                "form-state-tag",
                                                              class:
                                                                _vm.getTagClass(
                                                                  _vm.formDetail
                                                                    .formStateTxt
                                                                ),
                                                              attrs: {
                                                                size: "mini",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formDetail
                                                                    .formStateTxt
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-share-warp" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-total-num",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-view",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                      "margin-right": "5px",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .viewNum &&
                                                          _vm.formDetail
                                                            .viewNum > 10000
                                                          ? _vm.formDetail
                                                              .viewNum /
                                                              10000 +
                                                              "W+"
                                                          : _vm.formDetail
                                                              .viewNum == 10000
                                                          ? _vm.formDetail
                                                              .viewNum / 10000
                                                          : _vm.formDetail
                                                              .viewNum
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-total-num",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: { click: _vm.toLogin },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-share",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                      "margin-right": "5px",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .shareNum &&
                                                          _vm.formDetail
                                                            .shareNum > 10000
                                                          ? _vm.formDetail
                                                              .shareNum /
                                                              10000 +
                                                              "W+"
                                                          : _vm.formDetail
                                                              .shareNum == 10000
                                                          ? _vm.formDetail
                                                              .shareNum / 10000
                                                          : _vm.formDetail
                                                              .shareNum
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-total-num",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: { click: _vm.toLogin },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-star-off",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                      "margin-right": "5px",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .favoriteNum &&
                                                          _vm.formDetail
                                                            .favoriteNum > 10000
                                                          ? _vm.formDetail
                                                              .favoriteNum /
                                                              10000 +
                                                              "W+"
                                                          : _vm.formDetail
                                                              .favoriteNum ==
                                                            10000
                                                          ? _vm.formDetail
                                                              .favoriteNum /
                                                            10000
                                                          : _vm.formDetail
                                                              .favoriteNum
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-detail-top-bottom",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix form-date",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "fill-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "70px",
                                                        display: "inline-block",
                                                      },
                                                    },
                                                    [_vm._v("开始时间")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                      },
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .fillStartTime
                                                          ? _vm.formDetail
                                                              .fillStartTime
                                                          : "——"
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix form-date",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "fill-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "70px",
                                                        display: "inline-block",
                                                      },
                                                    },
                                                    [_vm._v("截止时间")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                      },
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .fillEndTime
                                                          ? _vm.formDetail
                                                              .fillEndTime
                                                          : "——"
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix form-date",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fill-date" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "70px",
                                                        display: "inline-block",
                                                      },
                                                    },
                                                    [_vm._v("填报人数")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                      },
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formDetail
                                                          .fillNum != null
                                                          ? _vm.formDetail
                                                              .fillNum +
                                                              "人可填"
                                                          : "填报人数无限制"
                                                      )
                                                    ),
                                                  ]),
                                                  _vm.formDetail.fillNumPublic
                                                    ? _c("span", [_vm._v(",")])
                                                    : _vm._e(),
                                                  _vm.formDetail.fillNumPublic
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formDetail
                                                                .completedFillNum !=
                                                                null
                                                                ? _vm.formDetail
                                                                    .completedFillNum +
                                                                    "人已填"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix form-date",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fill-date fl" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "70px",
                                                        display: "inline-block",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "normal",
                                                          },
                                                        },
                                                        [_vm._v("创")]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "normal",
                                                            margin: "0 6px",
                                                          },
                                                        },
                                                        [_vm._v("建")]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "normal",
                                                          },
                                                        },
                                                        [_vm._v("人")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 10px",
                                                      },
                                                    },
                                                    [_vm._v(":")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formDetail.founder
                                                          ? _vm.formDetail
                                                              .founder
                                                          : _vm.formDetail
                                                              .createName
                                                      )
                                                    ),
                                                  ]),
                                                  _vm.formDetail.agencyId
                                                    ? _c("img", {
                                                        staticClass:
                                                          "is-agency-icon",
                                                        attrs: {
                                                          src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-fill-btn" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    size: "medium",
                                                    disabled: true,
                                                  },
                                                  on: {
                                                    click: _vm.onFillClick,
                                                  },
                                                },
                                                [_vm._v("开始填报")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.viewTabName === "viewInfo"
                    ? _c(
                        "div",
                        {
                          staticClass: "form-info-tab-bottom",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tab-content-nav",
                              style: [
                                {
                                  display:
                                    _vm.formDetail.details == "" &&
                                    _vm.formCustomFileList.length == 0
                                      ? "none"
                                      : "block",
                                },
                              ],
                            },
                            [
                              _vm.formDetail && _vm.formDetail.details != ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "content-nav-title",
                                      class: { active: _vm.formInfoCur === 1 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onFormInfoTabClick(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "formDetailInfo",
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(_vm.formCustomDetails) + " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.formCustomFileList &&
                              _vm.formCustomFileList.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "content-nav-title",
                                      class: { active: _vm.formInfoCur === 2 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onFormInfoTabClick(2)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "formDetailFile",
                                        },
                                      }),
                                      _vm._v(_vm._s(_vm.formCustomFile) + " "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.formInfoCur === 1
                            ? _c("div", { staticClass: "info-tab-content" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "details-intro",
                                    attrs: { vhtml: "formDetail.details" },
                                  },
                                  [
                                    _c("pre", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formDetail.details
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.formInfoCur === 2
                            ? _c("div", { staticClass: "info-tab-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "details-file" },
                                  [
                                    _c("Download", {
                                      attrs: {
                                        fileList: _vm.formCustomFileList,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.viewTabName != "viewInfo",
                          expression: "viewTabName != 'viewInfo'",
                        },
                      ],
                      staticStyle: {
                        margin: "0",
                        height: "100%",
                        "flex-grow": "1",
                        padding: "0px",
                        "padding-top": "0px",
                        "background-color": "white",
                        "border-radius": "10px",
                      },
                    },
                    [
                      _vm.fillGridOptions.columns.length > 0
                        ? _c("MyVxeTable", {
                            ref: "fillGridTable",
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              display: "inline-flex",
                            },
                            attrs: {
                              columns: _vm.fillGridOptions.columns,
                              loading: _vm.fillGridOptions.loading,
                              data: _vm.fillGridOptions.data,
                              params: _vm.fillGridOptions.params,
                              pageConfig: _vm.fillGridOptions.pagerConfig,
                              selectAllRowsHandler_: _vm.GetAllDataRows,
                              agencyId: _vm.formDetail.agencyId,
                              formJson: _vm.showAllFieldFormJson,
                              formDetail: _vm.formDetail,
                            },
                            on: {
                              "page-change": _vm.handleDataPageChange,
                              "view-picture": _vm.onHandlePreviewImg,
                              "filter-change": _vm.fillFilterChanged,
                              "sort-change": _vm.fillSortChanged,
                              "clear-global-search": _vm.clearGlobalSearch,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "toolbar_search",
                                  fn: function () {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          "prefix-icon": "el-icon-search",
                                          placeholder: "全局搜索...",
                                          clearable: "",
                                        },
                                        on: {
                                          clear: _vm.onHandleResetFillClick,
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.onHandleQueryFillClick(
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.fillQueryParams.fillValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fillQueryParams,
                                              "fillValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "fillQueryParams.fillValue",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "status_text",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "当前状态",
                                          width: "150",
                                          field: "status",
                                          "header-class-name": "sysfield",
                                          filters: [{ data: {} }],
                                          "filter-render": {
                                            name: "filter-status",
                                          },
                                          params: _vm.filters.status,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c("StatusCode", {
                                                    attrs: {
                                                      statusCode: row.status,
                                                      row: row,
                                                      fillStatusCode:
                                                        row.fillStatus,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          700667606
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "operate",
                                  fn: function () {
                                    return [
                                      _c("vxe-column", {
                                        attrs: {
                                          type: "operate",
                                          "min-width": "150",
                                          "max-width": "150",
                                          "slots:default": "operate",
                                          fixed: "right",
                                          "header-class-name": "sysfield",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c("span", [_vm._v("操作")]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                var rowIndex = ref.rowIndex
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "mini",
                                                        status: "primary",
                                                        content: "详情",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onViewFillClick(
                                                            row["fillId"],
                                                            "FILL",
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("详情")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3580161991
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1362066262
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("VFormViewer", { ref: "dlgvFormViewer", attrs: { login: false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }