var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            "margin-top": "100px",
            "font-size": "14px",
            color: "#888888",
            "text-align": "center",
            "line-height": "30px",
          },
        },
        [_vm._v(" 正在跳转到小程序"), _c("br"), _vm._v("请稍候... ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }