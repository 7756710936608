<template>
  <div v-cloak style="height: 100vh;background-color: #f4f8fe;">

    <template v-if="viewName==='baseView'">

      <detail-nav :breadcrumb="breadcrumb"></detail-nav>
      <div class="detail-nav" v-if="false">

        <div class="detail-left">
          <router-link class="detail-left-logo" to="/index">
            <!-- <span class="home-icon" @click="this.$router.go(-1)"> -->
            <svg-icon icon-class="myHome" style="width: 18px; height: 18px; vertical-align: -2px;" />
            <!-- </span> -->
            <span>首页</span>
          </router-link>
          <svg-icon icon-class="myRightArrow" style="width: 18px; height: 18px; vertical-align: -2px;" class="separator" />
          <span class="headerNow" @click="onProjectNameClick">{{ projectDetail.name }}</span>
        </div>

        <div class="detail-right-login" @click="toLogin">
          <span class="right-login-text">您暂未登录，请微信扫码或账号登录</span>
          <el-button type="primary" size="mini">登录</el-button>
        </div>

      </div>

      <div class="clearfix app-container app-detail-container">

        <div class="clearfix project-relate-form">

          <div class="relate-form-left">
            <div class="menu-tool">
              <div class="menu-title-text">
                应用概览
              </div>
              <!-- <el-autocomplete v-model="detailFormName" :fetch-suggestions="querySearchName" @select="handleSelect" clearable placeholder="输入表单名称来搜索" :trigger-on-focus="false" size="small" class="nameSearch" style="width: 100%"></el-autocomplete> -->
            </div>
            <ul style="padding: 0; margin: 0 0 20px">
              <li v-for="(item, index) in formList" :class="{ active: cur == index }" class="dragItem" :key="index">
                <!-- <svg-icon icon-class="formO" style="width:20px; height:20px; vertical-align: middle" /> -->
                <span class="form-name" @click="onNavTabClick(item, index)">
                  <i v-if="item.icon" :class="item.icon" style="width:20px; height:20px; vertical-align: middle;"></i>
                  <i v-else class="el-icon-document" style="width:20px; height:20px; vertical-align: middle;"></i>
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </div>

          <!-- <div class="relate-form-center">
          <div></div>
        </div> -->

          <div class="relate-form-right">
            <div class="form-info-content" v-if="cur == null">
              <el-row :gutter="20" class="el-row" type="flex" style="margin: 0px;padding: 20px 20px 10px 15px;background-color: white;border-radius: 4px;box-sizing: border-box;">
                <el-col :span="9" :xs="24" style="padding: 0; width: 200px;">
                  <div class="project-detail-img">
                    <img :src="projectDetail.cover" v-if="projectDetail && projectDetail.cover">
                  </div>
                </el-col>
                <el-col :span="18" :xs="24" style="padding: 0 0 0 20px; position: relative; box-sizing: border-box; flex: 1; -webkit-flex: 1; min-width: calc(100% - 320px);">
                  <div class="project-detail-info">
                    <h1 class="clearfix project-detail-title">
                      <span>{{ projectDetail.name }}</span>
                    </h1>
                    <div class="project-detail-top-bottom">
                      <div class="clearfix project-detail-date">
                        <p class="create-date">
                          <span style="width: 70px; display: inline-block;">创建时间</span>
                          <span style="margin: 0 10px">:</span>
                          <span>{{ projectDetail.createTime }}</span>
                        </p>
                      </div>
                      <div class="clearfix project-detail-date">
                        <p class="create-date">
                          <span style="width: 70px; display: inline-block;">类<i style="opacity: 0;">类型</i>型</span>
                          <span style="margin: 0 10px">:</span>
                          <span>应用</span>
                        </p>
                      </div>
                      <div class="project-detail-creater">
                        <p class="creater">
                          <span style="width: 70px; display: inline-block;">
                            <i style="font-style: normal">创</i>
                            <i style="font-style: normal;margin: 0 6px;">建</i>
                            <i style="font-style: normal">人</i>
                          </span>
                          <span style="margin: 0 10px">:</span>
                          <span>{{ projectDetail.createName }}</span>
                          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="projectDetail.agencyId" />
                        </p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="el-row" v-if="projectDetail && projectDetail.details != ''" style="margin: 0;background-color:white;border-radius:4px;margin: 20px 0;margin-bottom:0px;">
                <div class="tab-content-nav" :style="[{display: projectDetail.details == '' && formCustomFileList.length == 0 ? 'none' : 'block'}]">
                  <div class="content-nav-title" @click="onFormInfoTabClick(1)" :class="{active:formInfoCur===1}">
                    <svg-icon icon-class="form-detail" style="width: 22px; height: 22px; margin-right: 5px; vertical-align: -5px;" />应用介绍
                  </div>
                  <div class="content-nav-title" @click="onFormInfoTabClick(2)" :class="{active:formInfoCur===2}" v-if="projectDetail.fileList && projectDetail.fileList.length > 0">
                    <svg-icon icon-class="form-file" style="width: 22px; height: 22px; margin-right: 5px; vertical-align: -5px;" />附件列表
                  </div>
                </div>
                <div class="info-tab-content" v-if="formInfoCur==1">
                  <div class="details-intro">
                    <pre v-html="projectDetail.details"></pre>
                  </div>
                </div>
                <div class="info-tab-content" v-if="formInfoCur==2">
                  <Download :fileList="projectDetail.fileList"></Download>
                </div>
              </el-row>
              <el-row v-else>
                <div style="margin: 0px;height:calc(100vh - 255px);margin-top:20px;padding: 20px 20px 10px 15px;background-color: white;border-radius: 4px;box-sizing: border-box;">
                  <el-empty :image-size="200" description="暂无内容"></el-empty>
                </div>
              </el-row>
            </div>

            <div class="form-info-content" v-else>
              <div v-show="formCur == 1" class="form-info-tab-content">
                <blank-view-bar :formJson="formJson" :formDetail="formDetail" :formId="formId" @viewChange="handleViewClick"></blank-view-bar>

                <template v-if="viewTabName === 'viewInfo'">
                  <el-row :gutter="20" class="el-row" type="flex" style="margin: 0;padding: 20px;padding-top:0px;">
                    <el-col :span="9" :xs="24" style="padding: 0; width: 200px; height: 120px;">
                      <div class="form-img">
                        <img :src="formDetail.cover + '?x-oss-process=image/resize,m_fixed,h_150,w_300'" v-if="formDetail && formDetail.cover">
                      </div>
                    </el-col>
                    <el-col :span="18" :xs="24" style="padding: 0 0 0 20px; position: relative; box-sizing: border-box; flex: 1; -webkit-flex: 1; min-width: calc(100% - 320px);">
                      <div class="form-info">
                        <h1 class="clearfix form-title">
                          <div class="form-title-left">
                            <span style="flex: 1; -webkit-flex: 1;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;">
                              {{ formDetail.name }}
                              <span style="width: 60px; margin-top: -2px; margin-left: 20px">
                                <el-tag v-if="!formDetail.isStop" size="mini" class="form-state-tag stop" type="info">已暂停</el-tag>
                                <el-tag v-else size="mini" class="form-state-tag" :class="getTagClass(formDetail.formStateTxt)">{{ formDetail.formStateTxt }}</el-tag>
                              </span>
                            </span>

                          </div>
                          <!-- <div class="form-share-warp">
                        <div class="collect" @click="toLogin">
                          <span class="collect-span" :class="[formDetail.favorite ? 'active' : '']">收藏</span>
                        </div>
                        <div class="form-share" @click="toLogin">
                          <span class="share-span">分享</span>
                        </div>
                      </div> -->
                          <el-col :span="9" :xs="24" type="flex" style="padding: 0; width: 300px;" class="form-num-wrap">
                            <span class="form-total-num">
                              <!-- <svg-icon icon-class="form-view" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                              <i class="el-icon-view" style="font-size: 20px;margin-right: 5px;"></i> {{ formDetail.viewNum
                            && formDetail.viewNum > 10000 ? formDetail.viewNum / 10000 + 'W+' : (formDetail.viewNum ==
                              10000 ?formDetail.viewNum / 10000 : formDetail.viewNum)}}
                            </span>
                            <span class="form-total-num" @click="toLogin" style="cursor: pointer;">
                              <!-- <svg-icon icon-class="form-share" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                              <i class="el-icon-share" style="font-size: 20px;margin-right: 5px;"></i> {{ formDetail.shareNum
                            && formDetail.shareNum > 10000 ? formDetail.shareNum / 10000 + 'W+' : (formDetail.shareNum ==
                              10000 ? formDetail.shareNum / 10000 : formDetail.shareNum)}}
                            </span>
                            <span class="form-total-num" @click="toLogin" style="cursor: pointer;">
                              <i class="el-icon-star-off" style="font-size: 20px;margin-right: 5px;"></i>
                              {{ formDetail.favoriteNum && formDetail.favoriteNum > 10000 ? formDetail.favoriteNum / 10000 +
                            'W+' : (formDetail.favoriteNum == 10000 ? formDetail.favoriteNum / 10000 :
                          formDetail.favoriteNum)}}
                              <!-- <svg-icon icon-class="form-collect" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                            </span>
                          </el-col>

                        </h1>
                        <div class="form-detail-top-bottom">
                          <div class="clearfix form-date">
                            <p class="fill-date">
                              <span style="width: 70px; display: inline-block;">开始时间</span>
                              <span style="margin: 0 10px">:</span>
                              <span>{{ formDetail.fillStartTime ? formDetail.fillStartTime : '——' }}</span>
                            </p>
                          </div>
                          <div class="clearfix form-date">
                            <p class="fill-date">
                              <span style="width: 70px; display: inline-block;">截止时间</span>
                              <span style="margin: 0 10px">:</span>
                              <span>{{ formDetail.fillEndTime ? formDetail.fillEndTime : '——' }}</span>
                            </p>
                          </div>
                          <div class="clearfix form-date">
                            <div class="fill-date">
                              <span style="width: 70px; display: inline-block;">填报人数</span>
                              <span style="margin: 0 10px">:</span>
                              <span>{{ formDetail.fillNum != null ? formDetail.fillNum + '人可填' : '填报人数无限制' }}</span>
                              <span v-if="formDetail.fillNumPublic">,</span>
                              <span v-if="formDetail.fillNumPublic">
                                {{ formDetail.completedFillNum != null ? formDetail.completedFillNum + '人已填' : '' }}
                              </span>
                            </div>
                          </div>
                          <div class="clearfix form-date">
                            <div class="fill-date fl">
                              <span style=" width: 70px; display: inline-block;">
                                <i style="font-style: normal">创</i>
                                <i style="font-style: normal;margin: 0 6px;">建</i>
                                <i style="font-style: normal">人</i>
                              </span>
                              <span style="margin: 0 10px">:</span>
                              <span>{{ formDetail.founder ? formDetail.founder : formDetail.createName }}</span>
                              <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="formDetail.agencyId" />
                              <!--                          <span v-else>（个人）</span>-->
                            </div>
                          </div>
                          <div class="form-fill-btn">
                            <el-button type="primary" size="medium" @click="onFillClick" :disabled="true" style="font-size: 18px;">开始填报</el-button>
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <div class="form-info-tab-bottom" :style="[{ borderTop: formDetail.details == '' && formCustomFileList.length == 0 ? 'none' : '20px solid #f4f8fe' }]">
                    <div class="tab-content-nav" :style="[{ display: formDetail.details == '' && formCustomFileList.length == 0 ? 'none' : 'block' }]">
                      <div class="content-nav-title" @click="onFormInfoTabClick(1)" :class="{ active: formInfoCur === 1 }" v-if="formDetail && formDetail.details != ''">
                        <!-- <svg-icon icon-class="form-detail" style="width: 20px; height: 20px; margin-right: 5px; vertical-align: -5px;" />  -->
                        <svg-icon icon-class="formDetailInfo" />{{ formCustomDetails }}
                      </div>
                      <div class="content-nav-title" @click="onFormInfoTabClick(2)" :class="{ active: formInfoCur === 2 }" v-if="formCustomFileList && formCustomFileList.length > 0">

                        <svg-icon icon-class="formDetailFile" />{{ formCustomFile }}
                      </div>
                    </div>
                    <div class="info-tab-content" v-if="formInfoCur === 1">
                      <div class="ql-editor details-intro" v-html="formDetail.details"></div>
                    </div>
                    <div class="info-tab-content" v-if="formInfoCur === 2">
                      <div class="details-file">
                        <Download :fileList="formCustomFileList"></Download>
                      </div>
                    </div>
                    <div v-if="formDetail.details == '' && formCustomFileList.length == 0">
                      <el-empty :image-size="200" description="暂无填报内容"></el-empty>
                    </div>
                  </div>
                </template>
                <div v-show="viewTabName!='viewInfo'" style="margin: 0;height:100%; flex-grow:1; padding: 0px;padding-top:0px;">
                  <MyVxeTable ref="fillGridTable" v-if="fillGridOptions.columns.length>0" :columns="fillGridOptions.columns" :loading="fillGridOptions.loading" :data="fillGridOptions.data" :params="fillGridOptions.params" :pageConfig="fillGridOptions.pagerConfig"
                              :selectAllRowsHandler_="GetAllDataRows" @page-change="handleDataPageChange" @view-picture="onHandlePreviewImg" @filter-change="fillFilterChanged" @sort-change="fillSortChanged" :agencyId="formDetail.agencyId" :formJson="showAllFieldFormJson" :formDetail="formDetail"
                              @clear-global-search="clearGlobalSearch" style="width:100%;height:100%;display:inline-flex">
                    <template #toolbar_search>
                      <el-input v-model="fillQueryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." @clear="onHandleResetFillClick" clearable @keyup.enter.native="onHandleQueryFillClick"></el-input>
                    </template>
                    <template v-slot:status_text>
                      <vxe-column title="当前状态" width="150" field="status" header-class-name="sysfield" :filters="[{ data: {} }]" :filter-render="{ name: 'filter-status' }" :params="filters.status">
                        <template #default="{ row }">
                          <StatusCode :statusCode="row.status" :row="row" :fillStatusCode="row.fillStatus"></StatusCode>
                        </template>
                      </vxe-column>
                    </template>
                    <template #operate>
                      <vxe-column type="operate" min-width="150" max-width="150" slots:default="operate" fixed="right" header-class-name="sysfield" align="center">
                        <template v-slot:header>
                          <span>操作</span>
                        </template>
                        <template #default="{ row, rowIndex }">
                          <el-button type="text" size="mini" status="primary" content="详情" @click="onViewFillClick(row['fillId'], 'FILL', row)">详情</el-button>
                        </template>
                      </vxe-column>
                    </template>
                  </MyVxeTable>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </template>
    <template v-if="viewName==='webView'">
      <WebViewIndex :projectDetail="projectDetail" :formGroupList="projectDetail.formGroupList" :fileList="formCustomFileList" :agencyId="projectDetail.agencyId" :isLogin="false" @go-login="loginDialog.open= true"></WebViewIndex>

    </template>

    <el-dialog title="登录" :visible.sync="loginDialog.open" width="340px" append-to-body 
      custom-class="el-dialog-hide-header login-dialog">
     <div slot="title"></div>
    <LoginDialog @login-success="handleLoginSuccess"></LoginDialog>
    </el-dialog>
    <VFormViewer ref="dlgvFormViewer" :login="false"></VFormViewer>
  </div>
</template>
<script>
import LoginDialog from '@/components/Login/index.vue'
import { getDateDiff } from "@/utils/whale"
import { formDetail, projectDetail, fillDetail, formFieldList, httpViewNum, customFormFieldList, formFilesList, httpGetFormFieldV2 } from "@/api/system/form"
import { removeToken } from "@/utils/auth"
import { deepClone } from "@/utils/deepClone.js";
import { getCodeImg, getQrImg, sendMobileCode } from "@/api/login"
import { unLoginListViews, unlLoginFillDetail, unLoginViewData, viewDetail } from '@/api/system/views.js'

import VFormViewer from "./components/vFormViewer/index.vue";
import StatusCode from "./components/statusCode.vue";
import MyVxeTable from "./components/tables/table.vue";
import viewsMixin from './dataView/viewMixin'
import BlankViewBar from './blankViewBar.vue'
import BlankViewBarMixin from './blankViewBarMixin'
import { mapState } from 'vuex'

import { VIEWTYPE } from '@/utils/enums.js'

import WebViewIndex from '@/views/system/webView/app/index'
import { getAgency } from '@/api/system/agency'

import Download from '@/components/FileDownload/download.vue'

import {
  projectGroup
} from "@/api/system/form";

const TIME_COUNT = 60;
export default {
  name: "multipleDetail",
  components: {
    VFormViewer, MyVxeTable, StatusCode, BlankViewBar,
    LoginDialog,
    WebViewIndex,Download
  },
  mixins: [viewsMixin, BlankViewBarMixin],
  computed: {
    ...mapState({
      viewName: state => state.designView.viewName
    }),
    showAllFieldFormJson: function () {
      return {
        formConfig: this.formJson.formConfig,
        widgetList: this.formJson.widgetList,
        fieldList: this.formJson.fieldList.map(x => { return { ...x, hidden: false } })
      }
    },    
    router(){
        return this.$route.name;
    },
    needLogin(){
        return this.router === 'Detail' || this.router==='multipleFormDetail'
    },
    breadcrumb: function(){
      return [this.projectDetail.name]
    }
  },
  data () {
    return {
      formLoading: false,
      projectId: null,
      projectDetail: {},
      cur: null,
      formInfoCur: 1,
      formList: [],
      formCur: 1,
      formDetail: {},
      formCustomDetails: '',
      formCustomFile: '',
      formCustomFileList: [],
      formFieldList: [],
      formData: {},
      number: null, //计数器
      agencyDeptOptions: [], //组织架构
      provinceList: [], //省市区联动
      cityList: [],
      countyList: [],
      addressFlagArr: [], //省市区联动过滤数组
      props: {
        label: 'label', // 显示的选项
        value: 'label', // 选择的value值
        children: 'children', // 子级属性名
        expandTrigger: 'hover'
      }, // 指定层级属性
      pickerOptions: {
        disabledDate: (time) => {
          let startDateTime = (new Date("1900/1/1")).getTime()
          let endDateTime = (new Date("2100/12/31")).getTime()
          return time.getTime() < startDateTime || time.getTime() > endDateTime
        }
      },
      formId: null,
      detailFormName: '',
      // 新版---登录弹框
      loginDialog: {
        open: false
      },
      loginCur: 1,
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: ""
      },
      loginFormRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" }
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" }
        ],
        code: [{ required: true, trigger: "change", message: "验证码不能为空" }]
      },
      mobileLoginForm: {
        mobile: "",
        smsCode: "",
      },
      mobileLoginRules: {
        mobile: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
        ],
        smsCode: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      isGetCode: true,
      count: 0,
      timer: null,
      isShowCode: false,
      codeUrl: "",
      loading: false,
      qrImg: 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/defaultQr.jpg', // 扫码登录二维码默认图片
      qrTimer: null, // 扫码登录定时器
      isTestVersion: process.env.NODE_ENV === 'development', // 区分正是环境or测试环境
      isValid: false, // 扫码登录二维码是否失效，默认不失效
      viewTabName: 'viewInfo',

      formJson: {},
      currentViewId: '', // 当前选中的视图id
      viewColumns: [],  // 所选视图的表格列
      viewFields: [],  // 所选视图的表单字段
      viewHideFields: [],
      viewReadonlyFields: [],
      viewActions: [], // 视图功能按钮
      viewStyle: {}, // 视图样式
      viewRange: 0, // 视图范围
      showDetailType: -1,  // 显示列表的类型-1 不显示，0视图，3个人填报，4审核列表，5管理列表
    }
  },
  created () {

    this.projectId = this.decode(this.$route.params.id)

    // console.log(this.projectId)

    if (!this.isBlank(this.$route.query.formId)) {
      this.formId = this.decode(this.$route.query.formId)
      // console.log( this.formId)
    }

    this.getProjectDetail()
  },
  mounted () {
    window.addEventListener('scroll', this.initHeight)
  },
  destroyed () {
    window.removeEventListener("scroll", this.initHeight, false)
  },
  watch: {},
  methods: {
    getAgency () {
      getAgency(this.projectDetail.agencyId).then(res => {
        this.logoImg = res.data.leftTopLogo || res.data.logo
        this.$store.dispatch('SetDesignViewLogo', this.logoImg);
      })
    },
    // 应用详情
    getProjectDetail () {
      let _this = this

      projectGroup(_this.projectId).then(response => {
        if (response.code == 200) {
          _this.projectDetail = response.data

          _this.$store.dispatch('EnableDesignView', response.data.enableDesign ? 'webView' : 'baseView');
          _this.getAgency();
          if (!_this.isBlank(_this.formId)) {
            let findFormIndex = _this.formList.findIndex((item) => item.formId == this.formId)
            // console.log('findFormIndex', findFormIndex)
            if (findFormIndex != -1) {
              _this.cur = findFormIndex
              _this.getFormDetail()
            }
          }
          _this.$forceUpdate();
          this.getProjectFiles();
        }
      })

      projectDetail(_this.projectId).then(response => {
        if (response.code == 200) {
          _this.formList = response.data.formList
        }
      })
    },
    // 表单详情
    getFormDetail () {
      let _this = this

      this.getFields();
      formDetail(_this.formId).then(response => {
        let formStateTxt = '' //表单状态（未开始、进行中、已结束）
        let curTime = response.now
        if (response.data.status == '1') {
          formStateTxt = '未发布'
        } else {
          if (!_this.isBlank(response.data.fillStartTime) && !_this.isBlank(response.data.fillEndTime)) {
            if (getDateDiff(response.data.fillStartTime, curTime)) {
              formStateTxt = '未开始'
            } else if (!getDateDiff(response.data.fillStartTime, curTime) && !getDateDiff(response.data.fillEndTime, curTime)) {
              formStateTxt = '已结束'
            } else {
              formStateTxt = '进行中'
            }
          } else {
            formStateTxt = '进行中'
          }
        }
        response.data.formStateTxt = formStateTxt
        _this.formDetail = response.data
        _this.getCustomFormFieldList(_this.formId)
        _this.getFormFiles(_this.formId)
      })
    },
    getFields () {
      // 获取表单配置(表单设计器)数据
      httpGetFormFieldV2(this.formId).then((response) => {
        if (response.data) {
          this.formJson = {
            formConfig: response.data.formConfig,
            widgetList: response.data.widgetList,
            fieldList: response.data.fieldList,
          };
        } else {
          this.formJson = {
            fieldList: [],
          };
        }
        this.formLoading = false;
      });
    },
    getCustomFormFieldList (formId) {
      let _this = this
      customFormFieldList(formId).then(response => {
        if (response.code == 200) {
          _this.formCustomDetails = !_this.isBlank(response.data.details) ? response.data.details : '表单介绍'
          _this.formCustomFile = !_this.isBlank(response.data.file) ? response.data.file : '表单附件'
        }
      })
    },
    getFormFiles (formId) {
      let _this = this
      formFilesList(formId).then(response => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            let suffix = _this.getSuffix(response.data[i].name)
            let suffixType = null
            if (suffix == 'jpeg' || suffix == 'JPEG' || suffix == 'jpg' || suffix == 'JPG' || suffix == 'png' || suffix == 'PNG') {
              suffixType = 'image'
            } else if (suffix == 'doc' || suffix == 'DOC' || suffix == 'docx' || suffix == 'DOCX') {
              suffixType = 'word'
            } else if (suffix == 'exl' || suffix == 'EXL' || suffix == 'xlsx' || suffix == 'XLSX') {
              suffixType = 'excel'
            } else if (suffix == 'pdf' || suffix == 'PDF') {
              suffixType = 'pdf'
            } else if (suffix == 'mp3' || suffix == 'MP3' || suffix == 'wave') {
              suffixType = 'audio'
            } else if (suffix == 'mp4' || suffix == 'MP4' || suffix == 'mov' || suffix == 'm4v' || suffix == 'mpg' || suffix == 'wma' || suffix == 'avi' || suffix == 'flv' || suffix == 'mkv' || suffix == 'rm' || suffix == 'rmvb') {
              suffixType = 'video'
            } else if (suffix == 'zip' || suffix == 'ZIP' || suffix == 'rar' || suffix == 'RAR') {
              suffixType = 'zip'
            } else if (suffix == 'ppt' || suffix == 'PPT' || suffix == 'pptx' || suffix == 'PPTX') {
              suffixType = 'ppt'
            } else {
              suffixType = 'other'
            }
            response.data[i].suffixType = suffixType
          }
          _this.formCustomFileList = response.data
          if (_this.formDetail && _this.formDetail.details != '') {
            _this.formInfoCur = 1
          } else {
            if (_this.formCustomFileList && _this.formCustomFileList.length > 0) {
              _this.formInfoCur = 2
            }
          }
        }
      })
    },
    getSuffix (name) {
      return name.split('.').pop().toLowerCase()
    },
    getProjectFiles () {
      let _this = this;
      for (let i = 0; i < this.projectDetail.fileList.length; i++) {
        let suffix = _this.getSuffix(this.projectDetail.fileList[i].name);
        let suffixType = null;
        if (
          suffix == "jpeg" ||
          suffix == "JPEG" ||
          suffix == "jpg" ||
          suffix == "JPG" ||
          suffix == "png" ||
          suffix == "PNG"
        ) {
          suffixType = "image";
        } else if (
          suffix == "doc" ||
          suffix == "DOC" ||
          suffix == "docx" ||
          suffix == "DOCX"
        ) {
          suffixType = "word";
        } else if (
          suffix == "exl" ||
          suffix == "EXL" ||
          suffix == "xlsx" ||
          suffix == "XLSX"
        ) {
          suffixType = "excel";
        } else if (suffix == "pdf" || suffix == "PDF") {
          suffixType = "pdf";
        } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
          suffixType = "audio";
        } else if (
          suffix == "mp4" ||
          suffix == "MP4" ||
          suffix == "mov" ||
          suffix == "m4v" ||
          suffix == "mpg" ||
          suffix == "wma" ||
          suffix == "avi" ||
          suffix == "flv" ||
          suffix == "mkv" ||
          suffix == "rm" ||
          suffix == "rmvb"
        ) {
          suffixType = "video";
        } else if (
          suffix == "zip" ||
          suffix == "ZIP" ||
          suffix == "rar" ||
          suffix == "RAR"
        ) {
          suffixType = "zip";
        } else if (
          suffix == "ppt" ||
          suffix == "PPT" ||
          suffix == "pptx" ||
          suffix == "PPTX"
        ) {
          suffixType = "ppt";
        } else {
          suffixType = "other";
        }
        this.projectDetail.fileList[i].suffixType = suffixType;
      }
    },
    toLogin () {
      // removeToken()
      // let id = this.projectId
      // this.$router.push({
      //   path: '/login?redirect=/form/multipleFormDetail/' + this.encode(id) + '?formId=' + this.encode(this.formId)
      // })
      this.loginDialog.open = true
    },
    // 左边 表单tab切换
    onNavTabClick (item, index) {
      this.cur = index
      this.formCur = 1
      this.formId = item.formId
      this.formDetail = {}
      this.formFieldList = []

      this.formLoading = true;
      this.getFormDetail()
    },
    onFillClick () {
      this.formCur = 2
    },
    // 新版
    querySearchName (queryString, cb) {
      let _this = this
      let formList = _this.formList
      let res = []
      for (let i = 0; i < formList.length; i++) {
        res.push({
          value: formList[i].name,
          formId: formList[i].formId
        })
      }
      if (queryString != "") {
        setTimeout(() => {
          let callBackArr = []; // 准备一个空数组，此数组是最终返给输入框的数组
          // 这个res是发请求，从后台获取的数据
          res.forEach((item, index) => {
            // 把数据库做遍历，拿用户输入的这个字，和数据库中的每一项做对比
            if (item.value.indexOf(queryString) > -1) { // 大于-1，只要包含就行，不再乎位置
              // 如果有具有关联性的数据
              callBackArr.push(item); // 就存到callBackArr里面准备返回呈现
            }
          });
          // 经过这么一波查询操作以后，如果这个数组还为空，说明没有查询到具有关联的数据，就直接返回给用户暂无数据
          if (callBackArr.length == 0) {
            cb([{ value: "暂无数据", formId: "暂无数据" }]);
          }
          // 如果经过这一波查询操作以后，找到数据了，就把装有关联数据的数组callBackArr呈现给用户
          else {
            cb(callBackArr);
          }

        }, 1000);
      }
    },
    handleSelect (selectItem) {
      console.log(selectItem)
      let formId = selectItem.formId
      let index = this.formList.findIndex((item) => item.formId == formId)
      // console.log('选中索引==', index)
      // 搜索完成后 需要选中某个表单 并知道选中索引 把选中索引赋值给this.cur 并调用 this.getFormDetail()
      this.cur = index
      this.formId = formId
      this.getFormDetail()
    },
    onFormInfoTabClick (index) {
      this.formInfoCur = index
    },
    // 新版 --- 点击导航 项目名称
    onProjectNameClick () {
      this.cur = null
      this.formCur = null
      this.formId = null
      this.getProjectDetail()
    },
    handleLoginSuccess(){
      let _this = this;
      if (!_this.isBlank(_this.formId)) {
        _this.$router.push({
          path: '/form/multipleFormDetail/' + _this.encode(_this.projectId) + '?formId=' + _this.encode(_this.formId)
        })
      } else {
        _this.$router.push({
          path: '/form/multipleFormDetail/' + _this.encode(_this.projectId)
        })
      }
    },
    getTagClass (text) {
      switch (text) {
        case "进行中":
          return "ing";
        case "已结束":
          return "stop";
        default:
          return "un-start";
      }
    },
    onDownloadFormFileClick (item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // 数据查看---上传附件---截取附件名
    getSuffName (url) {
      return !this.isBlank(url)
        ? decodeURIComponent(url).substring(url.indexOf("_") + 1)
        : "";
    },

    handleViewClick (viewName, viewId) {
      this.currentViewId = viewId;
      this.viewTabName = viewName;
      if (!viewId) return;
      Promise.all([this.getFormView(), this.getFormViewListData()]).then(
        (res) => {
        }
      );
    }

  },
}

</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

/* table 样式 */
.details-intro table {
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-collapse: collapse;
}

.details-intro table td,
.details-intro table th {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  padding: 10px 5px;
  text-align: center;
}

/* blockquote 样式 */
.details-intro blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
.details-intro code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 10px;
  margin: 0 3px;
  overflow-x: scroll;
}

.details-intro pre code {
  display: block;
}

.detail-nav {
  width: 100%;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 60px;
  -webkit-transform: translateZ(0);
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);

  .detail-left {
    float: left;
    line-height: 60px;
    font-size: 14px;
    color: #333333;

    .detail-left-logo {
      cursor: pointer;
      color: rgba(43, 52, 65, 0.6);
      font-size: 18px;
      font-weight: 500;

      .home-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-right: 8px;
        border-radius: 50%;
        background: #f5f5f7;
      }
    }

    .separator {
      margin: 0 11px 0 17px;
    }

    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .detail-right {
    height: 60px;
    position: absolute;
    right: 80px;
    top: 0;

    .form-share {
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      line-height: 60px;
      cursor: pointer;
      display: inline-block;
      margin-left: 20px;

      .share-span {
        background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/share.png')
          no-repeat left center;
        background-size: 24px 24px;
        padding-left: 30px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
      }
    }

    .collect {
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      line-height: 60px;
      cursor: pointer;
      display: inline-block;

      .collect-span {
        background: url(https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect.png)
          no-repeat left center;
        background-size: 24px 24px;
        padding-left: 30px;
        height: 40px;
        line-height: 40px;
        display: inline-block;

        &.active {
          background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collected.png')
            no-repeat left center;
          background-size: 24px 24px;
          padding-left: 30px;
          height: 40px;
          line-height: 40px;
          display: inline-block;
        }
      }
    }
  }

  .detail-right-login {
    display: flex;
    display: -webkit-flex;
    height: 60px;
    line-height: 60px;
    align-items: center;
    float: right;

    .right-login-text {
      margin-right: 20px;
    }
  }

  .form-fill-btn {
    line-height: 60px;
  }
}

.app-detail-container {
  overflow: hidden;
  height: calc(100vh - 80px);
  margin: 16px auto;
  background: #f4f8fe;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
  border-radius: 10px;
  padding-top: 0;

  .project-detail-img {
    img {
      width: 100%;
      max-width: 100%;
      border-radius: 6px;
    }
  }

  .project-detail-info {
    .project-detail-title {
      width: 100%;
      font-size: 22px;
      font-weight: 400;
      color: #404040;
      margin: 0 0 35px;
      line-height: 30px;

      span {
        max-width: 100%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .project-detail-top-bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .project-detail-date {
        width: 100%;
        font-size: 14px;
        height: 22px;
        line-height: 22px;
        margin: 0;
        color: #a5abb1;

        .create-date {
          margin: 0;
          padding: 0;
        }
      }

      .project-detail-creater {
        width: 100%;
        font-size: 14px;
        height: 22px;
        line-height: 22px;
        margin: 0;
        color: #a5abb1;

        .creater {
          margin: 0;
          padding: 0;

          .is-agency-icon {
            display: inline-block;
            width: 48px;
            height: 18px;
            margin-left: 5px;
            vertical-align: -3px;
          }
        }
      }
    }
  }

  .project-intro-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;

    .title-span {
      font-size: 14px;
      color: #404040;
      line-height: 20px;
    }

    .title-line {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      -moz-flex: 1;
      flex: 1;
      height: 1px;
      background: #e5e5e5;
      margin-left: 20px;
    }
  }

  .project-intro-con {
    width: 100%;
    font-size: 14px;
    color: #394349;
    word-break: break-all;
  }

  .project-relate-form {
    width: 100%;
    margin: 0px auto;
    box-sizing: border-box;
    display: flex;

    .relate-form-left {
      background: #ffffff;
      border-radius: 4px;
      justify-content: space-between;
      width: 280px;
      height: 100%;
      margin-right: 16px;
      box-sizing: border-box;

      // min-height: 1000px;
      // border-right: 1px solid #DEE1E7;
      .menu-tool {
        font-weight: 700;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px 15px 15px 20px;
        box-sizing: border-box;
        border-bottom: solid 1px #eff1f8;

        .menu-title-text {
          width: 80px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
          overflow: hidden;
          position: relative;
          padding-left: 10px;
          color: #232425;

          &::after {
            content: '';
            position: absolute;
            width: 4px;
            height: 15px;
            top: 50%;
            left: 0;
            margin-top: -7.5px;
            background: #3476f0;
            border-radius: 0 3px 3px 0;
          }
        }

        .nameSearch {
          flex: 1;
          -webkit-flex: 1;
          margin-right: 10px;
          transition: width 2s;
        }
      }

      .relate-form-list-title {
        height: 40px;
        line-height: 40px;
        margin: 0 0 10px;
        padding: 0;
        background: #f0f2f5;
        font-size: 14px;
        color: #404040;
        text-align: center;
      }

      .dragItem {
        width: 100%;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        list-style: none;
        color: #1f2d3d;
        font-size: 14px;
        font-weight: 400;
        padding: 0 12px 0 20px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        display: -webkit-flex;
        align-items: center;

        .form-name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          width: 238px;
        }

        .form-more-icon {
          cursor: pointer;
          position: absolute;
          right: 12px;
          display: none;
        }

        &.active {
          background: #e8f4ff;
          color: #3476f0;
          position: relative;
          font-weight: 500;
        }

        &:hover {
          background: #e8f4ff;
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .more-dialog {
          position: absolute;
          right: 30px;
          min-width: 140px;
          max-width: 140px;
          background: #fff;
          padding: 5px;
          -webkit-box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
          box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
          -webkit-transition: 0.3s all ease;
          transition: 0.3s all ease;
          cursor: pointer;
          top: 20px;
          z-index: 10;
          margin: 0 0 0 -70px;

          .x-menu-item {
            color: #1f2d3d;
            font-size: 14px;
            text-align: center;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background: #e8f4ff;
              border-radius: 3px;
            }
          }
        }
      }
    }

    .relate-form-right {
      // max-width: calc(100% - 300px);
      min-width: calc(100% - 300px);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      // min-height: 500px;
      justify-content: space-between;
      overflow-y: auto;

      .form-nav-ul {
        height: 40px;
        line-height: 40px;
        margin: auto;
        padding: 0;
        width: 100%;
        text-align: center;
        background: #f0f2f5;

        li {
          list-style: none;
          display: inline-block;
          font-size: 14px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          margin-right: 60px;
          font-size: 14px;
          color: #bababa;

          &.active {
            color: #394349;
            position: relative;

            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 100px;
              left: 50%;
              bottom: 0;
              height: 4px;
              background: #0078f8;
              -webkit-transform: scaleY(0.5);
              transform: scaleY(0.5);
              z-index: 1;
              margin-left: -50px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .form-info-content {
        height: 100%;
        max-width: 100%;
        box-sizing: border-box;

        .form-info-tab-content {
          width: 100%;
          height: 100%;
          min-height: calc(100vh - 80px);
          padding: 0;
          box-sizing: border-box;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .form-info-tab-bottom {
            width: 100%;
            .info-tab-content {
              // height: calc(100vh - 300px);
              overflow: auto;
            }

            .tab-content-nav {
              padding: 0 16px;
              width: 100%;
              display: flex;
              display: -webkit-flex;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #f2f3f4;
              // padding: 0 20px;
              box-sizing: border-box;
              padding: 0px 10px;
              cursor: pointer;

              .content-nav-title {
                font-size: 16px;
                margin-right: 30px;
                display: inline-block;

                &.active {
                  color: #3476f0;
                  border-bottom: 3px solid #3476f0;
                  line-height: 44px;

                  .svg-cion {
                    color: #3476f0;
                  }
                }
              }
            }
          }
        }

        .form-img {
          img {
            width: 100%;
            max-width: 100%;
            height: 120px;
            border-radius: 6px;
          }
        }

        .form-info {
          width: 100%;
          position: relative;
          padding: 0 0 0 0;
          box-sizing: border-box;
          height: 120px;

          .form-title {
            width: 100%;
            font-size: 20px;
            color: #404040;
            margin: 0;
            line-height: 20px;
            font-weight: 400;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            align-items: center;

            .form-title-left {
              flex: 1;
              -webkit-flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              display: -webkit-flex;
            }

            button {
              display: flex;
              display: -webkit-flex;
              flex-direction: row;
              align-items: center;
            }

            .form-share-warp {
              width: 170px;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              justify-content: flex-end;

              .collect {
                font-size: 14px;
                font-weight: 400;
                color: #909090;
                line-height: 30px;
                cursor: pointer;
                display: inline-block;

                .collect-span {
                  background: url(https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collect.png)
                    no-repeat left center;
                  background-size: 24px 24px;
                  padding-left: 30px;
                  height: 30px;
                  line-height: 30px;
                  display: inline-block;

                  &.active {
                    background: url(https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/collected.png)
                      no-repeat left center;
                    background-size: 24px 24px;
                    padding-left: 30px;
                    height: 30px;
                    line-height: 30px;
                    display: inline-block;
                  }
                }
              }

              .form-share {
                font-size: 14px;
                font-weight: 400;
                color: #909090;
                line-height: 30px;
                cursor: pointer;
                display: inline-block;
                margin-left: 20px;

                .share-span {
                  background: url(https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/share.png)
                    no-repeat left center;
                  background-size: 24px 24px;
                  padding-left: 30px;
                  height: 30px;
                  line-height: 30px;
                  display: inline-block;
                }
              }
            }
          }

          .form-detail-top-bottom {
            margin-top: 2px;
            width: 100%;

            .form-date {
              width: 100%;
              font-size: 14px;
              height: 22px;
              line-height: 22px;
              margin: 0;
              color: #a5abb1;

              .fill-date {
                margin: 0;
                padding: 0;

                .is-agency-icon {
                  display: inline-block;
                  width: 48px;
                  height: 18px;
                  margin-left: 5px;
                  vertical-align: -3px;
                }
              }
            }

            .form-fill-btn {
              position: absolute;
              bottom: 8px;
              right: 10px;
            }

            .formStatus {
              position: absolute;
              right: 0;
              bottom: 60px;
            }

            .form-fill {
              width: 100%;
              font-size: 12px;
              line-height: 22px;
              color: #909090;
              margin: 0 0;
            }

            .form-do {
              width: 100%;
              font-size: 14px;
              margin: 15px 0 0;
              text-align: right;
              display: block;

              span {
                margin-left: 10px;
              }
            }
          }
        }

        .form-num-wrap {
          width: 300px;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          line-height: 30px;

          .form-total-num {
            font-size: 16px;
            color: #909090;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            flex: 1;
            -webkit-flex: 1;
            text-align: center;
            justify-content: center;

            &:hover {
              color: #3476f0;
            }
          }
        }

        .details-title {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin: 0;
          justify-content: space-between;

          .title-span {
            font-size: 14px;
            color: #404040;
            line-height: 20px;
          }

          .title-line {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            -o-flex: 1;
            -moz-flex: 1;
            flex: 1;
            height: 1px;
            background: #e5e5e5;
            margin-left: 20px;
          }
        }

        .details-intro {
          width: 100%;
          font-size: 14px;
          color: #394349;
          word-break: break-all;
          padding: 10px 16px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.info-tab-content {
  padding: 20px 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.share-container {
  margin-top: -30px;
  width: 100%;

  p {
    padding: 0;
    margin: 10px auto;
  }

  .shareFillImg {
    display: block;
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }

  .share-lite-contain {
    width: 375px;
    height: 553px;
    background: #0078f8
      url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/shareBcg.png')
      no-repeat 220px 25px;
    background-size: 121px 78px;
    box-shadow: 0px 5px 20px 0px rgba(57, 67, 73, 0.2);

    .top-logo {
      width: 100%;
      padding: 25px 0 25px 25px;
      box-sizing: border-box;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 144px;
        height: 40px;
      }
    }

    .share-card {
      width: calc(100% - 50px);
      height: 438px;
      margin: 0 25px 25px;
      box-sizing: border-box;
      background: #ffffff;
      padding: 20px;
      position: relative;

      .share-form-img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 190px;
        margin-bottom: 20px;
      }

      .share-form-name {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #394349;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .share-form-fill {
        font-size: 13px;
        font-weight: 400;
        color: #394349;
        line-height: 18px;
      }

      .share-form-bottom {
        width: 285px;
        height: 100px;
        padding: 10px 15px;
        box-sizing: border-box;
        background: #f0f2f5;
        position: absolute;
        bottom: 20px;

        .share-form-qr {
          display: inline-block;
          width: 80px;
          height: 80px;
          vertical-align: middle;
        }

        .share-form-qr-text {
          font-size: 11px;
          font-weight: 400;
          color: #394349;
          line-height: 16px;
          text-align: right;

          span {
            display: block;
          }
        }
      }
    }
  }
}

.el-table .cell {
  white-space: nowrap;
  padding: 0 10px;
}

/*新版---登录弹框*/
.el-dialog {
  border-radius: 6px;
}

.form-state-tag {
  height: 18px;
  line-height: 18px;

  &.un-start {
    color: #3476f0;
    background-color: #ebf1fe;
    border-color: #d6e4fc;
  }

  &.ing {
    background-color: #effaf5;
    border-color: #dff5eb;
    color: #61cd9d;
  }

  &.stop {
    color: #a5abb1;
    background: #888d931a;
    border-color: #888d9380;
  }
}

//应用信息的切换
.tab-content-nav {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f2f3f4;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;

  .content-nav-title {
    color: #333;
    font-size: 15px;
    line-height: 20px;
    // font-weight: 500;
    margin-right: 20px;
    display: inline-block;
    position: relative;
    svg {
      // font-size: 18px;
      margin-right: 4px;
    }
  }
}

:v-deep .details-file {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  p,
  a {
    cursor: pointer;
    font-size: 13px;
    margin: 0 0 10px;
    color: #394349;
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      margin-left: 5px;
    }
  }
}
:v-deep .details-file {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .file-wrapper {
    position: relative;
    width: 752px;
    height: 40px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #646d7a;
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
    .down-icon {
      font-size: 16px;
      color: #3476f0;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      display: none;
    }
    &:hover {
      background: rgba(0, 92, 238, 0.07);
    }
    &:hover .down-icon {
      display: block;
    }
  }
}

.view-table {
  height: 100%;
  flex: 1;
}

.form-view-table {
  width: 100%;
  .el-table td.el-table__cell {
    border-bottom: 0px none;
    padding: 4px 0px;
  }
}

// 调整登录框的弹出框
.el-dialog.login-dialog{
  .el-dialog__header{
    height:0px;
    padding: 0px;
  }
  .el-dialog__headerbtn{
    z-index: 100;
  }
  .el-dialog__body{
    padding: 0px;
  }
}
</style>
