export const deepClone = function (origin) {
    if (origin === undefined) {
      return undefined
    }

    return JSON.parse(JSON.stringify(origin))
  }


/**
 * 判断真假，排除0为false的情况
 * @param {object} value
 * @returns
 */
export const isFalsy = (value) => value === 0 ? false : !value;

export const cleanObject = (object) => {
  const result = { ...object }
  Object.keys(result).forEach(key => {
    const value = result[key]
    if (isFalsy(value)) {
      delete result[key]
    }
  })
  return result
}


