<template>
  <div>
    <div style="position:absolute;color:white;z-index:100000;top:0px;bottom:0px;right:0px;left:0px;background-color:rgba(0,0,0,0.25);display:flex;justify-content:center;align-items:center;">
      <div style="text-align:center;line-height:100vw;width:100vh;height:100vw;display:inline-block;color:#333333">签名已在PC端保存，如需签名请重新扫码</div>
    </div>
  </div>
</template>
<script>
const websocketUrl = process.env.VUE_APP_WEBSOCKET_BASE_URL;

export default {
  data () {
    return {
      qrcode: '',
      random: ''
    }
  },
  created () {
  },
  mounted () {
  },
  beforeUnmount () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
</style>
