var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-node-btn-box" }, [
    _c(
      "div",
      { staticClass: "add-node-btn" },
      [
        _c(
          "el-popover",
          {
            attrs: { placement: "right-start" },
            model: {
              value: _vm.visible,
              callback: function ($$v) {
                _vm.visible = $$v
              },
              expression: "visible",
            },
          },
          [
            _c("div", { staticClass: "add-node-popover-body" }, [
              _c(
                "a",
                {
                  staticClass: "add-node-popover-item approver",
                  on: {
                    click: function ($event) {
                      return _vm.addType(2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-wrapper" }, [
                    _c("span", { staticClass: "iconfont" }, [_vm._v("")]),
                  ]),
                  _c("p", [_vm._v("审核人")]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "add-node-popover-item condition",
                  on: {
                    click: function ($event) {
                      return _vm.addType(3)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-wrapper" }, [
                    _c(
                      "span",
                      {
                        staticClass: "iconfont",
                        staticStyle: { color: "#ccc" },
                      },
                      [_vm._v("")]
                    ),
                  ]),
                  _c("p", [_vm._v("条件分支")]),
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "add-node-popover-item notifier",
                  on: {
                    click: function ($event) {
                      return _vm.addType(4)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-wrapper" }, [
                    _c("span", { staticClass: "iconfont" }, [_vm._v("")]),
                  ]),
                  _c("p", [_vm._v("查看人")]),
                ]
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: { slot: "reference", type: "button" },
                slot: "reference",
              },
              [_c("span", { staticClass: "iconfont" }, [_vm._v("")])]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }