var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "views-tabs block-container" },
    [
      _vm.formDetail.enableView
        ? _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleViewClick },
              model: {
                value: _vm.viewTabName,
                callback: function ($$v) {
                  _vm.viewTabName = $$v
                },
                expression: "viewTabName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { name: "viewInfo" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v(" 概览 "),
                ]),
              ]),
              _vm._l(_vm.viewList, function (item, index) {
                return _c(
                  "el-tab-pane",
                  { key: index, attrs: { name: "viewId:" + index } },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        : _c("div", { staticStyle: { height: "15px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }