<template>
  <div v-if="!query.token">
    
  </div>
</template>

<script>
import { getUserInfo } from "@/api/system/user"
import Cookies from "js-cookie"

export default {
  name: "Login",
  data () {
    return {

    }
  },

  created () {
    const { params, query } = this.$route

    if (query.token) {

      Cookies.set("User-Token", query.token)

      if (!query.token) {
        this.$router.push({ path: '/404' });
      }

      getUserInfo().then((user) => {
        if (user && user.code === 200) {
          this.$router.push({ path: query.redirect || "/index" });
        } else {
          this.$router.push({ path: "/401" });
        }
      })
    }

  },
  methods: {
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/loginBcg.png');
  background-size: cover;
}
.title {
  margin: 0px auto 20px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 340px;
  height: 502px;
  position: relative;
  .logo-info {
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
    .logo-image {
      width: 144px;
      height: 40px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .login-container {
    width: 100%;
    .login-tab {
      width: 100%;
      margin: 0;
      padding: 0;
      line-height: 32px;
      border-bottom: 1px solid #e5e5e5;
      height: 32px;
      cursor: pointer;
      li {
        display: inline-block;
        width: 50%;
        text-align: center;
        list-style: none;
        font-size: 16px;
        font-weight: 400;
        color: #bababa;
        &.active {
          color: #0078f8;
          position: relative;
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 128px;
            left: 50%;
            bottom: 0px;
            height: 4px;
            background: #0078f8;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
            z-index: 1;
            margin-left: -64px;
          }
        }
      }
    }
    .code-login {
      width: 100%;
      padding: 30px 35px 0;
      box-sizing: border-box;
    }
    .qr-login {
      width: 100%;
      margin: 0 auto;
      padding: 60px 0 40px;
      img {
        display: block;
        width: 166px;
        height: 166px;
        text-align: center;
        margin: auto;
        cursor: pointer;
      }
      p {
        margin: 20px auto 0;
        padding: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .el-input {
    height: 32px;
    input {
      height: 32px;
      line-height: 32px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 32px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 32px;
}
/*注册*/
.register-form {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .register-select {
    margin: 0 auto 20px;
  }
  .send-code {
    width: 100px;
    height: 32px;
  }
}
</style>
