<template>
  <div class="app-detail-container">

  </div>
</template>

<script>
import axios from 'axios'
import {getToken} from "@/utils/auth";
import { httpViewNum} from '@/api/system/form';
export default {
  name: "Detail",
  components: {},
  data() {
    return {
      id: null, // 应用id or 表单id
      type: null, // 类型 project：应用 form：表单
      isProjectForm: false, // 是否是应用内的表单
      projectFormId: null // 应用内的表单id
    }
  },
  created() {
    this.id = this.decode(this.$route.params.id)

    if (!this.isBlank(this.$route.query.type)) {
      this.type = this.decode(this.$route.query.type)
    }

    if (this.type == 'form') {
      this.setViewNum(this.id)
    }

    if (!this.isBlank(this.$route.query.formId)) {
      this.isProjectForm = true
      this.projectFormId = this.decode(this.$route.query.formId)
      this.setViewNum(this.projectFormId)
    }

    // console.log('this.$route.query.formId', this.projectFormId)

    this.linkTab()
  },
  mounted() {

  },
  methods: {
    linkTab() {
      let _this = this
      let goUrl = _this.isMobile()
      if (goUrl) {
        // 移动端地址
        _this.$router.push({
          path: '/h5Detail/' + (_this.isProjectForm ? _this.encode(_this.projectFormId) : _this.encode(this.id)),
          query: {
            type:  _this.isProjectForm ? _this.encode('form') : _this.encode(this.type)
          }
        })
      } else {
        if (getToken()) {
          let baseURL =  process.env.VUE_APP_BASE_API + '/user'
          axios.get(baseURL, {
            headers:{ Authorization: getToken() }
          })
            .then((res) => {
              if (res.data.code == 200) {
                let path = ''
                if (_this.type == 'project') {
                  if (!_this.isBlank(_this.projectFormId)) {
                    path = '/form/multipleFormDetail/' + _this.encode(_this.id) + '?formId=' + _this.encode(this.projectFormId)
                  } else {
                    path = '/form/multipleFormDetail/' + _this.encode(_this.id)
                  }
                } else if(_this.type == 'form') {
                  if (_this.isBlank(_this.projectFormId)) {
                    path = '/form/detail/' + _this.encode(_this.id)
                  } else {
                    path = '/form/multipleFormDetail/' + _this.encode(_this.id) + '?formId=' + _this.encode(this.projectFormId)
                  }
                }
                _this.$router.push({
                  path: path
                })
              } else {
                if (_this.type == 'project') {
                  if (_this.isBlank(_this.projectFormId)) {
                    _this.$router.push({
                      path: '/projectBlankDetail/' + _this.encode(_this.id)
                    })
                  } else {
                    _this.$router.push({
                      path: '/projectBlankDetail/' + _this.encode(_this.id) + '?formId=' + _this.encode(this.projectFormId)
                    })
                  }
                } else if(_this.type == 'form') {
                  _this.$router.push({
                    path: '/blankDetail/' + _this.encode(_this.id)
                  })
                }
              }
            })
        } else {
          if (_this.type == 'project') {
            if (_this.isBlank(_this.projectFormId)) {
              _this.$router.push({
                path: '/projectBlankDetail/' + _this.encode(_this.id)
              })
            } else {
              _this.$router.push({
                path: '/projectBlankDetail/' + _this.encode(_this.id) + '?formId=' + _this.encode(this.projectFormId)
              })
            }
          } else {
            this.$router.push({
              path: '/blankDetail/' + _this.encode(_this.id)
            })
          }
        }

      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    setViewNum(id) {
      httpViewNum(id).then(response => {})
    },
  }
}
</script>
