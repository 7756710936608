<template>
  <div>
    <el-dialog title="表单数据" :visible="true" visible.sync="isShow" width="340px" append-to-body :before-close="close">
      <div class="container">
        <div class="left" style="width:100%">
          <v-form-render ref="vformRender" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false">
            <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
              <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
            </template>
          </v-form-render>
          <div v-if="isEdit" style="text-align:center">
            <el-button type="primary" size="small" @click="save">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- <vFormInsideViewer ref="dlgvFormViewer"></vFormInsideViewer> -->
    <userSelect ref="userSelect" :callback="userSelectCallback"></userSelect>
  </div>
</template>
<script>
import { editFill } from '@/api/system/form'
import { getSessionStorageObj } from '@/utils/db'
import { httpGetFormFieldV2, fillDetail } from '@/api/system/form'
import userSelect from '@/views/system/mechan/components/user-select.vue'

import FillSelectTableView from '@/views/system/form/components/fillSelector/tableView'
export default {
  data () {
    return {
      formJson: {},
      formData: {},
      optionData: {},
      userSelectCallback: null,
    }
  },
  components: { userSelect, FillSelectTableView },
  methods: {
    show (fillId, formId, showHiddenWidgets) {
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        enableForm: "enableForm"
      });
    },
    showInside (fillId, formId, formJson, data, showHiddenButtons, showFields,formDetail) {
      this.$refs.dlgvFormViewer.showView({
        fillId, 
        formId, 
        formJson,
        formData: data,
        showHiddenWidgets: showHiddenButtons,
        showFields: showFields,
        formDetail: formDetail
      })
    },
    // async getWaterMark(fileName,fieldId,watermark, ossAddress){
    //   return getWaterMarkFile({
    //     fileName: fileName,
    //     agencyId: this.agencyId,
    //     fieldId, watermark,
    //     formId: this.formDetail.formId,
    //     ossAddress,
    //   })
    // }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: inline-flex;
  position: relative;
}
.left {
  display: inline-block;
  height: 100%;
  width: 740px;
  flex-grow: 1;
  /* margin-right: 220px; */
}
.right {
  width: 200px;
  height: 100%;
  flex-shrink: 0;
  padding: 10px;
  border-left: solid 1px #efefef;
  box-sizing: border-box;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
}
.log-title {
  height: 30px;
  line-height: 30px;
}
</style>
