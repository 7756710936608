<template>
  <div class="right-menu">
    <!-- 通知 -->
    <div clas="right-menu-agency" style="" v-if="agencyList && agencyList.length > 0">
      <el-dropdown @command="handleCommand" trigger="click">
        <router-link to="/index" @click.native="saveDefaultRouer('/index')">
          <span class="menu-agency-name">{{agencyName}}</span>
          <i class="el-icon-arrow-down el-icon--right" style="color: #fff"></i>
        </router-link>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,index) in agencyList" :key="index" :command="item.agencyId">{{item.agencyName}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="right-menu-item hover-effect">
      <el-popover placement="bottom" width="336" popper-class="el-popper-none" trigger="click" v-model="noticeVisible">
        <el-card :body-style="{ padding: '0px' }" shadow="never">
          <div slot="header" class="clearfix">
            <el-dropdown @command="handleNoticeList">
              <span v-if="noticeType === 0" class="el-dropdown-link">
                <i class="el-icon-bell"></i> 全部通知<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <span v-if="noticeType === 1" class="el-dropdown-link">
                <i class="el-icon-message"></i> 未读通知<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-bell" command="0">全部通知</el-dropdown-item>
                <el-dropdown-item icon="el-icon-message" command="1">未读通知</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <i class="el-icon-close" @click="noticeVisible = false" style="float: right; padding: 0px"></i>

            <el-dropdown @command="moreOption" style="float: right; padding: 0px 10px">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1" icon="el-icon-finished">标记所有已读</el-dropdown-item>
                <el-dropdown-item command="2" icon="el-icon-delete">删除所有已读消息</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <ul class="infinite-list" :infinite-scroll-disabled="infiniteScrollDisabled"  v-infinite-scroll="()=>{ queryParams.pageNum++; selectNotice() }" style="overflow: auto">
              <li v-for="(item,idx) in noticeList" :key="idx" @click="selectNoticeById(item.noticeId)" :class="['infinite-list-item', item.unRead === false ? 'infinite-list-item-read' : '',]">
                <div class="list-item-meta">
                  <div class="list-item-meta-avatar">
                    <el-avatar :size="30" :src="circleUrl"></el-avatar>
                  </div>
                  <div class="list-item-meta-content">
                    <h4 class="list-item-meta-title">{{ item.noticeTitle }}</h4>
                    <div class="list-item-meta-description">
                      <div>
                        <div>{{ item.noticeContent }},</div>
                        <!-- 跳转到表单的个人填报列表 -->
                        <router-link v-if="item.noticeType == 1" :to="{path: '/form/detail/' + encodeId(item.formId), query:{cur:1}}">
                          <h4>点击查看详情</h4>
                        </router-link>
                        <!-- 跳转到定向通知详情 -->
                        <router-link v-if="item.noticeType == 2" :to="{path: '/form/detail/' + encodeId(item.formId)}">
                          <h4>点击查看详情</h4>
                        </router-link>
                        <!-- 跳转到对应表单概览页 -->
                        <!-- <router-link v-if="item.noticeType == 3" :to="{path: '/form/multipleFormDetail/MTU5NDUyNDUxOTczOTk0OTA1Ng==', query:{formId:encodeId(item.formId)}}"> -->
                        <router-link v-if="item.noticeType == 3" :to="{path: '/form/multipleFormDetail/' + encodeId(item.projectId), query:{formId:encodeId(item.formId)}}">
                          <h4>点击查看详情</h4>
                        </router-link>
                        <div class="datetime">
                          {{ formatCreateTime(item.createTime) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="noticeList.length==0" class="infinite-list-item infinite-list-item-empty">
                暂无通知
              </li>
            </ul>
          </div>
        </el-card>
        <div slot="reference">
          <el-badge :value="unReadCount" :hidden="unReadCount === 0" :max="10" class="item">
            <i class="el-icon-bell" :style="{color: iconColor}"></i>
          </el-badge>
        </div>
      </el-popover>
    </div>
    <slot name="right-menu-middle"></slot>
    <div class="right-menu-item hover-effect">
      <template v-if="viewName=='webView'">
        <div class="avatar-container">
        <div class="avatar-wrapper"  @click="gotoMyForm">
          <img :src="userInfo.avatarUrl ? userInfo.avatarUrl + '?x-oss-process=image/resize,m_fixed,h_100,w_100' : defaultAvatarUrl" class="user-avatar"/>
        </div>
        </div>
      </template>
      <template v-else>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img :src="userInfo.avatarUrl ? userInfo.avatarUrl + '?x-oss-process=image/resize,m_fixed,h_100,w_100' : defaultAvatarUrl" class="user-avatar"/>
          <!-- <span class="user-name" :style="{color: nameColor}">您好，{{ agencyUser ? agencyUser.name : userInfo.nickName }}</span> -->
          <svg-icon icon-class="navbarDown" class="icon-navbar-down" :style="{color: iconColor}" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import { userAgencyMyList } from "@/api/system/user"
import { getAgencyUser } from '@/api/system/agency'
import { allRead, getNotice, listNotice, removeAllRead, unReadCount } from '@/api/system/notice'
import { globalBus } from '@/utils/globalBus'
import { setSessionStorageObj, getSessionStorageObj } from "@/utils/db";
import { mapGetters,mapState } from "vuex";
import moment from 'moment'

export default {
  name: 'TitleUserMenu',
  props: {
    nameColor: {
      type: String,
      default: '#FFFFFFCC'
    },
    iconColor: {
      type: String,
      default: '#FFFFFF99'
    },
    userData: Object
  },
  data () {
    return {
      noticeVisible: false,
      noticeType: 0,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // 通知公告表格数据
      noticeList: [],
      // 未读数
      unReadCount: 0,
      defaultAvatarUrl: require('../../assets/images/user-avatar-default.png'),
      userInfo: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        unRead: null,
        status: null,
      },
      agencyName: '',
      agencyList: [],
      agencyUserData: {
        name: ''
      },
      infiniteScrollDisabled: false
    }
  },
  created () {
    this.getUserInfo();
    this.selectNotice();
    this.selectUnReadCount();
    //在个人中心修改头像时 需要同步修改
    globalBus.$on("refreshUser", (data) => {
      this.getUserInfo();
    });
    this.getuserAgencyMyList();
  },
  computed: {
    ...mapGetters(["agencyUser"]),
    
    ...mapState({
      viewName: state => state.designView.viewName
    }),
  },
  methods: {
    encodeId: function (id) {
      return this.encode(id)
    },
    getUserInfo () {
      if (this.userData) {
        this.userInfo = this.userData;
      } else {
        this.$store.dispatch("GetUserInfo").then((res) => {
          this.userInfo = res.data;
        });
      }
      this.$store.dispatch("GetCurrentAgencyUser").then((res) => { });
    },
    handleNoticeList (command) {
      if (command == 1) {
        this.noticeType = 1
        this.queryParams.unRead = true
      } else {
        this.noticeType = 0
        this.queryParams.unRead = null
      }
      this.selectNotice(this.queryParams)
    },
    /**
     * 查询所有通知
     */
    selectNotice () {
      this.loading = true;
      listNotice(this.queryParams).then((response) => {
        this.noticeList = response.rows;
        if(this.noticeList.length == 0 ){
          this.infiniteScrollDisabled = true
        } else {
          this.infiniteScrollDisabled = false
        }
        this.total = response.total;
        this.loading = false;
      });
    },
    selectNoticeById (noticeId) {
      getNotice(noticeId).then((res) => {
        this.selectNotice(this.queryParams);
        if (this.unReadCount !== 0) {
          this.unReadCount = this.unReadCount - 1;
        }
      });
    },
    moreOption (command) {
      if (command === "1") {
        allRead().then(res => {
          this.selectNotice();
          this.unReadCount = 0
        });
      } else if (command === "2") {
        removeAllRead().then(res => {
          this.selectNotice();
        });
      }
    },
    selectUnReadCount () {
      unReadCount().then((res) => {
        this.unReadCount = res.data;
      });
    },
    async logout () {
      // 获取 agencyUserIds
      var userAgencyIds = (getSessionStorageObj('users').agencyIds||'').split(',')

      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          if (userAgencyIds.find(x => x == '32' || x== '16')) {

            // dom里添加一个a标签，退出登录
            let a = document.createElement("a");

            if(process.env.NODE_ENV === 'development'){
              a.href = "https://ssotest.open.com.cn/cas/logout?service=" + encodeURIComponent("https://form.jingyudao.net/login");
            }else{
              a.href = "https://auth.cqrk.edu.cn/cas/logout?service=" + encodeURIComponent("https://form.jingyudao.cn/login");
            }
            a.click();
          } else {

            this.$router.push({ path: "/login" });
          }
          //location.reload();
        });
      });
    },
    async getuserAgencyMyList () {
      let currAgencyId = getSessionStorageObj('agencyId')
      // let agencyUserRes = await getAgencyUser(currAgencyId)
      // this.agencyUserData = agencyUserRes.data;
      userAgencyMyList().then(res => {
        // console.log('getuserAgencyMyList', res.data)
        this.agencyList = res.data
        let agency = this.agencyList.find(x => x.agencyId == currAgencyId)
        // console.log('agency===',agency)

        if (agency) this.agencyName = agency.agencyName
        else if (this.agencyList.length > 0) this.agencyName = this.agencyList[0].agencyName;
        // 2023-06-27 lxq
        // if (this.agencyList && this.agencyList.length > 0)  {
        //   if (agency) this.logoImg = agency.agencyName
        //   else this.logoImg = this.agencyList[0].agencyName
        // }
      })
    },
    //保存登录后默认的跳转
    saveDefaultRouer (tag) {
      localStorage.setItem('defaultRouter', tag)
    },
    handleCommand (command) {
      setSessionStorageObj('agencyId', Number(command))
      this.$router.push({ path: '/index', query: { agencyId: command } })
      this.$router.go(0)
    },
    formatCreateTime(value){
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    gotoMyForm(){
      console.log('1231231')
      this.$router.push({ path: '/myForm'})
    }
  },
}
</script>

<style lang="scss" scoped>
.right-menu {
  width: auto;
  height: 56px;
  line-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  flex-shrink: 0;
  flex-grow: 0;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    display: inline-block;
    margin-right: 15px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);

    &.hover-effect {
      cursor: pointer;
      vertical-align: middle;
      transition: background 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }

    &:last-of-type {
      padding-right: 0px;
    }

    ::v-deep .el-badge {
      padding: 0px 10px;
      .el-badge__content.is-fixed {
        top: 10px;
        right: 18px;
      }
    }
  }
  .avatar-container {
    .avatar-wrapper {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-avatar {
        cursor: pointer;
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }

      .user-name {
        margin: 0 8px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
      }

      .icon-navbar-down {
        cursor: pointer;
        font-size: 20px;
        margin-right: -5px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
.infinite-list {
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;

  .infinite-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    border-bottom: 1px solid #eaeefb;
    cursor: pointer;
  }
  .infinite-list-item:hover {
    background: #e6f7ff;
    cursor: pointer;
  }
  .infinite-list-item-read {
    opacity: 0.4;
  }
  .infinite-list-item-empty {
    color: #c0c0c0;
  }

  .list-item-meta {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    max-width: 100%;
    padding: 5px 10px;

    .list-item-meta-avatar {
      margin-right: 16px;
      margin-top: 4px;
    }
    .list-item-meta-content {
      flex: 1 0;
      width: 0;
      color: rgba(0, 0, 0, 0.85);
      h4 {
        margin-top: 5px;
        margin-bottom: 4px;
      }
      .list-item-meta-title {
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 400;
      }
      .list-item-meta-description {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 1.5715;
      }
    }
  }
}
.menu-agency-name {
  color: #fff;
}
</style>
