var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "linkText", on: { click: _vm.exportExcel } }, [
        _vm._v("导出并下载EXCEL"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }