<template>
  <div class="add-node-btn-box">
    <div class="add-node-btn">
      <el-popover placement="right-start" v-model="visible">
        <div class="add-node-popover-body">
          <a class="add-node-popover-item approver" @click="addType(2)">
              <div class="item-wrapper">
                  <span class="iconfont"></span>
              </div>
              <p>审核人</p>
          </a>
          <a class="add-node-popover-item condition" @click="addType(3)">
            <div class="item-wrapper">
              <span class="iconfont" style="color: #ccc;"></span>
            </div>
            <p>条件分支</p>
          </a>
          <a class="add-node-popover-item notifier" @click="addType(4)">
            <div class="item-wrapper">
                <span class="iconfont"></span>
            </div>
            <p>查看人</p>
          </a>
        </div>
        <button class="btn" type="button" slot="reference">
            <span class="iconfont"></span>
        </button>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
    props: ["childNodeP", 'isEdit'],
    data() {
        return {
            visible: false
        }
    },
    methods: {
      addType(type) {
        if(this.isEdit) {
          this.$message({
            message: '暂不支持修改权限设置～',
            type: 'warning'
          })
          return false
        }
        this.visible = false
        if (type != 5) {
            var data;
            if (type == 2) {
              data = {
                "nodeName": "审核人",
                "error": true,
                "type": 2,
                "agencyDeptRoleId": '',
                "agencyDeptRole": [],
                "agencyUserId": '',
                "agencyUser": [],
                "userId": '',
                "workFlowNode": this.childNodeP,
              }
              this.$emit("update:childNodeP", data)
            } else if (type == 4) {
              // this.$message({
              //   message: '正在建设开发中， 敬请期待～',
              //   type: 'warning'
              // })
              data = {
                "nodeName": "查看人",
                "type": 4,
                "agencyDeptRoleId": '',
                "agencyDeptRole": [],
                "agencyUserId": '',
                "agencyUser": [],
                "userId": '',
                "workFlowNode": this.childNodeP,
              }
              this.$emit("update:childNodeP", data)
            }
        } else {
          this.$message({
            message: '正在建设开发中， 敬请期待～',
            type: 'warning'
          })
          // this.$emit("update:childNodeP", {
          //   "nodeName": "路由",
          //   "type": 5,
          //   "workFlowNode": null,
          //   "conditionNodes": [{
          //       "nodeName": "条件1",
          //       "error": true,
          //       "type": 3,
          //       "priorityLevel": 1,
          //       "conditionList": [],
          //       "nodeUserList": [],
          //       "workFlowNode": this.childNodeP,
          //   }, {
          //       "nodeName": "条件2",
          //       "type": 3,
          //       "priorityLevel": 2,
          //       "conditionList": [],
          //       "nodeUserList": [],
          //       "workFlowNode": null
          //   }]
          // })
        }
      }
    }
}
</script>
