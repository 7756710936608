<template>
<div>
    <div style="width:100%;margin-top:100px;font-size:14px;color:#888888;text-align:center;line-height:30px">
        正在跳转到小程序<br>请稍候...
    </div>
    <!-- code: {{code}}
    <el-button type="primary" @click="onAppletLiteClick">点击进入小程序</el-button>
    response: {{res}} -->
    </div>
</template>
<script>
import { generateSSOScheme } from '@/api/tool/wechat.js'
export default {
    data(){
        return {
            code:'',
            res:{}
        }
    },
    mounted(){
        this.code =this.$route.query.code;        
        this.onAppletLiteClick(this.code)
    },
    methods:{
        onAppletLiteClick(token){
            generateSSOScheme('sdwu',{
                code: this.code
            }).then(res=>{
                this.res=res;
                if(res.code == 200){
                    window.location.href = res.data
                }
            }).catch(e=>{
                console.log(e)
                this.res=e;
            })
        }
    }
}
</script>
