var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-menu" },
    [
      _vm.agencyList && _vm.agencyList.length > 0
        ? _c(
            "div",
            { attrs: { clas: "right-menu-agency" } },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/index" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.saveDefaultRouer("/index")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "menu-agency-name" }, [
                        _vm._v(_vm._s(_vm.agencyName)),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                        staticStyle: { color: "#fff" },
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.agencyList, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item.agencyId } },
                        [_vm._v(_vm._s(item.agencyName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu-item hover-effect" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                width: "336",
                "popper-class": "el-popper-none",
                trigger: "click",
              },
              model: {
                value: _vm.noticeVisible,
                callback: function ($$v) {
                  _vm.noticeVisible = $$v
                },
                expression: "noticeVisible",
              },
            },
            [
              _c(
                "el-card",
                {
                  attrs: { "body-style": { padding: "0px" }, shadow: "never" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "el-dropdown",
                        { on: { command: _vm.handleNoticeList } },
                        [
                          _vm.noticeType === 0
                            ? _c("span", { staticClass: "el-dropdown-link" }, [
                                _c("i", { staticClass: "el-icon-bell" }),
                                _vm._v(" 全部通知"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ])
                            : _vm._e(),
                          _vm.noticeType === 1
                            ? _c("span", { staticClass: "el-dropdown-link" }, [
                                _c("i", { staticClass: "el-icon-message" }),
                                _vm._v(" 未读通知"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ])
                            : _vm._e(),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: { icon: "el-icon-bell", command: "0" },
                                },
                                [_vm._v("全部通知")]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    icon: "el-icon-message",
                                    command: "1",
                                  },
                                },
                                [_vm._v("未读通知")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { float: "right", padding: "0px" },
                        on: {
                          click: function ($event) {
                            _vm.noticeVisible = false
                          },
                        },
                      }),
                      _c(
                        "el-dropdown",
                        {
                          staticStyle: { float: "right", padding: "0px 10px" },
                          on: { command: _vm.moreOption },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("i", { staticClass: "el-icon-more" }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: "1",
                                    icon: "el-icon-finished",
                                  },
                                },
                                [_vm._v("标记所有已读")]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: "2",
                                    icon: "el-icon-delete",
                                  },
                                },
                                [_vm._v("删除所有已读消息")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "infinite-scroll",
                            rawName: "v-infinite-scroll",
                            value: function () {
                              _vm.queryParams.pageNum++
                              _vm.selectNotice()
                            },
                            expression:
                              "()=>{ queryParams.pageNum++; selectNotice() }",
                          },
                        ],
                        staticClass: "infinite-list",
                        staticStyle: { overflow: "auto" },
                        attrs: {
                          "infinite-scroll-disabled":
                            _vm.infiniteScrollDisabled,
                        },
                      },
                      [
                        _vm._l(_vm.noticeList, function (item, idx) {
                          return _c(
                            "li",
                            {
                              key: idx,
                              class: [
                                "infinite-list-item",
                                item.unRead === false
                                  ? "infinite-list-item-read"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.selectNoticeById(item.noticeId)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "list-item-meta" }, [
                                _c(
                                  "div",
                                  { staticClass: "list-item-meta-avatar" },
                                  [
                                    _c("el-avatar", {
                                      attrs: { size: 30, src: _vm.circleUrl },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "list-item-meta-content" },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "list-item-meta-title" },
                                      [_vm._v(_vm._s(item.noticeTitle))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "list-item-meta-description",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(item.noticeContent) + ","
                                              ),
                                            ]),
                                            item.noticeType == 1
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        path:
                                                          "/form/detail/" +
                                                          _vm.encodeId(
                                                            item.formId
                                                          ),
                                                        query: { cur: 1 },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v("点击查看详情"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.noticeType == 2
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        path:
                                                          "/form/detail/" +
                                                          _vm.encodeId(
                                                            item.formId
                                                          ),
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v("点击查看详情"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.noticeType == 3
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        path:
                                                          "/form/multipleFormDetail/" +
                                                          _vm.encodeId(
                                                            item.projectId
                                                          ),
                                                        query: {
                                                          formId: _vm.encodeId(
                                                            item.formId
                                                          ),
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v("点击查看详情"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "datetime" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatCreateTime(
                                                        item.createTime
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm.noticeList.length == 0
                          ? _c(
                              "li",
                              {
                                staticClass:
                                  "infinite-list-item infinite-list-item-empty",
                              },
                              [_vm._v(" 暂无通知 ")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: {
                        value: _vm.unReadCount,
                        hidden: _vm.unReadCount === 0,
                        max: 10,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-bell",
                        style: { color: _vm.iconColor },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("right-menu-middle"),
      _c(
        "div",
        { staticClass: "right-menu-item hover-effect" },
        [
          _vm.viewName == "webView"
            ? [
                _c("div", { staticClass: "avatar-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "avatar-wrapper",
                      on: { click: _vm.gotoMyForm },
                    },
                    [
                      _c("img", {
                        staticClass: "user-avatar",
                        attrs: {
                          src: _vm.userInfo.avatarUrl
                            ? _vm.userInfo.avatarUrl +
                              "?x-oss-process=image/resize,m_fixed,h_100,w_100"
                            : _vm.defaultAvatarUrl,
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            : [
                _c(
                  "el-dropdown",
                  {
                    staticClass: "avatar-container",
                    attrs: { trigger: "click" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "avatar-wrapper" },
                      [
                        _c("img", {
                          staticClass: "user-avatar",
                          attrs: {
                            src: _vm.userInfo.avatarUrl
                              ? _vm.userInfo.avatarUrl +
                                "?x-oss-process=image/resize,m_fixed,h_100,w_100"
                              : _vm.defaultAvatarUrl,
                          },
                        }),
                        _c("svg-icon", {
                          staticClass: "icon-navbar-down",
                          style: { color: _vm.iconColor },
                          attrs: { "icon-class": "navbarDown" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/user/profile" } },
                          [_c("el-dropdown-item", [_vm._v("个人中心")])],
                          1
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: { divided: "" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.logout($event)
                              },
                            },
                          },
                          [_c("span", [_vm._v("退出登录")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }