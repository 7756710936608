var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.screenItem
    ? _c("iframe", {
        staticStyle: {
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
        },
        attrs: {
          src: _vm.screenItem.url,
          id: "myiframe",
          scrolling: "no",
          frameborder: "0",
          width: "100%",
          height: "100%",
        },
      })
    : _c("div", [_vm._v(" 未找到配置数据 ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }