<template>
  <div v-cloak style="height: 100%; display: flex; flex-direction: column">
    <detail-nav ref="detailNav" :breadcrumb="breadcrumb" @login-success="handleLoginSuccess"></detail-nav>
    <div class="detail-nav" v-if="false">
      <div class="detail-left">
        <router-link class="detail-left-logo" to="/index">
          <svg-icon
            icon-class="myHome"
            style="width: 18px; height: 18px; vertical-align: -2px"
          />
          <!-- </span> -->
          <span>首页</span>
        </router-link>
        <svg-icon
          icon-class="myRightArrow"
          style="width: 18px; height: 18px; vertical-align: -2px"
          class="separator"
        />
        <span class="headerNow">{{ formDetail.name }}</span>
      </div>
      <!-- <div class="detail-right-login" @click="toLogin">
        <span class="right-login-text">您暂未登录，请微信扫码或账号登录</span>
        <el-button type="primary" size="mini">登录</el-button>
      </div> -->
    </div>
    <div
      class="app-container form-detail-container"
      style="height: calc(100% - 60px)"
    >
      <div class="forms-content" style="height: 100%">
        <div
          v-show="cur == 1"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <blank-view-bar
            :formJson="formFieldList"
            :formDetail="formDetail"
            :formId="id"
            @viewChange="handleViewClick"
          ></blank-view-bar>

          <div class="form-detail-top-part" v-if="viewTabName === 'viewInfo'">
            <template>
              <el-row
                :gutter="20"
                class="el-row"
                type="flex"
                style="margin: 0px 0px 20px"
              >
                <el-col
                  :span="7"
                  :xs="24"
                  style="padding: 0; width: 200px; height: 120px"
                >
                  <div class="form-img">
                    <img
                      :src="
                        formDetail.cover +
                        '?x-oss-process=image/resize,m_fixed,h_150,w_300'
                      "
                      v-if="formDetail && formDetail.cover"
                    />
                  </div>
                </el-col>
                <el-col
                  :span="19"
                  :xs="24"
                  style="padding: 0; margin-left: 20px; position: relative"
                >
                  <div class="form-info">
                    <h1 class="clearfix form-title">
                      <div class="form-title-left">
                        <span
                          style="
                            flex: 1;
                            -webkit-flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                          >{{ formDetail.name }}
                          <span
                            style="
                              width: 60px;
                              margin-top: -2px;
                              margin-left: 20px;
                            "
                          >
                            <el-tag
                              v-if="!formDetail.isStop"
                              size="mini"
                              class="form-state-tag stop"
                              type="info"
                              >已暂停</el-tag
                            >
                            <el-tag
                              v-else
                              size="mini"
                              class="form-state-tag"
                              :class="getTagClass(formDetail.formStateTxt)"
                              >{{ formDetail.formStateTxt }}</el-tag
                            >
                          </span>
                        </span>
                      </div>
                      <div class="form-share-warp">
                        <span class="form-total-num">
                          <!-- <svg-icon icon-class="form-view" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                          <i
                            class="el-icon-view"
                            style="font-size: 20px; margin-right: 5px"
                          ></i>
                          {{
                            formDetail.viewNum && formDetail.viewNum > 10000
                              ? formDetail.viewNum / 10000 + "W+"
                              : formDetail.viewNum == 10000
                              ? formDetail.viewNum / 10000
                              : formDetail.viewNum
                          }}
                        </span>
                        <span
                          class="form-total-num"
                          @click="toLogin"
                          style="cursor: pointer"
                        >
                          <!-- <svg-icon icon-class="form-share" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                          <i
                            class="el-icon-share"
                            style="font-size: 20px; margin-right: 5px"
                          ></i>
                          {{
                            formDetail.shareNum && formDetail.shareNum > 10000
                              ? formDetail.shareNum / 10000 + "W+"
                              : formDetail.shareNum == 10000
                              ? formDetail.shareNum / 10000
                              : formDetail.shareNum
                          }}
                        </span>
                        <span
                          class="form-total-num"
                          @click="toLogin"
                          style="cursor: pointer"
                        >
                          <i
                            class="el-icon-star-off"
                            style="font-size: 20px; margin-right: 5px"
                          ></i>
                          {{
                            formDetail.favoriteNum &&
                            formDetail.favoriteNum > 10000
                              ? formDetail.favoriteNum / 10000 + "W+"
                              : formDetail.favoriteNum == 10000
                              ? formDetail.favoriteNum / 10000
                              : formDetail.favoriteNum
                          }}
                          <!-- <svg-icon icon-class="form-collect" style="width: 20px; height: 20px; margin-right: 5px;"/> -->
                        </span>
                      </div>
                    </h1>
                    <div class="form-detail-top-bottom">
                      <div class="clearfix form-date">
                        <p class="fill-date">
                          <span style="width: 70px; display: inline-block"
                            >开始时间</span
                          >
                          <span style="margin: 0 10px">:</span>
                          <span>{{
                            formDetail.fillStartTime
                              ? formDetail.fillStartTime
                              : "——"
                          }}</span>
                        </p>
                      </div>
                      <div class="clearfix form-date">
                        <p class="fill-date">
                          <span style="width: 70px; display: inline-block"
                            >截止时间</span
                          >
                          <span style="margin: 0 10px">:</span>
                          <span>{{
                            formDetail.fillEndTime
                              ? formDetail.fillEndTime
                              : "——"
                          }}</span>
                        </p>
                      </div>
                      <div class="clearfix form-date">
                        <div class="fill-date">
                          <span style="width: 70px; display: inline-block"
                            >填报人数</span
                          >
                          <span style="margin: 0 10px">:</span>
                          <span>{{
                            formDetail.fillNum != null
                              ? formDetail.fillNum + "人可填"
                              : "填报人数无限制"
                          }}</span>
                          <span v-if="formDetail.fillNumPublic">,</span>
                          <span v-if="formDetail.fillNumPublic">
                            {{
                              formDetail.completedFillNum != null
                                ? formDetail.completedFillNum + "人已填"
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="clearfix form-date">
                        <div class="fill-date fl">
                          <span style="width: 70px; display: inline-block">
                            <i style="font-style: normal">创</i>
                            <i style="font-style: normal; margin: 0 6px">建</i>
                            <i style="font-style: normal">人</i>
                          </span>
                          <span style="margin: 0 10px">:</span>
                          <span>{{
                            formDetail.founder
                              ? formDetail.founder
                              : formDetail.createName
                          }}</span>
                          <img
                            src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png"
                            class="is-agency-icon"
                            v-if="formDetail.agencyId"
                          />
                          <!--                          <span v-else>（个人）</span>-->
                        </div>
                      </div>
                      <div class="form-fill-btn">
                        <el-button
                          type="primary"
                          size="medium"
                          @click="onFillClick"
                          :disabled="true"
                          style="font-size: 18px"
                          >开始填报</el-button
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </template>
          </div>

          <div
            class="form-info-tab-bottom"
            v-if="viewTabName === 'viewInfo'"
            style="height: 100%"
          >
            <div
              class="tab-content-nav"
              :style="[
                {
                  display:
                    formDetail.details == '' && formCustomFileList.length == 0
                      ? 'none'
                      : 'block',
                },
              ]"
            >
              <div
                class="content-nav-title"
                @click="onFormInfoTabClick(1)"
                :class="{ active: formInfoCur === 1 }"
                v-if="formDetail && formDetail.details != ''"
              >
                <svg-icon icon-class="formDetailInfo" />{{ formCustomDetails }}
              </div>
              <div
                class="content-nav-title"
                @click="onFormInfoTabClick(2)"
                :class="{ active: formInfoCur === 2 }"
                v-if="formCustomFileList && formCustomFileList.length > 0"
              >
                <svg-icon icon-class="formDetailFile" />{{ formCustomFile }}
              </div>
            </div>
            <div class="info-tab-content" v-if="formInfoCur === 1">
              <div class="details-intro" vhtml="formDetail.details">
                <pre v-html="formDetail.details"></pre>
              </div>
            </div>
            <div class="info-tab-content" v-if="formInfoCur === 2">
              <div class="details-file">
                  <Download :fileList="formCustomFileList"></Download>
              </div>
            </div>
          </div>

          <div
            v-show="viewTabName != 'viewInfo'"
            style="
              margin: 0;
              height: 100%;
              flex-grow: 1;
              padding: 0px;
              padding-top: 0px;
              background-color: white;
              border-radius: 10px;
            "
          >
            <MyVxeTable
              ref="fillGridTable"
              v-if="fillGridOptions.columns.length > 0"
              :columns="fillGridOptions.columns"
              :loading="fillGridOptions.loading"
              :data="fillGridOptions.data"
              :params="fillGridOptions.params"
              :pageConfig="fillGridOptions.pagerConfig"
              :selectAllRowsHandler_="GetAllDataRows"
              @page-change="handleDataPageChange"
              @view-picture="onHandlePreviewImg"
              @filter-change="fillFilterChanged"
              @sort-change="fillSortChanged"
              :agencyId="formDetail.agencyId"
              :formJson="showAllFieldFormJson"
              :formDetail="formDetail"
              @clear-global-search="clearGlobalSearch"
              style="width: 100%; height: 100%; display: inline-flex"
            >
              <template #toolbar_search>
                <el-input
                  v-model="fillQueryParams.fillValue"
                  prefix-icon="el-icon-search"
                  placeholder="全局搜索..."
                  @clear="onHandleResetFillClick"
                  clearable
                  @keyup.enter.native="onHandleQueryFillClick"
                ></el-input>
              </template>
              <template v-slot:status_text>
                <vxe-column
                  title="当前状态"
                  width="150"
                  field="status"
                  header-class-name="sysfield"
                  :filters="[{ data: {} }]"
                  :filter-render="{ name: 'filter-status' }"
                  :params="filters.status"
                >
                  <template #default="{ row }">
                    <StatusCode
                      :statusCode="row.status"
                      :row="row"
                      :fillStatusCode="row.fillStatus"
                    ></StatusCode>
                  </template>
                </vxe-column>
              </template>
              <template #operate>
                <vxe-column
                  type="operate"
                  min-width="150"
                  max-width="150"
                  slots:default="operate"
                  fixed="right"
                  header-class-name="sysfield"
                  align="center"
                >
                  <template v-slot:header>
                    <span>操作</span>
                  </template>
                  <template #default="{ row, rowIndex }">
                    <el-button
                      type="text"
                      size="mini"
                      status="primary"
                      content="详情"
                      @click="onViewFillClick(row['fillId'], 'FILL', row)"
                      >详情</el-button
                    >
                  </template>
                </vxe-column>
              </template>
            </MyVxeTable>
          </div>
        </div>
      </div>
    </div>
    <VFormViewer ref="dlgvFormViewer" :login="false"></VFormViewer>
  </div>
</template>

<script>
import { getNowFormatDate, getDateDiff, parseTime } from "@/utils/whale";
import {
  formDetail,
  httpViewNum,
  formFieldList,
  customFormFieldList,
  formFilesList,
  httpGetFormFieldV2,
} from "@/api/system/form";
import { getCity, getCounty, getProvince } from "@/api/system/config";
import { removeToken } from "@/utils/auth";
import { getCodeImg, getQrImg, sendMobileCode } from "@/api/login";

import VFormViewer from "./components/vFormViewer/index.vue";
import StatusCode from "./components/statusCode.vue";
import MyVxeTable from "./components/tables/table.vue";
import viewsMixin from "./dataView/viewMixin";
import BlankViewBar from "./blankViewBar.vue";
import BlankViewBarMixin from "./blankViewBarMixin";
import Download from '@/components/FileDownload/download.vue'
import DetailNav from '@/components/DetailNav/index.vue'
const TIME_COUNT = 60;
export default {
  name: "Detail",
  components: { VFormViewer, MyVxeTable, StatusCode, BlankViewBar,Download,DetailNav },
  mixins: [viewsMixin, BlankViewBarMixin],
  data() {
    return {
      logoImg:
        "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png",
      cur: 1,
      id: null,
      formId: null,
      formAuth: {},
      formDetail: {},
      formFieldList: [],
      formCustomDetails: "",
      formCustomFile: "",
      formCustomFileList: [],
      formData: {},
      selectCheckbox: [], //多选
      agencyDeptOptions: [], //组织架构
      provinceList: [], //省市区联动
      cityList: [],
      countyList: [],
      addressFlagArr: [], //省市区联动过滤数组
      props: {
        label: "label", // 显示的选项
        value: "label", // 选择的value值
        children: "children", // 子级属性名
        expandTrigger: "hover",
      }, // 指定层级属性
      pickerOptions: {
        disabledDate: (time) => {
          let startDateTime = new Date("1900/1/1").getTime();
          let endDateTime = new Date("2100/12/31").getTime();
          return time.getTime() < startDateTime || time.getTime() > endDateTime;
        },
      },
      formInfoCur: null,
      loginCur: 1,
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      loginFormRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      mobileLoginForm: {
        mobile: "",
        smsCode: "",
      },
      mobileLoginRules: {
        mobile: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
        ],
        smsCode: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },
      isGetCode: true,
      count: 0,
      timer: null,
      isShowCode: false,
      codeUrl: "",
      loading: false,
      qrImg:
        "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/defaultQr.jpg", // 扫码登录二维码默认图片
      qrTimer: null, // 扫码登录定时器
      isTestVersion: process.env.NODE_ENV === "development", // 区分正是环境or测试环境
      isValid: false, // 扫码登录二维码是否失效，默认不失效

      viewTabName: "viewInfo",
      currentViewId: "", // 当前选中的视图id
      viewColumns: [], // 所选视图的表格列
      viewFields: [], // 所选视图的表单字段
      viewHideFields: [],
      viewReadonlyFields: [],
      viewActions: [], // 视图功能按钮
      viewStyle: {}, // 视图样式
      viewRange: 0, // 视图范围
      showDetailType: -1, // 显示列表的类型-1 不显示，0视图，3个人填报，4审核列表，5管理列表
    };
  },
  created() {
    //console.log(this.$route.params) //不是通过？形式传递的参数取值
    if (!this.isBlank(this.$route.params.id)) {
      this.id = this.decode(this.$route.params.id);
      this.formId = this.decode(this.$route.params.id);
    }

    this.getFormDetail();
    this.getFields();
  },
  mounted() {},
  computed: {
    showAllFieldFormJson: function () {
      return {
        formConfig: this.formJson.formConfig,
        widgetList: this.formJson.widgetList,
        fieldList: this.formJson.fieldList.map((x) => {
          return { ...x, hidden: false };
        }),
      };
    },
    breadcrumb:function(){
      return [
        this.formDetail.name
      ]
    }
  },
  methods: {
    onTabClick(index) {
      this.cur = index;
    },
    getFormDetail() {
      let _this = this;
      formDetail(_this.id).then((response) => {
        if (response.code == 200) {
          let formStateTxt = ""; //表单状态（未开始、进行中、已结束）
          // let curTime = response.now
          const curTime = response.now || parseTime(new Date());
          if (response.data.status == "1") {
            formStateTxt = "未发布";
          } else {
            let fillStartTime =
              response.data.fillStartTime || "1970-01-01 00:00:00";
            let fillEndTime =
              response.data.fillEndTime || "2099-12-31 23:59:59";

            // if (!_this.isBlank(response.data.fillStartTime) && !_this.isBlank(response.data.fillEndTime)) {
            //   if (getDateDiff(response.data.fillStartTime, curTime)) {
            //     formStateTxt = '未开始'
            //   } else if (!getDateDiff(response.data.fillStartTime, curTime) && !getDateDiff(response.data.fillEndTime, curTime)) {
            //     formStateTxt = '已结束'
            //   } else {
            //     formStateTxt = '进行中'
            //   }
            // } else {
            //   formStateTxt = '进行中'
            // }
            if (getDateDiff(fillStartTime, curTime)) {
              formStateTxt = "未开始";
            } else if (
              getDateDiff(curTime, fillStartTime) &&
              getDateDiff(fillEndTime, curTime)
            ) {
              formStateTxt = "进行中";
            } else {
              formStateTxt = "已结束";
            }
          }
          response.data.formStateTxt = formStateTxt;
        }
        _this.formDetail = response.data;

        // _this.getFormField()

        _this.getCustomFormFieldList();

        _this.getFormFiles();
      });
    },
    getFields() {
      // 获取表单配置(表单设计器)数据
      httpGetFormFieldV2(this.id).then((response) => {
        if (response.data) {
          this.formJson = {
            formConfig: response.data.formConfig,
            widgetList: response.data.widgetList,
            fieldList: response.data.fieldList,
          };
        } else {
          this.formJson = {
            fieldList: [],
          };
        }
      });
    },
    getFormField() {
      let _this = this;
      formFieldList(_this.id).then((response) => {
        if (response.code == 200) {
          if (!this.isBlank(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
              // 多项选择
              if (response.data[i].type == "7") {
                response.data[i].selectCheckbox = [];
              }
              if (response.data[i].type == "13") {
                response.data[i].content = _this.getTreeData(
                  JSON.parse(response.data[i].content)
                );
              }
            }
          }
          _this.formFieldList = response.data;
        }
      });
    },
    // type == 13 处理tree数据
    getTreeData(data) {
      let newArr = typeof data == "string" ? JSON.parse(data) : data;
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].children.length < 1) {
          newArr[i].children = undefined; // children若为空数组，则将children设为undefined
        } else {
          this.getTreeData(newArr[i].children); // children若不为空数组，则继续 递归调用 本方法
        }
      }
      return newArr;
    },
    getCustomFormFieldList() {
      let _this = this;
      customFormFieldList(_this.id).then((response) => {
        if (response.code == 200) {
          _this.formCustomDetails = !_this.isBlank(response.data.details)
            ? response.data.details
            : "表单介绍";
          _this.formCustomFile = !_this.isBlank(response.data.file)
            ? response.data.file
            : "表单附件";
        }
      });
    },
    getFormFiles() {
      let _this = this;
      formFilesList(_this.id).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            let suffix = _this.getSuffix(response.data[i].name);
            let suffixType = null;
            if (
              suffix == "jpeg" ||
              suffix == "JPEG" ||
              suffix == "jpg" ||
              suffix == "JPG" ||
              suffix == "png" ||
              suffix == "PNG"
            ) {
              suffixType = "image";
            } else if (
              suffix == "doc" ||
              suffix == "DOC" ||
              suffix == "docx" ||
              suffix == "DOCX"
            ) {
              suffixType = "word";
            } else if (
              suffix == "exl" ||
              suffix == "EXL" ||
              suffix == "xlsx" ||
              suffix == "XLSX"
            ) {
              suffixType = "excel";
            } else if (suffix == "pdf" || suffix == "PDF") {
              suffixType = "pdf";
            } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
              suffixType = "audio";
            } else if (
              suffix == "mp4" ||
              suffix == "MP4" ||
              suffix == "mov" ||
              suffix == "m4v" ||
              suffix == "mpg" ||
              suffix == "wma" ||
              suffix == "avi" ||
              suffix == "flv" ||
              suffix == "mkv" ||
              suffix == "rm" ||
              suffix == "rmvb"
            ) {
              suffixType = "video";
            } else if (
              suffix == "zip" ||
              suffix == "ZIP" ||
              suffix == "rar" ||
              suffix == "RAR"
            ) {
              suffixType = "zip";
            } else if (
              suffix == "ppt" ||
              suffix == "PPT" ||
              suffix == "pptx" ||
              suffix == "PPTX"
            ) {
              suffixType = "ppt";
            } else {
              suffixType = "other";
            }
            response.data[i].suffixType = suffixType;
          }
          _this.formCustomFileList = response.data;
          if (_this.formDetail && _this.formDetail.details != "") {
            _this.formInfoCur = 1;
          } else {
            if (
              _this.formCustomFileList &&
              _this.formCustomFileList.length > 0
            ) {
              _this.formInfoCur = 2;
            }
          }
        }
      });
    },
    getSuffix(name) {
      return name.split(".").pop().toLowerCase();
    },
    onFillClick() {
      this.cur = 2;
    },
    toLogin(){
      this.$refs.detailNav.toLogin();
    },
    handleLoginSuccess(){
      this.$router.push({
        path: "/form/detail/" + this.encode(this.id),
      });
    },
    onFormInfoTabClick(index) {
      this.formInfoCur = index;
    },
    getTagClass(text) {
      switch (text) {
        case "进行中":
          return "ing";
        case "已结束":
          return "stop";
        default:
          return "un-start";
      }
    },
    onDownloadFormFileClick(item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 数据查看---上传附件---截取附件名
    getSuffName(url) {
      return !this.isBlank(url)
        ? decodeURIComponent(url).substring(url.indexOf("_") + 1)
        : "";
    },

    handleViewClick(viewName, viewId) {
      this.currentViewId = viewId;
      this.viewTabName = viewName;
      if (!viewId) return;
      Promise.all([this.getFormView(), this.getFormViewListData()]).then(
        (res) => {}
      );
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
/* table 样式 */
.details-intro table {
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-collapse: collapse;
}
.details-intro table td,
.details-intro table th {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  padding: 10px 5px;
  text-align: center;
}
/* blockquote 样式 */
.details-intro blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}
/* code 样式 */
.details-intro code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 10px;
  margin: 0 3px;
  overflow-x: scroll;
}
.details-intro pre code {
  display: block;
}
/* ul ol 样式 */
.details-intro ul,
.details-intro ol {
  margin: 10px 0 10px 20px;
}
.detail-nav {
  width: 100%;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 60px;
  -webkit-transform: translateZ(0);
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  .detail-left {
    float: left;
    line-height: 60px;
    font-size: 14px;
    color: #333333;
    .detail-left-logo {
      cursor: pointer;
      color: rgba(43, 52, 65, 0.6);
      font-size: 18px;
      font-weight: 500;
      .home-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-right: 8px;
        border-radius: 50%;
        background: #f5f5f7;
      }
    }
    .separator {
      margin: 0 11px 0 17px;
    }
    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .detail-right-login {
    display: flex;
    display: -webkit-flex;
    height: 60px;
    line-height: 60px;
    align-items: center;
    float: right;
    .right-login-text {
      margin-right: 20px;
    }
  }
}
.form-detail-container {
  position: relative;
  padding-top: 0;
  margin: 16px auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  .forms-content {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    padding: 0;
    box-sizing: border-box;
    .form-detail-top-part {
      padding: 0px 20px 1px 20px;
      background: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      border-radius: 10px;
      .form-img {
        img {
          width: 100%;
          max-width: 100%;
          height: 120px;
          border-radius: 6px;
        }
      }
      .form-info {
        width: 100%;
        position: relative;
        padding: 0 0 0 0;
        box-sizing: border-box;
        height: 120px;
        .form-title {
          width: 100%;
          font-size: 20px;
          color: #333333;
          margin: 0;
          line-height: 30px;
          font-weight: 400;
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          align-items: center;
          .form-title-left {
            flex: 1;
            -webkit-flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            display: -webkit-flex;
          }
          button {
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            align-items: center;
          }
          .form-share-warp {
            width: 200px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: flex-end;
            .form-total-num {
              font-size: 16px;
              color: #909090;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              flex: 1;
              -webkit-flex: 1;
              text-align: center;
              justify-content: center;

              &:hover {
                color: #3476f0;
              }
            }
          }
        }
        .form-detail-top-bottom {
          margin-top: 2px;
          width: 100%;
          .form-date {
            width: 100%;
            font-size: 14px;
            height: 22px;
            line-height: 22px;
            margin: 0;
            color: #a5abb1;
            .fill-date {
              margin: 0;
              padding: 0;
              .is-agency-icon {
                display: inline-block;
                width: 48px;
                height: 18px;
                margin-left: 5px;
                vertical-align: -3px;
              }
            }
          }
          .form-fill-btn {
            position: absolute;
            bottom: 8px;
            right: 0;
          }
          .formStatus {
            position: absolute;
            right: 0;
            bottom: 60px;
          }
          .form-fill {
            width: 100%;
            font-size: 12px;
            line-height: 22px;
            color: #909090;
            margin: 0 0;
          }
          .form-do {
            width: 100%;
            font-size: 14px;
            margin: 15px 0 0;
            text-align: right;
            display: block;
            span {
              margin-left: 10px;
            }
          }
        }
      }
      .form-num-wrap {
        width: 300px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        line-height: 30px;
        .form-total-num {
          font-size: 16px;
          color: #909090;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          flex: 1;
          -webkit-flex: 1;
          text-align: center;
          justify-content: center;
        }
      }
    }
    .form-info-tab-bottom {
      width: 100%;
      background: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      box-shadow: 0px 1px 3px 0px rgba(16, 16, 17, 0.06);
      border-radius: 10px;
      margin-top: 16px;
      .tab-content-nav {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f2f3f4;
        padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;
        .content-nav-title {
          font-size: 16px;
          margin-right: 20px;
          display: inline-block;
          &.active {
            color: #3476f0;
            border-bottom: 3px solid #3476f0;
            line-height: 44px;

            .svg-cion {
              color: #3476f0;
            }
          }
        }
      }
    }
  }
}
.fontWeight {
  color: #000000 !important;
  font-weight: inherit !important;
}
.fontNormal {
  color: #606266 !important;
  font-weight: initial !important;
}
.share-container {
  margin-top: -30px;
  width: 100%;
  p {
    padding: 0;
    margin: 10px auto;
  }
  .shareFillImg {
    display: block;
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }
  .share-lite-contain {
    width: 375px;
    height: 553px;
    background: #0078f8
      url("https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/shareBcg.png")
      no-repeat 220px 25px;
    background-size: 121px 78px;
    box-shadow: 0px 5px 20px 0px rgba(57, 67, 73, 0.2);
    .top-logo {
      width: 100%;
      padding: 25px 0 25px 25px;
      box-sizing: border-box;
      img {
        display: inline-block;
        vertical-align: middle;
        width: 144px;
        height: 40px;
      }
    }
    .share-card {
      width: calc(100% - 50px);
      height: 438px;
      margin: 0 25px 25px;
      box-sizing: border-box;
      background: #ffffff;
      padding: 20px;
      position: relative;
      .share-form-img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 190px;
        margin-bottom: 20px;
      }
      .share-form-name {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #394349;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .share-form-fill {
        font-size: 13px;
        font-weight: 400;
        color: #394349;
        line-height: 18px;
      }
      .share-form-bottom {
        width: 285px;
        height: 100px;
        padding: 10px 15px;
        box-sizing: border-box;
        background: #f0f2f5;
        position: absolute;
        bottom: 20px;
        .share-form-qr {
          display: inline-block;
          width: 80px;
          height: 80px;
          vertical-align: middle;
        }
        .share-form-qr-text {
          font-size: 11px;
          font-weight: 400;
          color: #394349;
          line-height: 16px;
          text-align: right;
          span {
            display: block;
          }
        }
      }
    }
  }
}
.details-intro {
  width: 100%;
  font-size: 14px;
  color: #394349;
  word-break: break-all;
  padding: 0 20px;
  box-sizing: border-box;
}
.details-file {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  p {
    cursor: pointer;
    font-size: 13px;
    margin: 0 0 10px;
    color: #394349;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}
/*新版---登录弹框*/
.el-dialog {
  border-radius: 6px;
}
.pop-login-tab {
  line-height: 40px;
  border-bottom: 1px solid #e5e5e5;
  height: 40px;
  cursor: pointer;
  margin-left: -20px;
  margin-right: -20px;
  .login-tab-item {
    display: inline-block;
    width: 33.333%;
    text-align: center;
    list-style: none;
    font-size: 16px;
    font-weight: 400;
    color: #bababa;
    &.active {
      color: #0078f8;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 128px;
        left: 50%;
        bottom: 0px;
        height: 4px;
        background: #0078f8;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        z-index: 1;
        margin-left: -64px;
      }
    }
  }
}
.pop-login-content {
  padding: 0 35px;
  margin: 30px -20px 0;
  height: 280px;
  .login-code {
    width: 33%;
    height: 32px;
    float: right;
    .login-code-img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .login-lite-img {
    display: block;
    width: 166px;
    height: 166px;
    text-align: center;
    margin: auto;
    cursor: pointer;
  }
  .login-lite-text {
    margin: 20px auto 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.form-state-tag {
  height: 18px;
  line-height: 18px;

  &.un-start {
    color: #3476f0;
    background-color: #ebf1fe;
    border-color: #d6e4fc;
  }

  &.ing {
    background-color: #effaf5;
    border-color: #dff5eb;
    color: #61cd9d;
  }

  &.stop {
    color: #a5abb1;
    background: #888d931a;
    border-color: #888d9380;
  }
}
.info-tab-content {
  // height: calc(100vh - 303px);
  overflow: auto;
}

</style>
