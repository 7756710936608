var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.nodeConfig.type != 5
        ? _c(
            "div",
            { staticClass: "node-wrap" },
            [
              _c(
                "div",
                {
                  staticClass: "node-wrap-box",
                  class:
                    (_vm.nodeConfig.type == 1 ? "start-node" : "") +
                    (_vm.isTried && _vm.nodeConfig.error ? "active error" : ""),
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        style:
                          "background: rgb(" +
                          ["87, 106, 149", "255, 148, 62", "50, 150, 250"][
                            _vm.nodeConfig.type != 4
                              ? _vm.nodeConfig.type - 1
                              : _vm.nodeConfig.type - 2
                          ] +
                          ");",
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nodeConfig.type == 2,
                                expression: "nodeConfig.type==2",
                              },
                            ],
                            staticClass: "iconfont",
                          },
                          [_vm._v("")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nodeConfig.type == 4,
                                expression: "nodeConfig.type==4",
                              },
                            ],
                            staticClass: "iconfont",
                          },
                          [_vm._v("")]
                        ),
                        _vm.nodeConfig.type == 1
                          ? _c("span", [_vm._v("填报人")])
                          : _vm._e(),
                        _vm.nodeConfig.type == 2
                          ? _c("span", [_vm._v("审核人")])
                          : _vm._e(),
                        _vm.nodeConfig.type == 4
                          ? _c("span", [_vm._v("查看人")])
                          : _vm._e(),
                        _vm.nodeConfig.type != 1
                          ? _c("i", {
                              staticClass: "anticon anticon-close close",
                              on: {
                                click: function ($event) {
                                  return _vm.delNode()
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "content", on: { click: _vm.setPerson } },
                      [
                        _vm.nodeConfig.type == 1
                          ? _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _vm.nodeConfig.agencyUserId == "0"
                                  ? _c("span", [_vm._v("所有人")])
                                  : [
                                      _vm.nodeConfig.agencyUser &&
                                      _vm.nodeConfig.agencyUser.length == 0 &&
                                      _vm.nodeConfig.agencyDeptRole &&
                                      _vm.nodeConfig.agencyDeptRole.length ==
                                        0 &&
                                      _vm.selectHistoryName == ""
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.nodeConfig.agencyUserId ==
                                                "0"
                                                  ? ""
                                                  : "placeholder",
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.nodeConfig.agencyUserId ==
                                                    "0"
                                                    ? "所有人"
                                                    : "请选择" +
                                                        _vm.placeholderList[
                                                          _vm.nodeConfig.type -
                                                            1
                                                        ]
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.nodeConfig.agencyUser,
                                        function (item, index) {
                                          return [
                                            _c("span", [_vm._v(_vm._s(item))]),
                                            _vm.nodeConfig.agencyDeptRole &&
                                            _vm.nodeConfig.agencyDeptRole
                                              .length == 0
                                              ? [
                                                  index <
                                                  _vm.nodeConfig.agencyUser
                                                    .length -
                                                    1
                                                    ? _c("span", [_vm._v("、")])
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  index <
                                                  _vm.nodeConfig.agencyUser
                                                    .length
                                                    ? _c("span", [_vm._v("、")])
                                                    : _vm._e(),
                                                ],
                                          ]
                                        }
                                      ),
                                      _vm._l(
                                        _vm.nodeConfig.agencyDeptRole,
                                        function (item, index) {
                                          return [
                                            item.split("-")[0] == 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.split("-")[1])
                                                  ),
                                                ])
                                              : item.split("-")[1] == 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.split("-")[0])
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(_vm._s(item)),
                                                ]),
                                            index <
                                            _vm.nodeConfig.agencyDeptRole
                                              .length -
                                              1
                                              ? _c("span", [_vm._v("、")])
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectHistoryName)
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.nodeConfig.type == 2
                          ? _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _vm.nodeConfig.agencyUser &&
                                _vm.nodeConfig.agencyUser.length == 0 &&
                                _vm.nodeConfig.agencyDeptRole &&
                                _vm.nodeConfig.agencyDeptRole.length == 0
                                  ? _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(
                                        "请选择" +
                                          _vm._s(
                                            _vm.placeholderList[
                                              _vm.nodeConfig.type - 1
                                            ]
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.nodeConfig.agencyUser,
                                  function (item, index) {
                                    return [
                                      _c("span", [_vm._v(_vm._s(item))]),
                                      _vm.nodeConfig.agencyDeptRole &&
                                      _vm.nodeConfig.agencyDeptRole.length == 0
                                        ? [
                                            index <
                                            _vm.nodeConfig.agencyUser.length - 1
                                              ? _c("span", [_vm._v("、")])
                                              : _vm._e(),
                                          ]
                                        : [
                                            index <
                                            _vm.nodeConfig.agencyUser.length
                                              ? _c("span", [_vm._v("、")])
                                              : _vm._e(),
                                          ],
                                    ]
                                  }
                                ),
                                _vm._l(
                                  _vm.nodeConfig.agencyDeptRole,
                                  function (item, index) {
                                    return [
                                      item.split("-")[0] == 0
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.split("-")[1])),
                                          ])
                                        : item.split("-")[1] == 0
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.split("-")[0])),
                                          ])
                                        : _c("span", [_vm._v(_vm._s(item))]),
                                      index <
                                      _vm.nodeConfig.agencyDeptRole.length - 1
                                        ? _c("span", [_vm._v("、")])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.nodeConfig.type == 4
                          ? _c(
                              "div",
                              { staticClass: "text" },
                              [
                                _vm.nodeConfig.agencyUser &&
                                _vm.nodeConfig.agencyUser.length == 0 &&
                                _vm.nodeConfig.agencyDeptRole &&
                                _vm.nodeConfig.agencyDeptRole.length == 0
                                  ? _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(
                                        "请选择" +
                                          _vm._s(
                                            _vm.placeholderList[
                                              _vm.nodeConfig.type - 2
                                            ]
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.nodeConfig.agencyUser,
                                  function (item, index) {
                                    return [
                                      _c("span", [_vm._v(_vm._s(item))]),
                                      _vm.nodeConfig.agencyDeptRole &&
                                      _vm.nodeConfig.agencyDeptRole.length == 0
                                        ? [
                                            index <
                                            _vm.nodeConfig.agencyUser.length - 1
                                              ? _c("span", [_vm._v("、")])
                                              : _vm._e(),
                                          ]
                                        : [
                                            index <
                                            _vm.nodeConfig.agencyUser.length
                                              ? _c("span", [_vm._v("、")])
                                              : _vm._e(),
                                          ],
                                    ]
                                  }
                                ),
                                _vm._l(
                                  _vm.nodeConfig.agencyDeptRole,
                                  function (item, index) {
                                    return [
                                      item.split("-")[0] == 0
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.split("-")[1])),
                                          ])
                                        : item.split("-")[1] == 0
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.split("-")[0])),
                                          ])
                                        : _c("span", [_vm._v(_vm._s(item))]),
                                      index <
                                      _vm.nodeConfig.agencyDeptRole.length - 1
                                        ? _c("span", [_vm._v("、")])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c("i", { staticClass: "anticon anticon-right arrow" }),
                      ]
                    ),
                    _vm.isTried && _vm.nodeConfig.error
                      ? _c("div", { staticClass: "error_tip" }, [
                          _c("i", {
                            staticClass: "anticon anticon-exclamation-circle",
                            staticStyle: { color: "rgb(242, 86, 67)" },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("addNode", {
                attrs: {
                  childNodeP: _vm.nodeConfig.workFlowNode,
                  isEdit: _vm.isEdit,
                },
                on: {
                  "update:childNodeP": function ($event) {
                    return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
                  },
                  "update:child-node-p": function ($event) {
                    return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
                  },
                  "update:isEdit": function ($event) {
                    _vm.isEdit = $event
                  },
                  "update:is-edit": function ($event) {
                    _vm.isEdit = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.nodeConfig.type == 5
        ? _c("div", { staticClass: "branch-wrap" }, [
            _c(
              "div",
              { staticClass: "branch-box-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "branch-box" },
                  [
                    _c(
                      "button",
                      { staticClass: "add-branch", on: { click: _vm.addTerm } },
                      [_vm._v("添加条件")]
                    ),
                    _vm._l(
                      _vm.nodeConfig.conditionNodes,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-box" },
                          [
                            _c("div", { staticClass: "condition-node" }, [
                              _c(
                                "div",
                                { staticClass: "condition-node-box" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "auto-judge",
                                      class:
                                        _vm.isTried && item.error
                                          ? "error active"
                                          : "",
                                    },
                                    [
                                      index != 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sort-left",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.arrTransfer(
                                                    index,
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("<")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "title-wrapper" },
                                        [
                                          _vm.isInputList[index]
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.nodeName,
                                                    expression: "item.nodeName",
                                                  },
                                                ],
                                                staticClass:
                                                  "ant-input editable-title-input",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: item.nodeName,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.blurEvent(index)
                                                  },
                                                  focus: function ($event) {
                                                    return $event.currentTarget.select()
                                                  },
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item,
                                                      "nodeName",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isInputList[index]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "editable-title",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clickEvent(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.nodeName))]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "priority-title",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setPerson(
                                                    item.priorityLevel
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "优先级" +
                                                  _vm._s(item.priorityLevel)
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "anticon anticon-close close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delTerm(index)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      index !=
                                      _vm.nodeConfig.conditionNodes.length - 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sort-right",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.arrTransfer(index)
                                                },
                                              },
                                            },
                                            [_vm._v(">")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "content",
                                          on: {
                                            click: function ($event) {
                                              return _vm.setPerson(
                                                item.priorityLevel
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.conditionStr(item, index)
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm.isTried && item.error
                                        ? _c(
                                            "div",
                                            { staticClass: "error_tip" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "anticon anticon-exclamation-circle",
                                                staticStyle: {
                                                  color: "rgb(242, 86, 67)",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("addNode", {
                                    attrs: { childNodeP: item.workFlowNode },
                                    on: {
                                      "update:childNodeP": function ($event) {
                                        return _vm.$set(
                                          item,
                                          "workFlowNode",
                                          $event
                                        )
                                      },
                                      "update:child-node-p": function ($event) {
                                        return _vm.$set(
                                          item,
                                          "workFlowNode",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            item.workFlowNode && item.workFlowNode
                              ? _c("nodeWrap", {
                                  attrs: {
                                    nodeConfig: item.workFlowNode,
                                    tableId: _vm.tableId,
                                    isTried: _vm.isTried,
                                    directorMaxLevel: _vm.directorMaxLevel,
                                  },
                                  on: {
                                    "update:nodeConfig": function ($event) {
                                      return _vm.$set(
                                        item,
                                        "workFlowNode",
                                        $event
                                      )
                                    },
                                    "update:node-config": function ($event) {
                                      return _vm.$set(
                                        item,
                                        "workFlowNode",
                                        $event
                                      )
                                    },
                                    "update:isTried": function ($event) {
                                      _vm.isTried = $event
                                    },
                                    "update:is-tried": function ($event) {
                                      _vm.isTried = $event
                                    },
                                  },
                                })
                              : _vm._e(),
                            index == 0
                              ? _c("div", {
                                  staticClass: "top-left-cover-line",
                                })
                              : _vm._e(),
                            index == 0
                              ? _c("div", {
                                  staticClass: "bottom-left-cover-line",
                                })
                              : _vm._e(),
                            index == _vm.nodeConfig.conditionNodes.length - 1
                              ? _c("div", {
                                  staticClass: "top-right-cover-line",
                                })
                              : _vm._e(),
                            index == _vm.nodeConfig.conditionNodes.length - 1
                              ? _c("div", {
                                  staticClass: "bottom-right-cover-line",
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c("addNode", {
                  attrs: { childNodeP: _vm.nodeConfig.workFlowNode },
                  on: {
                    "update:childNodeP": function ($event) {
                      return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
                    },
                    "update:child-node-p": function ($event) {
                      return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "abow_dialog",
          attrs: {
            title: _vm.fillDialog.title,
            visible: _vm.fillDialog.open,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.fillDialog, "open", $event)
            },
            close: _vm.onCloseFillDialog,
          },
        },
        [
          _c("div", { staticClass: "userSelect-container" }, [
            _c(
              "div",
              { staticClass: "userSelect-group" },
              [
                [
                  (_vm.fillIds && _vm.fillIds.length > 0) ||
                  (_vm.fillAgencyDeptRoleIds &&
                    _vm.fillAgencyDeptRoleIds.length > 0) ||
                  _vm.selectHistoryName
                    ? _c("h1", { staticClass: "user-select-name-title" }, [
                        _c("span", [_vm._v("已选择：")]),
                        _c(
                          "span",
                          {
                            staticStyle: { float: "right", cursor: "pointer" },
                            on: { click: _vm.onDelDataAllFillClick },
                          },
                          [_vm._v("全部清空")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.fillNames, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedFillClick(index)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._l(_vm.fillAgencyDeptRole, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedFillDeptRoleClick(index)
                            },
                          },
                        },
                        [
                          item.split("-")[0] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[1]))])
                            : item.split("-")[1] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[0]))])
                            : _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm.selectHistoryName
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "selected-item",
                            on: { click: _vm.onDelSelectedHistoryClick },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.selectHistoryName))]),
                            _c("svg-icon", {
                              staticStyle: {
                                "margin-left": "20px",
                                float: "right",
                                "margin-top": "12px",
                                "font-size": "14px",
                              },
                              attrs: { "icon-class": "del" },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "userSelect-right" },
              [
                _c(
                  "el-form",
                  { ref: "userSelectForm", attrs: { inline: true } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.fillAgencyDeptOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    placeholder: "请选择归属部门",
                                  },
                                  on: {
                                    select: _vm.selectFillDepart,
                                    deselect: _vm.deselectFillDepart,
                                  },
                                  model: {
                                    value: _vm.fillParams.agencyDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fillParams,
                                        "agencyDeptId",
                                        $$v
                                      )
                                    },
                                    expression: "fillParams.agencyDeptId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.fillAgencyRoleOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    "disable-branch-nodes": true,
                                    placeholder: "请选择所属角色",
                                  },
                                  on: { select: _vm.selectFillRole },
                                  model: {
                                    value: _vm.fillParams.agencyRoleId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.fillParams,
                                        "agencyRoleId",
                                        $$v
                                      )
                                    },
                                    expression: "fillParams.agencyRoleId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入编号",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.fillParams.number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fillParams, "number", $$v)
                                    },
                                    expression: "fillParams.number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入用户名称",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.fillParams.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fillParams, "name", $$v)
                                    },
                                    expression: "fillParams.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              size: "mini",
                            },
                            on: { click: _vm.onSearchFillClick },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh", size: "mini" },
                            on: { click: _vm.onResetFillClick },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fillParams.agencyDeptId ||
                                  _vm.fillParams.agencyRoleId,
                                expression:
                                  "fillParams.agencyDeptId || fillParams.agencyRoleId",
                              },
                            ],
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              size: "mini",
                            },
                            on: { click: _vm.onFillAllAddClick },
                          },
                          [_vm._v("一键添加")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              size: "mini",
                            },
                            on: { click: _vm.onSelectHistoryFormClick },
                          },
                          [_vm._v("选择历史表单")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.fillList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        label: "编号",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "姓名",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "12%",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.fillIds.includes(
                                scope.row.agencyUserId.toString()
                              )
                                ? _c(
                                    "el-button",
                                    { attrs: { size: "mini", type: "text" } },
                                    [_vm._v("已选中")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "el-icon-select",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSelectDataFillRowClick(
                                            scope
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("添加")]
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fillParams.total > 0,
                      expression: "fillParams.total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.fillParams.total,
                    page: _vm.fillParams.pageNum,
                    limit: _vm.fillParams.pageSize,
                    pageSizes: _vm.pageSizes,
                    autoScroll: false,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.fillParams, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.fillParams, "pageSize", $event)
                    },
                    "update:pageSizes": function ($event) {
                      _vm.pageSizes = $event
                    },
                    "update:page-sizes": function ($event) {
                      _vm.pageSizes = $event
                    },
                    pagination: function ($event) {
                      return _vm.getReviewerList(_vm.nodeConfig.type)
                    },
                  },
                }),
                _vm.fillList && _vm.fillList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { float: "right" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmAddFillClick },
                          },
                          [_vm._v("确 定")]
                        ),
                        _c(
                          "el-button",
                          { on: { click: _vm.onCancelFillDialog } },
                          [_vm._v("取 消")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "abow_dialog",
          attrs: {
            title: _vm.reviewDialog.title,
            visible: _vm.reviewDialog.open,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.reviewDialog, "open", $event)
            },
            close: _vm.onCloseReviewDialog,
          },
        },
        [
          _c("div", { staticClass: "userSelect-container" }, [
            _c(
              "div",
              { staticClass: "userSelect-group" },
              [
                [
                  (_vm.reviewIds && _vm.reviewIds.length > 0) ||
                  (_vm.agencyDeptRoleIds && _vm.agencyDeptRoleIds.length > 0)
                    ? _c("h1", { staticClass: "user-select-name-title" }, [
                        _c("span", [_vm._v("已选择：")]),
                        _c(
                          "span",
                          {
                            staticStyle: { float: "right", cursor: "pointer" },
                            on: { click: _vm.onDelDataAllReviewClick },
                          },
                          [_vm._v("全部清空")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.reviewNames, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedReviewerClick(index)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._l(_vm.agencyDeptRole, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedDeptRoleClick(index)
                            },
                          },
                        },
                        [
                          item.split("-")[0] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[1]))])
                            : item.split("-")[1] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[0]))])
                            : _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "userSelect-right" },
              [
                _c(
                  "el-form",
                  { ref: "userSelectForm", attrs: { inline: true } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.agencyDeptOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    placeholder: "请选择归属部门",
                                  },
                                  on: {
                                    select: _vm.selectDepart,
                                    deselect: _vm.deselectDepart,
                                  },
                                  model: {
                                    value: _vm.reviewParams.agencyDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.reviewParams,
                                        "agencyDeptId",
                                        $$v
                                      )
                                    },
                                    expression: "reviewParams.agencyDeptId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.agencyRoleOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    "disable-branch-nodes": true,
                                    placeholder: "请选择所属角色",
                                  },
                                  on: { select: _vm.selectRole },
                                  model: {
                                    value: _vm.reviewParams.agencyRoleId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.reviewParams,
                                        "agencyRoleId",
                                        $$v
                                      )
                                    },
                                    expression: "reviewParams.agencyRoleId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入编号",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.reviewParams.number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reviewParams, "number", $$v)
                                    },
                                    expression: "reviewParams.number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入用户名称",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.reviewParams.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reviewParams, "name", $$v)
                                    },
                                    expression: "reviewParams.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              size: "mini",
                            },
                            on: { click: _vm.onSearchReviewerClick },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh", size: "mini" },
                            on: { click: _vm.onResetReviewerClick },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.reviewParams.agencyDeptId ||
                                  _vm.reviewParams.agencyRoleId,
                                expression:
                                  "reviewParams.agencyDeptId || reviewParams.agencyRoleId",
                              },
                            ],
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              size: "mini",
                            },
                            on: { click: _vm.onAllAddClick },
                          },
                          [_vm._v("一键添加")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.reviewerList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        label: "编号",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "姓名",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "12%",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.reviewIds.includes(
                                scope.row.agencyUserId.toString()
                              )
                                ? _c(
                                    "el-button",
                                    { attrs: { size: "mini", type: "text" } },
                                    [_vm._v("已选中")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "el-icon-select",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSelectDataReviewerRowClick(
                                            scope
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("添加")]
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reviewParams.total > 0,
                      expression: "reviewParams.total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.reviewParams.total,
                    page: _vm.reviewParams.pageNum,
                    limit: _vm.reviewParams.pageSize,
                    pageSizes: _vm.pageSizes,
                    autoScroll: false,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.reviewParams, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.reviewParams, "pageSize", $event)
                    },
                    "update:pageSizes": function ($event) {
                      _vm.pageSizes = $event
                    },
                    "update:page-sizes": function ($event) {
                      _vm.pageSizes = $event
                    },
                    pagination: function ($event) {
                      return _vm.getReviewerList(_vm.nodeConfig.type)
                    },
                  },
                }),
                _vm.reviewerList && _vm.reviewerList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { float: "right" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmAddReviewerClick },
                          },
                          [_vm._v("确 定")]
                        ),
                        _c(
                          "el-button",
                          { on: { click: _vm.onCancelReviewDialog } },
                          [_vm._v("取 消")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "abow_dialog",
          attrs: {
            title: _vm.viewerDialog.title,
            visible: _vm.viewerDialog.open,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewerDialog, "open", $event)
            },
            close: _vm.onCloseViewDialog,
          },
        },
        [
          _c("div", { staticClass: "userSelect-container" }, [
            _c(
              "div",
              { staticClass: "userSelect-group" },
              [
                [
                  (_vm.viewerIds && _vm.viewerIds.length > 0) ||
                  (_vm.agencyViewerDeptRoleIds &&
                    _vm.agencyViewerDeptRoleIds.length > 0)
                    ? _c("h1", { staticClass: "user-select-name-title" }, [
                        _c("span", [_vm._v("已选择：")]),
                        _c(
                          "span",
                          {
                            staticStyle: { float: "right", cursor: "pointer" },
                            on: { click: _vm.onDelDataAllViewClick },
                          },
                          [_vm._v("全部清空")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.viewerNames, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedViewClick(index)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._l(_vm.agencyViewerDeptRole, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.onDelSelectedViewDeptRoleClick(index)
                            },
                          },
                        },
                        [
                          item.split("-")[0] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[1]))])
                            : item.split("-")[1] == "0"
                            ? _c("span", [_vm._v(_vm._s(item.split("-")[0]))])
                            : _c("span", [_vm._v(_vm._s(item))]),
                          _c("svg-icon", {
                            staticStyle: {
                              "margin-left": "5px",
                              float: "right",
                              "margin-top": "12px",
                              "font-size": "14px",
                            },
                            attrs: { "icon-class": "del" },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "userSelect-right" },
              [
                _c(
                  "el-form",
                  { ref: "userSelectForm", attrs: { inline: true } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.agencyViewerDeptOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    placeholder: "请选择归属部门",
                                  },
                                  on: {
                                    select: _vm.selectViewDepart,
                                    deselect: _vm.deselectViewDepart,
                                  },
                                  model: {
                                    value: _vm.viewerParams.agencyDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.viewerParams,
                                        "agencyDeptId",
                                        $$v
                                      )
                                    },
                                    expression: "viewerParams.agencyDeptId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.agencyViewerRoleOptions,
                                    multiple: false,
                                    flat: false,
                                    "show-count": true,
                                    "default-expand-level": 1,
                                    "disable-branch-nodes": true,
                                    placeholder: "请选择所属角色",
                                  },
                                  on: { select: _vm.selectViewRole },
                                  model: {
                                    value: _vm.viewerParams.agencyRoleId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.viewerParams,
                                        "agencyRoleId",
                                        $$v
                                      )
                                    },
                                    expression: "viewerParams.agencyRoleId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入编号",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.viewerParams.number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.viewerParams, "number", $$v)
                                    },
                                    expression: "viewerParams.number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入用户名称",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.viewerParams.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.viewerParams, "name", $$v)
                                    },
                                    expression: "viewerParams.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              size: "mini",
                            },
                            on: { click: _vm.onSearchViewerClick },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh", size: "mini" },
                            on: { click: _vm.onResetViewerClick },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.viewerParams.agencyDeptId ||
                                  _vm.viewerParams.agencyRoleId,
                                expression:
                                  "viewerParams.agencyDeptId || viewerParams.agencyRoleId",
                              },
                            ],
                            staticClass: "filter-item",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              size: "mini",
                            },
                            on: { click: _vm.onViewAllAddClick },
                          },
                          [_vm._v("一键添加")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.viewerList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        label: "编号",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "姓名",
                        "min-width": "8%",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "12%",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.viewerIds.includes(
                                scope.row.agencyUserId.toString()
                              )
                                ? _c(
                                    "el-button",
                                    { attrs: { size: "mini", type: "text" } },
                                    [_vm._v("已选中")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "el-icon-select",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSelectDataViewerRowClick(
                                            scope
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("添加")]
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.viewerParams.total > 0,
                      expression: "viewerParams.total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.viewerParams.total,
                    page: _vm.viewerParams.pageNum,
                    limit: _vm.viewerParams.pageSize,
                    pageSizes: _vm.viewerPageSizes,
                    autoScroll: false,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.viewerParams, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.viewerParams, "pageSize", $event)
                    },
                    "update:pageSizes": function ($event) {
                      _vm.viewerPageSizes = $event
                    },
                    "update:page-sizes": function ($event) {
                      _vm.viewerPageSizes = $event
                    },
                    pagination: function ($event) {
                      return _vm.getReviewerList(_vm.nodeConfig.type)
                    },
                  },
                }),
                _vm.viewerList && _vm.viewerList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { float: "right" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmAddViewerClick },
                          },
                          [_vm._v("确 定")]
                        ),
                        _c(
                          "el-button",
                          { on: { click: _vm.onCancelViewDialog } },
                          [_vm._v("取 消")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择历史表单",
            visible: _vm.historyFormDialog.open,
            width: "560px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.historyFormDialog, "open", $event)
            },
            close: _vm.onCloseFormDialog,
          },
        },
        [
          _c("p", { staticStyle: { "margin-top": "-30px" } }, [
            _vm._v(
              "选择历史表单，作为填报人进行填报表单，可以选择审核通过与否"
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "userSelectHistoryForm",
              staticStyle: { "margin-top": "30px", "margin-bottom": "-30px" },
              attrs: {
                inline: true,
                model: _vm.historyFormDialog,
                rules: _vm.historyFormRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择历史表单", prop: "historyFormId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择历史表单" },
                      on: { change: _vm.onSelectFormName },
                      model: {
                        value: _vm.historyFormDialog.historyFormId,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyFormDialog, "historyFormId", $$v)
                        },
                        expression: "historyFormDialog.historyFormId",
                      },
                    },
                    _vm._l(_vm.historyFormList, function (item) {
                      return _c("el-option", {
                        key: item.formId,
                        attrs: { label: item.name, value: item.formId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否限制填报", prop: "historySelect" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.onRadioSelect },
                      model: {
                        value: _vm.historyFormDialog.historySelect,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyFormDialog, "historySelect", $$v)
                        },
                        expression: "historyFormDialog.historySelect",
                      },
                    },
                    [_vm._v("不限制")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.onRadioSelect },
                      model: {
                        value: _vm.historyFormDialog.historySelect,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyFormDialog, "historySelect", $$v)
                        },
                        expression: "historyFormDialog.historySelect",
                      },
                    },
                    [_vm._v("审核通过")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      on: { change: _vm.onRadioSelect },
                      model: {
                        value: _vm.historyFormDialog.historySelect,
                        callback: function ($$v) {
                          _vm.$set(_vm.historyFormDialog, "historySelect", $$v)
                        },
                        expression: "historyFormDialog.historySelect",
                      },
                    },
                    [_vm._v("审核不通过")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmSelectFormClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelFormDialog } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.nodeConfig.workFlowNode
        ? _c("nodeWrap", {
            attrs: {
              nodeConfig: _vm.nodeConfig.workFlowNode,
              isEdit: _vm.isEdit,
            },
            on: {
              "update:nodeConfig": function ($event) {
                return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
              },
              "update:node-config": function ($event) {
                return _vm.$set(_vm.nodeConfig, "workFlowNode", $event)
              },
              "update:isEdit": function ($event) {
                _vm.isEdit = $event
              },
              "update:is-edit": function ($event) {
                _vm.isEdit = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }