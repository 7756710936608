
import { deepClone } from "@/utils/deepClone.js";
import {
  unLoginListViews,
  unlLoginFillDetail,
  unLoginViewData,
  viewDetail,
} from "@/api/system/views.js";
export default {
  props: {
    // formJson: { type: Object, default: () => {} },
  },
  data() {
    return {
      fillQueryParams: {
        fillStatus: "",
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        updateStartTime: "",
        updateEndTime: "",
        formFieldId: "",
        fillValue: "",
      },

      formJson:{},
    //   currentViewId: '', // 当前选中的视图id
    //   viewColumns: [],  // 所选视图的表格列
    //   viewFields: [],  // 所选视图的表单字段
    //   viewHideFields: [],
    //   viewReadonlyFields: [],
    //   viewActions: [], // 视图功能按钮
    //   viewStyle: {}, // 视图样式
    //   viewRange: 0, // 视图范围
    //   showDetailType: -1,  // 显示列表的类型-1 不显示，0视图，3个人填报，4审核列表，5管理列表
  
    };
  },
  methods: {
    // 读取视图详情
    getFormView() {
      return new Promise(async (resolve, reject) => {
        // 视图信息
        let viewDetailRes = await viewDetail(this.currentViewId);

        let viewColumns = viewDetailRes.data.showColumns;
        let viewFields = viewDetailRes.data.showFields;

        this.viewActions = (viewDetailRes.data.actions || "").split(",");
        this.viewColumns = viewColumns.filter((x) => !x.hide);
        this.viewRange = viewDetailRes.data.viewRange;
        this.viewStyle = viewDetailRes.data.style;
        
        this.viewFields = viewFields
          .filter((x) => !x.hide)
          .map((x) => {
            return {
              ...x,
              name: x.field,
            };
          });
        this.viewHideFields = viewFields
          .filter((x) => x.hide || x.hide == undefined)
          .map((x) => {
            return {
              ...x,
              name: x.field,
            };
          });
        this.viewShowFields = viewFields
          .filter((x) => !x.hide || x.hide == undefined)
          .map((x) => {
            return {
              ...x,
              name: x.field,
            };
          });

        this.viewReadonlyFields = viewFields
          .filter((x) => x.editable == false || x.editable == undefined)
          .map((x) => {
            return {
              ...x,
              name: x.field,
            };
          });
        this.$nextTick(() => {
          if (this.$refs.fillGridTable)
            this.$refs.fillGridTable.setRowStyle({
              height:
                this.viewStyle.lineHeight == "high"
                  ? 50
                  : this.viewStyle.lineHeight == "mediumn"
                  ? 40
                  : this.viewStyle.lineHeight == "tight"
                  ? 30
                  : 40,
            });
        });
        this.getTableViewJson();
        resolve();
      });
    },
    getTableViewJson () {
      let formJson = deepClone(this.formJson);
      let showFields = this.viewColumns.map(x => { return { ...x, name: x.field } });
      if (showFields) {
        const showNames = showFields.map((x) => x.name);

        const findShowWidget = (widget) => {
          const newFormWidgetList = [];
          for (let i = 0; i < widget.widgetList.length; i++) {
            if (showNames.indexOf(widget.widgetList[i].options.name) >= 0) {
              newFormWidgetList.push(widget.widgetList[i]);
            }
            if (widget.widgetList[i].widgetList) {
              findShowWidget(widget.widgetList[i]);
            }
          }
          widget.widgetList = newFormWidgetList;
        }
        findShowWidget(formJson)
      }
      this.tableViewJson = formJson;
    },
    onViewFillClick (fillId, listType, row) {
        if (!fillId) {
          fillId = this.selectedDataRowKeys[0].fillId;
        }
        let formMode = 'view';
  
        unlLoginFillDetail(fillId, this.formId).then((response) => {
          if (response.code == 200) {
            this.fillId = response.data.fillId;
            const data = response.data.fillJson;
            const formFieldList = response.data.formFieldList;
  
            // this.$refs.dlgvFormViewer[this.listType == 'AUDIT' ? 'showView' : 'show']({
            this.$refs.dlgvFormViewer['show']({
              fillId,
              formId: this.formId,
              formJson: deepClone(this.formJson),
              formData: data,
              showHiddenWidgets: false, // this.listType != 'FILL' && this.listType != 'VIEW', /* 非个人填报列表显示隐藏字段 */
              hideFields: this.viewHideFields,
              readonlyFields: this.viewReadonlyFields,
              enableReadonly: false,// isAdmin, /* 管理列表启用只读字段 */
              agencyId: this.formDetail.agencyId,
              formDetail: this.formDetail,
              formMode,
              listType: this.listType,
              fillInfo: {
                fillUserName: row.fillUserName,
                fillUserAvatarUrl: row.fillUserAvatarUrl,
                fillUserId: response.data.userId,
                createTime: row.createTime,
                updateTime: row.updateTime,
              },
              callback: () => {
                this.$refs.dlgvFormViewer.disableForm();
              }
            });
  
          }
        })
      },
  },
};
