var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            color: "white",
            "z-index": "100000",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            "background-color": "rgba(0,0,0,0.25)",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "line-height": "100vw",
                width: "100vh",
                height: "100vw",
                display: "inline-block",
                color: "#333333",
              },
            },
            [_vm._v("签名已在PC端保存，如需签名请重新扫码")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }