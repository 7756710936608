<template>
<div>
  <div class="wrapper">
    <div class="linkText" @click="exportExcel">导出并下载EXCEL</div>
  </div>
  </div>
</template>
<script>

import { getToken, setToken } from '@/utils/auth'
import { fillDataDownload } from '@/api/system/form'
export default {
  data() {
    return {
      param: {}
    }
  },
  created() {

    let url = this.$route.params.param

    let localToken = getToken();
    this.param = JSON.parse(window.atob(url))

    console.log('获取到参数',this.param);

    if (!localToken) {
      localToken = this.param.token;
      setToken(localToken)
    }
  },
  methods: {
    exportExcel: function () {
      const formId = this.param.formId
      const loading =  this.$loading({
        fullscreen: true,
        lock: false,
        text: '导出中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.8)'
      })
      // const showImg = 'show'
      fillDataDownload({
        ...this.param
      }).then(
        (data) => {
          if (!data) {
            return false
          }
          const blob = new Blob([data])
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = `${decodeURIComponent(this.param.formName)}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          loading.close()
        },
        (error) => {
          this.$message.error('下载异常，请稍后再试')
          loading.close()
        }
      )
    }
  }
}
</script>
<style scoped>
.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.linkText {
  height: 30px;
  line-height: 30px;
  color: #0279f8;
}
</style>
