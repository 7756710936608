
import { showPicturePreview, deleleteFile} from "@/utils/formUtil";
import { getAgency, getAgencyUser, agencyUserList} from '@/api/system/agency'

import { aliUploader } from '@/utils/oss'
export default {
  data() {
    return {
      isShow: false,
      isShowInside: false,
      isEdit: false,
      fillId: "",
      formJson: {},
      formData: {},
    };
  },
  async created() {
    const userToken = this.cookie.get("User-Token");
    const sessionStorageUser= getSessionStorageObj("users")
    this.optionData = {
      userToken: userToken,
      apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
      formId: this.formId,
    };
    this.optionData.formId = this.formId; 
    this.optionData.formDataSelectHandler = this.$parent.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler = this.$parent.showDataAutoSelect;
    this.optionData.formDataSelectMultiFillHandler = this.$parent.showDataMultiSelect;
    this.optionData.isUserAdmin = sessionStorageUser.type > 1;
    //打开嵌套表单
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formPicturePreviewHandler = showPicturePreview
    this.optionData.formCreateUser=sessionStorageUser
    this.optionData.formDataCardRender = this.dataCardRender();
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW
  },
  methods: {
    show(fillId, formId, formJson, formData, showHiddenWidgets,enableReadonly) {
      this._show(
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        "enableForm",
        enableReadonly

      );
    },
    showView(fillId, formId, formJson, formData, showHiddenWidgets,enableReadonly) {
      this._show(
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        "disableForm",
        enableReadonly
      );
    },
    _show(fillId, formId, formJson, formData, showHiddenWidgets, enableForm,enableReadonly) {
      console.log("显示隐藏字段", showHiddenWidgets);
      const _this = this;
      _this.isShowHiddenWidgets = showHiddenWidgets;
      _this.formJson = formJson;
      _this.formData = formData;
      _this.formId = formId;
      _this.fillId = fillId;
      _this.isEdit = false;
      _this.isShow = true;

      // this.optionData.aliOSSUpload =aliOSSUpload(true,{
      //   formId:formId||'formId',
      //   fillId:fillId||'fillId',
      //   agencyId:'agencyId',
      //   userId:sessionStorageUser.userId||'userId',
      // })
      this.optionData.loadAgencyUserExtendFieldsHandler = getAgency
      this.optionData.loadAgencyUserListHandler = agencyUserList
      this.optionData.loadAgencyUserHandler = getAgencyUser
      this.optionData.aliOSSUpload =aliUploader
      this.optionData.aliOSSUploadConfig ={  //OK
        formId:'formId',
        agencyId:0,
        userId:sessionStorageUser.userId||'userId',
        uploadPath:'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
      }
      this.optionData.fileDelete = this.fileDelete

      _this.$nextTick(() => {
        _this.$refs.vformRender.setFormJson(formJson);
        _this.$refs.vformRender.setFormData(formData,_this.formId,_this.fillId,true);

        setTimeout(() => {
          if(enableReadonly){
            _this.$refs.vformRender[enableForm](_this.optionData.isUserAdmin);
          }else{
            _this.$refs.vformRender[enableForm]();
          }
          _this.$refs.vformRender.setIsDataEdit(false);
        }, 50);
        if (showHiddenWidgets) _this.showHiddenWidgets();
        else _this.hideHiddenWidgets();
      });
    },
    showDataView(fillSelectOption, fillData, callback) {
      //同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadFormDetail = new Promise((resolve, reject) => {
        formDetail(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });

      Promise.all([loadField, loadData,loadFormDetail]).then((values) => {
        this.showInside(
          fillData.fillId,
          fillSelectOption.formId,
          values[0],
          values[1],
          this.isShowHiddenWidgets,
          fillSelectOption.fields,
          values[2]
        );
      });
    },
    dataCardRender() {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    disableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.disableForm();
      });
    },
    enableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.enableForm(this.optionData.isUserAdmin);
      });
    },
    showHiddenWidgets() {
      this.$refs.vformRender.showHiddenWidgets();
    },
    hideHiddenWidgets() {
      this.$refs.vformRender.hideHiddenWidgets();
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    save(callback) {
      const _this = this;
      _this.$refs.vformRender
        .getFormData()
        .then((fillJson) => {
          editFill({
            fillId: _this.fillId,
            fillJson: fillJson,
          }).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("提交成功");
              _this.isShow = false;
              _this.$emit("on-save");
              if (callback) callback();
            }
          });
        })
        .catch((err) => {
          console.log(err);
          _this.msgError(err);
        });
    },
    close() {
        this.isShow = false;
    },
    showUserSelect(selectedUserList,callback){
      this.$refs.userSelect.showDialog(selectedUserList);
      this.userSelectCallback=callback;
    },
    fileDelete(file){
      deleleteFile(file.fileId).then(res=>{

      })
      if(file.thumbFileId){
        deleleteFile(file.thumbFileId).then(res=>{
        })
      }
    },
  },
};
